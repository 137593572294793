import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';

interface IFreqAmpOffset{
    channelPrefix: string;
    useFunction: boolean;
}
export const FreqAmpOffset: React.FC<IFreqAmpOffset> = ({channelPrefix, ...props}) => {
    return (<>
        <Vex.Line nobreak={!props.useFunction}>
            <Vex.Channel type="vector" var="freq" string={`${channelPrefix}frequency`}></Vex.Channel>
        </Vex.Line>
        <Vex.Line>
            <Vex.Channel type="float" var="amp" string={`${channelPrefix}amplitude`}></Vex.Channel>
        </Vex.Line>
        <Vex.Line>
            <Vex.Channel type="vector" var="offset" string={`${channelPrefix}offset`}></Vex.Channel>
        </Vex.Line>
    </>
    )
};
