import React, { Dispatch, SetStateAction, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { transform } from 'typescript';
import { useApp } from '../App';
import { ReactToggle } from '../Chapter/Chapter';
import { Link } from '../Chapter/Link/Link';
import { Gallery } from '../Gallery/Gallery';



const StyledPhotos = styled.div<{ darkTheme: boolean; }>`
    & .section {
      margin: 1.5vw auto;
      padding: 0px 1.5vw;
      max-width: 1280px;
      width: auto;
    }    
`



const Photos: React.FC = (props) => {
    const { darkTheme, setDarkTheme } = useApp();
    return (
        <StyledPhotos  {...{ darkTheme }}>
            {/* <div className="section" >
                <h2 className="item-title">Photography</h2>
                <p>
                    These were taken on the road from Nashville to San Mateo, CA in September 2020. Shot with a Canon EOS Rebel T3i and color adjusted in Photoshop
      </p>
            </div> */}
            <Gallery />
        </StyledPhotos>
    )
};

export default Photos;
