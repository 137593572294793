import React from 'react';
import { Vex } from './VexBlock';

type type = "int" | "float" | "vector" | "vector2" | "vector4" | "string";

export interface IVexChannel {
    type: type;
    var: string;
    string: string
}

export const VexChannel: React.FC<IVexChannel> = (props: IVexChannel) => {

    const channelFunctions: Record<type, string> = {
        int: "chi",
        float: "chf",
        vector: "chv",
        vector2: "chu",
        vector4: "chp",
        string: "chs"
    }

    return (<>
        <>
            <Vex.Code type>{`${props.type} `}</Vex.Code>
            <Vex.Code>{`${props.var} = `}</Vex.Code>
            <Vex.Code function >{`${channelFunctions[props.type]}`}</Vex.Code>
            <Vex.Code>{`(`}</Vex.Code>
            <Vex.Code string>{`"${props.string}"`}</Vex.Code>
            <Vex.Code>{`);`}</Vex.Code>
        </>
    </>
    )
};


