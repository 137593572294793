import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';

interface ITurbRoughAttenProps {
    fBm: boolean;
    turbulent: boolean;
    channelPrefix: string;
}

export const TurbRoughAttenChannels: React.FC<ITurbRoughAttenProps> = ({channelPrefix, ...props}: ITurbRoughAttenProps) => {
    return (<>
        <Vex.Line space>
            <Vex.Channel type={props.turbulent ? `int`: `float`} var="turbulence" string={`${channelPrefix}turbulence`}></Vex.Channel>
        </Vex.Line>
        <Vex.Line>
            <Vex.Channel type="float" var="rough" string={`${channelPrefix}roughness`}></Vex.Channel>
            <Vex.Code comment>&nbsp;&nbsp;{` // default is 0.5`}</Vex.Code>
        </Vex.Line>
        {props.fBm && (
            <Vex.Line>
                <Vex.Channel type="float" var="lacun" string={`${channelPrefix}lacunarity`}></Vex.Channel>
                <Vex.Code comment>&nbsp;{` // default is 2`}</Vex.Code>
            </Vex.Line>
        )}
        <Vex.Line>
            <Vex.Channel type="float" var="atten" string={`${channelPrefix}attenuation`}></Vex.Channel>
            <Vex.Code comment>{` // default is 1`}</Vex.Code>
        </Vex.Line>
    </>
    )
};
export const TurbRoughAttenArguments = <Vex.Code>{`, turbulence, rough, atten`}</Vex.Code>
