import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { useApp } from '../App';
import { iconName } from './Houdini';
import { icon } from './Section';

import python from "./../TypeIcons/python.svg"
import wrangle from "./../TypeIcons/wrangle.svg"
import hda from "./../TypeIcons/box.svg"
// import python from "./TypeIcons/python.svg"
// import wrangle from "./TypeIcons/wrangle.svg"


const StyledArticle = styled.article<{ darkTheme: boolean; iconColor?: string; }>`
    & header {
        
        & .title {
            display: flex;
            align-items: flex-start;
            margin-bottom: 0px; 


            & .icon {
                // background: ${props => props.iconColor};
                border-radius: 100%;
                height: 28px;
                width: 28px;
                min-height: 28px;
                min-width: 28px;
                margin: 0px 0px 0px 12px;
                & img {
                    height: 64%;
                    width: 64%;
                    margin: 18%;
                }
            }
            & h3 {
                margin-left: 0;
                margin-right: 0;
                // margin-top: 3.5rem;
                margin-top: 0px;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
                font-family: Montserrat, sans-serif;
                font-size: 1.75rem;
                margin-bottom: 0.4375rem;
                font-weight: 900;
                text-rendering: optimizeLegibility;
                line-height: 1.1;
                & a {
                    color: ${props => props.darkTheme ? "#31aae2" : "#1e70bf"};
                    box-shadow: none;
                    &:hover {
                        color: ${props => props.darkTheme ? "#40c2ff" : "#1e70bf"}; 
                    }
                }
            }
        }


        //date
        & small {
            font-size: 80%;
        }


    }

`

type availableIcons = "python" | "vex" | "hda"

interface IArticleLink {
    title: string;
    description: string;
    date: string;
    url: string;
    emoji: string;
    icons?: availableIcons[];
}


export const ArticleLink: React.FC<IArticleLink> = ({ title, date, description, url, emoji, ...props }: IArticleLink) => {
    const { darkTheme, setDarkTheme } = useApp();

    const icons: Record<availableIcons, icon> = {
        "python": {
            icon: python,
            color: "#3771a1"
        },
        "vex": {
            icon: wrangle,
            color: "#EE90E6"
        },
        "hda": {
            icon: hda,
            color: '#c69e81'
        },

    };
    const getIconColor: (icon: availableIcons) => string = (icon) => {
        return icons[icon].color;
    };

    return (
        <StyledArticle darkTheme={darkTheme} >
            <header>
                <div className="title">
                    <h3>
                        <Link to={url}>{title}</Link>
                        {/* <a rel="bookmark" href="/goodbye-clean-code/">Moving Points Along Normals</a> */}
                    </h3>
                    {(!!props.icons) && props.icons.map(_ => <>
                        <div className="icon" style={{ backgroundColor: getIconColor(_) }}>
                            {<img src={icons[_].icon} />}
                        </div>
                    </>)}
                </div>

                {/* <small>{`${date} • ${emoji}`}</small> */}
                {/* <small>{`${date}`}</small> */}
            </header>
            <p>{description}</p>
        </StyledArticle>
    )
};
