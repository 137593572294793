import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useReducer } from 'react';
import styled, { CSSObject } from 'styled-components'
import Toggle from 'react-toggle'
import houdiniLogo from "./../sideFX-square.svg"
import sun from './../sun.png'
import moon from './../moon.png'
import { Img } from '../Images/Images';
import { useApp } from '../App';
import { Link } from 'react-router-dom';
import { iconName } from '../Houdini/Houdini';
import { propss } from '../CodeBlock/CodeBlock';


interface IReactToggle {
  defaultChecked: boolean;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  styles?: CSSObject;
  checked: boolean;

}

const StyledToggleWrap = styled.div<Pick<IReactToggle, "styles">>`
  ${props => props.styles};
  & .react-toggle {
    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  & .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  & .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  
  & .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    // background-color: #4D4D4D;
    background-color: #0f1114;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;

    & img.sun {
        width: 21px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -50%); 
    }
    & img.moon {
        width: 27px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-60%, -50%); 
    }
  }
  
  & .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }
  
  & .react-toggle--checked .react-toggle-track {
    // background-color: #19AB27;
    background-color: #0f1114;
  }
  
  & .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    // background-color: #128D15;
    background-color: #0f1114;
  }
  
  & .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  & .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  & .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  & .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  & .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-color: #0f1114;
    border-radius: 50%;
    background-color: #FAFAFA;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  & .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    // border-color: #19AB27;
    // border-color: #31aae2;
  }
  
  & .react-toggle--focus .react-toggle-thumb {
    // -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    // -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    // box-shadow: 0px 0px 2px 3px #0099E0;
    -webkit-box-shadow: 0px 0px 3px 2px #31aae2;
    -moz-box-shadow: 0px 0px 3px 2px #31aae2;
    box-shadow: 0px 0px 2px 3px #31aae2;
    border-color: #31aae2;
  }
  
  & .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    // -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    // -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    // box-shadow: 0px 0px 5px 5px #0099E0;
    -webkit-box-shadow: 0px 0px 5px 5px #31aae2;
    -moz-box-shadow: 0px 0px 5px 5px #31aae2;
    box-shadow: 0px 0px 5px 5px #31aae2;
    border-color: #31aae2;
  }
      
`




export const ReactToggle: React.FC<IReactToggle> = ({ defaultChecked, onChange, styles, checked }: IReactToggle) => {
  return (
    <StyledToggleWrap styles={styles}>
      <Toggle {...{ defaultChecked, onChange, checked }} icons={{
        unchecked: <img className="sun" src={sun} alt="" />,
        checked: <img className="moon" src={moon} alt="" />,
      }} />
    </StyledToggleWrap>
  )
};



const StyledPage = styled.div<{ darkTheme: boolean; }>`
    min-height: 100vh;
    background: ${props => props.darkTheme ? "#282c35" : "white"};
    color: ${props => props.darkTheme ? "white" : "#222222"};
    transition: color 0.2s ease-out, background 0.2s ease-out;
    & > div {
        max-width: 42rem;
        padding: 42px 21px;
        margin-left: auto;
        margin-right: auto;
        & header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2.625rem;
            & h3 a {
                font-size: 1.4427rem;
                font-family: Montserrat, sans-serif;
                margin-top: 0px;
                margin-bottom: 0px;
                height: 42px;
                line-height: 2.625rem;
                color: #d15f6f;
                // color: #748c42;
                color: #31aae2;
            }
        }
        & footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 2.625rem;
          margin-top: 56px;
          & h3 a {
              font-size: 1.4427rem;
              font-family: Montserrat, sans-serif;
              margin-top: 0px;
              margin-bottom: 0px;
              height: 42px;
              line-height: 2.625rem;
              color: #d15f6f;
              // color: #748c42;
              color: #31aae2;
          }
      }
    }

`

// interface IPageContext {
//     darkTheme: boolean;
//     // setActiveTab: (label: string) => void
// }
// export const PageContext = React.createContext<IPageContext | undefined>(
//     undefined
// )
// export const usePage = (): IPageContext => {
//     const context = React.useContext(PageContext)
//     if (!context) {
//         throw new Error("This component must be used within a <Page> component.")
//     }
//     return context
// }

interface IPage {
  //defaultChecked: boolean;
  //onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  topic?: iconName;
  children?: any;
}

export const Page: React.FC<IPage> = ({ topic, ...props }: IPage) => {
  const { darkTheme, setDarkTheme } = useApp();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <StyledPage {...{ darkTheme }}>
      <div>
        <header>
          <h3><Link to={`/houdini`}>Houdini Notes</Link></h3>
          <ReactToggle checked={darkTheme} defaultChecked={darkTheme} onChange={() => setDarkTheme(_ => !_)} />
        </header>
        {/* <h1>{props.title}</h1> */}
        {props.children}
        <footer>
          <h3><Link to={`/houdini`}> ← Back to Houdini Notes</Link></h3>
        </footer>
      </div>
    </StyledPage>
  )
};

const StyledLessonHeader = styled.h1`
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.75rem;
  color: inherit;
  font-family: Montserrat,sans-serif;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  font-size: 2.5rem;
  line-height: 1.1;
`

interface ILesson {
  title: string;
  children: any;
}

export const Lesson: React.FC<ILesson> = (props: ILesson) => {
  return (
    <div>
      <StyledLessonHeader>{props.title}</StyledLessonHeader>
      {props.children}
    </div>
  )
};


// const StyledSection = styled.div`
//     & h2 {
//         font-family: 'Merriweather','Georgia',serif;
//         margin-block-end: 20px;
//         margin-block-start: 20px;
//         & img.houdini_logo {
//             height: 20px;
//             margin: 0;
//             padding: 0px 12px 0px 0px;
//         }
//     }
//     & hr {
//         margin-left: 0;
//         margin-right: 0;
//         margin-top: 0;
//         padding-bottom: 0;
//         padding-left: 0;
//         padding-right: 0;
//         padding-top: 0;
//         margin-bottom: calc(1.75rem - 1px);
//         background: #ccc;
//         border: none;
//         height: 1px;
//     }
//     & .title {
//         display: flex;
//         align-items: flex-end;
//     }

// `
// interface ISection {
//     title: string;
//     children: any;
// }

// export const Section: React.FC<ISection> = (props: ISection) => {
//     return (
//         <StyledSection>
//             <div className="title">
//                 <h2>
//                     <img className="houdini_logo" src={houdiniLogo} alt="" />
//                     {props.title}
//                 </h2>
//             </div>
//             {props.children}
//             <hr />
//         </StyledSection>
//     )
// };



const StyledWords = styled.p`
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    font-family: 'Merriweather','Georgia',serif;
    font-weight: 400;
    font-kerning: normal;
    font-size: 16px;
    & sup {
      vertical-align:super;
      font-weight: bold;
    }
`
interface IWordsProps {
  strong?: boolean;
}

export const Words: React.FC<IWordsProps> = (props) => {
  return (
    <StyledWords>
      {props.strong ? <strong>{props.children}</strong> : props.children}
    </StyledWords>
  )
};

const StyledOrderedList = styled.ol`
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    list-style-position: outside;
    list-style-image: none;
    & li {
        margin-bottom: calc(1.75rem / 2);
    }
    @media (max-width: 768px) {
        padding-left: 16px;
      }
`

export const OrderedList: React.FC = (props) => {
  return (
    <StyledOrderedList>
      {props.children}
    </StyledOrderedList>
  )
};




// const Span = styled.span<Pick<IColorSpan, "color">>`
//     color: ${props => props.color};
// `
// interface IColorSpan {
//     color: string;
//     children: any;
// }
// export const ColorSpan: React.FC<IColorSpan> = (props: IColorSpan) => {
//     return (
//         <Span {...props}>
//             {props.children}
//         </Span>
//     )
// }



// export const BreakSpan: React.FC = (props) => {
//     return (<>
//         <br />{props.children}
//     </>
//     )
// }


