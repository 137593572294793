import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { ParmTemplateGroup } from "./ParmTemplateGroup";
import { PythonEditorNode } from "./PythonEditorNode";
import { Parms } from "./Parms";
import { NodeCreation } from "./NodeCreation";
import { ListNodes } from "./ListNodes";
import { NodeConnection } from "./NodeConnection";
import { DisplayFlags } from "./DisplayFlags";
import { RecursiveChildren } from "./RecursiveChildren";
import { UI } from "./UI";
import { PythonJSON } from "./PythonJSON";

const base_url = "/houdini/python"

export const PythonRoutes = () => {
    return (<>
        <Route exact path={`${base_url}/pythoneditor`}>
            <PythonEditorNode />
        </Route>
        <Route exact path={`${base_url}/nodes`}>
            <NodeCreation />
        </Route>
        <Route exact path={`${base_url}/connect_nodes`}>
            <NodeConnection />
        </Route>
        <Route exact path={`${base_url}/display_flags`}>
            <DisplayFlags />
        </Route>
        <Route exact path={`${base_url}/parmtemplate`}>
            <ParmTemplateGroup />
        </Route>
        <Route exact path={`${base_url}/parms`}>
            <Parms />
        </Route>
        <Route exact path={`${base_url}/recursive_children`}>
            <RecursiveChildren />
        </Route>
        <Route exact path={`${base_url}/listnodes`}>
            <ListNodes />
        </Route>
        <Route exact path={`${base_url}/ui`}>
            <UI />
        </Route>
        <Route exact path={`${base_url}/json`}>
            <PythonJSON />
        </Route>
    </>
    )
};

export const PythonArticles = () => {
    return (<>
        <ArticleLink
            title="Python Editor Node"
            date="Jan 17, 2021"
            description="Create a Python Editor Node with Python"
            url={`${base_url}/pythoneditor`}
            emoji="🐓 || 🐣"
            icons={["python", "hda"]}
        />
        <ArticleLink
            title="Nodes"
            date="Feb 17, 2021"
            description="Create, Name, Color, Shape, Layout"
            url={`${base_url}/nodes`}
            emoji=""
        />
        <ArticleLink
            title="Connect Nodes"
            date="Feb 21, 2021"
            description="Connecting Nodes with Python"
            url={`${base_url}/connect_nodes`}
            emoji="🐍"
        />
        <ArticleLink
            title="Display Flags"
            date="Feb 21, 2021"
            description="Setting display flags in different contexts"
            url={`${base_url}/display_flags`}
            emoji="🐍"
        />
        <ArticleLink
            title="Parameters"
            date="Jan 16, 2021"
            description="hou.Parm class"
            url={`${base_url}/parms`}
            emoji="🐍"
        />
        <ArticleLink
            title="Edit Parameter Interface"
            date="Jan 16, 2021"
            description="hou.ParmTemplate class"
            url={`${base_url}/parmtemplate`}
            emoji="🐍"
        />
        <ArticleLink
            title="Recursively Access Sub Children"
            date="Jan 21, 2021"
            description="Recursively return sub children of nodes and ParmTemplate folders"
            url={`${base_url}/recursive_children`}
            emoji="🐍"
        />
        <ArticleLink
            title="Every Houdini Node"
            date="Jan 21, 2021"
            description="Get a JSON file of every houdini node using hython in command-line tools"
            url={`${base_url}/listnodes`}
            emoji="🐍"
        />
        <ArticleLink
            title="UI Elements"
            date="Jan 24, 2021"
            description="Display Message, Floating Panel"
            url={`${base_url}/ui`}
            emoji="🐍"
        />
        <ArticleLink
            title="JSON"
            date="Jan 25, 2021"
            description="JSON"
            url={`${base_url}/json`}
            emoji="🐍"
        />

    </>
    )
};