import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension, DistanceMetric, NoiseFunction } from '../NoiseConfig';

export interface ICellularVariables {
    inputDimension: dimension;
    noise: NoiseFunction;
    tabs?: number;
}

export const CellularVariables: React.FC<ICellularVariables> = ({ tabs, ...props }: ICellularVariables) => {
    return (<>
        { (() => {
            // worley
            if (props.noise == "Worley") {
                return <>
                    <Vex.Line {...{ tabs }}>
                        <Vex.Code type>{`int `}</Vex.Code>
                        <Vex.Code>{`seed; `}</Vex.Code>
                        <Vex.Code type>{`float `}</Vex.Code>
                        <Vex.Code>{`f1, f2, f3, f4;`}</Vex.Code>
                    </Vex.Line>
                </>
            }
            // vector
            else if (props.noise == "Voronoi") {
                return <>
                    <Vex.Line {...{ tabs }}>
                        <Vex.Code type>{`int `}</Vex.Code>
                        <Vex.Code>{`seed; `}</Vex.Code>
                        <Vex.Code type>{`float `}</Vex.Code>
                        <Vex.Code>{`f1, f2`}</Vex.Code>
                        {{
                            "1D": <>
                                <Vex.Code>{`, pos1, pos2;`}</Vex.Code>
                            </>,
                            "2D": <>
                                <Vex.Code>{`, pos1x, pos1y, pos2x, pos2y;`}</Vex.Code>
                            </>,
                            "3D": <>
                                <Vex.Code>{`; `}</Vex.Code>
                                <Vex.Code type>{`vector `}</Vex.Code>
                                <Vex.Code>{`pos1, pos2;`}</Vex.Code>
                            </>,
                            "4D": <>
                                <Vex.Code>{`; `}</Vex.Code>
                                <Vex.Code type>{`vector4 `}</Vex.Code>
                                <Vex.Code>{`pos1, pos2;`}</Vex.Code>
                            </>,
                        }[props.inputDimension]}
                    </Vex.Line>
                </>
            }
            else return <></>
        })()}
    </>)
}
