import React, { useState, useEffect, useRef } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Ref } from 'semantic-ui-react';
import styled from 'styled-components'
import { useApp } from '../App';
import { Route } from "react-router-dom";



const StyledHoudiniSplash = styled.div<{ darkTheme: boolean; }>`
    height: 100vh;
    background: ${props => props.darkTheme ? "#282c35" : "white"};
    color: ${props => props.darkTheme ? "white" : "#222222"};
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        max-width: 42rem;
        padding: 42px 21px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        & > header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 64px;
            & > .wrap {
                display: flex;
                align-items: flex-end;
                & h1 {
                    //font-size: 1.4427rem;
                    font-family: Montserrat, sans-serif;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    line-height: 2.625rem;
                    //color: #31aae2;
    
                    //font-size: 1.98818rem;
                    font-size: 32px;
                }
                & img.houdini {
                    margin-right: 16px;
                    width: 48px;
                    height: 48px;
                    align-self: center;
                }
            }

        }
        & .button {
            & a {
                color: ${props => props.darkTheme ? "white" : "black"};

                border: 2px solid ${props => props.darkTheme ? "white" : "black"};
                outline: none;
                color: ${props => props.darkTheme ? "white" : "black"};
                padding: 8px 48px;


                font-size: 18px;
                font-family: Montserrat, sans-serif;

                line-height: 2.625rem;

                font-style: normal;
                letter-spacing: .14em;
                width: fit-content;
                margin: auto;
            }
        }
        & > footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2.625rem;
            margin-top: 56px;
            & h3 a {
                font-size: 1.4427rem;
                font-family: Montserrat, sans-serif;
                margin: auto;
                height: 42px;
                line-height: 2.625rem;
                color: #d15f6f;
                // color: #748c42;
                color: #31aae2;
            }
        }

    }

`





const HoudiniSplash: React.FC = (props) => {
    const { darkTheme, setDarkTheme } = useApp();
    return (
        <StyledHoudiniSplash {...{ darkTheme }}>
            <div>
                <header>
                    <div className="wrap">
                        <img className="houdini" src={require("./../TypeIcons/swirl.svg")} alt="" />
                        <h1>Houdini Notes</h1>
                    </div>
                </header>
                <div className="button">
                    <Link to="/houdini">Enter</Link>
                </div>

                {/* <footer>
                    <h3><Link to={`/`}> ← Back to keatonwilliamson.com</Link></h3>
                </footer> */}
            </div>
        </StyledHoudiniSplash>
    )
};

export default withRouter(HoudiniSplash);







