import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';
import { useApp } from '../App';
import { LargeToggle } from '../Toggle/LargeToggle';



const StyledMobileBar = styled.div<{ darkTheme: boolean; navOpen: boolean; }>`
    @media only screen and (min-width: 769px) {
      display: none !important;
    }


    background: ${props => props.darkTheme ? "#2a2b37" : "white"};
    transition: background 0.2s ease-out;
    color: ${props => props.darkTheme ? "white" : "black"};
    z-index: 9999;
    position: sticky;
    top: 0px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .mobile-branding-wrapper {
      flex-grow: 0;
      align-self: center;
      padding: 3vw;
      & .mobile-site-title {
        font-family: futura-pt;
        font-weight: 300;
        font-style: normal;
        font-size: 17px;
        letter-spacing: .08em;
        text-transform: uppercase;
      }
    }
    & label.mobile-nav-toggle-label {
      padding: 3vw;
      display: block;
      cursor: pointer;
      & .hamburger-icon, .close-icon {
        width: 24px;
        height: 18px;
        stroke: ${props => props.darkTheme ? "white" : "dimgrey"};
      }
      & .close-icon {
        display: ${props => props.navOpen ? "block" : "none"};
    }
    & .hamburger-icon {
          display: ${props => props.navOpen ? "none" : "block"};

      }

    }
`
const StyledOverlayNav = styled.div<{ darkTheme: boolean; navOpen: boolean; }>`
    @media only screen and (min-width: 769px) {
        display: none;
    }
    background: ${props => props.darkTheme ? "#2a2b37" : "white"};
    transition: background 0.2s ease-out;
    position: ${props => props.navOpen ? "sticky" : "absolute"};
    z-index: 9998;
    box-sizing: border-box;
    min-height: 100vh;
    text-align: center;
    overflow-x: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0px;

    @media only screen and (max-width: 768px) {
        ${props => props.navOpen ? `
        //transition: left 0ms linear 100ms;
        width: 100%;
        overflow-y: scroll;
        visibility: visible;
        pointer-events: auto;
        left: 0;
        opacity: 1;
        width: 100%;
        ` : ``}
    }
    ${props => !props.navOpen ? `
    overflow-y: hidden;
    pointer-events: none;
    width: 0;
    ` : ``}

    & nav {

        font-family: futura-pt;
        font-weight: 300;
        font-style: normal;
        font-size: 22px;
        letter-spacing: .12em;
        line-height: 1.8em;
        text-transform: none;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        & a {
          color: ${props => props.darkTheme ? "white" : "black"};
          transition: color 0.2s ease-out;
        }
        &>div {
          display: block;
          margin: 0;
          cursor: pointer;
          transition: color 140ms cubic-bezier(.33,0,.2,1);
          &:hover {
            color: white;
          }
        }
        &>div+div {
        //   margin-left: 47px;
        }
      }
`

interface IMobileBarProps {
    navOpen: boolean;
    setNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
}


const MobileBar: React.FC<IMobileBarProps> = ({ navOpen, setNavOpen, ...props }) => {
    const { darkTheme, setDarkTheme } = useApp();
    // const [navOpen, setNavOpen] = useState<boolean>(false);
    const handleNavClick = () => {
        setNavOpen(false);
    }
    return (<>
        <StyledMobileBar navOpen={navOpen} {...{ darkTheme }}>
            <div className="mobile-branding-wrapper">
                <div className="mobile-site-title">Keaton Williamson</div>
            </div>
            <label className="mobile-nav-toggle-label" onClick={() => setNavOpen(_ => !_)}>
                <svg className="hamburger-icon" viewBox="0 0 24 18">
                    <g className="svg-icon">
                        <line fill="none" stroke-miterlimit="10" x1="0" y1="2" x2="24" y2="2"></line>
                        <line fill="none" stroke-miterlimit="10" x1="0" y1="9" x2="24" y2="9"></line>
                        <line fill="none" stroke-miterlimit="10" x1="0" y1="16" x2="24" y2="16"></line>
                    </g>
                </svg>
                <svg className="close-icon" viewBox="0 0 16 16">
                    <g className="svg-icon">
                        <line fill="none" stroke-miterlimit="10" x1="1.4" y1="1.4" x2="14.4" y2="14.4"></line>
                        <line fill="none" stroke-miterlimit="10" x1="1.4" y1="14.4" x2="14.4" y2="1.4"></line>
                    </g>
                </svg>
            </label>
        </StyledMobileBar>
        <StyledOverlayNav navOpen={navOpen} {...{ darkTheme }}>
            <nav>

                <div >
                    <Link id="apps" onClick={handleNavClick} to="/home/apps">Apps</Link>
                </div>
                <div>
                    <Link id="effects" onClick={handleNavClick} to="/home/effects">Graphics</Link>
                </div>
                <div>
                    <Link id="photography" onClick={handleNavClick} to="/home/photography">Photos</Link>
                </div>
                <div>
                    <Link id="photography" onClick={handleNavClick} to="/houdini">↗Houdini</Link>
                    {/* <a href="/houdini">Houdini↗</a> */}
                </div>
            </nav>
            <LargeToggle checked={darkTheme} styles={{ padding: "38px 0px" }} defaultChecked={darkTheme} onChange={() => setDarkTheme(_ => !_)} />
        </StyledOverlayNav>
    </>
    )
};

// export default withRouter(MobileBar);
export default MobileBar;
