import React from 'react';
import { Vex } from './VexBlock';

type dimension = "1D" | "2D" | "3D" | "4D";

export interface IVexTurbulenceLoop {
    nobreak?: boolean;
    outputDimension: dimension;
    children: any;
}

interface IOutputLookupItem {
    type: string,
    nval: string,
    weight: string;
    abbrevType: string
}

export const VexTurbulenceLoop: React.FC<IVexTurbulenceLoop> = ({ ...props}: IVexTurbulenceLoop) => {

    const outputLookup: Record<dimension, IOutputLookupItem> = {
        "1D": {
            type: "float",
            nval: "0",
            weight: "1",
            abbrevType: "f"

        },
        "2D": {
            type: "",
            nval: "",
            weight: "",
            abbrevType: ""

        },
        "3D": {
            type: "vector",
            nval: "{0, 0, 0}",
            weight: "1",
            abbrevType: "v"

        },
        "4D": {
            type: "",
            nval: "",
            weight: "",
            abbrevType: ""

        }

    }
    return (<>

        <Vex.Line space>
            <Vex.Code>{`turbulence += 1.0;`}</Vex.Code>
            <Vex.Code comment>{` // run at least once`}</Vex.Code>
        </Vex.Line>
        <Vex.Line>
            <Vex.Code type>{`int `}</Vex.Code>
            <Vex.Code>{`octaves = `}</Vex.Code>
            <Vex.Code function>{`min`}</Vex.Code>
            <Vex.Code>{`(`}</Vex.Code>
            <Vex.Code type>{`int`}</Vex.Code>
            <Vex.Code>{`(`}</Vex.Code>
            <Vex.Code function>{`ceil`}</Vex.Code>
            <Vex.Code>{`(turbulence)), 16);`}</Vex.Code>
            <Vex.Code comment>{` // max octaves 16`}</Vex.Code>
        </Vex.Line>
        <Vex.Line>
            <Vex.Code type >{`${outputLookup[props.outputDimension].type} `}</Vex.Code>
            <Vex.Code>{`nval = `}</Vex.Code>
            <Vex.Code>{`${outputLookup[props.outputDimension].nval};`}</Vex.Code>
        </Vex.Line>
        <Vex.Line>
            <Vex.Code type >{`float `}</Vex.Code>
            <Vex.Code>{`weight = 1;`}</Vex.Code>
        </Vex.Line>
        <Vex.Line>
            <Vex.Code type >{`float `}</Vex.Code>
            <Vex.Code>{`max_amp = 0;`}</Vex.Code>
        </Vex.Line>


        <Vex.Line space>
            <Vex.Code comment>{`// fBm`}</Vex.Code>
        </Vex.Line>

        <Vex.Loop length={`octaves`}>

            {props.children}




            <Vex.Line space tabs={1}>
                <Vex.Code comment>{`// fractional part of the last octave - equal to 1 on other octaves`}</Vex.Code>
            </Vex.Line>
            <Vex.Line tabs={1}>
                <Vex.Code type >{`float `}</Vex.Code>
                <Vex.Code>{`remainder = `}</Vex.Code>
                <Vex.Code function>{`min`}</Vex.Code>
                <Vex.Code>{`(turbulence - i, 1);`}</Vex.Code>
            </Vex.Line>



            <Vex.Line space tabs={1}>
                <Vex.Code comment>{`// update values`}</Vex.Code>
            </Vex.Line>
            <Vex.Line tabs={1}>
                <Vex.Code>{`nval += _nval * remainder;`}</Vex.Code>
            </Vex.Line>


            {/* <Vex.Line tabs={1}>
                <Vex.Code>{`${prefix}max_amp += (`}</Vex.Code>
                <Vex.Code type>{`float`}</Vex.Code>
                <Vex.Code >{`)1/`}</Vex.Code>
                <Vex.Code function>{`pow`}</Vex.Code>
                <Vex.Code >{`(2, i);`}</Vex.Code>
            </Vex.Line> */}

            <Vex.Line tabs={1}>
                <Vex.Code>{`max_amp += weight * remainder;`}</Vex.Code>
            </Vex.Line>

            <Vex.Line tabs={1}>
                <Vex.Code>{`freq *= lacun;`}</Vex.Code>
            </Vex.Line>
            <Vex.Line tabs={1}>
                <Vex.Code>{`weight *= rough;`}</Vex.Code>
            </Vex.Line>


        </Vex.Loop>

















        <Vex.Line space>
            <Vex.Code>{`nval /= max_amp;`}</Vex.Code>
            {/* <Vex.Code comment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{` // normalization`}</Vex.Code> */}
            <Vex.Code comment>{` // normalization`}</Vex.Code>
        </Vex.Line>
        <Vex.Line>
            <Vex.Code>{`nval = `}</Vex.Code>
            <Vex.Code function>{`sign`}</Vex.Code>
            <Vex.Code>{`(nval) * `}</Vex.Code>
            <Vex.Code function>{`pow`}</Vex.Code>
            <Vex.Code>{`(`}</Vex.Code>
            <Vex.Code function>{`abs`}</Vex.Code>
            <Vex.Code>{`(nval), atten);`}</Vex.Code>
            <Vex.Code comment>{` // attenuation`}</Vex.Code>
        </Vex.Line>

        <Vex.Line>
            <Vex.Code>{`nval *= amp;`}</Vex.Code>
            {/* <Vex.Code comment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{` // amplitude`}</Vex.Code> */}
        </Vex.Line>
    </>
    )
};

