import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from 'styled-components';
import { useApp } from '../../App';
import { Connect, IConnectProps } from './Connect';
import { Create, ICreateProps } from './Create';

const NodeSelectedButton = styled.div<{ selected: boolean }>`
        width: 160px;
        position: absolute;
        right: 0px;
        top: 8px;
        color: #dcdcdc;
        user-select: none;
        cursor: pointer;
        border-radius: 6px;


        ${props => props.selected ? `
        {
            background: #4E4E4E;
            border: 1.5px solid black;
            padding: 2px 6px;
            box-shadow: 0 0 0 2px #FFD400;
        }
        ` : `
        {
            background: #303030;
            padding: 4px 8px;
            
        }
        `};
    }
`


const StyledPython = styled.div<IPythonProps>`
    position: relative;
    & div.ui.dropdown {
        width: 200px;
        position: absolute;
        right: 0px;
        color: #dcdcdc;
        background: red;
        & * {
            background: red;
        }
    }
    & pre {
        word-wrap: break-word !important;
        overflow-x: hidden !important;
        background: #1e1e1e !important;
        margin-right: -21px;
        margin-left: -21px;
        padding: 12px 0px !important;
        padding-top:  ${props => props.nodeSelected ? "18px" : "12px"} !important;
        border-radius: 12px;
    }
    & code.language-python {
        & > span {
            padding: 0px 12px;
            display: block;
        }
        ${props => props.highlighted?.map(number => `& > span:nth-child(${number}) {
            background-color: #022a4b;
            padding-left: 9px;
            border-left: 3px solid #31aae2;
        }`)};
    }
`

export type IPythonSnippet<T> = (props: T) => string;

type OperatorContext = "SOP" | "DOP"
interface IPythonProps {
    darkTheme?: boolean;
    children: any;
    highlighted?: number[];
    nodeSelected?: string;
    OPContext?: OperatorContext;
}


interface IPythonComposition {
    Create: IPythonSnippet<ICreateProps>
    Connect: IPythonSnippet<IConnectProps>
}



const Python: React.FC<IPythonProps> & IPythonComposition = ({ OPContext = "SOP", ...props }: IPythonProps) => {
    const { darkTheme, nodeSelected, setNodeSelected } = useApp();

    return (
        <StyledPython {...props} {...{ darkTheme }}>
            {!!props.nodeSelected && <NodeSelectedButton onClick={() => setNodeSelected(_ => !_)} selected={nodeSelected}>{nodeSelected ? `with ${OPContext} selected` : "from /obj"}</NodeSelectedButton>
            }
            <SyntaxHighlighter
                wrapLongLines
                useInlineStyles={true}
                language="python"
                style={vs2015}
                wrapLines={true}
            >
                {props.children}
            </SyntaxHighlighter>
        </StyledPython >

    )
};

Python.Create = Create;
Python.Connect = Connect;

export { Python };

// props.highlighted?.includes(lineNumber) ? "" : ""}