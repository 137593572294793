import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { useApp } from '../App';
import { Link } from '../Chapter/Link/Link';
import { CloudLoader } from './CloudLoader';
import { Frame } from './Frame';
import { ILandingPageContentProps } from './Landing';




const StyledEffects = styled.div<{ darkTheme: boolean; loaded: boolean; }>`
    ${props =>
        !props.loaded ?
            `
            &>* {
                display: none
            };
            opacity: 0;
            `
            :
            `
            opacity: 1;
            `}
    transition: opacity .78s;
    & .section {
      margin: 1.5vw auto;
      padding: 0px 1.5vw;
      max-width: 1280px;
      width: auto;
      &.padtop {
          padding-top: 8vw;
      }
      & video {
          width: 100%;
      }
    }   
`



const Landing: React.FC<ILandingPageContentProps> = (props) => {
    const { darkTheme, setDarkTheme } = useApp();
    const [loadedElements, setLoadedElements] = useState({
        grayScott: false,
        whitney: false,
    });

    useEffect(() => {
        props.setPageContentLoaded(false);
    }, []);

    useEffect(() => {
        if (Object.values(loadedElements).every(_ => _)) {
            props.setPageContentLoaded(true);
        }
    }, [loadedElements]);

    const handleMediaLoad = (evt: any) => {
        let id = evt.target.id;
        setLoadedElements(obj => ({ ...obj, [id]: true }))
        // console.log("loaded ", evt.target.id)
    }

    const loaded = Object.values(loadedElements).every(_ => _);
    return (<>
        <CloudLoader loaded={loaded} />
        <StyledEffects loaded={loaded} {...{ darkTheme }}>
            <div className="section" >
                <h2 className="item-title">Mt. Whitney</h2>
                <p>Work in progress. Made with Houdini, Nuke, <Link text="Elevation Data" url="https://www.keatonwilliamson.com/houdini/terrain/satellitedata" /></p>
                <video id="whitney" onCanPlay={handleMediaLoad} autoPlay loop={true} muted>
                    <source src={"https://houdini-stuff.s3.amazonaws.com/Effects/whitney_v001.mp4"} />
                </video>
            </div>
            <div className="section padtop" >
                <h2 className="item-title">Gray scott algorithm</h2>
                <p>Gray Scott Reaction-Diffusion Model. Made with VEX in Houdini.</p>
                <video id="grayScott" onCanPlay={handleMediaLoad} autoPlay loop={true} muted>
                    <source src={"https://houdini-stuff.s3.amazonaws.com/Effects/GrayScottVideo_Trim.mp4"} />
                </video>
            </div>
            {/* <div className="section padtop" >
                <h2 className="item-title">Inspired Sphere</h2>
                <p>Christian Schaffer inspired ..sphere?</p>
                <img style={{width: 256}} src="https://houdini-stuff.s3.amazonaws.com/Effects/pink-sphere.png" />
            </div> */}

            <div className="section padtop" >
                <h2 className="item-title">Loader gif</h2>
                <p>Loader gif made with Houdini, Nuke, Photoshop</p>
                {darkTheme ? <img src={require("./cubes_dark.gif")} /> :
                    <img src={require("./cubes_light.gif")} />}
            </div>
            <div className="section padtop" >
                <h2 className="item-title">Houdini Notes</h2>
                <p>
                    Notes I'm keeping as I learn Houdini. <Link nowrap text="Go to Full Site →" url="https://www.keatonwilliamson.com/houdini" />
                </p>
                <Frame src="https://keatonwilliamson.com/houdini/landing" frameId="houdini" />
            </div>
        </StyledEffects>
    </>)
};

export default Landing;
