import React from 'react';
import { Vex } from './VexBlock';

export interface IVexLoop {
    length: string;
    nobreak?: boolean;
    children: any;
}

export const VexLoop: React.FC<IVexLoop> = (props: IVexLoop) => {
    return (<>
        <Vex.Line nobreak={props.nobreak}>
            <Vex.Code type>{`for `}</Vex.Code>
            <Vex.Code>{`(`}</Vex.Code>
            <Vex.Code type>{`int `}</Vex.Code>
            <Vex.Code>{`i = 0; i < `}</Vex.Code>
            <Vex.Code>{`${props.length}`}</Vex.Code>
            <Vex.Code>{`;`}</Vex.Code>
            <Vex.Code>{` i++) {`}</Vex.Code>
        </Vex.Line>
        {props.children}
        <Vex.Line>
            <Vex.Code>{`};`}</Vex.Code>
        </Vex.Line>
    </>
    )
};


