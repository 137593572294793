import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Code, Tip } from '../../CodeBlock/CodeBlock';
import { CCode } from '../../CodeBlock/CCode';


export const HDA: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="HDA">
                <Words>The names HDA (Houdini Digital Asset) and OTL (Operator Type Library) are pretty much used interchangably.</Words>
                <Section title="Accessing HDA library" icon="houdini">
                    <Words>To access your own folder of HDAs you'll need to add the folder path to the <Code>HOUDINI_OTLSCAN_PATH</Code> environment variable.</Words>
                    <Words>The default path is <Code>@/otls</Code>. You'll want to keep this in addition to your own path. You can use <Code>{`&`}</Code> as a shorthand for the default path. Paths are separated by a semicolon.</Words>
                    <CCode>{`// houdini.env

HOUDINI_OTLSCAN_PATH = "C:\\Users\\my_path;&"`}</CCode>

                </Section>
                <Section title="Revert HDA Back To Subnet" icon="houdini" divider>
                    <Words>I'm currently in the process of making my own HDA library and one thing I'd like to be able to do is to revert the HDA back to a subnet. The reason I want to do this is because I may want to use the HDA inside of another HDA and I don't want to have a dependency like that. So the best solution I can think of is this -</Words>
                    <OrderedList>
                        <li>Build the tool in Subnet "Small".</li>
                        <li>Collapse Subnet "Small" into Subnet "Big"</li>
                        <li>Drag and drop Subnet "Small" into the parameter interface of Subnet "Big"</li>
                        <li>Create Digital Asset out of Subnet "Big"</li>    
                    </OrderedList>
                    <Words>That way I can develop the HDA as part of a library as usual, but if at any time I want to un-HDA-ify it I can click <Code>Actions → Extract Contents</Code>. The only problem is that all of the parameters will still be referencing the HDA (Subnet "Big") which is now deleted. To handle this here's some python code that can be executed with a button on Subnet "Small".</Words>
                    <Python>{`# in the button callback script
                    
exec('''
# delete channel references
for p in kwargs['node'].parms():   
    p.deleteAllKeyframes()    
        
# delete output node   
[child for child in kwargs['node'].parent().children() if child.type().name() == 'output'][0].destroy()

# remove button parm
ptg = kwargs['node'].parmTemplateGroup()
ptg.remove('un_hda_ify')
kwargs['node'].setParmTemplateGroup(ptg)

# extract contents of parent
kwargs['node'].parent().extractAndDelete()
                    
''')`}</Python>

                    <Words>Since the callback script is multi-line I can't figure out how to paste it in, so here's a script to make the button for you. With Subnet "Small" selected, run this code </Words>
                    <Python>{`
# selected node
node = hou.node(hou.selectedNodes()[-1].path())

# ParmTemplateGroup
ptg = node.parmTemplateGroup()

# button
button = hou.ButtonParmTemplate("extract", "Extract HDA and Delete Channel References", script_callback_language=hou.scriptLanguage.Python)
button.setScriptCallback("""exec('''

# delete channel references
for p in kwargs['node'].parms():   
    p.deleteAllKeyframes()    
        
# delete output node   
[child for child in kwargs['node'].parent().children() if child.type().name() == 'output'][0].destroy()

# remove button parm
ptg = kwargs['node'].parmTemplateGroup()
ptg.remove('un_hda_ify')
kwargs['node'].setParmTemplateGroup(ptg)

# extract contents of parent
kwargs['node'].parent().extractAndDelete()

''')""")

ptg.append(button)

# apply changes
node.setParmTemplateGroup(ptg)
`}
                    </Python>
                    <Tip><strong>Tip: </strong>To edit the parameter interface of an HDA go to <Code>Type Properties</Code></Tip>
                </Section>
            </Lesson>
        </Page >
    )
};

// '''
//     https://ikrima.github.io/houdini_additional_python_docs/toolutils.html
//     toolutils.createModuleFromSection(module_name, node_type, section_name)
// '''





//button.setScriptCallback("exec('''for p in kwargs['node'].parms(): p.deleteAllKeyframes()\nfor p in kwargs['node'].parms(): p.revertToDefaults()\n[child for child in kwargs['node'].parent().children() if child.type() == 'output'][0].destroy()\nkwargs['node'].parent().extractAndDelete()''')")
{/* <Section title="Save to Library" icon="houdini">
Embedded. for sharing files
</Section>
<Section title="Asset Manager" icon="houdini">
In asset manager if you delete the cardboard box "type " it'll actuawlly remove the otl from disk
</Section>
<Section title="Asset Manager" icon="houdini">
Actions -- change type. keep parameters

</Section>
<Section title="Env var" icon="houdini">
$HOUDINI_OTLSCAN_PATH

help

hconfig -H HOUDINI_OTLSCAN_PATH

@/otls

HOUDINI_OTLSCAN_PATH = "C:\blah; otherpath;@/otls"
HOUDINI_OTLSCAN_PATH = "C:\blah; otherpath;&"



</Section>
<Section title="Inputs" icon="houdini">
Is reference checkbox does dashed lines



</Section>
<Section title="Namespace" icon="houdini">
fxphd::tentacles_create_lines::2

mynodes::awesomenode::2

</Section>
<Section title="On Created Script" icon="houdini">
<Python>
    {`
kwargs['node'].setColor(hou.Color((1,0,0)))

name_template = '{scope}_{basename}_1'

node_type_name_components = kwargs['type'].nameComponents()
node_name_kwargs = {
'scope': node_type_name_components[1].upper(),
'basename': node_type_name_components[1],
}

node_name = name_template.format(**node_name_kwargs)

kwargs['node'].setName(node_name, unique_name=True)

`}
</Python>

</Section> */}