import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Code, Tip } from '../../CodeBlock/CodeBlock';
import { Link } from '../../Chapter/Link/Link';

export const NodeConnection: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="Connecting Nodes">

                <Section title="Methods" icon="houdini" divider >
                    <Words>There are three methods for connecting nodes, each with an optional <Code>output_index</Code> parameter, giving six different options:</Words>
                    <Python>{`
# first output → first input
${Python.Connect({
                        out: {
                            node: "source"
                        },
                        in: {
                            node: "target"
                        },
                        comment: false
                    })}  
                    
# first output → next input
${Python.Connect({
                        out: {
                            node: "source"
                        },
                        in: {
                            node: "target",
                            i: "next"
                        },
                        comment: false
                    })}  

# first output → fifth input
${Python.Connect({
                        out: {
                            node: "source"
                        },
                        in: {
                            node: "target",
                            i: 4
                        },
                        comment: false
                    })}  
                    `}

                    </Python>
                    <br />
                    <Words>Using optional <Code>output_index</Code> parameter:</Words>
                    <Python>{`
# thrid output → first input
${Python.Connect({
                        out: {
                            node: "source",
                            i: 2
                        },
                        in: {
                            node: "target",
                        },
                        comment: false
                    })} 

# thrid output → next input
${Python.Connect({
                        out: {
                            node: "source",
                            i: 2
                        },
                        in: {
                            node: "target",
                            i: "next"
                        },
                        comment: false
                    })}  

# thrid output → fifth input
${Python.Connect({
                        out: {
                            node: "source",
                            i: 2
                        },
                        in: {
                            node: "target",
                            i: 4
                        },
                        comment: false
                    })}  
                    `}

                    </Python>
                    <Tip><strong>setNextInput:</strong>. If a node has some ordered inputs followed by a multi-input connector, the unordered_only parameter can be used to force the input to connect to the unordered multi-input connection instead of any of the ordered input which may not be connected.</Tip>
                    <Words><Link text="hou.Node.setInput()" url="https://www.sidefx.com/docs/houdini/hom/hou/Node.html#setInput" /> ─ SideFX docs</Words>
                </Section>
                <Section title="Print Node Connections" icon="houdini" divider>
                    <Words>Helpful when finding input indicies on a principled shader because theres so many</Words>
                    <Python highlighted={[12]}>
                        {`
# reference a principled shader
principled_shader = hou.node('/mat/principledshader1')

# display list of connections to principled shader
print(principled_shader.inputConnections())
                            `}
                    </Python>
                    <Words><Link text="hou.Node.inputConnections()" url="https://www.sidefx.com/docs/houdini/hom/hou/Node.html#inputConnections" /> ─ SideFX docs</Words>
                </Section>
            </Lesson>
        </Page >
    )
};
