import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { Link } from '../../Chapter/Link/Link';
import { Code, Tip } from '../../CodeBlock/CodeBlock';
import { TableComponent } from '../../TableComponent/TableComponent';
import { Section } from '../Section';


export const Fields: React.FC = (props) => {
    return (
        <Page topic="pyro">
            <Lesson title="Pyro Fields">
                <Section title="Legacy Pyro Fields (Combustion Model)" divider>
                    <TableComponent
                        headers={["Field", "Description"]}
                        colA={["fuel",
                            "temperature",
                            "heat",
                            "burn",
                            "vel",
                            "density"
                        ]}
                        colB={["Ignited by temperature to produce flames (heat), smoke (density), increased temperature, and expansion (divergence)",
                            "Ignites fuel. Used in buoyancy calculations.",
                            "Visible renderable flames. Persists throughout frames",
                            "Instantaneous intermediate field created where fuel ignites and heat is created. Doesn't persist throughout frames. You could bypass the whole combustion model by just sourcing this",
                            "Additional source",
                            "Visible as smoke"
                        ]}
                    />
                    <Tip><strong>Docs: </strong> Prior to Houdini 18, pyro simulations involving combustion used a fuel field. In hot areas, the fuel would ignite, contributing to the instantaneous burn field. This burn field would in turn refresh heat, which captured the emissive parts of the smoke. Finally, outputs would be generated from the accumulated heat and instantaneous burn fields.</Tip>
                </Section>
                <Section title="Sparse Pyro Field Differences" divider>
                    <TableComponent
                        headers={["Field", "Difference from Legacy"]}
                        colA={["flame",
                            "temperature"]}
                        colB={["This is the main field and is unique to Sparse Pyro. It's sourced like burn and rendered like heat. The three possible outputs are: smoke (density), temperature, and expansion (divergence).",
                            "Used in buoyancy calculations only. No fuel to ignite."
                        ]}
                    />
                    <Words><strong>Unused fields in sparse pyro:</strong></Words>
                    <TableComponent
                        // headers={["Unused Field", "Saying it's unused"]}
                        colA={[
                            "fuel",
                            "burn",
                            "heat"]}
                        colB={[
                            "Doesn't do anything",
                            "I don't think it does anything. There is language in the documentation that suggests using a 'burn volume' (see below) but the target fields in that example are flame and divergence. Not the same as an actual 'burn field'",
                            "I don't think it does anything but who knows it's 2021 anything can happen",
                        ]}
                    />
                    <Tip><strong>Docs: </strong> The new recommended workflow requires the instantaneous burn to be generated in SOPs and brought in as part of sourcing. A good example of this is the Sparse Fireball shelf tool.
                    <ul>
                            <li>The burn volume is created as the source of the initial detonation.</li>
                            <li>This instantaneous burn is then merged with the flame and divergence fields of the object.</li>
                        </ul>
                        <br />
                    This approach has the advantage of predictability and more control. For instance, if you would like the explosive fuel to rise, you can animate the burn source with exactly the desired speed.</Tip>
                </Section>
                <Section title="Where to add fields" divider>
                    <Words><strong>SOP Level:</strong></Words>
                    <OrderedList>
                        <li>Start as point attributes using a pyro source node or other</li>
                        <li>Convert point attributes to volumes using a volume rasterize attributes node or a vdb from particles </li>
                    </OrderedList>
                    <Words><strong>DOP Level:</strong></Words>
                    <OrderedList>
                        <li>Volume Source: Under the Volumes tab in the Operations section add each volume. This is where the SOP volumes get converted to fields used in the simulation.</li>
                        <li>Smoke Object: Under Visualization check multi-field and uncheck everything else. Go to Multi-Field tab and .. 
                            <br />
                            <br />Under Smoke: put <Code>density</Code> in Density Field and Diffuse Field.
                            <br />
                            <br />Under Emission:
                             <br />(sparse) put <Code>flame or temperature</Code>* in Emission Field and <Code>temperature</Code> in Emission Color Field
                             <br />(legacy) put <Code>temperature</Code> in Emission Field and <Code>heat</Code> in Emission Color Field</li>
                        <br /><i>*Sparse bonfire and explosion presets use temperature as emission field, but in most cases this should be set to flame to ensure that the fiery parts of your simulation glow.</i>

                    </OrderedList>
                </Section>
                <Words><strong>References:</strong></Words>
                <Words><Link text="Pyro H12" url="https://vimeo.com/44619541" /> ─ Peter Quint Vimeo</Words>
                <Words><Link text="Pyro effects (smoke and flames)" url="https://www.sidefx.com/docs/houdini/pyro/pyro.html" /> ─ SideFX Docs</Words>
                <Words><Link text="Pyro workflow" url="https://www.sidefx.com/docs/houdini/pyro/lookdev.html" /> ─ SideFX Docs</Words>
                <Words><Link text="Differences between legacy and sparse pyro" url="https://www.sidefx.com/docs/houdini/pyro/differences.html" /> ─ SideFX Docs</Words>
                <Words><Link text="Pyro Solver (sparse)" url="https://www.sidefx.com/docs/houdini/nodes/dop/pyrosolver_sparse.html" /> ─ SideFX Docs</Words>
                <Words><Link text="Sparse Pyro Process" url="https://www.youtube.com/watch?v=DZR8RLPpYxE" /> ─ Tyler Bay, Houdini HIVE</Words>
                <Words><Link text="Fix black render when rendering Fire" url="https://www.youtube.com/watch?v=BqgOkkFcJo8" /> ─ HoudiniSimon Youtube</Words>
            </Lesson>
        </Page >
    )
};
