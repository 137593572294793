import styled from 'styled-components';
import React, { useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useApp } from '../App';
import { VoidExpression } from 'typescript';



const StyledFrame = styled.div<{ darkTheme: boolean; }>`
    .wrap {
        // border: 2px solid red;
        width: 100%;
        height: 96vh;
        max-height: 872px;
        width: 100%;
        display: block;
        margin: auto;
        // border: none;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
            &.minimoog {
            max-height: 872px;
            }
            &.pokemon {
            border-radius: 8px;
            box-shadow: rgb(0 0 0 / 30%) 0px 4px 8px 0px;
            height: 84vh;
            }
            &.houdini {
            border-radius: 8px;
            box-shadow: rgb(0 0 0 / 30%) 0px 4px 8px 0px;
            height: 84vh;
            }
            iframe {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
            }
            .cover {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
                background: ${props => props.darkTheme ? "rgba(42, 43, 55, 0.9)" : "rgba(255, 255, 255, 0.9)"};
                backdrop-filter: blur(2px);
                transition: background 0.2s ease-out, backdrop-filter 0.1s ease-out;
            }
            .button {
                background: ${props => props.darkTheme ? "rgba(42, 43, 55, 0.9)" : "rgba(255, 255, 255, 0.9)"};
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                cursor: pointer;
                text-align: center;
                
                border: 2px solid ${props => props.darkTheme ? "white" : "black"};
                background-color: ${props => props.darkTheme ? "black" : "white"};
                outline: none;
                border-radius: 30px;
                color: ${props => props.darkTheme ? "white" : "black"};
                position: relative;
                padding: 8px 16px;


                font-size: 18px;
                font-family: futura-pt;
                font-weight: 500;
                font-style: normal;
                line-height: 1.3em;
                letter-spacing: .14em;
                text-transform: uppercase;



                margin: 0px 16px 8px 0px;
            }

            // .button:hover + .cover {
            //     background: transparent;
            //     backdrop-filter: blur(0px);
            // }
            
    }
`


interface FrameProps {
    frameId: string;
    // unLockedFrameId: string;
    // setUnlockedFrameId: (id: string) => void;
    handleFrameLoad?: (evt: any) => void;
    src: string;
}

export const Frame: React.FC<FrameProps> = (props) => {
    const { darkTheme } = useApp();
    return (<StyledFrame  {...{ darkTheme }}>
        <div className={`wrap ${props.frameId}`}>
            <iframe
                id={props.frameId}
                onLoad={props.handleFrameLoad}
                src={props.src}
            />
            
            {/* <div className="button">Check it out</div>
            <div className="cover"/> */}

        </div>

    </StyledFrame>
    )

};