import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { TableComponent } from '../../TableComponent/TableComponent';
import { Code } from '../../CodeBlock/CodeBlock';


export const SatelliteData: React.FC = (props) => {
    return (
        <Page topic="terrain">
            <Lesson title="Satellite Data in Heightfields">
                <Section title="Getting Data" icon="houdini" divider={false}>
                    <Words>Much of the data available online was captured on the <Link text="Shuttle Radar Topography Mission" url="https://www.jpl.nasa.gov/missions/shuttle-radar-topography-mission-srtm/" /> (SRTM) in 2000. The data covers over 80% of the Earth's surface at a 30m resolution.</Words>
                    <Words>Get SRTM 30m elevation data <Link text="on OpenTopography" url="https://portal.opentopography.org/raster?opentopoID=OTSRTM.082015.4326.1" /></Words>
                    <Words>More detailed data can be found <Link text="here on USGS" url="https://viewer.nationalmap.gov/basic/#/" /> under Elevation Products</Words>
                    <Words>Import the Tiff file using a <Link text="Heightfield File" url="https://www.sidefx.com/docs/houdini/nodes/sop/heightfield_file.html" /> Node. For large Tiff files you might face errors. Try setting a higher <Code>Resolution Limit</Code> under <Code>Preferences → Compositing → Cooking → Resolution Limit</Code></Words>
                </Section>
                <Section title="Scale Area" icon="houdini" divider={false}>
                    <Words>Elevation data at a spatial resolution of 30 meters means that each pixel represents 30 square meters of the Earth's surface.
                    </Words>
                    <Words>Some USGS <Link text="DEM" url="https://www.usgs.gov/faqs/what-are-digital-elevation-models-dems?qt-news_science_products=0#qt-news_science_products" /> data is measured in <Link text="arc-seconds" url="https://www.esri.com/news/arcuser/0400/wdside.html" /> instead of meters. 1 arc-second is equal to approximately 30 meters.</Words>

                    <TableComponent
                        colA={["1 arc-second",
                            "1/3 arc-second",
                            "1/9 arc second"]}
                        colB={["approximately 30.87 meters",
                            "approximately 10.29 meters",
                            "approximately 3.43 meters"]}
                    />

                    <Words>The spatial resolution in meters is helpful in achieving world scale because the unit size in Houdini is also in meters.</Words>
                    <Words>On the Heightfield File node: Select <Code>Size → Size Method → Grid Spacing</Code> Set <Code>Grid Spacing</Code> equal to the spatial resolution of your data. (e.g. 30m)</Words>
                </Section>
                <Section title="Scale Height" icon="houdini" divider={false}>

                    <Words>The heightfield scale may need to be remapped using a <Link text="Heightfield Remap" url="https://www.sidefx.com/docs/houdini/nodes/sop/heightfield_remap.html" /> node. 65,536 is the magic number for remapping 16-bit TIFF files. For reasons I don't entirely understand. Here are some proposed settings:</Words>
                    <TableComponent
                        colA={["Input Min",
                            "Input Max",
                            "Output Min",
                            "Output Max"]}
                        colB={["-1",
                            "1",
                            "−32,768",
                            "32,767"]}
                    />
                    <Words><strong>Explanation: </strong>Each meter of real world height is represented by a step value in the TIFF file. A 16-bit per channel image allows for 2<sup>16</sup> (or 65,536) steps in each channel. Unsigned 16-bit TIFF can hold the values 0 to 65535. If the TIFF is signed 16-bit (signed means it contains positive and negative values) possible values range from −32,768 to 32,767. (65,536 split evenly positive and negative)</Words>
                </Section>

                <Words><strong>References:</strong></Words>
                {/* <Words><Link text="VEX in Houdini" url="https://www.cgmasteracademy.com/courses/15-vex-in-houdini" /> by Johannes Richter</Words> */}


                <Words><Link text="Create Terrains From Elevation Maps" url="https://www.sidefx.com/tutorials/create-terrains-from-elevation-maps/" /> ─ SideFX tutorial</Words>
                <Words><Link text="HeightField File node large files" url="https://www.sidefx.com/forum/topic/49190/?page=1#post-222199" /> ─ SideFX forum</Words>
                <Words><Link text="Signedness" url="https://en.wikipedia.org/wiki/Signedness" /></Words>
                <Words><Link text="Bit Depth" url="http://facweb.cs.depaul.edu/sgrais/bit_depth.htm" /></Words>
            </Lesson>
        </Page >
    )
};
