import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { DisplacementBounds } from "./Displacement";
import { MaterialBuilder } from "./MaterialBuilder";
import { Triplanar } from "./Triplanar";


const base_url = "/houdini/mantra"

export const MantraRoutes = () => {
    return (<>
        <Route exact path={`${base_url}/triplanar`}>
            <Triplanar />
        </Route>
        <Route exact path={`${base_url}/material_builder`}>
            <MaterialBuilder />
        </Route>
        <Route exact path={`${base_url}/displacement`}>
            <DisplacementBounds />
        </Route>

    </>
    )
};

export const MantraArticles = () => {
    return (<>
        {/* <ArticleLink
            title="Tool: Triplanar Texture"
            date="Feb 17, 2021"
            description="Set up Triplanar projection easier"
            url={`${base_url}/triplanar`}
            emoji="📽"
        /> */}
        <ArticleLink
            title="Material Builder"
            date="Feb 18, 2021"
            description="Basic Implementation of Material Builder"
            url={`${base_url}/material_builder`}
            emoji="📽"
            icons={["python"]}
        />
        <ArticleLink
            title="Displacement Bounds"
            date="Feb 19, 2021"
            description="Notes on displacement bounds in Mantra"
            url={`${base_url}/displacement`}
            emoji="📽"
        />
    </>
    )
};