
import React, { useState } from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { TableComponent } from '../../TableComponent/TableComponent';
import { Code, Tip } from '../../CodeBlock/CodeBlock';
import { GridViewSelector } from '../../ThreeView/GridView/GridViewSelector';
import { TurbulentSprites } from '../../Sprites/TurbulentSprites';
import { Vex } from '../../Vex/VexBlock';
import { Dropdown } from 'semantic-ui-react';
import { useDropdowns } from '../../Form/useDropdowns';
import { DropdownForm, IDropdownState } from '../../Form/DropdownForm';
import { CCode } from '../../CodeBlock/CCode';
import { DomainWarpSprites } from '../../Sprites/DomainWarpSprites';
import { AnisotropySprites } from '../../Sprites/AnisotropySprites';
import { WideBlogSection } from '../../WebComponents/WideBlogSection';


export const AboutNoise: React.FC = (props) => {

    const [FreqAmpOffset, setFreqAmpOffset] = useState<IDropdownState>({
        // "Arguments": {
        //     options: [
        //         '(pos)',
        //         '(pos, turbulence, rough, atten)',
        //         '(pos, periodX, periodY, periodZ)',
        //         '(pos, periodX, periodY, periodZ, turbulence, rough, atten)'],
        //     value: 0
        // },
        // "Noise Type": {
        //     options: [
        //         'Original Perlin',
        //         'Sparse Convolution',
        //         'Alligator'],
        //     value: 0
        // },
        "Input": {
            options: ['1D Input', '2D Input', '3D Input', '4D Input'],
            value: 2
        },
        "Output": {
            options: ['1D Output', '3D Output'],
            value: 0
        }
    });
    const [OSA, setOSA] = useState<IDropdownState>({
        "Arguments": {
            options: [
                '(pos)',
                '(pos, turbulence, rough, atten)',
                '(pos, periodX, periodY, periodZ)',
                '(pos, periodX, periodY, periodZ, turbulence, rough, atten)'],
            value: 0
        },
        "Noise Type": {
            options: [
                'Original Perlin',
                'Sparse Convolution',
                'Alligator'],
            value: 0
        },
        "Output": {
            options: ['1D Output', '3D Output'],
            value: 0
        }
    });

    const [domainWarpFunction, setDomainWarpFunction] = useState<IDropdownState>({
        "Function": {
            options: ['Warp using the same fBm noise', 'Warp using a broad zero centered noise'],
            value: 0
        },
    });

    return (
        <Page topic="noise">
            <Lesson title="Working with Noise">
                {/* <Words>One of the confusing parts about working with noise is that each property or process can have several different names.</Words> */}
                <Section title="Frequency, Amplitude, Offset" icon="houdini" divider>
                    <Words><Code>Frequency</Code> of noise determines the size of details in your object. It doesn't actually change the noise size as it occurs in space, but multiplies the sample positions of your object. You can imagine it as capturing samples of noise as if your object was <Code><i>n</i></Code> times the size. So the amount of details that occur in that altered size will now appear to occur in your object's original size.</Words>
                    <Words>Can be <Code>Anisotropic</Code> meaning at a different scale in each dimension.</Words>
                    <Words>Also referred to as <Code>Element Size</Code>.</Words>
                    <Tip>1/Element Size = frequency</Tip>
                    <Words><Code>Amplitude</Code> is a strength multiplier. It scales the end result.</Words>
                    {/* <Words>You can increase the amplitude in any dimension using a vector. Sometimes referred to as <Code>scale</Code> when it's a vector</Words> */}
                    <Words><Code>Offset</Code> shifts the sample positions by adding a vector. Like teleporting your object. It doesn't affect the quality of the noise, just affects which part you see</Words>
                    <Vex>
                        <Vex.Line nobreak>
                            <Vex.Channel type="float" var="freq" string="frequency"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="float" var="amp" string="amplitude"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="vector" var="offset" string="offset"></Vex.Channel>
                        </Vex.Line>

                        <Vex.Line space>
                            <Vex.Code type >{`float `}</Vex.Code>
                            <Vex.Code>{`nval = `}</Vex.Code>
                            <Vex.Code function>{`noise`}</Vex.Code>
                            <Vex.Code >{`(v@P * freq + offset)  * amp;`}</Vex.Code>
                        </Vex.Line>
                    </Vex>
                    {/* <AnisotropySprites /> */}
                </Section>
                <Section title="Attenuation" icon="houdini" divider>
                    <Words>Attenuation is an exponential change in <Code>amplitude</Code>.</Words>
                    <Words>Written as <Code>noise</Code> to the power of <Code>attenuation</Code>. ( noise<sup>atten</sup> )</Words>
                    <Words>Default value is <Code>1</Code> and has no effect.</Words>
                    <Words>To handle negative values in zero centered noise, use the absolute value of the noise and then reapply the sign:</Words>
                    <Vex>
                        <Vex.Line nobreak>
                            <Vex.Channel type="float" var="atten" string="attenuation"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code comment>{`// attenuate the noise value`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Code>{`noise = `}</Vex.Code>
                            <Vex.Code function>{`sign`}</Vex.Code>
                            <Vex.Code>{`(noise) * `}</Vex.Code>
                            <Vex.Code function>{`pow`}</Vex.Code>
                            <Vex.Code>{`(`}</Vex.Code>
                            <Vex.Code function>{`abs`}</Vex.Code>
                            <Vex.Code>{`(noise), atten);`}</Vex.Code>
                        </Vex.Line>
                    </Vex>
                    <Tip>This step should come before amplitude</Tip>
                </Section>

                <Section title="Fractal Brownian Motion" icon="houdini" divider>
                    <Words><Code>Fractial Brownian Motion</Code> or <Code>fBm</Code>  is a loop that adds layers of noise together. Each successive layer has a higher frequency and lower amplitude.</Words>
                    <Words>In order to achieve this the <Code>amplitude</Code> and <Code>frequency</Code> values are multiplied by values called <Code>roughness</Code> and <Code>lacunarity</Code> at the end of each loop. That way the next iteration of the loop calculates noise using the updated amplitude and frequency.</Words>
                    <Words>This is used in <Code>Turbulent Noise</Code> and <Code>Anti-Aliased Noise</Code></Words>
                    <Words>The loops are called <Code>Octaves</Code>. Sometimes shown as a <Code>Turbulence</Code> parameter.</Words>
                    <Vex>
                        <Vex.Line nobreak>
                            <Vex.Channel type="float" var="freq" string="frequency"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="float" var="amp" string="amplitude"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Channel type="int" var="octaves" string="octaves"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="float" var="rough" string="roughness"></Vex.Channel>
                            <Vex.Code comment>{` // default is 0.5`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="float" var="lacun" string="lacunarity"></Vex.Channel>
                            <Vex.Code comment>{` // default is 2`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code type >{`float `}</Vex.Code>
                            <Vex.Code>{`nval = `}</Vex.Code>
                            <Vex.Code>{`0;`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Loop length="octaves">
                                <Vex.Line tabs={1}>
                                    <Vex.Code type >{`float `}</Vex.Code>
                                    <Vex.Code>{`nval += `}</Vex.Code>
                                    <Vex.Code function>{`noise`}</Vex.Code>
                                    <Vex.Code >{`(v@P * freq)  * amp;`}</Vex.Code>
                                </Vex.Line>
                                <Vex.Line tabs={1}>
                                    <Vex.Code>{`freq *= lacun;`}</Vex.Code>
                                </Vex.Line>
                                <Vex.Line tabs={1}>
                                    <Vex.Code>{`amp *= rough;`}</Vex.Code>
                                </Vex.Line>
                            </Vex.Loop>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code>{`f@noise = nval;`}</Vex.Code>
                        </Vex.Line>
                    </Vex>
                    <WideBlogSection max_width="1100px">
                        <TurbulentSprites />
                    </WideBlogSection>
                </Section>
                <Section title="Roughness, Lacunarity (fBm)" icon="houdini" divider>
                    <Words><Code>Roughness</Code> also called <Code>Persistence</Code> or <Code>Gain</Code> multiplies the amplitude after each iteration. It ranges from (0 - 1). Default value is around 0.5.</Words>
                    <Words><Code>Lacunarity</Code> multiplies the frequency after each iteration. Default value is around 2. </Words>
                </Section>
                <Section title="Normalization (fBm)" icon="houdini" divider>
                    <Tip>Thanks @IgorElovikov on the cgwiki discord for showing me this.</Tip>

                    <Words>As octaves of noise are summed together the peaks will go beyond the initial range. Say your starting range is (0 - 1). After three octaves at a roughness of 0.5, you could have values as high as 1.75 (1 + 0.5 + 0.25). To offset this, you can normalize the noise by accumulating a max possible amplitude in the fBm loop and then using it to divide the noise. </Words>
                    <Vex>
                        <Vex.Line nobreak>
                            <Vex.Code type>{`float `}</Vex.Code>
                            <Vex.Code>{`max_possible_amp = 0;`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code comment>{`// fBm loop`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line nobreak>
                            <Vex.Loop length="octaves">
                                <Vex.Line tabs={1}>
                                    <Vex.Code>{`max_possible_amp += amp;`}</Vex.Code>

                                </Vex.Line>
                            </Vex.Loop>
                            <Vex.Line space>
                                <Vex.Code comment>{`// divide noise by max possible amplitude`}</Vex.Code>
                            </Vex.Line>
                            <Vex.Line>
                                <Vex.Code>{`nval /= max_possible_amp;`}</Vex.Code>
                            </Vex.Line>
                            <Vex.Line space>
                                <Vex.Code comment>{`// or you could do multiplication instead. Same thing`}</Vex.Code>
                            </Vex.Line>
                            <Vex.Line>
                                <Vex.Code>{`nval *= (`}</Vex.Code>
                                <Vex.Code type>{`float`}</Vex.Code>
                                <Vex.Code>{`)1/max_possible_amp;`}</Vex.Code>
                            </Vex.Line>
                        </Vex.Line>
                    </Vex>
                    <Words>The turblulent noise functions in Houdini make this calculation with an assumed roughness of 0.5, regardless of the user-defined roughness. To use a specific roughness use this function: <Code>max_amp += 1/pow(1/target_rough, i);</Code> </Words>
                    <Vex>
                        <Vex.Line nobreak>
                            <Vex.Code comment>{`// to use 0.5 roughness`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line >
                            <Vex.Code>{`max_possible_amp += (`}</Vex.Code>
                            <Vex.Code type>{`float`}</Vex.Code>
                            <Vex.Code>{`)1/`}</Vex.Code>
                            <Vex.Code function>{`pow`}</Vex.Code>
                            <Vex.Code>{`(`}</Vex.Code>
                            <Vex.Code>{`2, i);`}</Vex.Code>
                        </Vex.Line>
                    </Vex>
                </Section>
                <Section title="Distortion" icon="houdini" divider>
                    <Words><Code>Distortion</Code> also known as <Code>Domain Warping</Code> shifts the sample positions used in a noise calculation by a noised amount.</Words>
                    {/* <Words><Code>Noise(Noise(v@P))</Code></Words> */}
                    {/* <Words><strong>Short explanation:</strong></Words> */}
                    <Words>It's an <Code>offset</Code> with a noise driven magnitude per point.</Words>
                    {/* <Words><strong>Creative explanation:</strong></Words>
                    <Words>Imagine each point on a geometry is a wilderness explorer. Each explorer will go off into the wilderness to capture a sample of noise and bring it back. They all go in the same direction, but different distances. The noise value (<Code>float</Code>) calculated at the explorer's rest position will affect the <Code>magnitude</Code> or distance the explorer will travel.</Words>
                    <Words>Using the xyz sliders below, determine the <Code>direction</Code> you want to send the wilderness explorers. Then use bottom slider to bring them back from the wilderness with the new noise values they sampled.</Words>
                    <Words>Things you'll notice:</Words>
                    <Words>1. Explorers with a high <Code>magnitude</Code> will travel farther into the wilderness, slicing through more layers of noise before reaching their sample position. Explorers with a low value won't travel far.</Words>
                    <Words>2. When the explorers reach their sample collection position deep in the wilderness (bottom slider 100%) the sampled colors you see in 3D space are not yet distorted or warped. That's because the noise samples are still in their natural habitat. When the samples are brought back they appear distorted or warped relative to each other because each explorer traveled a noised distance to collect their sample.</Words> */}
                    <Tip>Use the xyz sliders to create warped noise, then use the bottom slider to see where each value came from.</Tip>
                    <DomainWarpSprites />
                    {/* <DropdownForm dropdownState={domainWarpFunction} setDropdownState={setDomainWarpFunction} />
                    <DomainWarpVex useBroadNoise={domainWarpFunction.Function.value === 1} /> */}
                </Section>
                {/* <Section title="Vex Generator" icon="houdini">
                    <Vex>
                        <Vex.Line nobreak>
                            <Vex.Channel type="float" var="freq" string="frequency"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="float" var="amp" string="amplitude"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="vector" var="offset" string="offset"></Vex.Channel>
                        </Vex.Line>
                        <>
                            {
                                [
                                    <></>,
                                    <>
                                        <Vex.Line space>
                                            <Vex.Channel type="int" var="turbulence" string="turbulence"></Vex.Channel>
                                        </Vex.Line>
                                        <Vex.Line>
                                            <Vex.Channel type="float" var="rough" string="roughness"></Vex.Channel>
                                        </Vex.Line>
                                        <Vex.Line>
                                            <Vex.Channel type="float" var="atten" string="attenuation"></Vex.Channel>
                                        </Vex.Line>
                                    </>,
                                    <>
                                        <Vex.Line space>
                                            <Vex.Channel type="int" var="px" string="period_x"></Vex.Channel>
                                        </Vex.Line>
                                        <Vex.Line>
                                            <Vex.Channel type="int" var="py" string="period_y"></Vex.Channel>
                                        </Vex.Line>
                                        <Vex.Line>
                                            <Vex.Channel type="int" var="pz" string="period_z"></Vex.Channel>
                                        </Vex.Line>
                                    </>,
                                    <>
                                        <Vex.Line space>
                                            <Vex.Channel type="int" var="px" string="period_x"></Vex.Channel>
                                        </Vex.Line>
                                        <Vex.Line>
                                            <Vex.Channel type="int" var="py" string="period_y"></Vex.Channel>
                                        </Vex.Line>
                                        <Vex.Line>
                                            <Vex.Channel type="int" var="pz" string="period_z"></Vex.Channel>
                                        </Vex.Line>
                                        <Vex.Line space>
                                            <Vex.Channel type="int" var="turbulence" string="turbulence"></Vex.Channel>
                                        </Vex.Line>
                                        <Vex.Line>
                                            <Vex.Channel type="float" var="rough" string="roughness"></Vex.Channel>
                                        </Vex.Line>
                                        <Vex.Line>
                                            <Vex.Channel type="float" var="atten" string="attenuation"></Vex.Channel>
                                        </Vex.Line>
                                    </>,
                                ][OSA.Arguments.value]
                            }
                        </>

                        <Vex.Line space>
                            <Vex.Code comment>
                                {`// ${OSA.Arguments.value === 2 || OSA.Arguments.value === 3 ? "periodic " : ""}${["original perlin", "sparse convolution", "alligator"][OSA["Noise Type"].value]} noise`}
                            </Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Code type >{`${OSA.Output.value === 1 ? "vector" : "float"} `}</Vex.Code>
                            <Vex.Code>{`noise = `}</Vex.Code>
                            <Vex.Code function>{`${["onoise", "snoise", "anoise"][OSA["Noise Type"].value]}`}</Vex.Code>
                            <Vex.Code>{`${['(v@P * freq + offset)',
                                '(v@P * freq + offset, turbulence, rough, atten)',
                                '(v@P * freq + offset, px, py, pz)',
                                '(v@P * freq + offset, px, py, pz, turbulence, rough, atten)'][OSA.Arguments.value]
                                }
                            `}</Vex.Code>
                            <>{[
                                <>
                                    <Vex.Code>{` * amp;`}</Vex.Code>
                                </>,
                                <>
                                    <Vex.Code>{` * (`}</Vex.Code>
                                    <Vex.Code type>{`vector`}</Vex.Code>
                                    <Vex.Code>{`)amp;`}</Vex.Code>
                                </>
                            ][OSA.Output.value]}
                            </>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code>{`${OSA.Output.value === 1 ? "v" : "f"}@noise = noise;`}</Vex.Code>
                        </Vex.Line>
                    </Vex>
                </Section> */}



                {/* <Section title="fBm" icon="houdini">

                    <DropdownForm dropdownState={OSA} setDropdownState={setOSA} />
                    <Vex>
                        <Vex.Line nobreak>
                            <Vex.Channel type="float" var="freq" string="frequency"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="float" var="amp" string="amplitude"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="vector" var="offset" string="offset"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="float" var="atten" string="attenuation"></Vex.Channel>
                            <Vex.Code comment>{` // default is 1`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Channel type="int" var="octaves" string="octaves"></Vex.Channel>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="float" var="rough" string="roughness"></Vex.Channel>
                            <Vex.Code comment>{` // default is 0.5`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Channel type="float" var="lacun" string="lacunarity"></Vex.Channel>
                            <Vex.Code comment>{` // default is 2`}</Vex.Code>
                        </Vex.Line>

                        <Vex.Line space>
                            <Vex.Code type >{`vector `}</Vex.Code>
                            <Vex.Code>pos = v@P;</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Code type >{`${OSA.Output.value === 1 ? "vector" : "float"} `}</Vex.Code>
                            <Vex.Code>{`nval = `}</Vex.Code>
                            <Vex.Code>{`${OSA.Output.value === 1 ? "{0,0,0};" : "0;"} `}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Code type >{`${OSA.Output.value === 1 ? "vector" : "float"} `}</Vex.Code>
                            <Vex.Code>{`weight = `}</Vex.Code>
                            <Vex.Code>{`${OSA.Output.value === 1 ? "{1,1,1};" : "1;"} `}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code comment>
                                {`// fBm - ${OSA.Arguments.value === 2 || OSA.Arguments.value === 3 ? "periodic " : ""}${["original perlin", "sparse convolution", "alligator"][OSA["Noise Type"].value]} noise - ${["1D", "3D"][OSA.Output.value]} output`}
                            </Vex.Code>
                            <Vex.Loop length="octaves + 1">
                                <Vex.Line tabs={1}>
                                    <Vex.Code>{`nval += `}</Vex.Code>
                                    <Vex.Code function>{`${["onoise", "snoise", "anoise"][OSA["Noise Type"].value]}`}</Vex.Code>
                                    <Vex.Code>{`(pos * freq + offset)`}</Vex.Code>
                                    <>{[
                                        <>
                                            <Vex.Code>{` * weight;`}</Vex.Code>
                                        </>,
                                        <>
                                            <Vex.Code>{` * (`}</Vex.Code>
                                            <Vex.Code type>{`vector`}</Vex.Code>
                                            <Vex.Code>{`)weight;`}</Vex.Code>
                                        </>
                                    ][OSA.Output.value]}
                                    </>
                                </Vex.Line>
                                <Vex.Line tabs={1}>
                                    <Vex.Code>{`freq *= lacun;`}</Vex.Code>
                                </Vex.Line>
                                <Vex.Line tabs={1}>
                                    <Vex.Code>{`weight *= rough;`}</Vex.Code>
                                </Vex.Line>
                            </Vex.Loop>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code comment>{`// optional amplitude curve ranging from (1 - 0.5). lessens by octave`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Code>{`nval *= (`}</Vex.Code>
                            <Vex.Code function>{`pow`}</Vex.Code>
                            <Vex.Code>{`(3, -octaves) + 1) * 0.5;`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code comment>{`// apply attenuation`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Code>{`nval = `}</Vex.Code>
                            <Vex.Code function>{`sign`}</Vex.Code>
                            <Vex.Code>{`(nval) * `}</Vex.Code>
                            <Vex.Code function>{`pow`}</Vex.Code>
                            <Vex.Code>{`(`}</Vex.Code>
                            <Vex.Code function>{`abs`}</Vex.Code>
                            <Vex.Code>{`(nval), atten);`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code comment>{`// apply amplitude`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Code>{`nval *= amp;`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line space>
                            <Vex.Code comment>{`// out noise`}</Vex.Code>
                        </Vex.Line>
                        <Vex.Line>
                            <Vex.Code>{`${["f", "v"][OSA.Output.value]}@noise = nval;`}</Vex.Code>
                        </Vex.Line>
                    </Vex>

                </Section> */}

                {/* <Section title="Range" icon="houdini" divider={false}>
                    <TableComponent
                    headers={["Noise", "Function", "Min", "Max"]}
                    
                    colA={["Perlin",
                            "Original Perlin",
                            "Sparse Convolution",
                            "Simplex",
                            "Alligator"]}

                        colB={["noise()",
                            "onoise()",
                            "snoise()",
                            "xnoise()",
                            "anoise()"]}

                        colC={["0",
                            "-1",
                            "-1.7",
                            "0",
                            "0"]}

                        colD={["1",
                            "1",
                            "1.7",
                            "1",
                            "1",
                            "1"]}

                    />
                </Section> */}
                {/* <Section title="Grid attempt" icon="houdini" divider={false}>
                    <GridViewSelector
                        modelNames={["Avocado", "Polywire Normals"]}
                        modelPaths={[
                            "https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/avocado.glb",
                            "https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/spiky_avocado.glb",
                        ]}
                        // HDRI="https://vex-assets.s3.amazonaws.com/quattro_canti.jpg"
                        HDRI="https://vex-assets.s3.amazonaws.com/san_giuseppe_bridge.jpg"
                        logCameraLocation
                        cameraPosition={{
                            x: 94.86136123628478,
                            y: 50.37136063621601,
                            z: 28.464073246174113
                        }}
                        roughnessMultiplier={1.2}
                    // modelYRotation={4.4}
                    />
                </Section> */}
                {/* <Section title="Original Perlin" icon="houdini" divider>
                    <Tip>These functions are similar to wnoise and vnoise. However, they are marginally less efficient in computation and don’t have the same characteristics. The bounds on the noise are roughly (-1, 1). Only 3D noise is supported. However, this noise has the ability to compute turbulence with roughness and attenuation on the noise.</Tip>
                    <Words><Link text="Original Perlin Noise" url="https://www.sidefx.com/docs/houdini/vex/functions/onoise.html" /> ─ SideFX Docs</Words>
                </Section> */}
                {/* <Section title="Sparse Convolution" icon="houdini" divider={false}>
                    <Tip>These functions are similar to wnoise. The noise returned is based on the weights of all of the closest points, with each point’s contribution based on a meta-ball like rolloff curve. That is, if the sample point is close to the sphere, its contribution will be greater.

The bounds on the noise are roughly (-1.7, 1.7). Only 3D noise is supported. However, this noise has the ability to compute turbulence with roughness and attenuation on the noise.</Tip>
                    <Tip>Sparse Convolution noise is similar to Worley noise. The noise returned is based on the weights of all of the closest points, with each point’s contribution based on a meta-ball like rolloff curve. That is, if the sample point is close to the sphere, its contribution will be greater. The bounds on the noise are roughly (-1.7, 1.7) when turbulence is 0.</Tip>
                </Section> */}
                {/* <Section title="Alligator" icon="houdini" divider={false}>
                    <Tip>These functions generate "alligator" noise, a type of cellular noise similar to Worley noise (wnoise). It is currently not possible to simulate alligator noise using the Worley functions, but it’s possible to get a very similar "look".

The bounds on the noise are roughly (0, 1). This function only supports 3D noise.</Tip>
                </Section> */}
                <Words><strong>References:</strong></Words>
                {/* <Words><Link text="VEX in Houdini" url="https://www.cgmasteracademy.com/courses/15-vex-in-houdini" /> ─ Johannes Richter</Words> */}
                <Words><Link text="Noise in VEX" url="https://mrkunz.com/blog/03-04-2017_Using-noise-in-VEX.html" /> ─ mrkunz.com</Words>
                <Words><Link text="Turbulence in Houdini" url="https://vimeo.com/361104780" /> ─ Manja Vimeo</Words>
                <Words><Link text="Wrangle Pattern" url="https://sites.google.com/site/fujitarium/Houdini/sop/wrangle/wrangle-pattern" /> ─ Ryoji CG Memo</Words>
                <Words><Link text="Domain Warping" url="https://www.iquilezles.org/www/articles/warp/warp.htm" /> ─ Inigo Quilez</Words>
                <Words><Link text="Domain Warping" url="https://observablehq.com/@mbostock/domain-warping" /> ─ Mike Bostock</Words>
            </Lesson>
        </Page >
    )
};


// group 1
// exact same params
// perlin noise()
// simplex xnoise()

// group 2
// params exact the same (click through tabs)
// onoise() original perlin
// snoise() sparse convolution
// anoise() alligator

// group 3 
// exact same params
// wnoise()
// mwnoise()
// cwnoise()

// group 4
// curlnoise()
// curlxnoise()

// 4.5 for the 2d functions
// curlnoise2d()
// curlxnoise2d()



// This operator can compute three types of 1D and 3D noise with the ability to compute turbulence with roughness and attenuation:

// Perlin noise              (string value "pnoise")
// Original Perlin noise     (string value "onoise")
// Sparse Convolution noise  (string value "snoise")
// Alligator noise           (string value "anoise")
// Simplex noise             (string value "xnoise")
// Zero Centered Perlin      (string value "correctnoise")
// Original Perlin noise is similar to Perlin noise, but marginally less efficient in computation and with different characteristics. The bounds on the noise are roughly (-1, 1) when turbulence is 0.

// Perlin noise sums octaves of a noise with range about (0, 1), resulting in a non-zero centered result. Thus the zero centered perlin better matches the ranges of the other noise fields.

// Sparse Convolution noise is similar to Worley noise. The noise returned is based on the weights of all of the closest points, with each point’s contribution based on a meta-ball like rolloff curve. That is, if the sample point is close to the sphere, its contribution will be greater. The bounds on the noise are roughly (-1.7, 1.7) when turbulence is 0.

// Alligator noise is similar to Worley noise. It is currently not possible to simulate Alligator noise using the Worley functions, but it is possible to get a very similar 'look'. The bounds on the noise are roughly (0, 0.5) when turbulence is 0.

// Simplex noise is very close to Perlin noise, except with the samples on a simplex mesh rather than a grid. This results in less grid artifacts. It also uses a higher order bspline to provide better derivatives.

// The relative costs for computing noise of different types is roughly:

// Cost | Noise Type
// -----+-------------------------
//  1.0 | Perlin Noise             (see Periodic Noise operator)
//  1.1 | Original Perlin Noise
//  1.8 | Worley Noise             (see Worley Noise operator)
//  1.8 | Periodic Worley Noise    (see Periodic Worley Noise operator)
//  2.1 | Sparse Convolution Noise
//  2.3 | Alligator Noise

// https://www.sidefx.com/docs/houdini/nodes/vop/turbnoise.html

// conditional props
{/* <Vex.Code {...(OSA.Output.value === 1 ? { float: true } : { vector: true })} ></Vex.Code> */ }


// vector npos = v@P/1. + set(0., 666., 0.);   // Noise input 3D position
// float namp = 1.;                            // namp (Noise amplitude)
// float nval = 0., nweight = 0.;              // Init nval (Noise output value), and nweight (Used to normalize octaves)
// int oct = 9;                                // Number of Octaves
// for( int i = 0; i < oct; i++ )    {
//     float __nval = fit(abs(-0.5+noise(set(npos.x,npos.y,npos.z,f@Time))), 0.0, 0.1, 1., 0.);
//     nval += __nval * namp;                  // Amplitude
//     nweight += namp;                        // Accumulate weight
//     npos *= 2.132433;                       // Lacunarity
//     namp *= 0.666;                          // Roughness
// }
// v@Cd = 1 - pow(nval / nweight, 0.8765);     // Visualize Noise Output

//https://www.classes.cs.uchicago.edu/archive/2015/fall/23700-1/final-project/MusgraveTerrain00.pdf



// float fBm(vector pos){
//     int ot = chi("octives");
//     float freq = chf("frequency"),
//             amp =  chf("amp"),
//             lac = ch("lacuarity"),
//             pers = ch("persistence"),
//             result = 0;
//     for(int i=0;i<ot;i++){
//         result+= snoise(pos*freq)*amp;
//         amp*=pers;
//         freq*=lac;
//     }
//     return result;
// }
// //distort all axises at the same time
// float fBm_distortXYZ(vector pos){
//     vector dx = chv("domain_distortX"),dy =  chv("domain_distortY"), dz =  chv("domain_distortZ");
//     vector lookup = set(fBm(pos+dx), fBm(pos+dy), fBm(pos+dz));
//    return fBm(pos + lookup*chf("warpAmount1"));
// };
// //manually pick axises (0-2)
// float fBm_distortAxis(vector pos; int axis){
//     vector dx = chv("domain_distort"),dy =  chv("domain_distort"), dz =  chv("domain_distort");
//     float distortedVal = fBm(pos+dx);
//     float val = fBm(pos);
//     vector lookup = val;
//     lookup[axis] = distortedVal;
//    return fBm(pos + lookup*chf("warpAmount1"));
// };

// close
// y = 1 - (0.333333) * x^(0.15)\ 



/// notes on sphere setup


// parking garage hdri

// radius 5
// sphere frequency 800
// principle shader with roughness 0.5 and reflectivity 0.5
// typical sun and environment
// camera at focal len 50
// resolution 512 x 512
// translate -12, 0, 12
//rotate 0, -45, 0



// mike bostock

// float onoise(vec3 v) {
//     float sum = 0.0;
//     float frequency = 1.0;
//     float amplitude = 1.0;
//     float max = 0.0;
//     for (int i = 0; i < octaves; i++) {
//       sum += snoise(v * frequency) * amplitude;
//       max += amplitude;
//       amplitude *= persistence;
//       frequency *= 2.0;
//     }
//     return sum / max;
//   }