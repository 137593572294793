import React, { useEffect, useState } from "react";
import { Message, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { useApp } from "../App";
import { Lesson, Page, ReactToggle, Words } from "../Chapter/Chapter";
import { Section } from "../Houdini/Section";
import { LargeToggle } from "../Toggle/LargeToggle";
import { WideBlogSection } from "../WebComponents/WideBlogSection";

const StyledCopyPaste = styled.div<{ darkTheme: boolean; }>`
    min-height: 100vh;
    background: ${props => props.darkTheme ? "#282c35" : "white"};
    color: ${props => props.darkTheme ? "white" : "#222222"};
    transition: color 0.2s ease-out, background 0.2s ease-out;
    & > div {
        max-width: 42rem;
        padding: 42px 21px;
        margin-left: auto;
        margin-right: auto;
    }
    & p {
        cursor: pointer;
    }





    //https://codepen.io/themechills/pen/dMamgG
    
    /* Animation */
    
    @keyframes fadeInUp {
        from {
            transform: translate3d(0,40px,0)
        }
    
        to {
            transform: translate3d(0,0,0);
            opacity: 1
        }
    }
    
    @-webkit-keyframes fadeInUp {
        from {
            transform: translate3d(0,40px,0)
        }
    
        to {
            transform: translate3d(0,0,0);
            opacity: 1
        }
    }
    
    .animated[fadeUP='1'] {
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both
    }
    
    .animatedFadeInUp[fadeUP='1'] {
        opacity: 0
    }
    
    .fadeInUp[fadeUP='1'] {
        opacity: 0;
        animation-name: fadeInUp;
        -webkit-animation-name: fadeInUp;
    }
    .message {
        position: sticky;
        top: 8px;
    }
`



export const CopyPaste: React.FC<{}> = (props) => {
    const { darkTheme, setDarkTheme } = useApp();
    const [copiedText, setCopiedText] = useState<string>("");
    const [messageVisible, setMessageVisible] = useState<number>(0);

    const copyText: React.MouseEventHandler<HTMLParagraphElement> = (evt) => {
        var text = evt.currentTarget.textContent as string;
        // alert("Copied the text: " + text);
        navigator.clipboard.writeText(text)
        setCopiedText(text)
        setMessageVisible(1)
    }

    // useEffect(() => {
    //     if (messageVisible) {
    //         setTimeout(() => {
    //             setMessageVisible(false)
    //             console.log("hey")
    //         }, 2000)
    //     }
    // }, [messageVisible, copiedText]);


    return (<StyledCopyPaste {...{ darkTheme }}>
        {/* <Page topic="python"> */}
        <LargeToggle checked={darkTheme} styles={{ position: "absolute", zIndex: 1, top: 24, right: 48 }} defaultChecked={darkTheme} onChange={() => setDarkTheme(_ => !_)} />
        {/* <ReactToggle checked={darkTheme} defaultChecked={darkTheme} onChange={() => setDarkTheme(_ => !_)} /> */}
        <Lesson title="Leenks 2 uhplai 2 jobz">
            <div className="message">
                <Message
                    className={`animated animatedFadeInUp fadeInUp ${copiedText}`}
                    positive
                    onClick={() => setMessageVisible(1)}
                    onAnimationEnd={() => setMessageVisible(0)}
                    fadeUP={messageVisible}

                >Copied: {copiedText}
                </Message>
            </div>
            <Popup content={copiedText} />
            <Section title="LinkedIn" icon="houdini">
                <p onClick={copyText}>https://www.linkedin.com/in/keaton-williamson/</p>
            </Section>
            <Section title="Website" icon="houdini">
                <p onClick={copyText}>https://www.keatonwilliamson.com/</p>
            </Section>
            <Section title="Github" icon="houdini">
                <p onClick={copyText}>https://github.com/keatonwilliamson</p>
            </Section>
            <Section title="Frontend web" icon="houdini">
                <p onClick={copyText}>I'm a Frontend UI focused dev with Typescript, React, and Sass experience. I also do 3D graphics and simulations in Houdini.</p>
            </Section>
            <Section title="React" icon="houdini">
                <p onClick={copyText}>I'm a Frontend UI focused dev with 2 years of React and Typescript experience. I also do 3D graphics and simulations in Houdini. I keep a site of notes here - https://www.keatonwilliamson.com</p>
            </Section>
            <Section title="Jr. Game Dev" icon="houdini">
                <p onClick={copyText}>I'm a web developer with experience in Houdini. I'm familiar with VEX and Python in Houdini. I keep a site of notes here - https://www.keatonwilliamson.com/houdini</p>
            </Section>
            <Section title="Indeed" icon="houdini">
                <p onClick={copyText}>Application Developer</p>
                <p onClick={copyText}>Paradigm Environmental</p>
            </Section>
            <Section title="Other" icon="houdini">
                <p onClick={copyText}>I'm a Typescript/React/Sass web developer specializing in UI and Data Vis. I have 3D graphics experience using SideFX Houdini's C based language and python API</p>
            </Section>
            <Section title="Creative" icon="houdini">
                <p onClick={copyText}>I'm a Frontend UI creative developer specializing in Typescript and React. I have 3D graphics experience using Python and a C based language in Houdini.</p>
            </Section>
            <h4>If you've built or made substantial contributions to any projects, sites, portfolios, blogs, etc., please link to them along with a brief description.</h4>
            <p onClick={copyText}>Storm water installation map site -
            https://web.paradigmh2o.com/smc-gi/map

            I keep notes on the 3D software I'm learning here -
https://www.keatonwilliamson.com/</p>
            <h4>Describe yourself in a short phrase. e.g. "Machine learning engineer from Twitter", "Recent CS college grad who interned two summers at startups", "DevOps engineer who scaled a site to 10M+ users", "Frontend developer specializing in mobile interfaces" *</h4>
            <p>Frontend developer with 3D graphics experience</p>
            <h4>What are you looking for in your next role? What would you like to avoid? (Might include aspects like technologies you want to work with, industries you’re interested in, team dynamics, software development practices … whatever is important to you) *</h4>
            <p>I'm interested in using React, Typescript, Sass to build UI. I'm also interested in incorporating 3D graphics into web development</p>
            <h4>Optionally, describe a project that you worked on that you are proud of. It's best if you can describe it from start to finish including the motivation and technical details. If you are describing a team effort, please be specific about your personal contribution.</h4>
            <p>I worked on a Green Infrastructure Tracking Tool
            ( https://web.paradigmh2o.com/smc-gi/map )
I coded and designed a good portion of the UI in tandem with the rest of the team. At one point I decided we could use some pictures so I drove from Nashville to Atherton to take actual photos of the map locations.</p>
            <Section title="Junior Pipeline TD" icon="houdini">
                <p onClick={copyText}>I have a web development background and focus on VEX and Python in Houdini. I keep notes here - keatonwilliamson.com/houdini</p>
            </Section>
            <Section title="Japan Dev" icon="houdini">
                <p onClick={copyText}>Hi, I'm a fullstack creative developer and designer with experience in Typescript, React, and Sass. I would like to come to Japan and learn Japanese but require a visa. I also have experience doing 3D graphics and simulations in Houdini. My current work is hosted here https://www.keatonwilliamson.com/
- Keaton</p>
            </Section>
            <Section title="Creative Front End" icon="houdini">
                <p onClick={copyText}>I'm a creative Frontend UI developer specializing in Typescript and React. I also have experience doing 3D graphics in Three.js and Houdini.

Check out my portfolio site here 
www.keatonwilliamson.com
- Keaton</p>
            </Section>
            <Section title="Computer Vision" icon="houdini">
                <p onClick={copyText}>Hi, I'm a React Dev that has branched out to learning Houdini full-time for the last 6 months. I've been keeping React site for notes on what I've learned and experimenting with 3D Visualization on the web. I have some backend experience with C# and I've been writing a good amount of Python recently for Houdini. Looks like a really cool company!

-Keaton
https://www.keatonwilliamson.com/</p>
            </Section>
            <Section title="Paradigm" icon="houdini">
                <p onClick={copyText}>{`● Member of the design and development team on several public facing applications in the stormwater
management space.\n
● Developed applications using React, Typescript, D3.js, Sass, and ESRI Map API.\n
● Iterated on UI/UX concepts using Figma.`}</p>
            </Section>
            <p onClick={copyText}>Knowledge of 3D math</p>
        </Lesson>
        {/* </Page > */}
    </StyledCopyPaste>
    )

};



// 93 calendar days – 27 days skipped:
// Excluded 13 Saturdays
// Excluded 13 Sundays
// Excluded 1 holiday:

// 22 days a month

// 66 times 8 times 34 = 17952

// tn tax 15.3%


//17952 times .847 = 15205.34


