import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension } from '../NoiseConfig';

export interface IPeriodChannel {
    dimension: dimension;
    channelPrefix: string;
}

export const PeriodChannel: React.FC<IPeriodChannel> = ({ channelPrefix, ...props }: IPeriodChannel) => {
    // def periodicChannels(**kwargs):
    // ch_names = kwargs["ch_names"]

    // period_x = '\n' + 'int period_x = chi("{}");'.format(ch_names["period_x"])
    // period_y = '\n' + 'int period_y = chi("{}");'.format(ch_names["period_y"])
    // period_z = '\n' + 'int period_z = chi("{}");'.format(ch_names["period_z"])
    // period_w = '\n' + 'int period_w = chi("{}");'.format(ch_names["period_w"])

    // channels = {
    //     1: period_x,
    //     2: period_x + period_y,
    //     3: period_x + period_y + period_z,
    //     4: period_x + period_y + period_z + period_w
    // }[kwargs["inputDimension"]]

    // return '\n' + channels if kwargs["is_periodic"] else ""

    const JSX: Record<dimension, any> = {
        "1D": <>
            <Vex.Line>
                <Vex.Channel type="int" var="period_x" string={`${channelPrefix}period_x`}></Vex.Channel>

            </Vex.Line>

        </>,
        "2D": <>
            <Vex.Line>
                <Vex.Channel type="int" var="period_x" string={`${channelPrefix}period_x`}></Vex.Channel>
            </Vex.Line>
            <Vex.Line>
                <Vex.Channel type="int" var="period_y" string={`${channelPrefix}period_y`}></Vex.Channel>
            </Vex.Line>
        </>,
        "3D": <>
            <Vex.Line>
                <Vex.Channel type="int" var="period_x" string={`${channelPrefix}period_x`}></Vex.Channel>
            </Vex.Line>
            <Vex.Line>
                <Vex.Channel type="int" var="period_y" string={`${channelPrefix}period_y`}></Vex.Channel>
            </Vex.Line>
            <Vex.Line>
                <Vex.Channel type="int" var="period_z" string={`${channelPrefix}period_z`}></Vex.Channel>
            </Vex.Line>
        </>,
        "4D": <>
            <Vex.Line>
                <Vex.Channel type="int" var="period_x" string={`${channelPrefix}period_x`}></Vex.Channel>
            </Vex.Line>
            <Vex.Line>
                <Vex.Channel type="int" var="period_y" string={`${channelPrefix}period_y`}></Vex.Channel>
            </Vex.Line>
            <Vex.Line>
                <Vex.Channel type="int" var="period_z" string={`${channelPrefix}period_z`}></Vex.Channel>
            </Vex.Line>
            <Vex.Line>
                <Vex.Channel type="int" var="period_w" string={`${channelPrefix}period_w`}></Vex.Channel>
            </Vex.Line>
        </>
    }

    return JSX[props.dimension]
};