import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { CameraFrustrum } from "./CameraFrustrum";

const base_url = "/houdini/camera"

export const CameraRoutes = () => {
    return (<>
        <Route exact path={`${base_url}/frustrum`}>
            <CameraFrustrum />
        </Route>
    </>
    )
};

export const CameraArticles = () => {
    return (<>
        <ArticleLink
            title="Camera Frustrum"
            date="May 28, 2021"
            description="Generate a geometry camera frustrum or scattered points frustrum."
            icons={["vex"]}
            url={`${base_url}/frustrum`}
            emoji="📦"
        />
    </>
    )
};