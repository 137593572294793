import React, { Component, useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { myDOMRectReadOnly, useResizeObserver } from "./UseResizeObserver";

const StyledGallery = styled.div<{ nowrap?: boolean; containerHeight: number; }>` 
    width: 100%;
    min-width: 472px;
    padding: 1.5vw;
    height: ${props => props.containerHeight}px;
    & div.container {
        margin: auto;
        width: 100%;
        position: relative;
    }
    & div.image {
        position: absolute;
        & img {
            width: 100%;
            display: inline-block;
        }
    }
`

interface GalleryProps {
    // nowrap?: boolean;
    // url: string,
    // text?: string
}
interface GalleryImage {
    url: string,
    width: number;
    height: number;
}

interface DOMImage {
    url: string,
    width: number;
    height: number;
    top: number;
    left: number;
}

export const Gallery: React.FC<GalleryProps> = () => {
    const [dimensions, setDimensions] = useState({ top: 0, left: 0 });
    const ref = useRef(null);

    // Optional callback to access the full DOMRect object if required.
    const optionalCallback = (entry: myDOMRectReadOnly) =>
        setDimensions({ top: entry.x, left: entry.left });

    // Access the width and the height returned from the observed element.
    const [width, height] = useResizeObserver(ref, optionalCallback);

    // -------- END RESIZE OBSERVER CODE -------------- //




    const [data, setData] = useState<GalleryImage[]>([]);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const data = require('./Gallery.json');
        setData(data.images);
    }, []);

    const getColumns = (width: number) => {
        return Math.floor(width / 400);
    }
    const getElements = (width: number, data: GalleryImage[]) => {
        let columnCount = Math.floor(width / 400);
        let guttersTotalWidth = (columnCount - 1) * 20;
        let imagesTotalWidth = width - guttersTotalWidth;
        let imageWidth = Math.floor(imagesTotalWidth / columnCount);
        let lastColumnImageWidth = imagesTotalWidth - (imageWidth * (columnCount - 1))

        const getImageHeight = (width: number, height: number, columnWidth: number) => {
            return Math.floor((columnWidth / width) * height)
        }

        // array of length columnCount
        let columnHeights: number[] = new Array(columnCount).fill(0);
        let DOMImages: DOMImage[] = [];

        data.forEach(d => {
            let shortestColumnIdx: number = columnHeights.indexOf(Math.min(...columnHeights));
            let shortestColumnIsLast: boolean = shortestColumnIdx == (columnCount - 1);
            let adjustedImageWidth = (shortestColumnIsLast ? lastColumnImageWidth : imageWidth)
            let imageHeight: number = getImageHeight(d.width, d.height, adjustedImageWidth);
            DOMImages.push({
                url: d.url,
                width: adjustedImageWidth,
                height: imageHeight,
                top: columnHeights[shortestColumnIdx],
                left: shortestColumnIdx * (imageWidth + 20)
            })
            columnHeights[shortestColumnIdx] += (imageHeight + 20)
        })
        // console.log(DOMImages)
        // console.log(columnHeights, "col heihghts")
        return {
            containerHeight: Math.max(...columnHeights) + 64,
            images: DOMImages
        };
    }

    const {containerHeight, images} = getElements(Math.round(width), data);

    return (
        <StyledGallery containerHeight={containerHeight} ref={ref}>
            {/* {JSON.stringify(data)} */}
            {/* {data.map(_ => <div>{_.width}</div>)} */}
            {/* {`height: ${height}, width: ${width} columns: ${getColumns(width)}`} */}
            <div className="container">
                {!!data && images.map((d, i) =>
                    <div key={i} className="image" style={{ width: d.width, height: d.height, top: d.top, left: d.left }}>
                        <img src={`${d.url}`} />
                    </div>)}
            </div>
        </StyledGallery>
    )
}


