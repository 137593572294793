import { VexRoutes } from "./VEX/Articles"

import React from 'react';
import { GeometryRoutes } from "./Geometry/GeometryArticles";
import { PythonRoutes } from "./Python/Articles";
import { TerrainRoutes } from "./Terrain/Articles";
import { NoiseRoutes } from "./Noise/Articles";
import { VolumesRoutes } from "./Volumes/Articles";
import { UiRoutes } from "./UI/Articles";
import { NukeRoutes } from "./Nuke/Articles";
import { MantraRoutes } from "./Mantra/Articles";
import { PyroRoutes } from "./Pyro/Articles";
import { HDARoutes } from "./HDA/Articles";
import { BlenderRoutes } from "./Blender/Articles";
import { CameraRoutes } from "./Camera/Articles";


export const HoudiniRoutes: React.FC = (props) => {
    return (
        <>
            {/* <GeometryRoutes/> */}
            <PythonRoutes />
            <VexRoutes />
            <TerrainRoutes />
            <NoiseRoutes/>
            <VolumesRoutes/>
            <UiRoutes/>
            <HDARoutes/>
            <NukeRoutes/>
            <MantraRoutes/>
            <PyroRoutes/>
            <BlenderRoutes/>
            <CameraRoutes/>

        </>
    )
}