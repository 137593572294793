import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';


export const UI: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="Python UI Elements">
                <Section title="Display popup message" icon="houdini">
                    <Python highlighted={[6]}>
                        {`
# create geo           
geo = hou.node('/obj').createNode('geo', 'jake')

# display popup of node name
hou.ui.displayMessage('Name of geo is {}'.format(geo.name())) 
                            `}
                    </Python>
                </Section>
                {/* <Section title="Need test: Open Node in Floating Panel" icon="houdini" divider>
                    <Python highlighted={[8]}>
                        {`
# create geo
geo = hou.node('/obj').createNode('geo')

# open node in floating panel
desktop = hou.ui.curDesktop()
pane = desktop.createFloatingPane(hou.paneTabType.Parm)
pane.setCurrentNode(geo)
pane.setPin(True)
                            `}
                    </Python>
<Words><strong>Add a Button parm that opens self in floating panel:</strong></Words>
                    <Python highlighted={[9, 10]}>
                        {`
# create geo
geo = hou.node('/obj').createNode('geo')

# get parm template group
ptg = geo.parmTemplateGroup()

# define floating panel button parameter
floating_panel_button = hou.ButtonParmTemplate("panel", "Open Floating Panel", script_callback_language=hou.scriptLanguage.Python,join_with_next=True)
floating_panel_button.setScriptCallback("exec('''pane = hou.ui.curDesktop().createFloatingPane(hou.paneTabType.Parm)\\npane.setCurrentNode(kwargs['node'])\\npane.setPin(True)''')")

# append new parameter
ptg.append(floating_panel_button)

# apply changes
geo.setParmTemplateGroup(ptg)
                            `}
                    </Python>
                </Section> */}
            </Lesson>
        </Page >
    )
};
