import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { CalcVoxelSize } from "./CalcVoxelSIze";

import { VelocityVolume } from "./VelocityVolume";
import { VolumeFitFloat } from "./VolumeFitFloat";



const base_url = "/houdini/volumes"

export const VolumesRoutes = () => {
    return (<>
        <Route exact path={`${base_url}/calc_voxel_size`}>
            <CalcVoxelSize />
        </Route>
        <Route exact path={`${base_url}/velocity_volume`}>
            <VelocityVolume />
        </Route>
        <Route exact path={`${base_url}/volume_fit_float`}>
            <VolumeFitFloat />
        </Route>

    </>
    )
};

export const VolumesArticles = () => {
    return (<>
        <ArticleLink
            title="Calculate Voxel Size by Divisions"
            date="Jan 22, 2021"
            description="Calculate a voxel size that you can use everywhere using uniform divisions"
            url={`${base_url}/calc_voxel_size`}
            emoji="🎲"
            icons={["python", "hda"]}
        />
        <ArticleLink
            title="VDB Velocity Field"
            date="March 1, 2021"
            description="Create a vdb velocity field "
            url={`${base_url}/velocity_volume`}
            emoji="🔁"
            icons={["python"]}
        />
        <ArticleLink
            title="Fit Scalar Volume HDA"
            date="April 29, 2021"
            description="Fits a scalar volume or VDB to a new range."
            url={`${base_url}/volume_fit_float`}
            emoji="🎚"
            icons={["hda"]}
        />
    </>
    )
};