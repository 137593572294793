import React, { Dispatch, SetStateAction, useState } from 'react';
import logo from './logo.svg';
// import './App.css';
import styles from './App.module.scss';
import { ColorSpan as C, CodeBlock as Block, BreakSpan as Br } from './CodeBlock/CodeBlock';
import image from './CodeBlock/image.png'
import { ApplicationViews } from './ApplicationViews';
import './Fonts/Fonts.css'


interface IAppContext {
  darkTheme: boolean;
  setDarkTheme: Dispatch<SetStateAction<boolean>>;
  nodeSelected: boolean;
  setNodeSelected: Dispatch<SetStateAction<boolean>>;
  houdiniScrollPos: number;
  setHoudiniScrollPos: Dispatch<SetStateAction<number>>;
}

export const AppContext = React.createContext<IAppContext | undefined>(
  undefined
);

export const useApp = (): IAppContext => {
  const context = React.useContext(AppContext)
  if (!context) {
    throw new Error("This component must be used within a <App> component.")
  }
  return context
};

const App: React.FC = (props) => {

  const [darkTheme, setDarkTheme] = useState(() => {
    let time = (new Date()).getHours();
    if (time >= 6 && time < 18) return false;
    else return true;
  });

  const [houdiniScrollPos, setHoudiniScrollPos] = useState<number>(0);

  const [nodeSelected, setNodeSelected] = useState(false);

  return (
    <AppContext.Provider value={{ darkTheme, setDarkTheme, nodeSelected, setNodeSelected, houdiniScrollPos, setHoudiniScrollPos }}>
      <ApplicationViews />
    </AppContext.Provider>

  )
};

export default App;

