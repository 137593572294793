import React from 'react';
import { Checkbox, Input } from 'semantic-ui-react';
import styled from 'styled-components'
import { useApp } from '../App';

const StyledCheckboxSingle = styled.div<{ darkTheme: boolean; disabled?: boolean; }>`
    padding: 8px 0px 0px;

    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;

    & .label {
        padding: 4px;
        color: ${props => props.darkTheme ? "white" : "black"};
        opacity: ${props => props.disabled ? 0.5 : 1};
    }

    &  label:after {
        transition: background .2s ease,left .2s ease !important;
    }
`

export interface CheckboxSingleOption { name: string, disabled?: boolean };

interface IInputSingleProps {
    id: string;
    label: string;
    value: string;
    setValue: (id: string, value: string) => void;
    dark?: boolean;
    placeholder: string;
    disabled?: boolean;
}

export const InputSingle: React.FC<IInputSingleProps> = (props: IInputSingleProps) => {
    const { darkTheme } = useApp();

    const handleChange = (event: any, data: any) => {
        let id = data.id;
        let value = data.value;
        props.setValue(id, value);
    };

    return (
        <StyledCheckboxSingle disabled={props.disabled} darkTheme={(props.dark) ? true : darkTheme}>
            <div className="item">
                <div className="label">{props.label}</div>
                <Input
                    placeholder={props.placeholder}
                    onChange={handleChange}
                    value={props.value}
                    id={props.id}
                    disabled={props.disabled}
                />
            </div>
        </StyledCheckboxSingle>
    )
};

