import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { AboutNoise } from "./AboutNoise";
import { NoiseStats } from "./NoiseStats";
import { VexNoiseGenerators } from "./VexGenerators/VexNoiseGenerators";
import { DomainWarping } from "./DomainWarping";
import { TurbulenceVisualizer } from "./TurbulenceVisualizer";


const base_url = "/houdini/noise"

export const NoiseRoutes = () => {
    return (<>
        <Route exact path={`${base_url}/vex_noise_generator`}>
            <VexNoiseGenerators />
        </Route>
        {/* <Route exact path={`${base_url}/stats`}>
            <NoiseStats />
        </Route> */}
        <Route exact path={`${base_url}/about_noise`}>
            <AboutNoise />
        </Route>
        <Route exact path={`${base_url}/domain_warping`}>
            <DomainWarping />
        </Route>
        <Route exact path={`${base_url}/turbulence_visualizer`}>
            <TurbulenceVisualizer />
        </Route>

    </>
    )
};

export const NoiseArticles = () => {
    return (<>
        <ArticleLink
            title="VEX Noise Generator"
            date="March 5, 2021"
            description="Online tool to generate VEX code for noise calls. Also made into an HDA with plenty of python"
            url={`${base_url}/vex_noise_generator`}
            emoji="🌑"
            icons={["vex", "hda", "python"]}
        />
        <ArticleLink
            title="Noise in VEX"
            date="Mar 2, 2021"
            description="Frequency, Amp, fBm, Attenuation, Distortion"
            url={`${base_url}/about_noise`}
            emoji="🌑"
        />
        {/* <ArticleLink
            title="VEX Noise Function Stats"
            date="Mar 4, 2021"
            description="Bounds, Inputs, Outputs, Periodic"
            url={`${base_url}/stats`}
            emoji="〽"
        /> */}
        <ArticleLink
            title="Turbulence Visualizer"
            date="May 5, 2021"
            description="Turbulence Visualizer"
            url={`${base_url}/turbulence_visualizer`}
            emoji="🌈"
        />
        <ArticleLink
            title="Domain Warping"
            date="Mar 15, 2021"
            description="Domain Warping"
            url={`${base_url}/domain_warping`}
            emoji="🌈"
        />
        {/* <ArticleLink
            title="Original Perlin, Sparse Convolution, Alligator"
            date="Jan 20, 2021"
            description="Original Perlin, Sparse Convolution, Alligator"
            url={`${base_url}/vexfunctions`}
            emoji="🌑"
        /> */}
    </>
    )
};