import React from "react"
import { Route, withRouter, RouteComponentProps } from "react-router-dom"

import Houdini from "./Houdini/Houdini"

import { HoudiniRoutes } from "./Houdini/HoudiniRoutes"
import Landing from "./Landing/Landing"
import Web from "./Landing/Web"
import Photos from "./Landing/Photos"
import Resume from "./Landing/Resume"
import HoudiniSplash from "./Houdini/HoudiniSplash"
import Effects from "./Landing/Effects"
import { CopyPaste } from "./Random/CopyPaste"
import { CodeExamples } from "./Random/CodeExamples"

export const ApplicationViews: React.FC = () => {
    return (
        <>
            {/* personal site */}
            <Route exact path={["/", "/home/:navitem?", "/home/apps", "/home/effects", "/home/photography", "/home/resume"]}>
                <Landing>
                    {/* <Route exact path={["/", "/home/apps"]} render={() => (
                        <Web />
                    )} />
                    <Route exact path="/home/effects" render={() => (
                        <Effects />
                    )} />
                    <Route exact path="/home/photography" render={() => (
                        <Photos />
                    )} /> */}
                    {/* <Route exact path="/home/resume" render={() => (
                        <Resume />
                    )} /> */}
                </Landing>
            </Route>

            {/* houdini homepage */}
            <Route exact path="/houdini">
                <Houdini />
            </Route>
            <Route exact path="/copypaste">
                <CopyPaste />
            </Route>
            <Route exact path="/code_examples">
                <CodeExamples />
            </Route>
            <Route exact path="/houdini/landing">
                <HoudiniSplash />
            </Route>
            <HoudiniRoutes />
        </>
    )
}
