import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Tip } from '../../CodeBlock/CodeBlock';
import { Javascript } from '../../CodeBlock/Javascript';



type rampBasis = "hou.rampBasis.Linear" |
    "hou.rampBasis.Constant" |
    "hou.rampBasis.CatmullRom" |
    "hou.rampBasis.MonotoneCubic" |
    "hou.rampBasis.Bezier" |
    "hou.rampBasis.BSpline" |
    "hou.rampBasis.Hermite"

interface ramp {

}

type exprLanguage = "hou.exprLanguage.Python" | "hou.exprLanguage.Hscript";

interface expression {
    expression: string;
    language?: exprLanguage;
}

type parm = string | number | expression

interface node {
    type: string;
    name: string;
    children?: node[];
    parms?: Record<string, parm>[]
    spareParms?: Record<string, parm>[]
}


export const RecursiveChildren: React.FC = (props) => {
    // let geo = {
    //     name: "geo",
    //     children: [
    //         { name: "box" },
    //         { name: "grid" },
    //         {
    //             name: "solver",
    //             children: [
    //                 { name: "input" },
    //                 {
    //                     name: "matnet",
    //                     children: [
    //                         { name: "material" }
    //                     ]
    //                 }]
    //         }]
    // }
    // let data = []
    // const getNodeInfo = (node: node) => {
    //     let info: any = {name: `${node.name}!!`}
    //     if (node.children) {
    //         info.children = [...node.children.map(_ => getNodeInfo(_))]
    //     }
    //     return info
    // }


    return (
        <Page topic="python" >
            <Lesson title="Recursively Access Sub Children">
                <Section title="Get Node Name, Children" icon="houdini" divider>
                    <Python>
                        {`
# reference a node with children
node = hou.node('/obj/geo1')

# recursively return all sub children of node
def getNodeInfo(node):
    info = {}
    info['name'] = node.name()
    if node.children() and node.isEditable():
        info['children'] = [getNodeInfo(child) for child in node.children()]
    return info

print(getNodeInfo(node))

# node {
#    name: string; 
#    children: node[];
# }
                            `}
                    </Python>
                    <Words><Link text="hou.Node.isEditable()" url="https://www.sidefx.com/docs/houdini/hom/hou/Node.html#isEditable" /></Words>
                    <Words><Link text="hou.Node.children()" url="https://www.sidefx.com/docs/houdini/hom/hou/Node.html#children" /></Words>
                </Section>
                <Section title="Get All Parms of All Subchildren" icon="houdini" divider>
                    <Words>Get a single array of all the children and subchildren in the material context. Then get a list of all the parms. Then increment every sheen parameter.</Words>
                    <Python>
                        {`
# reference a node with children
node = hou.node('/mat')

# recursively return all sub children of node
def getListOfChildren(node):
    if node.children() and node.isEditable():
        for child in node.children():
            yield child
            if child.children() and child.isEditable():
                for subchild in child.children():
                    yield subchild
                    
# get all the parms from a list of nodes                   
def getParmsOfNodes(nodelist):
    for node in nodelist:
        for parm in node.parms():
            yield parm
            
# increment sheen                  
def incrementSheen(parmlist):
    for parm in parmlist:
        if parm.name() == "sheen":
            currentVal = parm.eval()
            parm.set(currentVal + 0.1)
            print("incremented sheen!")
                                
      
listOfNodes = getListOfChildren(node)
listOfParms = getParmsOfNodes(listOfNodes)

#print([parm.name() for parm in listOfParms])

incrementSheen(listOfParms)
                            `}
                    </Python>
                </Section>
                <Section title="Get ParmTemplates inside Folders" icon="houdini" divider>
                    <Python>
                        {`
import json
from collections import OrderedDict 

# define function
def getParmTemplateData(node):
    def getData(parm_template):    
        # define ordered dict
        data = OrderedDict([('label', parm_template.label()),
                            ('type', parm_template.type().name()),
                            ('name', parm_template.name())]) 
        
        # if is FolderParmTemplate, get contained ParmTemplates
        if (parm_template.type() == hou.parmTemplateType.Folder and parm_template.isActualFolder()):
            data.update({'parm_templates': [getData(pt) for pt in parm_template.parmTemplates()]})
            
        return data 
        
    return OrderedDict([('parm_templates', [getData(pt) for pt in node.parmTemplateGroup().entries()]),])
    
# create geo
geo = hou.node('/obj').createNode('geo') 

# get ParmTemplate data
parm_templates = getParmTemplateData(geo)

# print data
print(json.dumps(parm_templates, indent=4))

# parm_template {
#    label: string; 
#    type: string; 
#    name: string; 
#    parm_templates?: parm_template[];
# }
                            `}
                    </Python>
                </Section>
                {/* <Section title="parms" icon="houdini" divider>
                    <Python>
                        {`
node = hou.node('/obj/geo1')
#spareParms() → tuple of hou.Parm

def getParm(parm):
    # expression check
    try:
        parm.expression()
    except hou.OperationFailed:
        pass
    else:
        return { 
            "expression": parm.expression(),
            "language": parm.expressionLanguage()
        }
    # ramp check
    try:
        parm.evalAsRamp()
    except TypeError:
        pass
    else:
        return "its a ramp"
    # not a ramp or expression
    return parm.rawValue()
      
  
    
def getEditedParms(parms):
    editedparms = {}
    for parm in parms:
        if not parm.isAtDefault():
            editedparms[parm.name()] = getParm(parm)
    return editedparms


# recursively return {name, children, parms} for all sub children
def getNodeInfo(node):
    info = {}
    info['name'] = node.name()
    info['type'] = node.type().name()
    editedParms = getEditedParms(node.parms())
    if editedParms: info['parms'] = editedParms
    if node.children() and node.isEditable():
        info['children'] = [getNodeInfo(child) for child in node.children()]
    return info

print(getNodeInfo(node))
                            `}
                    </Python>
                </Section> */}
                <Words><strong>References:</strong></Words>
                <Words><Link text="Python error handling" url="https://stackabuse.com/python-exception-handling/" /></Words>
            </Lesson>
        </Page >
    )
};
