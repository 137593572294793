import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { BlenderGeometryPython } from "./BlenderGeometryPython";

const base_url = "/houdini/blender"

export const BlenderRoutes = () => {
    return (<>
        <Route exact path={`${base_url}/geometry_python`}>
            <BlenderGeometryPython />
        </Route>
        <Route exact path={`${base_url}/calc_thickness`}>

        </Route>

    </>
    )
};

export const BlenderArticles = () => {
    return (<>
        <ArticleLink
            title="Geometry Python"
            date="May 27, 2021"
            description=""
            url={`${base_url}/geometry_python`}
            emoji="📦"
        />
    </>
    )
};