import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension } from '../NoiseConfig';

export interface IPeriodArguments {
    inputDimension: dimension;
    fBm: boolean;
}

export const PeriodArguments: React.FC<IPeriodArguments> = ({ ...props}: IPeriodArguments) => {

    // def periodicArguments(**kwargs):
    // if not kwargs["is_periodic"]: 
    //     return ""
    // elif kwargs["use_fBm"]:
    //     return {
    //         1: ', px',
    //         2: ', px, py',
    //         3: ', px, py, pz',
    //         4: ', px, py, pz, pw'
    //     }[kwargs["inputDimension"]] 
    // else:
    //     return {
    //         1: ', period_x',
    //         2: ', period_x, period_y',
    //         3: ', period_x, period_y, period_z',
    //         4: ', period_x, period_y, period_z, period_w'
    //     }[kwargs["inputDimension"]] 
    const x = props.fBm ? "px" : "period_x"
    const y = props.fBm ? "py" : "period_y"
    const z = props.fBm ? "pz" : "period_z"
    const w = props.fBm ? "pw" : "period_w"

    const JSX: Record<dimension, any> = {
        "1D": <>
            <Vex.Code>{`, ${x}`}</Vex.Code>
        </>,
        "2D": <>
            <Vex.Code>{`, ${x}, ${y}`}</Vex.Code>
        </>,
        "3D": <>
            <Vex.Code>{`, ${x}, ${y}, ${z}`}</Vex.Code>
        </>,
        "4D": <>
            <Vex.Code>{`, ${x}, ${y}, ${z}, ${w}`}</Vex.Code>
        </>
    }

    return JSX[props.inputDimension]
};
