import React from 'react';
import { Lesson, Page, Words } from '../../Chapter/Chapter';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { ImageContainer, Img } from '../../Images/Images';

export const VelocityVolume: React.FC = (props) => {
    return (
        <Page topic="volumes">
            <Lesson title="VDB Velocity Field">
                <Section title="" icon="houdini" divider>
                    <ImageContainer>
                        <Img ratio={0.75} src="https://houdini-stuff.s3.amazonaws.com/Houdini/GIFS/vdb_velocity_field_gif.gif" />
                    </ImageContainer>
                    <Words>Make a VDB velocity field with visualization. Hopefully save some time</Words>
                </Section>
                <Section title="Extractable HDA" icon="hda" divider>
                    <ImageContainer>
                        <Img ratio={1} src="https://houdini-stuff.s3.amazonaws.com/Houdini/VOLUMES/vdb_velocity_field.jpg" />
                    </ImageContainer>
                    <Words>Download this network as an HDA. Theres a button on the HDA to extract the network</Words>
                    <Words><Link text="vdbvelocityfield.hdalc" url="https://github.com/keatonwilliamson/hda/raw/master/vdbvelocityfield.hdalc" /></Words>

                </Section>
                <Section title="Python" icon="python" divider>
                    <Words>Make the network with python</Words>
                    <Python>
                        {`
# create geo
geo = hou.node('/obj').createNode('geo', 'velocity_volume')
geo.setUserData("nodeshape", "cloud")
geo.moveToGoodPosition()

# create geometry
sphere = geo.createNode('sphere')
bounds = geo.createNode('bound')

# create vdb
vdb = geo.createNode('vdb')
vdb_activate = geo.createNode('vdbactivate')
volume_wrangle = geo.createNode('volumewrangle')

# create visualization
null = geo.createNode('null')
volume_slice = geo.createNode('volumeslice')
volume_trail = geo.createNode('volumetrail')

# connect
bounds.setFirstInput(sphere)
vdb_activate.setFirstInput(vdb)
vdb_activate.setInput(1, bounds)
volume_wrangle.setFirstInput(vdb_activate)
null.setFirstInput(volume_wrangle)
volume_slice.setFirstInput(null)
volume_trail.setFirstInput(volume_slice)
volume_trail.setInput(1, null)

# set parms
vdb.parm("name1").set("vel")
vdb.parm("type1").set(3) # vector float
vdb_activate.parm("regiontype1").set(3) # set radio button to 'reference'
volume_wrangle.parm("snippet").set("v@vel = curlnoise(v@P);") # vex code

# organize
geo.layoutChildren()
volume_trail.setDisplayFlag(1)
volume_trail.setRenderFlag(1)

# --- Made in Houdini 18.5 ---

                            `}
                    </Python>

                </Section>

                <Words><strong>References:</strong></Words>
                <Words><Link text="VDB Advect Growth" url="https://vimeo.com/322589039" /> ─ Alexander Golubeff</Words>
                <Words><Link text="Switch Radio Button in Python" url="https://forums.odforce.net/topic/33053-switch-radio-button-in-python/" /> ─ odforce</Words>
            </Lesson>
        </Page >
    )
};