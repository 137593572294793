import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from 'styled-components';
import { useApp } from '../App';


const StyledCCode = styled.div<ICCodeProps>`
    position: relative;
    & div.ui.dropdown {
        width: 200px;
        position: absolute;
        right: 0px;
        color: #dcdcdc;
        background: red;
        & * {
            background: red;
        }
    }
    & pre {
        word-wrap: break-word !important;
        overflow-x: hidden !important;
        background: #1e1e1e !important;
        margin-right: -21px;
        margin-left: -21px;
        padding: 12px 0px !important;
        padding-top: 12px !important;
        border-radius: 12px;
    }
    & code.language-c {
        & > span {
            padding: 0px 12px;
            display: block;
        }
        ${props => props.highlighted?.map(number => `& > span:nth-child(${number}) {
            background-color: #022a4b;
            padding-left: 9px;
            border-left: 3px solid #31aae2;
        }`)};
    }
`

interface ICCodeProps {
    darkTheme?: boolean;
    children: any;
    highlighted?: number[];
}


export const CCode: React.FC<ICCodeProps> = ({ ...props }: ICCodeProps) => {
    const { darkTheme } = useApp();

    return (
        <StyledCCode {...props} {...{ darkTheme }}>
            <SyntaxHighlighter
                wrapLongLines
                useInlineStyles={true}
                language="c"
                style={vs2015}
                wrapLines={true}
            >
                {props.children}
            </SyntaxHighlighter>
        </StyledCCode >

    )
};
