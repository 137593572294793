import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension } from '../NoiseConfig';

export interface IFBmPeriodicVariables {
    inputDimension: dimension;
    channelPrefix: string;
    tabs: number;
}

export const FBmPeriodicVariables: React.FC<IFBmPeriodicVariables> = ({ channelPrefix, tabs, ...props }: IFBmPeriodicVariables) => {

    // def fBmPeriodicVariables(**kwargs):
    // tab = kwargs["tab"]
    // period_x = '\n' + tab + 'int px = period_x * period_scale;'
    // period_y = '\n' + tab + 'int py = period_y * period_scale;'
    // period_z = '\n' + tab + 'int pz = period_z * period_scale;'
    // period_w = '\n' + tab + 'int pw = period_w * period_scale;'

    // vars = {
    //     1: period_x,
    //     2: period_x + period_y,
    //     3: period_x + period_y + period_z,
    //     4: period_x + period_y + period_z + period_w
    // }[kwargs["inputDimension"]]

    // return '\n' + tab + "// period" + '\n' + tab +"int period_scale = int(pow(2, i));" + vars + '\n' if kwargs["is_periodic"] else ""
    const variable = (letter: string) => {
        return <Vex.Line {...{ tabs }}>
            <Vex.Code type>{`int `}</Vex.Code>
            <Vex.Code>{`p${letter} = ${channelPrefix}period_${letter} * period_scale;`}</Vex.Code>
        </Vex.Line>
    }

    const JSX: Record<dimension, any> = {
        "1D": <>
            {variable("x")}
        </>,
        "2D": <>
            {variable("x")}
            {variable("y")}
        </>,
        "3D": <>
            {variable("x")}
            {variable("y")}
            {variable("z")}
        </>,
        "4D": <>
            {variable("x")}
            {variable("y")}
            {variable("z")}
            {variable("w")}
        </>
    }

    return <>
        <Vex.Line>

        </Vex.Line>
        <Vex.Line {...{ tabs }}>
            <Vex.Code comment>{`// period`}</Vex.Code>
        </Vex.Line>
        <Vex.Line {...{ tabs }}>
            <Vex.Code type>{`int `}</Vex.Code>
            <Vex.Code>{`period_scale = `}</Vex.Code>
            <Vex.Code type>{`int`}</Vex.Code>
            <Vex.Code>{`(`}</Vex.Code>
            <Vex.Code function>{`pow`}</Vex.Code>
            <Vex.Code>{`(2, i));`}</Vex.Code>
        </Vex.Line>

        {JSX[props.inputDimension]}
        <Vex.Line>

        </Vex.Line>
    </>
};