import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components'
import { useApp } from '../App';

const StyledCheckboxSingle = styled.div<{ darkTheme: boolean; }>`
    
    padding: 8px 0px 0px;

    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    & .label {
        padding: 4px;
        color: ${props => props.darkTheme ? "white" : "black"};
    }

    &  label:after {
        transition: background .2s ease,left .2s ease !important;
    }

    & .ui.toggle.checkbox input:checked~label:before {
        background-color: #31aae2!important;
    }
    & .ui.toggle.checkbox label:before {
        background: #545454!important;
    }
`

export interface CheckboxSingleOption { name: string, disabled?: boolean };

interface ICheckboxSingleProps {
    id: string;
    label: string;
    value: boolean;
    setValue: (id: string, value: boolean) => void;
    dark?: boolean;
}

export const CheckboxSingle: React.FC<ICheckboxSingleProps> = (props: ICheckboxSingleProps) => {
    const { darkTheme } = useApp();

    const handleChange = (event: any, data: any) => {
        // console.log(event, data)
        let id = event.target.id;
        let value = data.checked;
        props.setValue(id, value);
        console.log(id, value);
    };

    return (
        <StyledCheckboxSingle darkTheme={(props.dark) ? true : darkTheme}>
            <div className="item">
                <div className="label">{props.label}</div>
                <Checkbox
                    toggle
                    // label={props.label}
                    onChange={handleChange}
                    checked={props.value}
                    id={props.id}
                />
                {/* <Dropdown
                    id={props.id}
                    onChange={handleDropdownChange}
                    selection
                    options={props.options.map((_, i) => ({
                        text: _.name,
                        key: i,
                        value: i,
                        ...(_.disabled && { disabled: _.disabled })
                    }))}
                    value={props.value}
                >
                </Dropdown> */}
            </div>
        </StyledCheckboxSingle>
    )
};

