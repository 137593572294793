import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension } from '../NoiseConfig';

export interface IOutNoiseProps {
    dimension: dimension;
    useFunction: boolean;
    functionName: string;
}

export const FunctionHeader: React.FC<IOutNoiseProps> = ({ useFunction, ...props }: IOutNoiseProps) => {
    const functionName = (!!props.functionName) ? props.functionName : "Noise";
    const outTypes: Record<dimension, string> = {
        "1D": "float",
        "2D": "",
        "3D": "vector",
        "4D": ""
    }

    return useFunction ?
        <>
            <Vex.Line nobreak>
                <Vex.Code>{`function `}</Vex.Code>
                <Vex.Code type>{`${outTypes[props.dimension]}`}</Vex.Code>
                <Vex.Code>{` ${functionName}(`}</Vex.Code>
                <Vex.Code type>{`vector `}</Vex.Code>
                <Vex.Code>{`P;)`}</Vex.Code>
            </Vex.Line>
            <Vex.Line>
                <Vex.Code>{`{`}</Vex.Code>
            </Vex.Line>
        </>
        :
        <></>
};
