import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import styles from './MovingPointsAlongTheNormal.module.scss';
import { ColorSpan as C, CodeBlock as Block, BreakSpan as Br, Code } from '../../CodeBlock/CodeBlock';
import {
    slate, thiss, propss, variable, func, type, white, purple, red, greenParen, lightBrack, medBrack, darkBrack, string, number, array, comment
} from '../../CodeBlock/CodeBlock';
import {
    VEX_type,
    VEX_comment,
    VEX_string,
    VEX_func,
    VEX_white
} from '../../CodeBlock/CodeBlock';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { ThreeFrame } from '../../Chapter/ThreeFrame/ThreeFrame';
import { ThreeView } from '../../ThreeView/ThreeView';
import { AddParameterButton } from '../../Chapter/InlineButton/InlineButton'
import { ThreeViewWithVisibilitySelector } from '../../ThreeView/ThreeViewWithVisibilitySelector';
import { Section } from '../Section';







// const StyledLesson = styled.div`
//     padding: 12px;
//     border-radius: 12px;
// `
// interface ILesson {
//     title: string;
//     children: any;
// }

export const AddingGeoToNormal: React.FC = (props) => {
    return (
        <Page topic="vex">
            <Lesson title="Draw Lines Between Points">
                <Section title="Starting Geometry:" icon="houdini" divider={false}>
                    <Words>This <Link text="Free Avocado" url="https://www.cgtrader.com/free-3d-models/food/vegetable/avocado-8713d9c4-01ca-47fd-9dd5-89fa79a2ccfc" /> from cgtrader</Words>
                    <ImageContainer>
                        <Img src="https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/normal_avocado.jpg" />
                    </ImageContainer>
                    <ThreeViewWithVisibilitySelector
                        modelNames={["Avocado", "Polywire Normals"]}
                        modelPaths={[
                            "https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/avocado.glb",
                            "https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/spiky_avocado.glb",
                        ]}
                        // HDRI="https://vex-assets.s3.amazonaws.com/quattro_canti.jpg"
                        HDRI="https://vex-assets.s3.amazonaws.com/san_giuseppe_bridge.jpg"
                        // logCameraLocation
                        cameraPosition={{
                            x: -57.91879482205282,
                            y: 124.89315615919259,
                            z: 40.83437437310162
                        }}
                        roughnessMultiplier={1.2}
                        modelYRotation={4.4}
                    />
                </Section>


                <Section title="Set Shared Ids" icon="houdini">
                    <ImageContainer>
                        <Img ratio={0.5} src="https://houdini-stuff.s3.amazonaws.com/VEX/AddGeoToNormals/points.jpg" />
                    </ImageContainer>
                    {/* <Words>Following steps:</Words> */}

                    <Words>Drop a <Link text="Wrangle Node" url="https://www.sidefx.com/docs/houdini/nodes/sop/attribwrangle.html" /> running over <Code>primitives</Code> and add the following vex code:</Words>


                    <Block>
                        <Br>
                            <C color={VEX_comment}>{`//add point at centroid of each primitive`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_comment}>{`//@P gives centroid of primitive`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_type}>{`int `}</C>
                            <C color={VEX_white}>{`point_on_model = `}</C>
                            <C color={VEX_func}>{`addpoint`}</C>
                            <C color={VEX_white}>{`(0, v@P);`}</C>
                        </Br>
                        <Br></Br>
                        <C color={VEX_comment}>{`//add point at primitive normal position`}</C>
                        <Br>
                            <C color={VEX_type}>{`int `}</C>
                            <C color={VEX_white}>{`point_on_normal = `}</C>
                            <C color={VEX_func}>{`addpoint`}</C>
                            <C color={VEX_white}>{`(0, v@P + v@N);`}</C>
                        </Br>
                        <Br></Br>
                        <Br>
                            <C color={VEX_comment}>{`//set a shared id attribute on the points`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_comment}>{`//@primnum gives the index of the current iteration`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_comment}>{`//the id will be unique to the iteration`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_func}>{`setpointattrib`}</C>
                            <C color={VEX_white}>{`(0, `}</C>
                            <C color={VEX_string}>{`"id"`}</C>
                            <C color={VEX_white}>{`, point_on_model, @primnum, `}</C>
                            <C color={VEX_string}>{`"set"`}</C>
                            <C color={VEX_white}>{`);`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_func}>{`setpointattrib`}</C>
                            <C color={VEX_white}>{`(0, `}</C>
                            <C color={VEX_string}>{`"id"`}</C>
                            <C color={VEX_white}>{`, point_on_normal, @primnum, `}</C>
                            <C color={VEX_string}>{`"set"`}</C>
                            <C color={VEX_white}>{`);`}</C>
                        </Br>
                        <Br></Br>
                        <Br>
                            <C color={VEX_comment}>{`//remove the primitive if you want`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_func}>{`removeprim`}</C>
                            <C color={VEX_white}>{`(0, @primnum, 1);`}</C>
                        </Br>
                        <Br></Br>
                        <Br></Br>
                    </Block>
                </Section>
                <Section title="Add Lines" icon="houdini">
                    <ImageContainer>
                        <Img ratio={0.5} src="https://houdini-stuff.s3.amazonaws.com/VEX/AddGeoToNormals/lines.jpg" />
                    </ImageContainer>
                    <Words>Drop an <Link text="Add" url="https://www.sidefx.com/docs/houdini/nodes/sop/add.html" /> Node with these parameters: <Code>Polygons → By Group → By Attribute → id</Code></Words>
                    <Words>This method uses the matching id attributes to draw a line between each point on the model and the corresponding point at the normal position.</Words>
                    <ImageContainer>
                        <Img ratio={0.465} src="https://houdini-stuff.s3.amazonaws.com/VEX/AddGeoToNormals/add.jpg" />
                    </ImageContainer>
                </Section>
                <Section title="Add Polywire" icon="houdini">
                    <ImageContainer>
                        <Img ratio={0.5} src="https://houdini-stuff.s3.amazonaws.com/VEX/AddGeoToNormals/wire.jpg" />
                    </ImageContainer>
                    <Words>Drop a <Link text="PolyWire" url="https://www.sidefx.com/docs/houdini/nodes/sop/polywire.html" /> Node if you want.</Words>
                    <Words>Here's the final node network:</Words>
                    <ImageContainer>
                        <Img ratio={0.7} src="https://houdini-stuff.s3.amazonaws.com/VEX/AddGeoToNormals/network.jpg" />
                    </ImageContainer>
                </Section>
                
                <Words><strong>References:</strong></Words>
                {/* <Words><Link text="VEX in Houdini" url="https://www.cgmasteracademy.com/courses/15-vex-in-houdini" /> by Johannes Richter</Words> */}
                <Words><Link text="5 Ways to replace a Prim with a Point | Houdini Quicktips" url="https://www.youtube.com/watch?v=iyZ8dIqJ47U" /> by David Kahl</Words>
            </Lesson>
        </Page>
    )
};
