import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { CalcThicknessHDA } from "./CalcThickness";
import { HDA } from "./HDA";


const base_url = "/houdini/hda"

export const HDARoutes = () => {
    return (<>
        <Route exact path={`${base_url}/hda`}>
            <HDA />
        </Route>
        <Route exact path={`${base_url}/calc_thickness`}>
            <CalcThicknessHDA />
        </Route>

    </>
    )
};

export const HDAArticles = () => {
    return (<>
        <ArticleLink
            title="HDA"
            date="April 16, 2021"
            description="Making a Library"
            url={`${base_url}/hda`}
            emoji="📦"
        />
        <ArticleLink
            title="Calculate Thickness"
            date="April 24, 2021"
            description="Calculate the thickness of Geometry by shooting rays inside"
            url={`${base_url}/calc_thickness`}
            emoji="🚿"
        />
    </>
    )
};