import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Tip } from '../../CodeBlock/CodeBlock';


export const DisplayFlags: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="Display Flags">
                <Words><Link text="Network types and node flags" url="https://www.sidefx.com/docs/houdini/network/flags.html" /> ─ SideFX docs</Words>
                <Section title="Object" icon="houdini" divider>
                    <Python>
                        {`
# Selectable
geo.parm('picking').set(1)

# Display
geo.setDisplayFlag(1)
                            `}
                    </Python>
                </Section>
                <Section title="Sop" icon="houdini" divider>

                    <Python>
                        {`
# Bypass
sphere.bypass(1)

# Lock
sphere.setHardLocked(1)

# Template
sphere.setTemplateFlag(1)

# Display
sphere.setDisplayFlag(1)

# Render
sphere.setRenderFlag(1)
`}
                    </Python>
                    <Words><Link text="hou.SopNode class" url="https://www.sidefx.com/docs/houdini/hom/hou/SopNode.html" /> ─ SideFX docs</Words>
                </Section>
                <Section title="Dop" icon="houdini" divider>

                    <Python>
                        {`
# Bypass
sphere.bypass(1)

# Hidden
sphere.setTemplateFlag(1)

# Output
sphere.setDisplayFlag(1)
`}
                    </Python>
                    <Words><Link text="hou.DopNode class" url="https://www.sidefx.com/docs/houdini/hom/hou/DopNode.html" /> ─ SideFX docs</Words>
                </Section>
                <Section title="Cop" icon="houdini" divider>

                    <Python>
                        {`
# Bypass
color.bypass(1)

# Template
color.setTemplateFlag(1)

# Output
color.setRenderFlag(1)

# Display
color.setDisplayFlag(1)
`}
                    </Python>
                    <Words><Link text="hou.CopNode class" url="https://www.sidefx.com/docs/houdini/hom/hou/CopNode.html" /> ─ SideFX docs</Words>
                </Section>

            </Lesson>
        </Page >
    )
};
