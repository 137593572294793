import React from 'react';

export interface IVexLine {
    tabs?: number;
    nobreak?: boolean;
    space?: boolean;
};

export const VexLine: React.FC<IVexLine> = ({ tabs, nobreak = false, space = false, ...props }) => {
    const indent = new Array(tabs ? tabs * 4 : 0).fill(null).map((_, i) => <React.Fragment key={i}>&nbsp;</React.Fragment>);
    return (<>
        {nobreak ? <></> : < br />}
        {space ? <>< br /></> : <></>}
        <>{indent}{props.children}</>
    </>
    )
};
