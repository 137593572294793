import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Code, Tip } from '../../CodeBlock/CodeBlock';
import { CCode } from '../../CodeBlock/CCode';


export const CalcThicknessHDA: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="Calculate Thickness HDA">
                <Section title="" icon="houdini">
                    <ImageContainer>
                        <Img ratio={0.5625} src="https://houdini-stuff.s3.amazonaws.com/Houdini/GIFS/measure_thickness.gif" />
                    </ImageContainer>
                    <Words>This is a spinoff of the Labs Calculate Occlusion. Rather than shooting rays outside the geometry and averaging how many rays hit something, it shoots rays inside the geometry and averages the distance traveled by the rays.</Words>
                    <Link text="calculate_thickness.hdalc" url="https://github.com/keatonwilliamson/hda/raw/master/calculate_thickness.hdalc"></Link>
                    <CCode>{`// main functionality

int prim;
vector pos, uvw;
vector origin = v@P - (v@N * 0.001);
float dist;

float cone_width = radians(ch('cone_width'));
int rays = chi('rays');
vector ray_dir;

float distances[] = array();

for (int i = 0; i < rays; i++ ) {

    ray_dir = sample_direction_cone(v@N * -1, cone_width , vector2(rand(i*42069)));
    prim = intersect(0, origin, ray_dir*9999, pos, uvw);
    if (prim != -1) {
        dist = length(pos - v@P);
        append(distances, dist);
    }
}

f@_thickness = avg(distances);`}</CCode>
                </Section>
            </Lesson>
        </Page >
    )
};
