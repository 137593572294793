import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Link, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';
import { transform } from 'typescript';
import { useApp } from '../App';
import { ReactToggle } from '../Chapter/Chapter';
import { Gallery } from '../Gallery/Gallery';
import { LargeToggle } from '../Toggle/LargeToggle';
import { CloudLoader } from './CloudLoader';
import { Link as L } from './../Chapter/Link/Link';
import MobileBar from './MobileBar';
import Web from './Web';
import Effects from './Effects';
import Photos from './Photos';


const StyledImg = styled.div<{ darkTheme: boolean; }>`
    z-index: -1;
    width: 100vw;
    height: 66.66667vw;
    position: relative;
    margin: auto;
    pointer-events: none;

    //transform: translateZ(-600px) translateY(-100vh) scale(3);
    transform: translateZ(-300px) translateY(-50vh) scale(2);
    transform-origin: center top;

    //border: 7px solid red;
    position: absolute;
    top: 0;
    & img {
      position: absolute; 
      right: 0; 
      bottom: 0;
      min-width: 100%; 
      min-height: 100%;
      max-width: 100%; 
      max-height: 100%;
      width: auto; 
      height: auto; 
      overflow: hidden;
      transition: opacity 0.2s ease-out;
      &.light {
        opacity: ${props => props.darkTheme ? 0 : 1};
      }
      &.dark {
        opacity: ${props => props.darkTheme ? 1 : 0};
      }
    }
`
const StyledVideo = styled.div<{ darkTheme: boolean; }>`

    //border: 7px solid red;
    z-index: -2;
    width: 100vw;
    height: 66.66667vw;
    position: relative;
    margin: auto;

    transform: translateZ(-1200px) translateY(-200vh) scale(5);
    transform: translateZ(-600px) translateY(-100vh) scale(3);
    transform-origin: center top;

    & video {
      position: absolute; 
      right: 0; 
      bottom: 0;
      min-width: 100%; 
      min-height: 100%;
      max-width: 100%; 
      max-height: 100%;
      width: auto; 
      height: auto; 
      overflow: hidden;

      transition: opacity 0.2s ease-out;
    }
    & video.light {
    }
    & video.dark {
      opacity: ${props => props.darkTheme ? 1 : 0};
    }
    & header {
      position: absolute;
      top: 17vw;
      width: 100vw;
      flex-direction: column;
      align-items: center;
      & nav {
        margin: auto;
        font-family: futura-pt;
        font-weight: 400;
        font-style: normal;
        font-size: 18px;
        letter-spacing: .35em;
        text-transform: uppercase;
        line-height: 1em;
        display: block;
        width: fit-content;
        & a {
          color: ${props => props.darkTheme ? "white" : "black"};
          transition: color 0.2s ease-out;
        }
        &>div {
          display: inline-block;
          margin: 0;
          cursor: pointer;
          transition: color 140ms cubic-bezier(.33,0,.2,1);
          &:hover {
            color: white;
          }
        }
        &>div+div {
          margin-left: 47px;
        }
      }
    }
`

const StyledLanding = styled.div<{ darkTheme: boolean; headerMedialoaded: boolean; pageContentLoaded: boolean; navOpen: boolean; }>`




${props =>
    !props.headerMedialoaded ?
      `
  &>* {
    display: none
  };
  opacity: 0;
  `
      :
      `
  opacity: 1;
  `}
  transition: opacity .78s;
  background: ${props => props.darkTheme ? "#2a2b37" : "white"};
  transition: background 0.2s ease-out;
  
  height: 500px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-perspective: 300px;
  perspective: 300px;
  
  & svg.triangle {
    display: block;
    & polygon {
      fill: ${props => props.darkTheme ? "#2a2b37" : "white"};
      transition: fill 0.2s ease-out;
    } 
  }
  
  & .content-container {
    background: ${props => props.darkTheme ? "#2a2b37" : "white"};
    color: ${props => props.darkTheme ? "white" : "#222222"};
    transition: background 0.2s ease-out;
    padding-top: 3vw;
    min-height: 100vh;
  }
  
  & .site {
    margin: 1.5vw auto;
    padding: 0px 1.5vw;
    max-width: 1280px;
    width: auto;
  }
  
  @media screen and (min-aspect-ratio: 1 / 1) and (min-width: 864px) and (max-width: 1920px) {
    .item-title {
      font-size: 2.08333333vw;
    }
  }
  @media screen and (min-aspect-ratio: 1 / 1) and (max-width: 864px) {
    .item-title {
      font-size: 18px;
    }
  }
  @media screen and (min-aspect-ratio: 1 / 1) and (min-width: 1920px) {
    .item-title {
      font-size: 40px;
    }
  }
  .item-title {
    font-family: futura-pt;
    font-weight: 400;
    font-style: normal;
    line-height: 1.3em;
    letter-spacing: .14em;
    text-transform: uppercase;
  }
  
  & .frame {
    width: 100%;
    height: 96vh;
    max-height: 872px;
    width: 100%;
    display: block;
    margin: auto;
    border: none;
    overflow-y: auto;
    overflow-x: hidden;
    &.minimoog {
      max-height: 872px;
    }
    &.pokemon {
      
    }
    &.houdini {
      //border: 7px solid ${props => props.darkTheme ? "white" : "rgba(0,0,0,.1)"};
      border-radius: 8px;
      box-shadow: rgb(0 0 0 / 30%) 0px 4px 8px 0px;
      height: 84vh;
    }
  }
  
  
  @media only screen and (max-width: 768px) {
  
    overflow-y: ${props => (props.navOpen || !props.pageContentLoaded) ? "hidden" : "auto"} !important;
  
    & .styled_video, styled_img {
      display: none;
    }
    & svg.triangle { 
      display: none !important;
    }
  }
  
  
  `

interface RouteInfo {
  navitem: string;
}

interface ILandingProps extends RouteComponentProps<RouteInfo> {
}

export interface ILandingPageContentProps {
  setPageContentLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const Landing: React.FC<ILandingProps> = (props) => {
  const { darkTheme, setDarkTheme } = useApp();
  const [navOpen, setNavOpen] = useState<boolean>(false);

  const [pageContentLoaded, setPageContentLoaded] = useState<boolean>(true);

  const [loadedElements, setLoadedElements] = useState({
    imgLight: false,
    imgDark: false,
    vidLight: false,
    vidDark: false,
    // childFirstLoad: true
  });

  const headerMedialoaded = Object.values(loadedElements).every(_ => _);


  const containerRef = useRef(null);

  const executeScroll = (ref: React.MutableRefObject<any>) => {
    if (!!ref) ref.current.scrollIntoView({ behavior: "smooth" });
    // window.scrollTo({ behavior: "smooth", top: ref.current.offsetTop });
  };

  const handleNavClick = (evt: any) => {
    // props.match.params.navitem
    if (props.match.params.navitem == evt.target.id) {
      executeScroll(containerRef);
    }
  }
  const handleHoudiniNotesClick = (evt: any) => {
    // props.match.params.navitem

  }

  useEffect(() => {
    // props.match.params.topic
    // console.log(props.match.params.navitem)
    if (!!props.match.params.navitem) executeScroll(containerRef);
    // !!containerRef && executeScroll(containerRef);
  }, [props.match.params.navitem]);

  const handleMediaLoad = (evt: any) => {
    let id = evt.target.id;
    setLoadedElements(obj => ({ ...obj, [id]: true }))
    // console.log("loaded ", evt.target.id)
  }




  return (
    <>
      <CloudLoader loaded={headerMedialoaded} />
      <StyledLanding pageContentLoaded={pageContentLoaded} headerMedialoaded={headerMedialoaded} {...{ darkTheme, navOpen }}>
        {/* <StyledMobileBar loaded={loaded} {...{ darkTheme }}>
          <div className="mobile-branding-wrapper">
            <div className="mobile-site-title">Keaton Williamson</div>
          </div>
          <label className="mobile-nav-toggle-label">
            <svg className="hamburger-icon" viewBox="0 0 24 18">
              <g className="svg-icon">
                <line fill="none" stroke-miterlimit="10" x1="0" y1="2" x2="24" y2="2"></line>
                <line fill="none" stroke-miterlimit="10" x1="0" y1="9" x2="24" y2="9"></line>
                <line fill="none" stroke-miterlimit="10" x1="0" y1="16" x2="24" y2="16"></line>
              </g>
            </svg>
          </label>
          <LargeToggle checked={darkTheme} styles={{ padding: "3vw" }} defaultChecked={darkTheme} onChange={() => setDarkTheme(_ => !_)} />
        </StyledMobileBar> */}
        <MobileBar {...{ navOpen, setNavOpen }} />
        <StyledVideo darkTheme={darkTheme} className="styled_video">
          <LargeToggle checked={darkTheme} styles={{ position: "absolute", zIndex: 1, top: 24, right: 48 }} defaultChecked={darkTheme} onChange={() => setDarkTheme(_ => !_)} />

          <video id="vidLight" onCanPlay={handleMediaLoad} autoPlay loop={true} muted className="light" >
            <source src={"https://houdini-stuff.s3.amazonaws.com/Landing/corrected_mountain_light_large.mp4"} />
          </video>
          <video id="vidDark" onCanPlay={handleMediaLoad} autoPlay loop={true} muted className="dark" >
            <source src={"https://houdini-stuff.s3.amazonaws.com/Landing/mountain_dark_large.mp4"} />
          </video>
          <header>
            <nav>
              <div >
                <Link id="apps" onClick={handleNavClick} to="/home/apps">Apps</Link>
              </div>
              <div>
                <Link id="effects" onClick={handleNavClick} to="/home/effects">Graphics</Link>
              </div>
              <div>
                <Link id="photography" onClick={handleNavClick} to="/home/photography">Photos</Link>
              </div>
              <div>
                <Link id="photography" to="/houdini">↗Houdini</Link>
                {/* <a href="/houdini">Houdini↗</a> */}
              </div>
              {/* <div>
                <Link id="resume" onClick={handleNavClick} to="/home/resume">Resume</Link>
              </div> */}
            </nav>
          </header>
        </StyledVideo>
        <svg style={{ marginTop: "-10vw" }} className="triangle" viewBox="0 0 700 50" width="100vw"><polygon points="0 50,700 50, 0 0"></polygon></svg>
        <div ref={containerRef} className="content-container">
          {/* {props.children} */}
          <Route exact path={["/", "/home/apps"]} render={() => (
            <Web {...{setPageContentLoaded}}/>
          )} />
          <Route exact path="/home/effects" render={() => (
            <Effects {...{setPageContentLoaded}}/>
          )} />
          <Route exact path="/home/photography" render={() => (
            <Photos />
          )} />
        </div>
        <StyledImg className="styled_img" darkTheme={darkTheme}>
          <img id="imgLight" onLoad={handleMediaLoad} className="light" src="https://houdini-stuff.s3.amazonaws.com/Landing/corrected_mountain_light.png" />
          <img id="imgDark" onLoad={handleMediaLoad} className="dark" src="https://houdini-stuff.s3.amazonaws.com/Landing/mountain_dark_small.png" />
        </StyledImg>

      </StyledLanding>
    </>
  )
};

export default withRouter(Landing);
