import React, { Component, useEffect, useRef } from "react";
import styles from './Link.module.scss';
import styled from 'styled-components';

const A = styled.a<{nowrap?: boolean}>`
    ${props => props.nowrap ? "white-space: nowrap;" : ""}
    color: #31aae2;
    &:hover {
        text-decoration: none;
    }

`

interface LinkProps {
    nowrap?: boolean;
    url: string,
    text?: string
}
export const Link: React.FC<LinkProps> = ({ url, text, nowrap }) => <A nowrap={nowrap} href={url} target="_blank" rel="nofollow noopener noreferrer">{text ? text : url}</A>

