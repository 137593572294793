import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { NukeShortcuts } from "./NukeShortcuts";


const base_url = "/houdini/nuke"

export const NukeRoutes = () => {
    return (<>
        <Route exact path={`${base_url}/hotkeys`}>
            <NukeShortcuts />
        </Route>

    </>
    )
};

export const NukeArticles = () => {
    return (<>
        <ArticleLink
            title="Nuke Shortcuts"
            date="Feb 10, 2021"
            description="Nuke shortcuts"
            url="/houdini/nuke/hotkeys"
            emoji="💣"
        />
    </>
    )
};