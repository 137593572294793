
import React, { useState } from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Code, Tip } from '../../CodeBlock/CodeBlock';
import { TurbulentSprites } from '../../Sprites/TurbulentSprites';
import { Vex } from '../../Vex/VexBlock';
import { DropdownForm, IDropdownState } from '../../Form/DropdownForm';
import { DomainWarpSprites } from '../../Sprites/DomainWarpSprites';
import { WideBlogSection } from '../../WebComponents/WideBlogSection';


export const TurbulenceVisualizer: React.FC = (props) => {

    return (
        <Page topic="noise">
            <Lesson title="Turbulence Visualizer">
                <Section title="" icon="houdini" divider>
                    <WideBlogSection max_width="1100px">

                        <TurbulentSprites />
                    </WideBlogSection>

                </Section>
            </Lesson>
        </Page >
    )
};


// group 1
// exact same params
// perlin noise()
// simplex xnoise()

// group 2
// params exact the same (click through tabs)
// onoise() original perlin
// snoise() sparse convolution
// anoise() alligator

// group 3 
// exact same params
// wnoise()
// mwnoise()
// cwnoise()

// group 4
// curlnoise()
// curlxnoise()

// 4.5 for the 2d functions
// curlnoise2d()
// curlxnoise2d()



// This operator can compute three types of 1D and 3D noise with the ability to compute turbulence with roughness and attenuation:

// Perlin noise              (string value "pnoise")
// Original Perlin noise     (string value "onoise")
// Sparse Convolution noise  (string value "snoise")
// Alligator noise           (string value "anoise")
// Simplex noise             (string value "xnoise")
// Zero Centered Perlin      (string value "correctnoise")
// Original Perlin noise is similar to Perlin noise, but marginally less efficient in computation and with different characteristics. The bounds on the noise are roughly (-1, 1) when turbulence is 0.

// Perlin noise sums octaves of a noise with range about (0, 1), resulting in a non-zero centered result. Thus the zero centered perlin better matches the ranges of the other noise fields.

// Sparse Convolution noise is similar to Worley noise. The noise returned is based on the weights of all of the closest points, with each point’s contribution based on a meta-ball like rolloff curve. That is, if the sample point is close to the sphere, its contribution will be greater. The bounds on the noise are roughly (-1.7, 1.7) when turbulence is 0.

// Alligator noise is similar to Worley noise. It is currently not possible to simulate Alligator noise using the Worley functions, but it is possible to get a very similar 'look'. The bounds on the noise are roughly (0, 0.5) when turbulence is 0.

// Simplex noise is very close to Perlin noise, except with the samples on a simplex mesh rather than a grid. This results in less grid artifacts. It also uses a higher order bspline to provide better derivatives.

// The relative costs for computing noise of different types is roughly:

// Cost | Noise Type
// -----+-------------------------
//  1.0 | Perlin Noise             (see Periodic Noise operator)
//  1.1 | Original Perlin Noise
//  1.8 | Worley Noise             (see Worley Noise operator)
//  1.8 | Periodic Worley Noise    (see Periodic Worley Noise operator)
//  2.1 | Sparse Convolution Noise
//  2.3 | Alligator Noise

// https://www.sidefx.com/docs/houdini/nodes/vop/turbnoise.html

// conditional props
{/* <Vex.Code {...(OSA.Output.value === 1 ? { float: true } : { vector: true })} ></Vex.Code> */ }


// vector npos = v@P/1. + set(0., 666., 0.);   // Noise input 3D position
// float namp = 1.;                            // namp (Noise amplitude)
// float nval = 0., nweight = 0.;              // Init nval (Noise output value), and nweight (Used to normalize octaves)
// int oct = 9;                                // Number of Octaves
// for( int i = 0; i < oct; i++ )    {
//     float __nval = fit(abs(-0.5+noise(set(npos.x,npos.y,npos.z,f@Time))), 0.0, 0.1, 1., 0.);
//     nval += __nval * namp;                  // Amplitude
//     nweight += namp;                        // Accumulate weight
//     npos *= 2.132433;                       // Lacunarity
//     namp *= 0.666;                          // Roughness
// }
// v@Cd = 1 - pow(nval / nweight, 0.8765);     // Visualize Noise Output

//https://www.classes.cs.uchicago.edu/archive/2015/fall/23700-1/final-project/MusgraveTerrain00.pdf



// float fBm(vector pos){
//     int ot = chi("octives");
//     float freq = chf("frequency"),
//             amp =  chf("amp"),
//             lac = ch("lacuarity"),
//             pers = ch("persistence"),
//             result = 0;
//     for(int i=0;i<ot;i++){
//         result+= snoise(pos*freq)*amp;
//         amp*=pers;
//         freq*=lac;
//     }
//     return result;
// }
// //distort all axises at the same time
// float fBm_distortXYZ(vector pos){
//     vector dx = chv("domain_distortX"),dy =  chv("domain_distortY"), dz =  chv("domain_distortZ");
//     vector lookup = set(fBm(pos+dx), fBm(pos+dy), fBm(pos+dz));
//    return fBm(pos + lookup*chf("warpAmount1"));
// };
// //manually pick axises (0-2)
// float fBm_distortAxis(vector pos; int axis){
//     vector dx = chv("domain_distort"),dy =  chv("domain_distort"), dz =  chv("domain_distort");
//     float distortedVal = fBm(pos+dx);
//     float val = fBm(pos);
//     vector lookup = val;
//     lookup[axis] = distortedVal;
//    return fBm(pos + lookup*chf("warpAmount1"));
// };

// close
// y = 1 - (0.333333) * x^(0.15)\ 



/// notes on sphere setup


// parking garage hdri

// radius 5
// sphere frequency 800
// principle shader with roughness 0.5 and reflectivity 0.5
// typical sun and environment
// camera at focal len 50
// resolution 512 x 512
// translate -12, 0, 12
//rotate 0, -45, 0



// mike bostock

// float onoise(vec3 v) {
//     float sum = 0.0;
//     float frequency = 1.0;
//     float amplitude = 1.0;
//     float max = 0.0;
//     for (int i = 0; i < octaves; i++) {
//       sum += snoise(v * frequency) * amplitude;
//       max += amplitude;
//       amplitude *= persistence;
//       frequency *= 2.0;
//     }
//     return sum / max;
//   }