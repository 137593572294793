import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { FontFromVex } from "./FontFromVex";
import { HDA } from "./HDA";
import { KeyboardShortcuts } from "./KeyboardShortcuts";


const base_url = "/houdini/ui"

export const UiRoutes = () => {
    return (<>
        <Route exact path={`${base_url}/hotkeys`}>
            <KeyboardShortcuts />
        </Route>
        <Route exact path={`${base_url}/font_from_vex`}>
            <FontFromVex />
        </Route>
    </>
    )
};

export const UiArticles = () => {
    return (<>
        <ArticleLink
            title="Keyboard Shortcuts"
            date="November 22, 2020"
            description="hot keys"
            url={`${base_url}/hotkeys`}
            emoji="🔥"
        />
        <ArticleLink
            title="Font From VEX Using Spare Inputs"
            date="April 25, 2021"
            description="Build dynamic strings in VEX to be used in a Font Node"
            url={`${base_url}/font_from_vex`}
            emoji="📝"
            icons={["vex"]}
        />
    </>
    )
};