import React from 'react';
import { ColorSpan as C, CodeBlock as Block, BreakSpan as Br, Code } from '../../CodeBlock/CodeBlock';
import {
    VEX_type,
    VEX_comment,
    VEX_string,
    VEX_func,
    VEX_white
} from '../../CodeBlock/CodeBlock';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { ThreeFrame } from '../../Chapter/ThreeFrame/ThreeFrame';
import { ThreeView } from '../../ThreeView/ThreeView';
import { AddParameterButton } from '../../Chapter/InlineButton/InlineButton'
import { ThreeViewWithVisibilitySelector } from '../../ThreeView/ThreeViewWithVisibilitySelector';
import { TableComponent } from '../../TableComponent/TableComponent';
import { Section } from '../Section';

export const KeyboardShortcuts: React.FC = (props) => {
    return (
        <Page topic="ui">
            <Lesson title="Keyboard Shortcuts">



                <Section title="Node Network" icon="houdini" divider={false}>
                    <TableComponent
                        colA={["Scissors to cut node connectors",
                            "Connect nodes",
                            "Bend lines",
                            "Change wiring style",
                            "Align multiple nodes",
                            "Move node and all upstream nodes",
                            "Move node and all downstream nodes",
                            "Connect multiple node outputs to multiple inputs",
                            "Jump out a level",
                            "Dive in a level",
                            "Change node Color",
                            "Change node Shape",
                            "Change the default color or shape of a node",
                            "Open mini parameter window",
                            "Make a sticky note",
                        "Small Network Window"]}
                        colB={["Hold Y and slash across",
                            "Hold J and slash across",
                            "Alt + drag",
                            "Shift + S",
                            "Select multiple + Hold A + drag up, down, left, or right",
                            "Shift + drag",
                            "Ctrl + drag",
                            "Shift + click outputs & drag to first input",
                            "U",
                            "I",
                            "C",
                            "Z",
                            "Ctrl + drag the color/shape onto the node",
                            "P",
                            "Shift + P",
                        "O"]}
                    />

                </Section>
                <Section title="UI Navigation" icon="houdini" divider={false}>
                    <TableComponent
                        colA={["Open Visualizer Marker Menu"]}
                        colB={["D"]}
                    />

                </Section>

                <Section title="3D Viewport" icon="houdini" divider={false}>

                    <TableComponent
                        colA={["Camera Perspective",
                            "Top Perspective",
                            "Front Perspective",
                            "Right Perspective",
                            "UV View",
                            "Wireframe"]}
                        colB={["Space + 1",
                            "Space + 2",
                            "Space + 3",
                            "Space + 4",
                            "Space + 5",
                            "W"]}
                    />

                </Section>
                <Section title="Geometry" icon="houdini" divider={false}>

                    <TableComponent
                        colA={["Mirror transform handle moves",
                            "Select a loop of polygons",
                            "Unselect geometry"]}
                        colB={["Shift + drag handle",
                            "Shift + A & Middle mouse adjacent",
                            "Ctrl + drag square"]}
                    />

                </Section>
            </Lesson>
        </Page>
    )
};


// show groups shift z
// switch merge inputs r


// <Lesson title="Under Construction Notes">

// <Section title="Moving Points Along the Normals">
//     <ImageContainer>
//         <Img ratio={0.5} src="https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/fat_avocado_snap.jpg" />
//     </ImageContainer>

//     <Words>This VEX code will mimic the behavior of a <Link text="Peak SOP" url="https://www.sidefx.com/docs/houdini/nodes/sop/peak.html" />. Add the following code to a <Link text="Wrangle Node" url="https://www.sidefx.com/docs/houdini/nodes/sop/attribwrangle.html" /> running over <Code>points</Code>.</Words>
//     <Block>
//         <Br>
//             <C color={VEX_type}>{`float `}</C>
//             <C color={VEX_white}>{`amount = `}</C>
//             <C color={VEX_func}>{`chf`}</C>
//             <C color={VEX_white}>{`(`}</C>
//             <C color={VEX_string}>{`"amount"`}</C>
//             <C color={VEX_white}>{`);`}</C>
//         </Br>
//         <Br></Br>
//         <Br></Br>
//         <C color={VEX_comment}>{`// move points along normal by amount`}</C>
//         <Br>
//             <C color={VEX_white}>{`v@P += v@N * amount;`}</C>
//         </Br>
//         <Br></Br>
//         <Br></Br>
//     </Block>
//     <ImageContainer>
//         <Img src="https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/exploded_avocado.jpg" />
//     </ImageContainer>
//     <Words>Press the <strong>Add Parameter</strong> <AddParameterButton /> button located to the right of the code editor. This will autogenerate a parameter slider for your <Code>amount</Code> variable.</Words>
//     <Words>Your geometry should expand like a balloon because the vector position <Code>{`{x, y, x}`}</Code> of every point on the mesh has been summed with the normal vector <Code>{`{x, y, x}`}</Code>, causing it to move position outward.</Words>
//     <Words><strong>Example: </strong>If the original point is at <Code>{`{3.0, 5.0, 4.0}`}</Code> and the normal direction is <Code>{`{0.7, 0.5, 1.0}`}</Code> the new point position will be <Code>{`{3.7, 5.5, 5}`}</Code>. The <Code>"amount"</Code> <Link text="slider" url="https://www.sidefx.com/docs/houdini/vex/functions/chf.html" />  acts as a multiplier for this movement.</Words>
//     <Words><strong>Tip: </strong>It's a good idea to add a <Link text="Normal Node" url="https://www.sidefx.com/docs/houdini/nodes/sop/normal.html" /> after this operation to recompute lost normals.</Words>

// </Section>

// <Section title="Moving Points In The Opposite Direction">
//     <ImageContainer>
//         <Img ratio={0.5} src="https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/skinny_avocado_snap.jpg" />
//     </ImageContainer>

//     <Words>To move in points in the opposite direction of the normal, simply change the <Code>+=</Code> to <Code>-=</Code></Words>
//     <Block>
//         <Br></Br>
//         <C color={VEX_comment}>{`// move points in the opposite direction of normal by amount`}</C>
//         <Br>
//             <C color={VEX_white}>{`v@P -= v@N * amount;`}</C>
//         </Br>
//         <Br></Br>
//         <Br></Br>
//     </Block>


// </Section>
// <Section title="Adding Polywire to Normals">
//     <ImageContainer>
//         <Img ratio={0.5} src="https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/spiky_avocado_snap.jpg" />
//     </ImageContainer>
//     <Words>In this example you'll add geometry along the normals with the following steps:</Words>

//     <OrderedList>
//         <li>Add a point at the centroid of each primitive in your model</li>
//         <li>Add a point at the end of each normal vector</li>
//         <li>Set a shared id attribute on both</li>
//         <li>Link them together with a primitive using the new shared id attribute and an <Link text="Add Geometry Node" url="https://www.sidefx.com/docs/houdini/nodes/sop/add.html" /></li>
//         <li>Replace your new primitives (lines) with geometry using a <Link text="Polywire Node" url="https://www.sidefx.com/docs/houdini/nodes/sop/polywire.html" /></li>
//     </OrderedList>
//     <Words>First, drop a <Link text="Wrangle Node" url="https://www.sidefx.com/docs/houdini/nodes/sop/attribwrangle.html" /> running over <Code>primitives</Code> and add the folling VEX code:</Words>

//     <Block>
//         <Br>
//             <C color={VEX_comment}>{`// remove primitive on every iteration`}</C>
//         </Br>
//         <Br>
//             <C color={VEX_func}>{`removeprim`}</C>
//             <C color={VEX_white}>{`(0, @primnum, 1);`}</C>
//         </Br>
//         <Br></Br>
//         <Br>
//             <C color={VEX_comment}>{`// execute this logic every 3 iterations to reduce the number of normals visualized`}</C>
//         </Br>
//         <Br>
//             <C color={VEX_type}>{`if `}</C>
//             <C color={VEX_white}>{`(@primnum % 3 == 0) {`}</C>
//         </Br>
//         <Br></Br>
//         <Br tab={1}>
//             <C color={VEX_comment}>{`// OG point on the mesh`}</C>
//         </Br>
//         <Br>
//             <C color={VEX_type}>{`int `}</C>
//             <C color={VEX_white}>{`original_point = `}</C>
//             <C color={VEX_func}>{`addpoint`}</C>
//             <C color={VEX_white}>{`(0, v@P);`}</C>
//         </Br>
//         <Br></Br>
//         <C color={VEX_comment}>{`// new point at the end of the normal vector`}</C>
//         <Br>
//             <C color={VEX_type}>{`int `}</C>
//             <C color={VEX_white}>{`normal_point = `}</C>
//             <C color={VEX_func}>{`addpoint`}</C>
//             <C color={VEX_white}>{`(0, v@P + v@N);`}</C>
//         </Br>
//         <Br></Br>
//         <Br>
//             <C color={VEX_comment}>{`// set the id on both equal to the index of the current iteration (@primnum)`}</C>
//         </Br>
//         <Br>
//             <C color={VEX_comment}>{`// use this shared id to link them later`}</C>
//         </Br>
//         <Br></Br>
//         <Br>
//             <C color={VEX_func}>{`setpointattrib`}</C>
//             <C color={VEX_white}>{`(0, `}</C>
//             <C color={VEX_string}>{`"id"`}</C>
//             <C color={VEX_white}>{`, original_point, @primnum, `}</C>
//             <C color={VEX_string}>{`"set"`}</C>
//             <C color={VEX_white}>{`);`}</C>
//         </Br>
//         <Br>
//             <C color={VEX_func}>{`setpointattrib`}</C>
//             <C color={VEX_white}>{`(0, `}</C>
//             <C color={VEX_string}>{`"id"`}</C>
//             <C color={VEX_white}>{`, normal_point, @primnum, `}</C>
//             <C color={VEX_string}>{`"set"`}</C>
//             <C color={VEX_white}>{`);`}</C>
//         </Br>
//         <Br>
//             <C color={VEX_white}>{`};`}</C>
//         </Br>
//         <Br></Br>
//     </Block>
//     <Words>This single wrangle node covers steps 1 though 3.</Words>

// </Section>
// <Words><strong>References:</strong></Words>
// <Words><Link text="VEX in Houdini" url="https://www.cgmasteracademy.com/courses/15-vex-in-houdini" /> by Johannes Richter</Words>
// <Words><Link text="5 Ways to replace a Prim with a Point | Houdini Quicktips" url="https://www.youtube.com/watch?v=iyZ8dIqJ47U" /> by David Kahl</Words>
// </Lesson>


// [".pic.gz",
// ".pic.Z",
// ".rat",
// ".picnc",
// ".ratnc",
// ".png",
// ".psd",
// ".psb",
// ".cin",
// ".kdk",
// ".fit",
// ".gif",
// ".gif89",
// ".ies",
// ".jpg",
// ".jpeg",
// ".qtl",
// ".rla",
// ".rlb",
// ".rla16",
// ".r16",
// ".r32",
// ".pix",
// ".sgi",
// ".rgb",
// ".rgba",
// ".si",
// ".pic",
// ".tif",
// ".tiff",
// ".tif3",
// ".tif16",
// ".tx",
// ".tga",
// ".vst",
// ".vtg",
// ".yuv",
// ".exr"]