
import React from 'react';
import { Lesson, Page, Words } from '../Chapter/Chapter';
import { CCode } from '../CodeBlock/CCode';
import { Javascript } from '../CodeBlock/Javascript';
import { Gallery } from '../Gallery/Gallery';
import { Python } from '../Houdini/Hom/Python';
import { Generator } from '../Houdini/Noise/VexGenerators/Generator';
import { Section } from '../Houdini/Section';
import { TurbulentSprites } from '../Sprites/TurbulentSprites';
import { ThreeViewWithVisibilitySelector } from '../ThreeView/ThreeViewWithVisibilitySelector';
import { WideBlogSection } from '../WebComponents/WideBlogSection';


export const CodeExamples: React.FC = (props) => {

    return (
        <Page topic="noise">
            <Lesson title="Vex Noise Generator">
                <WideBlogSection max_width="1280px">
                    <Generator />
                </WideBlogSection>
                <Section title="">
                    <Words>This is the React code for the Vex code generator. There are a lot of smaller compnents not included here that make up the fine details of the string, but this shows the main reducer that handles state</Words>
                    <WideBlogSection max_width="1280px">
                        <Javascript>{`
import React, { useReducer, useState } from 'react';
import { Vex } from '../../../Vex/VexBlock';
import { config, dimension, distance, DistanceMetric, distanceMetrics, INoiseConfig, INoiseState, InputArrays, NoiseFunction, noiseOptions } from './NoiseConfig';
import { DropdownSingle} from '../../../Form/DropdownSingle';
import { CheckboxSingle } from '../../../Form/CheckboxSingle';
import styled from 'styled-components';
import { useApp } from '../../../App';
import { FreqAmpOffset } from './Components/FreqAmpOffset';
import { TurbRoughAttenChannels } from './Components/TurbRoughAtten';
import { PeriodChannel } from './Components/PeriodChannel';
import { OutNoise } from './Components/OutNoise';
import { Position } from './Components/Position';
import { NoiseVEXFunction } from './Components/NoiseVEXFunction';
import { JitterChannel } from './Components/JitterChannel';
import { CellularVariables } from './Components/CellularVariables';
import { NoiseComment, NoiseCommentString } from './Components/NoiseComment';
import { CellularFunctions } from './Components/CellularFunctions';
import { InputSingle } from '../../../Form/InputSingle';
import { FunctionHeader } from './Components/FunctionHeader';

const initialState: INoiseState = {
    noise: "Perlin",
    input: "3D",
    output: "1D",
    periodic: false,
    turbulent: false,
    distanceMetric: "Euclidian",
    distance: "F1",
    fBm: false,
    curlSimplex: false,
    curl2D: false,
    channelPrefix: "",
    functionName: "",
    useFunction: false
};


type Action =
    | { type: 'NOISE', payload: { noise: NoiseFunction } }
    | { type: 'INPUT', payload: { input: dimension } }
    | { type: 'OUTPUT', payload: { output: dimension } }
    | { type: 'PERIODIC', payload: { periodic: boolean } }
    | { type: 'TURBULENT', payload: { turbulent: boolean } }
    | { type: 'DISTANCE_METRIC', payload: { distanceMetric: DistanceMetric } }
    | { type: 'DISTANCE', payload: { distance: distance } }
    | { type: 'FBM', payload: { fBm: boolean } }
    | { type: 'CURL_SIMPLEX', payload: { curlSimplex: boolean } }
    | { type: 'CURL_2D', payload: { curl2D: boolean } }
    | { type: 'CHANNEL_PREFIX', payload: { channelPrefix: string } }
    | { type: 'USE_FUNCTION', payload: { useFunction: boolean } }
    | { type: 'FUNCTION_NAME', payload: { functionName: string } }

const StyledGenerator = styled.div<{ darkTheme: boolean; }>\`
    // grid-template-columns: repeat(auto-fit, minmax(512px, 1fr));
    display: flex;
    flex-wrap: wrap-reverse;
    box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
    // overflow: hidden;
    border-radius: 12px;
    background: #1e1e1e;
    & .controls {
        flex-basis: 600px;
        flex-grow: 1;
        flex-shrink: 1; 
        background: #3a3a3a;
        border-radius: 12px;
        padding: 1em 1em 0.25em;
        margin: 0px;
        // display: flex;
        // justify-content: center;
        & .wrap {
            // margin: auto;
            // max-width: 700px;

        }
        & .header {
            color: white;
            padding: 8px 8px 0px;
            font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
            // text-transform: uppercase;
            font-size: 21px;
            margin-bottom: 16px !important;
        }
        & .grid {
            display: grid;
            grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
            grid-gap: 1em;
            margin-bottom: 1em;
            & .folder {
                flex-grow: 1;
                padding: 8px 16px;
            }

        }
        & .border {
            border: 1px solid #ffffff80;
            border-radius: 8px;
        }

    }
\`

const reducer: React.Reducer<INoiseState, Action> = (state: INoiseState, action: Action): INoiseState => {
    switch (action.type) {
        case 'NOISE':
            return {
                noise: action.payload.noise,
                input: (() => {
                    let inputItem = config[action.payload.noise].input.find(_ => _.name == state.input);
                    // if found and not disabled don't change
                    if (inputItem != undefined && !inputItem.disabled) return state.input;
                    // otherwise default to 3D
                    else return "3D" as dimension;
                })(),
                output: (() => {
                    let outputItem = config[action.payload.noise].output.find(_ => _.name == state.output);
                    // if found and not disabled don't change - addition: and if not coming back from curl noise
                    if (outputItem != undefined && !outputItem.disabled && state.noise != "Curl") return state.output;
                    // or default to 3D
                    else if (action.payload.noise === "Curl") return "3D" as dimension;
                    // or 1D
                    else return "1D" as dimension;
                })(),
                periodic: state.periodic,
                turbulent: (() => {
                    // if switching to a turbulent noise and fBm is on - turn turbulence off
                    if (config[action.payload.noise].turbulence && state.fBm) return false;
                    else return state.turbulent
                })(),
                distanceMetric: state.distanceMetric,
                distance: state.distance,
                fBm: state.fBm,
                curlSimplex: state.curlSimplex,
                curl2D: (() => {
                    if (action.payload.noise == "Curl") {
                        if (state.input === "2D") return true;
                        else if (state.input === "4D") return false;
                        else return state.curl2D
                    }
                    else return state.curl2D
                })(),
                channelPrefix: state.channelPrefix,
                useFunction: state.useFunction,
                functionName: state.functionName
            };
        case 'INPUT':
            return { ...state, input: action.payload.input };
        case 'OUTPUT':
            return { ...state, output: action.payload.output };
        case 'PERIODIC':
            return { ...state, periodic: action.payload.periodic };
        case 'TURBULENT':
            return {
                ...state,
                turbulent: action.payload.turbulent,
                // if turbulence changed to true turn off fbm
                fBm: action.payload.turbulent ? false : state.fBm
            };
        case 'DISTANCE_METRIC':
            return { ...state, distanceMetric: action.payload.distanceMetric };
        case 'DISTANCE':
            return { ...state, distance: action.payload.distance };
        case 'FBM':
            return {
                ...state,
                fBm: action.payload.fBm,
                // if is turb noise and fBm changed to true  turn off turbulence
                turbulent: (config[state.noise].turbulence && action.payload.fBm) ? false : state.turbulent
            };
        case 'CURL_SIMPLEX':
            return { ...state, curlSimplex: action.payload.curlSimplex };
        case 'CURL_2D':
            return {
                ...state,
                curl2D: action.payload.curl2D,
                input: (() => {
                    if (action.payload.curl2D == true) {
                        if (state.input === "4D") return "3D"
                        else return state.input
                    }
                    else {
                        if (state.input === "2D") return "3D"
                        else return state.input
                    }
                })()
            };
        case 'CHANNEL_PREFIX':
            return { ...state, channelPrefix: action.payload.channelPrefix };
        case 'USE_FUNCTION':
            return { ...state, useFunction: action.payload.useFunction };
        case 'FUNCTION_NAME':
            return { ...state, functionName: action.payload.functionName };
        default:
            // return state;
            throw new Error();
    }
}

export const Generator: React.FC = (props) => {
    const { darkTheme } = useApp();
    const [state, dispatch] = useReducer<React.Reducer<INoiseState, Action>>(reducer, initialState);


    interface IDropdownLookups {
        noise: NoiseFunction[];
        input: dimension[];
        output: dimension[];
        distanceMetrics: DistanceMetric[];
        distances: distance[];
    }

    const Lookups: IDropdownLookups = {
        noise: ["Perlin", "Simplex", "Original Perlin", "Sparse Convolution", "Alligator", "Worley", "Flow", "Voronoi", "Curl"],
        input: ["1D", "2D", "3D", "4D"],
        output: ["1D", "3D"],
        distanceMetrics: ["Euclidian", "Manhattan", "Chebyshev"],
        distances: ["F1", "F2-F1"]
    }

    const handleDropdownChange = (id: string, value: number) => {
        if (id === "noise") dispatch({ type: "NOISE", payload: { noise: Lookups.noise[value] } });
        else if (id === "input") dispatch({ type: "INPUT", payload: { input: Lookups.input[value] } });
        else if (id === "output") dispatch({ type: "OUTPUT", payload: { output: Lookups.output[value] } });
        else if (id === "distanceMetric") dispatch({ type: "DISTANCE_METRIC", payload: { distanceMetric: Lookups.distanceMetrics[value] } });
        else if (id === "distance") dispatch({ type: "DISTANCE", payload: { distance: Lookups.distances[value] } });
    }
    const handleCheckboxChange = (id: string, value: boolean) => {
        if (id === "turbulent") dispatch({ type: "TURBULENT", payload: { turbulent: value } });
        else if (id === "periodic") dispatch({ type: "PERIODIC", payload: { periodic: value } });
        else if (id === "fBm") dispatch({ type: "FBM", payload: { fBm: value } });
        // curl
        else if (id === "curlSimplex") dispatch({ type: "CURL_SIMPLEX", payload: { curlSimplex: value } });
        else if (id === "curl2D") dispatch({ type: "CURL_2D", payload: { curl2D: value } });
        else if (id === "useFunction") dispatch({ type: "USE_FUNCTION", payload: { useFunction: value } });
    }
    const handleInputChange = (id: string, value: string) => {
        if (id === "channel_prefix") dispatch({ type: "CHANNEL_PREFIX", payload: { channelPrefix: value } });
        else if (id === "function_name") dispatch({ type: "FUNCTION_NAME", payload: { functionName: value } });
    }

    const distanceMetricsDropdown = () => {
        return (state.noise === "Worley" ? < DropdownSingle
            label="Distance"
            id="distanceMetric"
            value={Lookups.distanceMetrics.indexOf(state.distanceMetric)}
            setValue={handleDropdownChange}
            options={distanceMetrics}
            dark /> : <></>)
    }

    const distancesDropdown = () => {
        let outputVal = config[state.noise].distances
        return (outputVal ? < DropdownSingle
            label="Value"
            id="distance"
            value={Lookups.distances.indexOf(state.distance)}
            setValue={handleDropdownChange}
            options={outputVal}
            dark
        /> : <></>)
    }
    const periodicToggle = () => {
        return config[state.noise].periodic ?
            <CheckboxSingle
                id="periodic"
                label="Periodic"
                value={state.periodic}
                setValue={handleCheckboxChange}
                dark
            /> : <></>
    }
    const turbulenceToggle = () => {
        return config[state.noise].turbulence ?
            <CheckboxSingle
                id="turbulent"
                label="Built-in Turbulence"
                value={state.turbulent}
                setValue={handleCheckboxChange}
                dark
            /> : <></>
    }
    const curlToggles = () => {
        return state.noise === "Curl" ?
            <>
                <CheckboxSingle
                    id="curlSimplex"
                    label="Simplex Based"
                    value={state.curlSimplex}
                    setValue={handleCheckboxChange}
                    dark
                />
                <CheckboxSingle
                    id="curl2D"
                    label="2D Curl"
                    value={state.curl2D}
                    setValue={handleCheckboxChange}
                    dark
                />
            </>
            : <></>
    }

    const getInputOptions = () => {
        if (state.noise === "Curl") {
            if (state.curl2D) return InputArrays.twoAndThree
            else return InputArrays.threeAndFour
        }
        else return config[state.noise].input
    }

    const flowVariable = (<>
        {state.useFunction ?
            <>
                <Vex.Line>
                    <Vex.Channel type="float" var="flow" string={\`\${state.channelPrefix}flow\`}></Vex.Channel>
                </Vex.Line>
            </>
            :
            <Vex.Line>
                <Vex.Code type>{'float '}</Vex.Code>
                <Vex.Code>{'flow = @Time;'}</Vex.Code>
            </Vex.Line>
        }
    </>)

    return (
        <StyledGenerator {...{ darkTheme }}>
            <Vex styles={{ margin: "0px !important", borderRadius: "12px", flexGrow: 1, flexBasis: 600, flexShrink: 999 }}>
                <FunctionHeader dimension={state.output} useFunction={state.useFunction} functionName={state.functionName} />
                <FreqAmpOffset useFunction={state.useFunction} channelPrefix={state.channelPrefix} />

                {(config[state.noise].periodic && state.periodic) && <PeriodChannel dimension={state.input} channelPrefix={state.channelPrefix} />}
                {(state.noise === "Flow") && flowVariable}
                {(state.noise === "Voronoi") && <JitterChannel inputDimension={state.input} />}
                {((config[state.noise].turbulence && state.turbulent) || state.fBm) && <TurbRoughAttenChannels fBm={state.fBm} turbulent={state.turbulent} channelPrefix={state.channelPrefix} />}

                {state.fBm ? <>
                    <Vex.TurbulenceLoop
                        outputDimension={state.output}
                    >
                        <NoiseComment tabs={1} periodic={state.periodic} noise={state.noise} inputDimension={state.input} outputDimension={state.output} turbulent={state.turbulent} distanceMetric={state.distanceMetric} curl2D={state.curl2D} />
                        <CellularVariables tabs={1} inputDimension={state.input} noise={state.noise} />
                        <Position noise={state.noise} dimension={state.input} tabs={1} useFunction={state.useFunction} channelPrefix={state.channelPrefix} />
                        <CellularFunctions tabs={1} noise={state.noise} inputDimension={state.input} distanceMetric={state.distanceMetric} periodic={state.periodic} useFunction={state.useFunction} />
                        <NoiseVEXFunction
                            tabs={1}
                            fBm={state.fBm}
                            periodic={state.periodic}
                            distanceMetric={state.distanceMetric}
                            noise={state.noise}
                            curlSimplex={state.curlSimplex}
                            curl2D={state.curl2D}
                            inputDimension={state.input}
                            outputDimension={state.output}
                            turbulent={state.turbulent}
                            distance={state.distance}
                            useFunction={state.useFunction}
                        />
                    </Vex.TurbulenceLoop>


                </> : <>
                    <Vex.Line />
                    <NoiseComment periodic={state.periodic} noise={state.noise} inputDimension={state.input} outputDimension={state.output} turbulent={state.turbulent} distanceMetric={state.distanceMetric} curl2D={state.curl2D} />
                    <CellularVariables inputDimension={state.input} noise={state.noise} />
                    <Position noise={state.noise} dimension={state.input} useFunction={state.useFunction} channelPrefix={state.channelPrefix} />
                    <CellularFunctions noise={state.noise} inputDimension={state.input} distanceMetric={state.distanceMetric} periodic={state.periodic} useFunction={state.useFunction} />
                    <NoiseVEXFunction
                        fBm={state.fBm}
                        periodic={state.periodic}
                        distanceMetric={state.distanceMetric}
                        noise={state.noise}
                        curlSimplex={state.curlSimplex}
                        curl2D={state.curl2D}
                        inputDimension={state.input}
                        outputDimension={state.output}
                        turbulent={state.turbulent}
                        distance={state.distance}
                        useFunction={state.useFunction}
                    />
                </>}


                <OutNoise dimension={state.output} useFunction={state.useFunction} functionName={state.functionName} />

            </Vex>
            <div className="controls">
                <div className="wrap">


                    <p className="header">{\`\${state.fBm ? "Turbulent" : ""} \${NoiseCommentString({
                        periodic: state.periodic,
                        noise: state.noise,
                        inputDimension: state.input,
                        outputDimension: state.output,
                        turbulent: state.turbulent,
                        distanceMetric: state.distanceMetric,
                        curl2D: state.curl2D
                    })}\`}</p>
                    <div className="grid">
                        <div className="folder border">

                            <DropdownSingle
                                label="Noise Type"
                                id="noise"
                                value={Lookups.noise.indexOf(state.noise)}
                                setValue={handleDropdownChange}
                                options={noiseOptions}
                                dark />
                            <DropdownSingle
                                label="Input"
                                id="input"
                                value={Lookups.input.indexOf(state.input)}
                                setValue={handleDropdownChange}
                                options={getInputOptions()}
                                dark />
                            <DropdownSingle
                                label="Output"
                                id="output"
                                value={Lookups.output.indexOf(state.output)}
                                setValue={handleDropdownChange}
                                options={config[state.noise].output}
                                dark />
                            <CheckboxSingle
                                id="fBm"
                                label="Add Turbulence"
                                value={state.fBm}
                                setValue={handleCheckboxChange}
                                dark
                            />


                        </div>
                        <div className="folder border">
                            {distanceMetricsDropdown()}
                            {distancesDropdown()}
                            {turbulenceToggle()}
                            {periodicToggle()}
                            {curlToggles()}

                        </div>
                    </div>
                    <div className="grid border">
                        <div className="folder">
                            <InputSingle
                                id="channel_prefix"
                                label="Ch Prefix"
                                placeholder="Channel prefix..."
                                value={state.channelPrefix}
                                setValue={handleInputChange}
                                dark
                            />
                        </div>
                        <div className="folder">

                            <InputSingle
                                id="function_name"
                                label="Function Name"
                                placeholder="Function name..."
                                value={state.functionName}
                                setValue={handleInputChange}
                                dark
                                disabled={!state.useFunction}
                            />
                            <CheckboxSingle
                                id="useFunction"
                                label="Make Into Function"
                                value={state.useFunction}
                                setValue={handleCheckboxChange}
                                dark
                            />

                        </div>
                    </div>
                </div>
            </div>

        </StyledGenerator >
    )
};
                        
                        `}
                        </Javascript>
                    </WideBlogSection>
                </Section>
            </Lesson>
            <Lesson title="Turbulence Visualizer">
                <WideBlogSection max_width="1280px">
                    <TurbulentSprites />
                </WideBlogSection>
                <Section title="">
                    <Words>React code for the turbulence visualizer. Theres a function called getLocalURL that fetches giant spritesheets and creates blobs. Theres a function called getOffset that determines which index of the spritesheet to show based on state. There's also this long array of presets that needs to go. It was to test out the preset modal, but it's way too nonperformant rendering those huge images in the modal</Words>
                </Section>
                <WideBlogSection max_width="1280px">
                    <Javascript>
                        {`
import React, { Component, useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import { Button, Icon, Loader, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { useApp } from "../App";
import { CheckboxSingle } from "../Form/CheckboxSingle";
import { DropdownSingle, DropdownSingleOption } from "../Form/DropdownSingle";
import { SliderSingle } from "../Form/SliderSingle";

export interface ISpritesProps {

}

const Root = styled.div<{ darkTheme: boolean, xOffset: number; yOffset: number; }>\`

    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;

    background: white;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(512px, 1fr));

    box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
    overflow: hidden;
    border-radius: 12px;

    @media (max-width: 728px) {
        border-radius: 0px;
    }
    & .presets {
        position: absolute;
        top: 12px;
        left: 12px;
    }
    & .sprite_container {

        padding: 24px 16px;
        box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        & .loader_wrap {
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 512px;
            width: 512px;
            & .ui.loader:after {
                border-color: #31aae2 transparent transparent !important;
            }  
        }
        & .sprite {
            overflow: hidden;
            position: relative;
            display: inline-block;
            height: 512px;
            width: 512px;
            border-radius: 12px;
            transform: scale(0.75);
            margin: auto;
            color: black;
    
            & img {
                position: absolute;
                top: -\${props => props.yOffset}px;
                left: -\${props => props.xOffset}px;
                filter: brightness(1.1);
                &.back {
                    z-index: -999;
                    visibility: hidden;
                }
            }
        }
    }



    & .controls {
        background: #3a3a3a;
        // border-radius: 8px;
        padding: 1em 1em 0.25em;
        // margin-bottom: 16px;
        // display: flex;
        // justify-content: center;
        width: 100%;
        & .wrap {
            margin: auto;
            max-width: 700px;

        }
        & .header {
            color: white;
            padding: 8px 8px 0px;
            font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
            // text-transform: uppercase;
            font-size: 21px;
            margin-bottom: 16px !important;
        }
        & .grid {
            display: grid;
            grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
            grid-gap: 1em;
            margin-bottom: 1em;
            & .folder {
                flex-grow: 1;
                padding: 8px 16px 24px;
            }

        }
        & .border {
            border: 1px solid #ffffff80;
            border-radius: 8px;
        }

    }
\`
const StyledPresetContainer = styled.div<{}>\`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(512px, 1fr));
    width: inherit;
    & .card {
            overflow: hidden;
            position: relative;
            display: inline-block;
            height: 512px;
            width: 512px;
            border-radius: 12px;
            transform: scale(0.75);
            margin: auto;
            box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
            transition: 200ms all;
            border: 3px solid #00000000;
            &:hover {
                cursor: pointer;
                transform: scale(0.8);
            }
            &.selected {
                border: 3px solid #31aae2;
                box-shadow: #31aae2 0px 5px 15px;
            }
    }
\`


type ITurbulentNoiseFunction =
    "Perlin" |
    "Original Perlin" |
    "Sparse Convolution" |
    "Alligator" |
    "Worley" |
    "Voronoi";

type DistanceMetric = "Euclidian" | "Manhattan" | "Chebyshev";

type distance = "F1" | "F2-F1";

export interface ITurbulentSpritesState {
    // noise
    noise: ITurbulentNoiseFunction;
    distanceMetric: DistanceMetric;
    distance: distance;
    inverted: boolean;

    // params
    turbulence: number;
    roughness: number;
    attenuation: number;
}

const initialState: ITurbulentSpritesState = {
    noise: "Perlin",
    distanceMetric: "Euclidian",
    distance: "F1",
    inverted: false,
    turbulence: 3,
    roughness: 2,
    attenuation: 2
};


type Action =
    | { type: 'NOISE', payload: { noise: ITurbulentNoiseFunction } }
    | { type: 'DISTANCE_METRIC', payload: { distanceMetric: DistanceMetric } }
    | { type: 'DISTANCE', payload: { distance: distance } }
    | { type: 'INVERTED', payload: { inverted: boolean } }
    | { type: 'TURBULENCE', payload: { turbulence: number } }
    | { type: 'ROUGHNESS', payload: { roughness: number } }
    | { type: 'ATTENUATION', payload: { attenuation: number } }
    | { type: 'SET_STATE', payload: { state: ITurbulentSpritesState } }

interface IDropdownLookups {
    noise: ITurbulentNoiseFunction[];
    distanceMetric: DistanceMetric[];
    distance: distance[];
}

const noiseOptions: DropdownSingleOption[] = [{ name: "Perlin" }, { name: "Original Perlin" }, { name: "Sparse Convolution" }, { name: "Alligator" }, { name: "Worley" }, { name: "Voronoi" }]

const distanceMetricOptions: DropdownSingleOption[] = [{ name: "Euclidian" }, { name: "Manhattan" }, { name: "Chebyshev" }];

const distanceOptions: DropdownSingleOption[] = [{ name: "F1" }, { name: "F2-F1" }];



type INoiseName =
    "Perlin" |
    "Original Perlin" |
    "Sparse Convolution" |
    "Alligator" |
    "AlligatorInverted" |
    "Voronoi" |
    "WorleyF1" |
    "WorleyF2-F1" |
    "WorleyF1Inverted" |
    "WorleyF2-F1Inverted" |
    "ManhattanF1" |
    "ManhattanF2-F1" |
    "ManhattanF1Inverted" |
    "ManhattanF2-F1Inverted" |
    "ChebyshevF1" |
    "ChebyshevF2-F1" |
    "ChebyshevF1Inverted" |
    "ChebyshevF2-F1Inverted";

interface spriteURL {
    noiseName: INoiseName;
    remoteURL: string;
    localURL: string;
    loaded: boolean;
}

const initialSpriteURLs: spriteURL[] = [
    {
        noiseName: "Perlin",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/Perlin.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "Original Perlin",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/OriginalPerlin.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "Sparse Convolution",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/SparseConvolution.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "Alligator",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/Alligator.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "AlligatorInverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/AlligatorInverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "Voronoi",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/VoronoiF1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "WorleyF1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/WorleyF1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "WorleyF2-F1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/WorleyF2-F1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "WorleyF1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/WorleyF1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "WorleyF2-F1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/WorleyF2-F1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ManhattanF1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ManhattanF1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ManhattanF2-F1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ManhattanF2-F1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ManhattanF1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ManhattanF1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ManhattanF2-F1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ManhattanF2-F1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ChebyshevF1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ChebyshevF1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ChebyshevF2-F1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ChebyshevF2-F1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ChebyshevF1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ChebyshevF1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ChebyshevF2-F1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ChebyshevF2-F1Inverted.jpg",
        localURL: "",
        loaded: false
    }
]

const getLocalURL: (remoteURL: string) => Promise<string> = async (remoteURL) => {
    if (!!remoteURL) {
        const response = await fetch(remoteURL);
        if (response.ok) {
            console.log(response)
            const blob = await response.blob();
            const localURL = URL.createObjectURL(blob);
            return localURL;
        }
        else return "";
    }
    else return "";
}


const getOffset: (turb: number, rough: number, atten: number) => { x: number, y: number } = (turb, rough, atten) => {
    const idx = ((turb * 36) + (rough * 6) + atten);
    const tileXSize = 512;
    const tileYSize = 512;
    const rows = 16;
    const columns = 16;
    const xOffset = (idx % columns) * tileXSize;
    const yOffset = (Math.floor(idx / columns)) * tileYSize;
    return { x: xOffset, y: yOffset };
}


const reducer: React.Reducer<ITurbulentSpritesState, Action> = (state: ITurbulentSpritesState, action: Action): ITurbulentSpritesState => {
    switch (action.type) {
        case 'NOISE':
            return { ...state, noise: action.payload.noise };
        case 'DISTANCE_METRIC':
            return { ...state, distanceMetric: action.payload.distanceMetric };
        case 'DISTANCE':
            return { ...state, distance: action.payload.distance };
        case 'INVERTED':
            return { ...state, inverted: action.payload.inverted };
        case 'TURBULENCE':
            return { ...state, turbulence: action.payload.turbulence };
        case 'ROUGHNESS':
            return { ...state, roughness: action.payload.roughness };
        case 'ATTENUATION':
            return { ...state, attenuation: action.payload.attenuation };
        case 'SET_STATE':
            return { ...action.payload.state };

        default:
            return state;
        //throw new Error();
    }
}

const presetIMG = (state: ITurbulentSpritesState, spriteURLs: spriteURL[]) => {
    const offset = getOffset(state.turbulence || 0, state.roughness || 0, state.attenuation || 0);
    const style = { top: -offset.y, left: -offset.x, position: 'absolute' as 'absolute' }
    return <img style={style} className="front" src={getSpriteSheetURLFromState(state, spriteURLs)} />

}

const getNoiseNameFromState: (state: ITurbulentSpritesState) => INoiseName = (state) => {
    // let state: ITurbulentSpritesState = { ...s }
    return {
        "Perlin": "Perlin",
        "Original Perlin": "Original Perlin",
        "Sparse Convolution": "Sparse Convolution",
        "Voronoi": "Voronoi",
        "Alligator": (state.inverted ? "AlligatorInverted" : "Alligator"),
        "Worley": {
            "Euclidian": {
                "F1": (state.inverted ? "WorleyF1Inverted" : "WorleyF1"),
                "F2-F1": (state.inverted ? "WorleyF2-F1Inverted" : "WorleyF2-F1")
            }[state.distance],
            "Manhattan": {
                "F1": (state.inverted ? "ManhattanF1Inverted" : "ManhattanF1"),
                "F2-F1": (state.inverted ? "ManhattanF2-F1Inverted" : "ManhattanF2-F1")
            }[state.distance],
            "Chebyshev": {
                "F1": (state.inverted ? "ChebyshevF1Inverted" : "ChebyshevF1"),
                "F2-F1": (state.inverted ? "ChebyshevF2-F1Inverted" : "ChebyshevF2-F1")
            }[state.distance]
        }[state.distanceMetric],
    }[state.noise] as INoiseName

}

const getSpriteSheetURLFromState: (state: ITurbulentSpritesState, spriteURLs: spriteURL[]) => string = (state, spriteURLs) => {
    const noiseName = getNoiseNameFromState(state);
    if (!!noiseName) {
        const obj = spriteURLs.find(_ => _.noiseName == noiseName)
        if (!!obj) return obj.localURL;
        else return ""
    }
    else return ""
}

const presets: ITurbulentSpritesState[] = [
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Perlin",
        roughness: 3,
        turbulence: 4
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Original Perlin",
        roughness: 2,
        turbulence: 5
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Sparse Convolution",
        roughness: 2,
        turbulence: 5,
    },
    {
        attenuation: 5,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Alligator",
        roughness: 3,
        turbulence: 5
    },
    {
        attenuation: 2,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Alligator",
        roughness: 2,
        turbulence: 4
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: true,
        noise: "Alligator",
        roughness: 3,
        turbulence: 5
    },
    {
        attenuation: 1,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Alligator",
        roughness: 3,
        turbulence: 4
    },
    {
        attenuation: 5,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 3
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: true,
        noise: "Worley",
        roughness: 2,
        turbulence: 5
    },
    {
        attenuation: 1,
        distance: "F2-F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 3
    },
    {
        attenuation: 2,
        distance: "F2-F1",
        distanceMetric: "Euclidian",
        inverted: true,
        noise: "Worley",
        roughness: 2,
        turbulence: 4
    },
    {
        attenuation: 3,
        distance: "F2-F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 3
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Voronoi",
        roughness: 2,
        turbulence: 4,
    },
    {
        attenuation: 2,
        distance: "F1",
        distanceMetric: "Manhattan",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 4,
    },
    {
        attenuation: 4,
        distance: "F2-F1",
        distanceMetric: "Chebyshev",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 5,
    },
    {
        attenuation: 5,
        distance: "F1",
        distanceMetric: "Manhattan",
        inverted: true,
        noise: "Worley",
        roughness: 2,
        turbulence: 5
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Chebyshev",
        inverted: true,
        noise: "Worley",
        roughness: 2,
        turbulence: 4,
    },
    {
        attenuation: 2,
        distance: "F2-F1",
        distanceMetric: "Chebyshev",
        inverted: true,
        noise: "Worley",
        roughness: 3,
        turbulence: 5
    }

]

export const TurbulentSprites: React.FC<ISpritesProps> = (props: ISpritesProps) => {
    const { darkTheme } = useApp();

    const [state, dispatch] = useReducer<React.Reducer<ITurbulentSpritesState, Action>>(reducer, initialState);
    const [offset, setOffset] = useState<number[]>([0, 0]);

    const [openPresetsModal, setOpenPresetsModal] = React.useState(false)
    const [selectedPreset, setSelectedPreset] = React.useState<ITurbulentSpritesState | null>(null)
    const [spriteURLs, setSpriteURLs] = React.useState<spriteURL[]>(initialSpriteURLs)

    const modal = <>
        <Modal
            size="fullscreen"
            open={openPresetsModal}
            onClose={() => setOpenPresetsModal(false)}
            onOpen={() => {
                setSelectedPreset(null)
                setOpenPresetsModal(true)
            }}
            trigger={<Button>Presets</Button>}
        >
            <Modal.Header>Presets</Modal.Header>
            <Modal.Content image scrolling style={{ flexDirection: "column", alignItems: "center" }}  >

                <StyledPresetContainer>

                    {presets.map(s => <>
                        <div
                            className={\`card \${s == selectedPreset ? "selected" : ""}\`}
                            onClick={() => {
                                if (selectedPreset != s) setSelectedPreset(s)
                                else setSelectedPreset(null);
                            }}>
                            {presetIMG(s, spriteURLs)}
                        </div>
                    </>)}
                </StyledPresetContainer>

                {/* </div> */}

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    if (!!selectedPreset) {
                        dispatch({ type: "SET_STATE", payload: { state: selectedPreset } });
                    }
                    setOpenPresetsModal(false)
                }} primary>
                    {!selectedPreset ? "Back" : "Use"} <Icon name='chevron right' />
                </Button>
            </Modal.Actions>
        </Modal>
    </>;





    useEffect(() => {

        async function getURLS(spriteURLs: spriteURL[]): Promise<spriteURL[]> {
            let _spriteURLs = [...spriteURLs];
            _spriteURLs = await Promise.all(_spriteURLs.map(async (_) => {
                let obj: spriteURL = { ..._ }
                const localURL = await getLocalURL(_.remoteURL);
                if (!!localURL) {
                    obj.localURL = localURL;
                }
                obj.loaded = true;
                setSpriteURLs(s => s.map(o => o.noiseName == _.noiseName ? obj : o))
                return obj
            }));
            return _spriteURLs
        }

        getURLS(spriteURLs);

    }, [])


    useEffect(() => {
        const offset = getOffset(state.turbulence, state.roughness, state.attenuation);
        setOffset([offset.x, offset.y])
    }, [state])

    const Lookups: IDropdownLookups = {
        noise: ["Perlin", "Original Perlin", "Sparse Convolution", "Alligator", "Worley", "Voronoi"],
        distanceMetric: ["Euclidian", "Manhattan", "Chebyshev"],
        distance: ["F1", "F2-F1"]
    }


    const handleDropdownChange = (id: string, value: number) => {
        if (id === "noise") dispatch({ type: "NOISE", payload: { noise: Lookups.noise[value] } });
        else if (id === "distanceMetric") dispatch({ type: "DISTANCE_METRIC", payload: { distanceMetric: Lookups.distanceMetric[value] } });
        else if (id === "distance") dispatch({ type: "DISTANCE", payload: { distance: Lookups.distance[value] } });
    }
    const handleSliderChange = (id: string, value: number) => {
        if (id === "turbulence") dispatch({ type: "TURBULENCE", payload: { turbulence: value } });
        if (id === "roughness") dispatch({ type: "ROUGHNESS", payload: { roughness: value } });
        if (id === "attenuation") dispatch({ type: "ATTENUATION", payload: { attenuation: value } });
    }
    const handleCheckboxChange = (id: string, value: boolean) => {
        if (id === "inverted") dispatch({ type: "INVERTED", payload: { inverted: value } });
    }


    console.log(state)
    return (
        <Root xOffset={offset[0]} yOffset={offset[1]} {...{ darkTheme, }}>
            <div className="sprite_container">

                <div className="presets">
                    {modal}
                </div>

                {spriteURLs.every(_ => _.loaded) ?
                    <div className="sprite">
                        <img className="front" src={getSpriteSheetURLFromState(state, spriteURLs)} />
                    </div>
                    :
                    <div className="loader_wrap">
                        <div className="percent"></div>
                        <Loader size='massive' active inline='centered'>Loading {Math.round(spriteURLs.filter(_ => _.loaded).length / spriteURLs.length * 100)}%</Loader >
                    </div>
                }

            </div>

            <div className="controls">
                <div className="wrap">
                    <div className="grid">
                        <div className="folder border">
                            <SliderSingle darkTheme="dark" id="turbulence" value={state.turbulence} setValue={handleSliderChange} label={\`Turbulence: \${state.turbulence}\`} min={0} max={5} />
                            <SliderSingle darkTheme="dark" id="roughness" value={state.roughness} setValue={handleSliderChange} label={\`Roughness: \${(state.roughness / 5).toFixed(1)}\`} min={0} max={5} />
                            <SliderSingle darkTheme="dark" id="attenuation" value={state.attenuation} setValue={handleSliderChange} label={\`Attenuation: \${[0, 0.1, 0.5, 1.0, 1.5, 2.0][state.attenuation]}\`} min={0} max={5} />
                        </div>
                        <div className="folder border">
                            < DropdownSingle
                                label="Noise Type"
                                id="noise"
                                value={Lookups.noise.indexOf(state.noise)}
                                setValue={handleDropdownChange}
                                options={noiseOptions}
                                dark
                            />
                            {state.noise == "Worley" && (<>
                                < DropdownSingle
                                    label="Distance Metric"
                                    id="distanceMetric"
                                    value={Lookups.distanceMetric.indexOf(state.distanceMetric)}
                                    setValue={handleDropdownChange}
                                    options={distanceMetricOptions}
                                    dark
                                />
                                < DropdownSingle
                                    label="Value"
                                    id="distance"
                                    value={Lookups.distance.indexOf(state.distance)}
                                    setValue={handleDropdownChange}
                                    options={distanceOptions}
                                    dark
                                />
                            </>
                            )}
                            {((state.noise == "Worley") || (state.noise == "Alligator")) && (<>
                                <CheckboxSingle
                                    id="inverted"
                                    label="Inverted"
                                    value={state.inverted}
                                    setValue={handleCheckboxChange}
                                    dark
                                />
                            </>
                            )}
                        </div>
                    </div>
                </div>
            </div>



        </Root>
    )
};


`}
                    </Javascript>
                </WideBlogSection>
            </Lesson>
            <Lesson title="Three.js React">
                <WideBlogSection max_width="1280px">
                    <ThreeViewWithVisibilitySelector
                        modelNames={["Avocado", "Polywire Normals", "BMP"]}
                        modelPaths={[
                            "https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/avocado.glb",
                            "https://vex-assets.s3.amazonaws.com/MovePointsAlongNormals/spiky_avocado.glb",
                            "https://photogrammetry-models.s3.amazonaws.com/bransten-3/branston3trimmed.glb"
                        ]}
                        // HDRI="https://vex-assets.s3.amazonaws.com/quattro_canti.jpg"
                        HDRI="https://vex-assets.s3.amazonaws.com/san_giuseppe_bridge.jpg"
                        // logCameraLocation
                        cameraPosition={{
                            x: -57.91879482205282,
                            y: 124.89315615919259,
                            z: 40.83437437310162
                        }}
                        roughnessMultiplier={1.2}
                        modelYRotation={4.4}
                    />
                </WideBlogSection>
                <Words>The model takes a while to load. I was way to generous with the model resolution. This was an early test using Three.js with Typescript in React. The code is kind of a mess. Lots of commented out stuff. was still experimenting with lighting. It's also a little verbose using all the useRefs for the variables Three.js is using but that was the quickest way to store them in a functional component safely typed. Needs improvement </Words>
                <WideBlogSection max_width="1280px">
                    <Javascript>
                        {`
import React, { Component, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import styles from './ThreeView.module.scss'
import spinner from './../water-spin.svg'

// import cannon from './Images/cannon.jpg'

// import HDRI from './../Images/kiara_5_noon.jpg'

interface vector {
    x: number;
    y: number;
    z: number;
}

export interface IThreeViewProps {
    modelPaths: string[];
    HDRI: string;
    logCameraLocation?: boolean;
    cameraPosition?: vector;
    modelVisibilities?: boolean[];
    roughnessMultiplier?: number;
    modelYRotation?: number;
    setLoaded?: (loaded: boolean) => void;
}

export const ThreeView: React.FC<IThreeViewProps> = ({ modelPaths, HDRI, logCameraLocation, cameraPosition, ...props }: IThreeViewProps) => {
    // the target div for everything
    const mount = useRef(document.createElement('div'));

    const scene = useRef<THREE.Scene>();
    const camera = useRef<THREE.PerspectiveCamera>();
    const controls = useRef<OrbitControls>();
    const requestID = useRef<number>();
    const renderer = useRef<THREE.WebGLRenderer>();
    const clock = useRef<THREE.Clock>();
    const mixer = useRef<THREE.AnimationMixer>();

    // loader stuff
    const loader = useRef<GLTFLoader>();
    const manager = useRef<THREE.LoadingManager>();
    const textureLoader = useRef<THREE.TextureLoader>();
    const [percentLoaded, setPercentLoaded] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    // const modelPaths = useRef<string[]>([
    //     // '/Models/Blender/untitled9.glb',
    //     // \`\${process.env.PUBLIC_URL}/Models/Blender/bones/street.glb\`,
    //     // \`\${process.env.PUBLIC_URL}/Models/Blender/bones/tree2.glb\`,
    //     // "https://bmp-assets.s3.amazonaws.com/photogrammetry.glb",
    //     "https://bmp-assets.s3.amazonaws.com/leftBaseGrass.glb",
    //     // 'https://bmp-assets.s3.amazonaws.com/street.glb',
    //     "https://bmp-assets.s3.amazonaws.com/shrubFrontLeft.glb",
    //     "https://bmp-assets.s3.amazonaws.com/shrubBackLeft.glb",
    //     "https://bmp-assets.s3.amazonaws.com/tree2.glb",
    //     "https://bmp-assets.s3.amazonaws.com/tree1.glb",

    // ]);

    // lights
    const hemisphereLight = useRef<THREE.HemisphereLight>();
    const spotLight = useRef<THREE.SpotLight>();
    const ambientLight = useRef<THREE.AmbientLight>();
    const directionalLight = useRef<THREE.DirectionalLight>();
    const lightDistanceFactor = useRef<number>(1.5);

    // HDRI
    const pmremGenerator = useRef<THREE.PMREMGenerator>();
    const pngCubeRenderTarget = useRef<THREE.WebGLRenderTarget>();

    // Models object

    const models = useRef<Record<string, THREE.Group>>({ 0: new THREE.Group() });

    useEffect(() => {
        logCameraLocation && setInterval(() => { console.log(camera.current) }, 3000);
        sceneSetup();
        addCustomSceneObjects();
        startAnimationLoop();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
            requestID.current && window.cancelAnimationFrame(requestID.current);
            controls.current && controls.current.dispose();
        }
    }, []);

    useEffect(() => {
        if (models.current && props.modelVisibilities) props.modelVisibilities.forEach((_, i) => {
            if (models.current[i]) models.current[i].visible = _
        });
    }, [props.modelVisibilities]);

    // Standard scene setup in Three.js. Check "Creating a scene" manual for more information
    // https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
    const sceneSetup = () => {
        // get container dimensions and use them for scene sizing
        const width = mount.current.clientWidth;
        const height = mount.current.clientHeight;


        camera.current = new THREE.PerspectiveCamera(
            75, // fov = field of view
            width / height, // aspect ratio
            0.01, // near plane
            1000 // far plane
        );

        camera.current.position.z = 10; // is used here to set some distance from a cube that is located at z = 0
        // OrbitControls allow a camera to orbit around the object
        // https://threejs.org/docs/#examples/controls/OrbitControls
        if (cameraPosition) {
            camera.current.position.x = cameraPosition.x;
            camera.current.position.y = cameraPosition.y;
            camera.current.position.z = cameraPosition.z;
        }
        controls.current = new OrbitControls(camera.current, mount.current);
        renderer.current = new THREE.WebGLRenderer({ alpha: true });
        renderer.current.setSize(width, height);
        renderer.current.toneMapping = THREE.ReinhardToneMapping;
        renderer.current.toneMappingExposure = 2.8;
        renderer.current.shadowMap.enabled = true;

        scene.current = new THREE.Scene();
        // scene.current.add(new THREE.AxesHelper(500))

        mount.current.appendChild(renderer.current.domElement); // mount using React ref
    };




    // Here should come custom code.
    // Code below is taken from Three.js BoxGeometry example
    // https://threejs.org/docs/#api/en/geometries/BoxGeometry
    const addCustomSceneObjects = () => {

        manager.current = new THREE.LoadingManager();

        manager.current.onStart = (url, itemsLoaded, itemsTotal) => {
            // console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
        };
        manager.current.onLoad = () => {
            console.log('Loading complete!');
            setLoaded(true)
            props.setLoaded && props.setLoaded(true);
        };
        manager.current.onProgress = (url, itemsLoaded, itemsTotal) => {
            // console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
            setPercentLoaded(itemsLoaded / itemsTotal);

        };
        manager.current.onError = (url) => {
            console.log('There was an error loading ' + url);
        };

        // HDRI
        textureLoader.current = new THREE.TextureLoader();
        textureLoader.current && textureLoader.current.load(HDRI, function (texture) {
            if (renderer.current) {
                pmremGenerator.current = new THREE.PMREMGenerator(renderer.current);
                if (pmremGenerator.current) pmremGenerator.current.compileEquirectangularShader();
                pngCubeRenderTarget.current = pmremGenerator.current.fromEquirectangular(texture);
                if (scene.current) {
                    scene.current.background = pngCubeRenderTarget.current.texture;
                }
            }
            texture.dispose();
        }, undefined, function (e) {
            console.log(e, "TextureLoader error");
        });


        loader.current = new GLTFLoader(manager.current);

        // loader.current.load(process.env.PUBLIC_URL + '/Models/Blender/untitled9.glb', function (gltf) {
        //     gltf.scene.scale.set(10, 10, 10) // scale here
        //     // add shadows
        //     gltf.scene.traverse(_ => {
        //         if ((_ instanceof THREE.Mesh)) {
        //             _.castShadow = true;
        //             _.receiveShadow = true;
        //             if (_.material.map) _.material.map.anisotropy = 16;
        //         }
        //     })

        //     if (scene.current) scene.current.add(gltf.scene);
        // }, undefined, function (error) {
        //     console.error(error, "Errror loading GLTF");
        // });

        // loader.current.load(process.env.PUBLIC_URL + '/Models/Blender/untitled9.glb', function (gltf) {
        //     gltf.scene.scale.set(10, 10, 10) // scale here
        //     // add shadows
        //     gltf.scene.traverse(_ => {
        //         if ((_ instanceof THREE.Mesh)) {
        //             _.castShadow = true;
        //             _.receiveShadow = true;
        //             if (_.material.map) _.material.map.anisotropy = 16;
        //         }
        //     })

        //     if (scene.current) scene.current.add(gltf.scene);
        // }, undefined, function (error) {
        //     console.error(error, "Errror loading GLTF");
        // });


        const loadGLTF = (i: number) => {
            let index = i.toString();
            let path = modelPaths[i];
            loader.current && loader.current.load(path, function (gltf) {
                if (models.current) models.current[index] = gltf.scene;
                if (models.current[i]) {

                    models.current[index] = gltf.scene;
                    if (props.modelVisibilities && props.modelVisibilities[i] === false) models.current[index].visible = false;
                    models.current[index].scale.set(10, 10, 10);
                    models.current[index].rotateY(props.modelYRotation || 0);
                    // add shadows
                    models.current[index].traverse(_ => {
                        if ((_ instanceof THREE.Mesh)) {
                            _.material.roughness *= props.roughnessMultiplier || 1;
                            _.castShadow = true;
                            _.receiveShadow = true;
                            // if (_.material.map) _.material.map.anisotropy = 16;
                        }
                    });
                    // add to scene
                    if (scene.current) scene.current.add(models.current[index]);

                    // run it again for the next model
                    !!modelPaths[i + 1] && loadGLTF(i + 1);
                }
            }, undefined, function (error) {
                console.error(error, \`Error loading \${path}\`);
                // run it again for the next model
                !!modelPaths[i + 1] && loadGLTF(i + 1);
            });
        }

        // modelPaths.forEach(loadGLTF);
        loadGLTF(0);


        //       _____  
        //     .'     \`.
        //    /         \
        //   |           | 
        //   '.  +^^^+  .'
        //     \`. \./ .'
        //       |_|_|  
        //       (___)    
        //       (___)
        //       \`---'


        // {
        ambientLight.current = new THREE.AmbientLight(0xFFFFFF, 1.2);
        if (scene.current) scene.current.add(ambientLight.current);


        // directionalLight.current = new THREE.DirectionalLight(0xffffff, 5);
        // directionalLight.current.position.set(50, 90, 45);

        // directionalLight.current.castShadow = true;
        // directionalLight.current.shadow.bias = -0.0001;
        // directionalLight.current.shadow.mapSize.width = 1024 * 4;
        // directionalLight.current.shadow.mapSize.height = 1024 * 4;
        // if (scene.current) scene.current.add(directionalLight.current);

        // let lightHelper = new THREE.DirectionalLightHelper(directionalLight.current)
        // if (scene.current) scene.current.add(lightHelper);



        // spotLight.current = new THREE.SpotLight(0xffa95c, 4);
        // spotLight.current = new THREE.SpotLight(0xf9e3d0, 4);
        // if (spotLight.current) spotLight.current.position.set(5 * lightDistanceFactor.current, 9 * lightDistanceFactor.current, 4.5 * lightDistanceFactor.current);
        // if (scene.current) scene.current.add(spotLight.current.target);
        // spotLight.current.castShadow = true;
        // spotLight.current.shadow.bias = -0.0001;
        // spotLight.current.shadow.mapSize.width = 1024 * 4;
        // spotLight.current.shadow.mapSize.height = 1024 * 4;
        // if (scene.current) scene.current.add(spotLight.current);

        // let newlightHelper = new THREE.SpotLightHelper(spotLight.current);
        // if (scene.current) scene.current.add(newlightHelper);



        let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.4);
        hemiLight.color.setHSL(0.6, 1, 0.6);
        hemiLight.groundColor.setHSL(0.095, 1, 0.75);
        hemiLight.position.set(0, 50, 0);
        // if (scene.current) scene.current.add(hemiLight);

        // let hemiLightHelper = new THREE.HemisphereLightHelper(hemiLight, 10);
        // if (scene.current) scene.current.add(hemiLightHelper);




        // https://threejs.org/examples/#webgl_lights_hemisphere
        let dirLight = new THREE.DirectionalLight(0xC9E2FF, .2);
        dirLight.color.setHSL(0.1, 1, 0.95);
        dirLight.position.set(10, 8, 8);
        dirLight.position.multiplyScalar(30);
        
        dirLight.castShadow = true;
        
        dirLight.shadow.mapSize.width = 2048;
        dirLight.shadow.mapSize.height = 2048;
        
        var d = 50;
        
        dirLight.shadow.camera.left = - d;
        dirLight.shadow.camera.right = d;
        dirLight.shadow.camera.top = d;
        dirLight.shadow.camera.bottom = - d;
        
        dirLight.shadow.camera.far = 3500;
        dirLight.shadow.bias = - 0.0001;

        if (scene.current) scene.current.add(dirLight);

        // let dirLightHelper = new THREE.DirectionalLightHelper(dirLight, 10);
        // if (scene.current) scene.current.add(dirLightHelper);



        // }

        //       _____  
        //     .'     \`.
        //    /         \
        //   |           |                             
        //   '.  +^^^+  .'
        //     \`. \./ .'
        //       |_|_|  
        //       (___)    
        //       (___)
        //       \`---'


    };



    const handleWindowResize = () => {
        const width = mount.current.clientWidth;
        const height = mount.current.clientHeight;

        renderer.current && renderer.current.setSize(width, height);
        if (camera.current) camera.current.aspect = width / height;

        // Note that after making changes to most of camera properties you have to call
        // .updateProjectionMatrix for the changes to take effect.
        camera.current && camera.current.updateProjectionMatrix();
    };

    const startAnimationLoop = () => {

        requestID.current = requestAnimationFrame(animate);
    };

    // https://sbcode.net/threejs/gltf-animation/
    clock.current = new THREE.Clock()

    // https://medium.com/swlh/building-a-3d-interactive-with-react-and-threejs-70dfd212bf67
    // const animate = () => {
    //     requestID.current = window.requestAnimationFrame(animate);
    //     if (controls.current) controls.current.update();
    //     if (mixer.current && clock.current) mixer.current.update(clock.current.getDelta());
    //     (renderer.current && scene.current && camera.current) && renderer.current.render(scene.current, camera.current);
    // };

    // https://medium.com/swlh/building-a-3d-interactive-with-react-and-threejs-70dfd212bf67
    const animate = () => {
        requestID.current = window.requestAnimationFrame(animate);
        if (controls.current) controls.current.update();
        if (mixer.current && clock.current) mixer.current.update(clock.current.getDelta());
        (renderer.current && scene.current && camera.current) && renderer.current.render(scene.current, camera.current);
        // if (spotLight.current) spotLight.current.position.set(50*lightDistanceFactor.current, 90*lightDistanceFactor.current, 45*lightDistanceFactor.current);
        // if (directionalLight.current) directionalLight.current.position.set(5*lightDistanceFactor.current, 9*lightDistanceFactor.current, 4.5*lightDistanceFactor.current);


        // this.renderScene();  //perform animation/shader updates here
    };


    return (
        <div className={styles.root}>
            { !loaded && <div className={styles.loading}>
                <div className={styles.loader} />
                <p className={styles.percent}>{(percentLoaded * 100).toFixed(0)}%</p>
                <p className={styles.subtext}>Loading...</p>
                {/* <img src={spinner} alt=""/> */}
            </div>}
            <div className={styles.three}
                style={{
                    visibility: \`\${loaded ? "visible" : "hidden"}\`
                } as React.CSSProperties} ref={mount} />
        </div>
    )
};



`}
                    </Javascript>
                </WideBlogSection>
            </Lesson>
            <Lesson title="React Photo Gallery">
                <WideBlogSection >

                    <Gallery/>
                </WideBlogSection>
                <WideBlogSection max_width="1280px">
<Words>React code to make this photo gallery. The layout logic is made using a Resize Observer in Javascript and an algorithm using image heights instead of CSS. That way the images look good on all screen sizes. The images are a little too high res so it takes too long to load </Words>
                    <Javascript>
                        {`
import React, { Component, useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { myDOMRectReadOnly, useResizeObserver } from "./UseResizeObserver";

const StyledGallery = styled.div<{ nowrap?: boolean; containerHeight: number; }>\` 
width: 100%;
min-width: 472px;
padding: 1.5vw;
height: \${props => props.containerHeight}px;
& div.container {
    margin: auto;
    width: 100%;
    position: relative;
}
& div.image {
    position: absolute;
    & img {
        width: 100%;
        display: inline-block;
    }
}
\`

interface GalleryProps {
    // nowrap?: boolean;
    // url: string,
    // text?: string
}
interface GalleryImage {
    url: string,
    width: number;
    height: number;
}

interface DOMImage {
    url: string,
    width: number;
    height: number;
    top: number;
    left: number;
}

export const Gallery: React.FC<GalleryProps> = () => {
    const [dimensions, setDimensions] = useState({ top: 0, left: 0 });
    const ref = useRef(null);
    
    // Optional callback to access the full DOMRect object if required.
    const optionalCallback = (entry: myDOMRectReadOnly) =>
    setDimensions({ top: entry.x, left: entry.left });
    
    // Access the width and the height returned from the observed element.
    const [width, height] = useResizeObserver(ref, optionalCallback);
    
    // -------- END RESIZE OBSERVER CODE -------------- //
    
    
    
    
    const [data, setData] = useState<GalleryImage[]>([]);
    
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const data = require('./Gallery.json');
        setData(data.images);
    }, []);
    
    const getColumns = (width: number) => {
        return Math.floor(width / 400);
    }
    const getElements = (width: number, data: GalleryImage[]) => {
        let columnCount = Math.floor(width / 400);
        let guttersTotalWidth = (columnCount - 1) * 20;
        let imagesTotalWidth = width - guttersTotalWidth;
        let imageWidth = Math.floor(imagesTotalWidth / columnCount);
        let lastColumnImageWidth = imagesTotalWidth - (imageWidth * (columnCount - 1))
        
        const getImageHeight = (width: number, height: number, columnWidth: number) => {
            return Math.floor((columnWidth / width) * height)
        }
        
        // array of length columnCount
        let columnHeights: number[] = new Array(columnCount).fill(0);
        let DOMImages: DOMImage[] = [];
        
        data.forEach(d => {
            let shortestColumnIdx: number = columnHeights.indexOf(Math.min(...columnHeights));
            let shortestColumnIsLast: boolean = shortestColumnIdx == (columnCount - 1);
            let adjustedImageWidth = (shortestColumnIsLast ? lastColumnImageWidth : imageWidth)
            let imageHeight: number = getImageHeight(d.width, d.height, adjustedImageWidth);
            DOMImages.push({
                url: d.url,
                width: adjustedImageWidth,
                height: imageHeight,
                top: columnHeights[shortestColumnIdx],
                left: shortestColumnIdx * (imageWidth + 20)
            })
            columnHeights[shortestColumnIdx] += (imageHeight + 20)
        })
        // console.log(DOMImages)
        // console.log(columnHeights, "col heihghts")
        return {
            containerHeight: Math.max(...columnHeights) + 64,
            images: DOMImages
        };
    }
    
    const {containerHeight, images} = getElements(Math.round(width), data);
    
    return (
        <StyledGallery containerHeight={containerHeight} ref={ref}>
        {/* {JSON.stringify(data)} */}
        {/* {data.map(_ => <div>{_.width}</div>)} */}
        {/* {\`height: \${height}, width: \${width} columns: \${getColumns(width)}\`} */}
        <div className="container">
        {!!data && images.map((d, i) =>
            <div key={i} className="image" style={{ width: d.width, height: d.height, top: d.top, left: d.left }}>
            <img src={\`\${d.url}\`} />
            </div>)}
            </div>
            </StyledGallery>
            )
        }
        
        
        
        `}
                    </Javascript>
                </WideBlogSection>
            </Lesson>

            <Lesson title="Python Vex Noise Generator">
                <Section title="">
                    <Words>Working on a Python version of the noise generator that works in Houdini. Haven't posted it yet but here's the main code.</Words>
                </Section>
                <WideBlogSection max_width="1280px">
                    <Python>{`import re

#######################################
#######################################
########## String Building ############
#######################################
#######################################

#########################################
###### Get Input / Output Dimensions ####

# returns a number 1-4
def getInputDimension(node):

    noise = node.parm("noise").eval()
    use_curl2D = node.parm("use_curl2D").eval()
    
    dimension_lut = {
        "input_all": [1,2,3,4],            
        "input_three": [3],     
        "input_flow": [2,3,4],
        "input_curl": [3,4],
        "input_curl2D": [2,3]
    }
    
    parm_name = {
        0: "input_all", #perlin
        1: "input_all", #simplex
        2: "input_three", #original_perlin
        3: "input_three", #sparse_convolution
        4: "input_three", #alligator
        5: "input_all", #worley
        6: "input_all", #voronoi
        7: "input_flow", #flow
        8: "input_curl2D" if use_curl2D else "input_curl" #curl
    }[noise]
    
    parm_val = node.parm(parm_name).eval()
    
    return dimension_lut[parm_name][parm_val]
    
# returns a number 1 or 3
def getOutputDimension(node):

    noise = node.parm("noise").eval()
    
    dimension_lut = {
        "output_both": [1,3],               
        "output_one": [1],               
        "output_three": [3]             
    }
    
    parm_name = {
        0: "output_both", #perlin
        1: "output_both", #simplex
        2: "output_both", #original_perlin
        3: "output_both", #sparse_convolution
        4: "output_both", #alligator
        5: "output_one", #worley
        6: "output_one", #voronoi
        7: "output_both", #flow
        8: "output_three" #curl
    }[noise]
    
    parm_val = node.parm(parm_name).eval()
    
    return dimension_lut[parm_name][parm_val]

    
######################################
######## Parameter Config ############


def getParameterConfig(node):
        
    def encodeFunctionName(str):   
        #https://www.sidefx.com/docs/houdini/hom/hou/text.html
        if bool(str): return hou.text.encode(str)
        else: return "Noise"

    def isPeriodic(**kwargs):
        periodic = bool(kwargs['periodic'])
        not_curl = kwargs['noise'] != 8
        return periodic and not_curl
        
    def useBuiltInTurbulence(**kwargs): 
        noise = kwargs["noise"]
        is_original_sparse_or_alligator = (noise == 2 or noise == 3 or noise == 4)
        use_built_in = bool(kwargs['enable_turbulence']) and bool(kwargs['enable_built_in_turbulence'])
        return is_original_sparse_or_alligator and use_built_in
        
    def usefBm(**kwargs):
        return bool(kwargs["enable_turbulence"]) and not useBuiltInTurbulence(**kwargs)
    
    def isZeroCentered(**kwargs):
        noise = kwargs["noise"]
        is_original_or_sparse = (noise == 2 or noise == 3)
        return is_original_or_sparse
        
    def getChannelNames(**kwargs):        
        prefix = kwargs["ch_prefix"]     
        names = ["frequency",
                "amplitude",
                "offset",
                "attenuation",
                "pos_4D",
                "period_x",
                "period_y",
                "period_z",
                "period_w",
                "flow",
                "jitter_x",
                "jitter_y",
                "jitter_z",
                "turbulence",
                "int_turbulence",
                "roughness",
                "lacunarity"]
        # channel names are put in a dictionary so they can optionally be prefixed during wrangle generation
        ch_names = {names[i]: names[i] for i in range(len(names))}
        return ch_names
        
        
    def getNoiseTitleString(**kwargs):
        noise = kwargs["noise"]
        inputDimension = kwargs["inputDimension"]
        use_curl = (noise == 8)
        
        inverted = "Inverted " if (kwargs["invert"]) else ""
        turbulent = "Turbulent " if kwargs["enable_turbulence"] else ""
        input = {
            1: "1D ",
            2: "2D ",
            3: "",
            4: "4D "
        }[inputDimension]
        
        periodic = "Periodic " if kwargs["is_periodic"] else ""
        noiseName = {
            0: "Perlin",
            1: "Simplex",
            2: "Original Perlin",
            3: "Sparse Convolution",
            4: "Alligator",
            5: {0: "", 1: "Manhattan ", 2: "Chebyshev "}[kwargs["distance_metric"]] + "Worley",
            6: "Voronoi",
            7: "Flow",
            8: "Curl"
        }[noise] + " "
        curlSimplex = "Simplex " if (use_curl and kwargs["use_curlSimplex"]) else ""
        curl2D = "2D " if (use_curl and kwargs["use_curl2D"]) else ""
        
        output = " ─ 3D output" if (kwargs["outputDimension"] == 3 and not use_curl ) else ""
        
        return inverted  + periodic + turbulent + input + curlSimplex + noiseName  + curl2D + "Noise" + output
               
  
    parms = {
        "noise":             node.parm("noise").eval(),
        "periodic":          node.parm("periodic").eval(),
        "enable_turbulence": node.parm("enable_turbulence").eval(),
        "enable_built_in_turbulence": node.parm("enable_built_in_turbulence").eval(),
        "distance_metric":   node.parm("distance").eval(), # Euclidean, Manhattan, Chebyshev
        "distance_value":    node.parm("distance_value").eval(), # F1, F2-F1
        "use_curl2D":        node.parm("use_curl2D").eval(),
        "use_curlSimplex":   node.parm("use_curlSimplex").eval(),
        "invert":            node.parm("invert").eval(),
        "make_function":     node.parm("make_function").eval(),
        "function_name":     encodeFunctionName(node.parm("function_name").eval()),
        "output_name":       node.parm("output_name").eval(),
        "ch_prefix":         node.parm("ch_prefix").eval(),
        "octave_normalization": node.parm("octave_normalization").eval(),
    }
            
    config = {
        "inputDimension":  getInputDimension(node),
        "outputDimension": getOutputDimension(node),
        "is_periodic":     isPeriodic(**parms),
        "use_built_in_turbulence": useBuiltInTurbulence(**parms),
        "use_inversion":   bool(parms["invert"]) and not useBuiltInTurbulence(**parms),
        "is_zero_centered": isZeroCentered(**parms),
        "use_fBm": usefBm(**parms),
        "tab": (' ' * 4) if usefBm(**parms) else "",
        "ch_names": getChannelNames(**parms)
    }
    
    # merge parms into config
    config.update(parms)
    
    # add title string
    config["noise_title_string"] = getNoiseTitleString(**config)

    return config
    
        
    
######################
##### Channels #######

def freqAmpOffsetAttenChannels(**kwargs):
    ch_names = kwargs["ch_names"]
    freq = 'vector freq = chv("{}");'.format(ch_names["frequency"])
    amp = '\n' + 'float amp = chf("{}");'.format(ch_names["amplitude"])
    offset = '\n' + 'vector offset = chv("{}");'.format(ch_names["offset"])
    atten = '\n' + 'float atten = chf("{}"); // default is 1'.format(ch_names["attenuation"])
    return freq + amp + offset + atten;
    
def pos4DChannel(**kwargs):
    ch_names = kwargs["ch_names"]
    pos4D = '\n' + 'float pos4D = chf("{}"); // @Time'.format(ch_names["pos_4D"])
    return pos4D if (kwargs["inputDimension"] == 4) else "" 
        
    
def periodicChannels(**kwargs):
    ch_names = kwargs["ch_names"]

    period_x = '\n' + 'int period_x = chi("{}");'.format(ch_names["period_x"])
    period_y = '\n' + 'int period_y = chi("{}");'.format(ch_names["period_y"])
    period_z = '\n' + 'int period_z = chi("{}");'.format(ch_names["period_z"])
    period_w = '\n' + 'int period_w = chi("{}");'.format(ch_names["period_w"])
    
    channels = {
        1: period_x,
        2: period_x + period_y,
        3: period_x + period_y + period_z,
        4: period_x + period_y + period_z + period_w
    }[kwargs["inputDimension"]]
                
    return '\n' + channels if kwargs["is_periodic"] else ""
    
    
def flowChannel(**kwargs):
    ch_names = kwargs["ch_names"]
    flow = '\n' + 'float flow = chf("{}"); // @Time'.format(ch_names["flow"])
    return flow if (kwargs["noise"] == 7) else "" 

def jitterChannel(**kwargs):
    ch_names = kwargs["ch_names"]

    jitter_x = '\n' + 'float jitter_x = chf("{}");'.format(ch_names["jitter_x"])
    jitter_y = '\n' + 'float jitter_y = chf("{}");'.format(ch_names["jitter_y"])
    jitter_z = '\n' + 'float jitter_z = chf("{}");'.format(ch_names["jitter_z"])
    
    channels = {
        1: jitter_x,
        2: jitter_x + jitter_y,
        3: jitter_x + jitter_y + jitter_z,
        4: jitter_x + jitter_y + jitter_z
    }[kwargs["inputDimension"]]
                
    jitter = {
        1: '',
        2: '',
        3: '\n' + 'vector jitter = set(jitter_x, jitter_y, jitter_z);',
        4: '\n' + 'vector4 jitter = set(jitter_x, jitter_y, jitter_z, 0);'
    }[kwargs["inputDimension"]]
    return '\n' + channels + jitter if (kwargs["noise"] == 6) else ""

def turbRoughLacunChannels(**kwargs):
    ch_names = kwargs["ch_names"]
    use_built_in_turbulence = kwargs["use_built_in_turbulence"]
    is_periodic = kwargs["is_periodic"]
    
    def turb():
        if use_built_in_turbulence: str = 'int turbulence = chi("{}");'.format(ch_names["int_turbulence"])
        else: str = 'float turbulence = chf("{}");'.format(ch_names["turbulence"])
        return '\n\n' + str
       
    def lacun():
        if not use_built_in_turbulence: 
            if is_periodic: return '\n' + "float lacun = 2.0; // must be 2 for tileable periodic noise"
            else: return '\n' + 'float lacun = chf("{}");  // default is 2'.format(ch_names["lacunarity"])
        else: return ""
       
    rough = '\n' + 'float rough = chf("{}");  // default is 0.5'.format(ch_names["roughness"])
    
    if (bool(kwargs["enable_turbulence"])):
        return turb() + rough + lacun()
    else: 
        return ""

    
##########################
##### Noise Function #####    
    
def positionArguments(**kwargs): 
    return {
        1: '(posx',
        2: '(pos.x, pos.y',
        3: '(pos',
        4: '(pos'
    }[kwargs["inputDimension"]]

def periodicArguments(**kwargs):
    if not kwargs["is_periodic"]: 
        return ""
    elif kwargs["use_fBm"]:
        return {
            1: ', px',
            2: ', px, py',
            3: ', px, py, pz',
            4: ', px, py, pz, pw'
        }[kwargs["inputDimension"]] 
    else:
        return {
            1: ', period_x',
            2: ', period_x, period_y',
            3: ', period_x, period_y, period_z',
            4: ', period_x, period_y, period_z, period_w'
        }[kwargs["inputDimension"]]    
    
def noiseFunction(**kwargs):
    def getNval(**kwargs):
        underscore = "_" if kwargs["use_fBm"] else ""
        type = {
            1: "float ",
            3: "vector "
        }[kwargs["outputDimension"]]
        return type + underscore + "nval = "
        
    pos_args = positionArguments(**kwargs)
    period_args = periodicArguments(**kwargs)   
    is_periodic = kwargs["is_periodic"]
    turb_args = ', turbulence, rough, atten' if kwargs["use_built_in_turbulence"] else ''
    cellular_value = ["f1;", "(f2 - f1);"][kwargs["distance_value"]]
    if bool(kwargs["use_curl2D"]):
        curl_func = "curlxnoise2d" if kwargs["use_curlSimplex"] else "curlnoise2d"
    else: 
        curl_func = "curlxnoise" if kwargs["use_curlSimplex"] else "curlnoise"
    
    noise = {
        0: ("pnoise" if is_periodic else "noise") + pos_args + period_args + ");", #perlin
        1: ("pxnoise" if is_periodic else "xnoise") + pos_args + period_args + ");", #simplex
        2: "onoise" + pos_args + period_args + turb_args + ");", #original_perlin
        3: "snoise" + pos_args + period_args + turb_args + ");", #sparse_convolution
        4: "anoise" + pos_args + period_args + turb_args + ");", #alligator
        5: cellular_value, #worley
        6: cellular_value, #voronoi
        7: ("flowpnoise" if is_periodic else "flownoise") + pos_args + period_args + ", flow);", #flow
        8: curl_func + pos_args + ");"  #curl
    }[kwargs["noise"]]
    
    return "\n" + kwargs["tab"] + getNval(**kwargs) + noise

    

def cellularFunction(**kwargs):
    def cellularArguments(**kwargs):
        noise = kwargs["noise"]
        if (noise == 5): # Worley 
            return ', seed, f1, f2, f3, f4'
        elif (noise == 6): # Voronoi
            return {
                1: ', jitter_x, seed, f1, f2, pos1, pos2',
                2: ', jitter_x, jitter_y, seed, f1, f2, pos1x, pos1y, pos2x, pos2y',
                3: ', jitter, seed, f1, f2, pos1, pos2',
                4: ', jitter, seed, f1, f2, pos1, pos2',
            }[kwargs["inputDimension"]]
        else: 
            return ""
           
    pos_args = positionArguments(**kwargs)        
    cell_args = cellularArguments(**kwargs)
    period_args = periodicArguments(**kwargs)   
    tab = kwargs["tab"]
    noise = kwargs["noise"]
    
    if (noise == 5): # Worley
        func_name = {
            0: "wnoise",
            1: "mwnoise",
            2: "cwnoise"
        }[kwargs["distance_metric"]]
        
        return "\n" + tab + func_name + pos_args + cell_args + period_args + ");"
        
    elif (noise == 6): # Voronoi
        seed_pos = {
            1: "\n" + tab + 'pos1 = (float)(pos1 - offset) / freq;' + "\n" + tab + 'pos2 = (float)(pos2 - offset) / freq;',
            2: "\n" + tab + 'vector pos1 = (set(pos1x, pos1y) - offset) / freq;' + "\n" + tab + 'vector pos2 = (set(pos2x, pos2y) - offset) / freq;',
            3: "\n" + tab + 'pos1 = (pos1 - offset) / freq;' + "\n" + tab + 'pos2 = (pos2 - offset) / freq;',
            4: "\n" + tab + 'pos1 = (pos1 - offset) / freq;' + "\n" + tab + 'pos2 = (pos2 - offset) / freq;',
        }[kwargs["inputDimension"]]
        
        return "\n" + tab + 'vnoise' + pos_args + cell_args + period_args + ");" + seed_pos
    else: 
        return ""
    

        
def cellularVariables(**kwargs):
    tab = kwargs["tab"]
    noise = kwargs["noise"]
    if (noise == 5): # Worley
        return "\n" + tab + 'int seed; float f1, f2, f3, f4;'
    elif (noise == 6): # Voronoi
        str = 'int seed; float f1, f2'
        args = {
            1: ', pos1, pos2;',
            2: ', pos1x, pos1y, pos2x, pos2y;',
            3: '; vector pos1, pos2;',
            4: '; vector4 pos1, pos2;'
        }[kwargs["inputDimension"]]
        return "\n" + tab + str + args
    else: 
        return ""
        
           
def getPosition(**kwargs):
    tab = kwargs["tab"]
    P = "P" if kwargs["make_function"] else "v@P"
    return {
        1: "\n" + tab + 'float posx = set({} * freq + offset);'.format(P),
        2: "\n" + tab + 'vector2 pos = set({} * freq + offset);'.format(P),
        3: "\n" + tab + 'vector pos = set({} * freq + offset);'.format(P),
        4: "\n" + tab + 'vector4 pos = set({} * freq + offset);'.format(P) + "\n" + tab + "pos.w = pos4D;",
    }[kwargs["inputDimension"]]

       
#########################
########## fBm ##########


    
def fBmInversion(**kwargs):
    tab = kwargs["tab"]
    if (kwargs["use_inversion"]):
        if (kwargs["outputDimension"] == 3): # 3D noises
            inversion = "_nval = -1 * _nval; // invert"
        elif (kwargs["is_zero_centered"]): # Original, Sparse
            inversion = "_nval = -1 * _nval; // zero-centered inversion"
        else: 
            inversion = "_nval = 1 - _nval; // inversion assuming 0-1 range"
        return "\n" + tab + inversion 
    else:
        return ""
        
def fBmPeriodicVariables(**kwargs):
    tab = kwargs["tab"]
    period_x = '\n' + tab + 'int px = period_x * period_scale;'
    period_y = '\n' + tab + 'int py = period_y * period_scale;'
    period_z = '\n' + tab + 'int pz = period_z * period_scale;'
    period_w = '\n' + tab + 'int pw = period_w * period_scale;'
    
    vars = {
        1: period_x,
        2: period_x + period_y,
        3: period_x + period_y + period_z,
        4: period_x + period_y + period_z + period_w
    }[kwargs["inputDimension"]]
                
    return '\n' + tab + "// period" + '\n' + tab +"int period_scale = int(pow(2, i));" + vars + '\n' if kwargs["is_periodic"] else ""
    
    

def fBmLoop(noise_function, **kwargs):

    def nval(**kwargs):
        return {
            1: 'float nval = 0;',
            3: 'vector nval = {0, 0, 0};',
        }[kwargs["outputDimension"]]
    
    def weight(**kwargs):  
        return {
            1: '    _nval *= weight;',
            3: '    _nval *= (vector)weight;',
        }[kwargs["outputDimension"]] + '\n'
        
    def octaveNormalization(**kwargs):
        return {
            0: 'max_amp += (float)(1/pow(2, i)) * remainder;',
            1: 'max_amp += weight * remainder;'
        }[kwargs["octave_normalization"]]
        
  
        
    str = '\n\n' + '''turbulence += 1.0; // run at least once
int octaves = min(int(ceil(turbulence)), 16); // max octaves 16
{nval}
float weight = 1;
float max_amp = 0;

// fBm
for (int i = 0; i < octaves; i++) {{
{fBmPeriodicVariables}{noise_function}{inversion}
{weight}
    // fractional part of the last octave - equal to 1 on other octaves
    float remainder = min(turbulence - i, 1);

    // update values
    nval += _nval * remainder;
    {octave_normalization}
    freq *= lacun;
    weight *= rough;
}};

nval /= max_amp; // normalization'''

    return str.format(
                    nval = nval(**kwargs),
                    noise_function = noise_function,
                    weight = weight(**kwargs),
                    inversion = fBmInversion(**kwargs),
                    fBmPeriodicVariables = fBmPeriodicVariables(**kwargs),
                    octave_normalization = octaveNormalization(**kwargs),
                )    

##################################
####### Header / Footer ##########

def functionHeader(**kwargs):
    type = {
        1: "float ", 
        3: "vector "
    }[kwargs["outputDimension"]]
    header = "function " + type + kwargs["function_name"] + "(vector P;)" + "\n{\n"
    return header if kwargs["make_function"] else ""
  
       
def getInversion(**kwargs):
    if (kwargs["use_inversion"] and not kwargs["enable_turbulence"]):
        if (kwargs["outputDimension"] == 3): # 3D noises
            inversion = "nval = -1 * nval; // invert"
        elif (kwargs["is_zero_centered"]): # Original, Sparse
            inversion = "nval = -1 * nval; // zero-centered inversion"
        else: 
            inversion = "nval = 1 - nval; // inversion assuming 0-1 range"            
        return "\n" + inversion 
    else:
        return ""   
    
def getAttenuation(**kwargs):
    if not (kwargs["use_built_in_turbulence"]):
        return "\n" + "nval = sign(nval) * pow(abs(nval), atten); // attenuation"
    else: 
        return ""
  
        
def getAmp(**kwargs):
    cast = {
        1: "",
        3: "(vector)"
    }[kwargs["outputDimension"]]
    
    return "\n" + "nval *= " + cast + "amp; // amp"
    
    
def outputNoise(**kwargs):

    def encodeAttribName(str):        
        if bool(str): return hou.text.encodeAttrib(str)
        else: return "noise"
        
    make_function = kwargs["make_function"]
    function_name = kwargs["function_name"]
    output_name = encodeAttribName(kwargs["output_name"])
    output_string = {
        1: "f@" + output_name + " = " + (function_name + "(v@P);" if make_function else "nval;"),
        3: "v@"+ output_name + " = " + (function_name + "(v@P);" if make_function else "nval;"),
    }[kwargs["outputDimension"]]
    
    make_function_ending = ("\n\n" + "return nval;" + "\n" + "};") if make_function else ""
    
    return  make_function_ending + "\n\n" + output_string
    
    
##########################
##### Build String #######    

def getVEXString(node, config):
    
    channels = (
        freqAmpOffsetAttenChannels(**config)
        + pos4DChannel(**config)
        + periodicChannels(**config)     
        + flowChannel(**config) 
        + jitterChannel(**config) 
        + turbRoughLacunChannels(**config)
    )
          
    noise_function = (
        "\n\n" + config["tab"] + "// " + config["noise_title_string"]
        + cellularVariables(**config)
        + getPosition(**config)
        + cellularFunction(**config)
        + noiseFunction(**config)
    )
    
    footer = (
        "\n" 
        + getInversion(**config)
        + getAttenuation(**config)
        + getAmp(**config)
        + outputNoise(**config)
    )
    
    ##### Build String #######
    str = ""
    str += functionHeader(**config)
    str += channels
    if (config["use_fBm"]):
        str += fBmLoop(noise_function, **config)
    else:
        str += noise_function
    str += footer
    
    return str
    
    
    
#######################################
#######################################
###### Spare Parameter Creation #######
#######################################
#######################################

def freqAmpOffsetAttenParms(**kwargs):
    ch_names = kwargs["ch_names"]
    freq = hou.FloatParmTemplate(ch_names["frequency"], "Frequency", 3, default_value=(1,1,1))
    amp = hou.FloatParmTemplate(ch_names["amplitude"], "Amplitude", 1, max=5, default_value=((1),))
    offset = hou.FloatParmTemplate(ch_names["offset"], "Offset", 3, default_value=((0),(0),(0),))
    atten = hou.FloatParmTemplate(ch_names["attenuation"], "Attenuation", 1, max=2, default_value=(1,))
    return (freq,amp,offset,atten);
    
    
def pos4DParm(**kwargs):
    ch_names = kwargs["ch_names"]
    pos4D = hou.FloatParmTemplate(ch_names["pos_4D"], "Position 4D", 1, default_expression=("@Time",))
    if (kwargs["inputDimension"] == 4 ): return (pos4D,)
    else: return ()
    
def flowParm(**kwargs):
    ch_names = kwargs["ch_names"]
    flow = hou.FloatParmTemplate(ch_names["flow"], "Flow", 1, default_expression=("@Time",))
    if (kwargs["noise"] == 7 ): return (flow,)
    else: return ()
    
    
def jitterParm(**kwargs):
    
    ch_names = kwargs["ch_names"]
    jitter_x = hou.FloatParmTemplate(ch_names["jitter_x"], "Jitter X", 1, max=1, default_value=(1,))
    jitter_y = hou.FloatParmTemplate(ch_names["jitter_y"], "Jitter Y", 1, max=1, default_value=(1,))
    jitter_z = hou.FloatParmTemplate(ch_names["jitter_z"], "Jitter Z", 1, max=1, default_value=(1,))
    
    parm_templates = {
        1: (jitter_x,),
        2: (jitter_x, jitter_y),
        3: (jitter_x, jitter_y, jitter_z),
        4: (jitter_x, jitter_y, jitter_z)
    }[kwargs["inputDimension"]]
            
    folder = hou.FolderParmTemplate(
        "jitter_folder",
        "Voronoi Jitter",
        parm_templates = parm_templates,
        folder_type = hou.folderType.Simple
    )
    
    return (folder,) if (kwargs["noise"] == 6)  else ()
          
    
def periodicParms(**kwargs):
    ch_names = kwargs["ch_names"]
    period_x = hou.IntParmTemplate(ch_names["period_x"], "Period X", 1, max=10, default_value=(1,))
    period_y = hou.IntParmTemplate(ch_names["period_y"], "Period Y", 1, max=10, default_value=(1,))
    period_z = hou.IntParmTemplate(ch_names["period_z"], "Period Z", 1, max=10, default_value=(1,))
    period_w = hou.IntParmTemplate(ch_names["period_w"], "Period W", 1, max=10, default_value=(9999,))
    
    parm_templates = {
        1: (period_x,),
        2: (period_x, period_y),
        3: (period_x, period_y, period_z),
        4: (period_x, period_y, period_z, period_w)
    }[kwargs["inputDimension"]]
            
    folder = hou.FolderParmTemplate(
        "periodic_folder",
        "Periodic",
        parm_templates = parm_templates,
        folder_type = hou.folderType.Simple
    )
    
    return (folder,) if kwargs["is_periodic"] else ()
    
    

    
def turbRoughLacunParms(**kwargs):
    ch_names = kwargs["ch_names"]
    use_built_in_turbulence = kwargs["use_built_in_turbulence"]
    is_periodic = kwargs["is_periodic"]
    
    def turb():
        if use_built_in_turbulence: return hou.IntParmTemplate(ch_names["int_turbulence"], "Turbulence", 1, max=6, default_value=(3,))
        else: return hou.FloatParmTemplate(ch_names["turbulence"], "Turbulence", 1, max=6, default_value=(3,))

    rough = hou.FloatParmTemplate(ch_names["roughness"], "Roughness", 1, max=1, default_value=(0.5,))
    lacun = hou.FloatParmTemplate(ch_names["lacunarity"], "Lacunarity", 1, max=4 ,default_value=(2,))
    
    parm_templates = (turb(), rough,)
    if (not use_built_in_turbulence and not is_periodic):
        parm_templates += (lacun,)
        
    folder = hou.FolderParmTemplate(
        "fBm_folder",
        "Turbulence",
        parm_templates = parm_templates,
        folder_type = hou.folderType.Simple
    )
    
    return (folder,) if kwargs["enable_turbulence"] else ()


    
# returns a folder of parms
def getSpareParms(node, config):
        
    ########## Parms ##########
    parms = tuple()
    parms += freqAmpOffsetAttenParms(**config)  # freq, amp, offset
    parms += pos4DParm(**config)           # 4D input
    parms += flowParm(**config)            # flow
    parms += jitterParm(**config)          # voronoi jitter
    parms += periodicParms(**config)       # periodic
    parms += turbRoughLacunParms(**config) # fBm
    
    ##### Define Folder ####### 
    folder = hou.FolderParmTemplate("noise_parameters", "Noise Parameters", folder_type=hou.folderType.Simple)
    folder.setParmTemplates(parms)
    
    return folder
    
    
#####################################
########### Actions #################

   
def showHideNoiseParms(node, config):

    # get all parm names in a folder and sub folders
    def allParmNames(node, group_or_folder): 
    
        # recursively get all parmTemplates in a group or folder
        def allParmTemplates(group_or_folder):
            for parm_template in group_or_folder.parmTemplates():
                if (parm_template.type() == hou.parmTemplateType.Folder and parm_template.isActualFolder()):
                    for sub_parm_template in allParmTemplates(parm_template):
                        yield sub_parm_template
                else: yield parm_template
        
        parm_tuples = [node.parmTuple(template.name()) for template in allParmTemplates(group_or_folder)]
        parms = [p for tupl in parm_tuples for p in tupl] # flatten
        return [p.name() for p in parms] # return string names
    
        
    # get generated parms
    generated_parm_folder = getSpareParms(node, config)
    generated_parm_names = allParmNames(node, generated_parm_folder)

    # all parms in existing noise folder
    noise_parm_folder = node.parmTemplateGroup().findFolder("Noise Parameters")
    noise_parm_names = allParmNames(node, noise_parm_folder)

    # if the noise parm is in the list of generated parm names, show it, else hide
    for p in node.parms():
        if (p.name() in noise_parm_names):
            if (p.name() in generated_parm_names):
                p.hide(False)
            else: 
                p.hide(True)
                            
    
#####################################
########### Update ##################

def generateWrangle(node):

   #https://www.sidefx.com/docs/houdini/hom/hou/text.html
    def prefixParmName(parm_name, config):        
        if bool(parm_name): return hou.text.encodeParm(config["ch_prefix"] + parm_name)
        else: return ""   


    # get config 
    config = getParameterConfig(node)
    
    # prefix the channel names - prefixing is optional
    config["ch_names"] = {k: prefixParmName(v, config) for (k,v) in config["ch_names"].items()}
        
    # create node
    wrangle_type = "volumewrangle" if (node.parm("wrangle_type").eval()) else "attribwrangle"
    wrangle = node.parent().createNode(wrangle_type) 
                    
    # set vex string
    str = getVEXString(node, config)
    wrangle.parm("snippet").set(str)
    
    # set node name - replace non-alphanumeric with "_"
    name = re.sub("[^0-9a-zA-Z]+", "_", config["noise_title_string"])
    wrangle.setName(name, unique_name=True)
    
    # connect
    connection = node.inputConnections()[0]
    wrangle.setFirstInput(connection.inputNode(), connection.inputIndex())
    wrangle.moveToGoodPosition()
    
    # add parms
    generated_parm_folder = getSpareParms(node, config)
    ptg = wrangle.parmTemplateGroup()
    ptg.append(generated_parm_folder)
    wrangle.setParmTemplateGroup(ptg)
    
    ###############################
    #### Set parms to values on HDA
    
    # lut to get the original un-prefixed parm names
    parm_name_lut = {v: k for (k,v) in config["ch_names"].items()}
    
    for pt in wrangle.parmTuples():
        is_noise_parm = pt.name() in list(parm_name_lut.keys())
        nameOfTupleOnHDA = parm_name_lut[pt.name()] if is_noise_parm else pt.name()
        hda_pt = node.parmTuple(nameOfTupleOnHDA)
        if hda_pt is not None:
            for i in range(len(pt)):
                hda_parm = hda_pt[i]
                try:
                    expression = hda_parm.expression()
                except:
                    expression = ""
                finally:
                    if bool(expression):
                        language = hda_parm.expressionLanguage()
                        pt[i].setExpression(expression, language=language)
                    else:
                        pt[i].set(hda_parm.eval())
                        
        # manually set the group parm - different for volume and attrib wrangles
        if wrangle_type == "volumewrangle":
            wrangle.parm("group").set(node.parm("volume_wrangle_group").eval())
        elif wrangle_type == "attribwrangle":
            wrangle.parm("group").set(node.parm("attribute_wrangle_group").eval())
        
def updateAll(node):
    # get config
    config = getParameterConfig(node)
    
    # update vex string
    str = getVEXString(node, config)
    node.parm("generated_vex").set(str)
        
    # show / hide noise parms
    showHideNoiseParms(node, config)
    
#####################################
####### Optional Sync Parms #########


def syncInputDropdowns(node):
    # call on change of noise, input, or use_curl2D

    # get current input dimension
    input_dimension = getInputDimension(node)
    
    lut = {
        "input_all": [1,2,3,4],            
        "input_three": [3],     
        "input_flow": [2,3,4],
        "input_curl": [3,4],
        "input_curl2D": [2,3]
    }
    
    # sync all dropdowns if they contain current value
    for key in lut:
        arr = lut[key]
        if input_dimension in arr:
            idx = arr.index(input_dimension)
            node.parm(key).set(idx)
            
                                            
                    `}
                    </Python>
                </WideBlogSection>
            </Lesson>
            <Lesson title="VEX Code Example">
                <Section title="">
                    <Words>
                        Vex is a C Based language in Houdini. This code is part of a tool I'm building that advects attributes across the surface of a geometry. Haven't finished the tool yet but here's the main part.
                    </Words>
                </Section>
                <WideBlogSection max_width="1280px">
                    <CCode>
                        {`/////////////////////////////////
//////// DEFINE FUNCTIONS /////////

function vector getNextPosLerp(vector current_pos; vector next_pos; float remaining_dist;) {
    float dist_to_next_pos = length(next_pos - current_pos);
    float percent_to_next = float(min(remaining_dist, dist_to_next_pos))/(float)dist_to_next_pos;
    vector __next_pos = lerp(current_pos, next_pos, percent_to_next);
    return __next_pos;
}

function int orientedHalfEdgeIntersectsZAxis(int halfEdge; vector intersect_pos; int ptnum; vector4 orient;) {    

    function vector orient(vector pos; int ptnum; vector4 orient;) {
        vector position = pos;
        position -= point(0, "P", ptnum);
        position = qrotate(qinvert(orient), position);
        return position;
    }

    function vector undoOrient(vector pos; int ptnum; vector4 orient;) {
        vector position = pos;
        position = qrotate(orient, position);
        position += point(0, "P", ptnum);
        return position;
    }

    // get src and dst points of half edge
    int srcpoint = hedge_srcpoint(0, halfEdge);     
    int dstpoint = hedge_dstpoint(0, halfEdge);
    vector src_pos = point(0, "P", srcpoint); 
    vector dst_pos = point(0, "P", dstpoint);
    
    // redefine these positions as if the geometry was translated and oriented. Unique translate/orient per ptnum
    src_pos = orient(src_pos, ptnum, orient);
    dst_pos = orient(dst_pos, ptnum, orient);
    
    // if the half edge intersects the z-axis at this redefined position, return the intersect position
    if (sign(src_pos.x) != sign(dst_pos.x)) {
        vector dir = dst_pos - src_pos;
        float dotV = 1 / dot(dir, {1, 0 , 0}); 
        float ratio = -src_pos.x * dotV;
        vector z_axis_pos = (dir * ratio) + src_pos;
        
        z_axis_pos = undoOrient(z_axis_pos, ptnum, orient); // undo orient
        intersect_pos = z_axis_pos; // write to parameter
        return 1;
    }
    else {
        intersect_pos = {-1,-1,-1};
        return 0;    
    } 
};

function int getNextPrimAndIntersectionPosFromDeadEndPoint(int dead_end_point; vector last_dir; int ptnum; vector4 orient; vector write_intersect_pos;) {
    vector dead_end_pos = point(0, "P", dead_end_point);
    int prims[] = pointprims(0, dead_end_point); // neighbour prims
     
    float dot = -9999;
    vector intersect_pos;
    int half_edge;
    
    foreach(int p; prims) {
        int half_edges[] = prim(0, "half_edges", p);     
        foreach(int he; half_edges) {
            vector __intersect_pos;
            int intersects = orientedHalfEdgeIntersectsZAxis(he, __intersect_pos, ptnum, orient);
            if (intersects) {
                float tolerance = 0.0001;
                int intersection_is_at_dead_end = (length(__intersect_pos - dead_end_pos) < tolerance) ? 1 : 0;
                if (!intersection_is_at_dead_end) {
                    vector dir = normalize(__intersect_pos - dead_end_pos);
                    float __dot = dot(dir, last_dir);
                    if (__dot > dot) {
                        dot = __dot;
                        half_edge = he;
                        intersect_pos = __intersect_pos;
                    }
                    
                }
                
            }
        }    
    }
        
    int equivalent_half_edge = hedge_nextequiv(0, half_edge);
    int next_prim = hedge_prim(0, equivalent_half_edge);
    
    // write and return
    write_intersect_pos = intersect_pos;
    return next_prim;
    
};


function int getNextPrimAndIntersectionPos(int current_prim; vector src_pos; int ptnum; vector4 orient; vector write_intersect_pos;) {
    // gets the half edges of a prim
    // finds the positions where half edges intersect the z-axis ()
    // returns the furthest position
    
    int half_edges[] = prim(0, "half_edges", current_prim);
    int half_edge_prim_neighbours[] = prim(0, "half_edge_prim_neighbours", current_prim);
    
    // variables to return
    vector intersect_pos;
    int next_prim = -1;
    
    float dist_to_intersection = 0; // compare to this every loop
    foreach(int i; int he; half_edges) {
        // see if the half edge intersects the z-axis
        vector __intersect_pos;
        int intersects = orientedHalfEdgeIntersectsZAxis(he, __intersect_pos, ptnum, orient);
        
        // find the half edge that intersects the z axis at the furthest position from the current pos
        float __dist_to_intersection = length(__intersect_pos - src_pos);
        if (intersects && __dist_to_intersection > dist_to_intersection) {
            dist_to_intersection = __dist_to_intersection;
            
            //half_edge = he;
            intersect_pos = __intersect_pos;
            next_prim = half_edge_prim_neighbours[i];
        }
    }
    
    // write variable and return
    write_intersect_pos = intersect_pos;
    return next_prim;
    
}; 

////////////////////////////////
//////// END FUNCTIONS /////////

int start_prim = i@start_prim;
vector start_pos = v@P;

// mask
float mask = f@total_mask;
vector vel = v@advection_velocity;
float magnitude = length(vel) * mask;

// optional magnitude reduction
float overall_reduction = 0.1;
if (chi("advection_velocity_dropdown") == 0) {
    overall_reduction /= chf("advection_velocity_noise_frequency");
}

float dist = chf("dist") * magnitude * overall_reduction;

// init
float remaining_dist = dist;
float dist_to_intersect;
vector prev_dir = normalize(v@forward);
int current_prim = start_prim;
vector src_pos = start_pos;
vector dst_pos = start_pos;

int max_prims_traversed = chi("max_prims_traversed");
int j = 0;
while(remaining_dist > 0 && j < max_prims_traversed) {
    src_pos = dst_pos;
    
    vector intersect_pos;
    int intersect_pos_is_src_pos;
    int next_prim;
    int success;

    // if a full circle is made around the geometry, reinitialize
    if (dst_pos == start_pos) {
        current_prim = start_prim;
        src_pos = start_pos;
        dst_pos = start_pos;
    };
    
    next_prim = getNextPrimAndIntersectionPos(current_prim, src_pos, @ptnum, p@orient, intersect_pos);
    
    success = (next_prim == -1 ) ? 0 : 1;
    intersect_pos_is_src_pos = (length(intersect_pos - src_pos) < 0.000000001) ? 1 : 0;
    
    // if unsuccessful, try to get the next prim and intersection using the dead end point function
    if (!success || intersect_pos_is_src_pos) {
    
        int dead_end_point = nearpoint(0, src_pos);
        
        next_prim = getNextPrimAndIntersectionPosFromDeadEndPoint(dead_end_point, prev_dir, @ptnum, p@orient, intersect_pos);
        
        success = (next_prim == -1) ? 0 : 1;
        intersect_pos_is_src_pos = (length(intersect_pos - src_pos) < 0.000000001) ? 1 : 0;
        
        // if still unsuccessful, give up and go home
        if(!success || intersect_pos_is_src_pos) {
            i@error = 1;
            break;
        }
    }
    
    dist_to_intersect = length(intersect_pos - src_pos);
    dst_pos = intersect_pos;
    current_prim = next_prim;
    prev_dir = normalize(intersect_pos - src_pos);
    remaining_dist -= dist_to_intersect; 
    j++;  
};

// add error attribute if the point got stuck and the while loop was ended
if (j >= max_prims_traversed) i@error = 1;

remaining_dist += dist_to_intersect; // bring the remaining dist back from the negative value requred to end the while loop
vector sample_pos = getNextPosLerp(src_pos, dst_pos, remaining_dist);
v@sample_pos = sample_pos;

////////////////////////////////////
//// SAMPLE VALUE 
int prim;
vector uv;
float xyzdist = xyzdist(0, sample_pos, prim, uv);

float value = primuv(0, "input_attribute", prim, uv);

// optional blend previous iteration
float blend_amt = clamp(chf("bias"), 0, 1);
value = lerp(f@input_attribute, value, blend_amt);

f@input_attribute = value;


////////////////////
////// UPDATE ARRAY
float value_array[] = f[]@value_array;
append(value_array, value);
f[]@value_array = value_array;`}
                    </CCode>
                </WideBlogSection>
            </Lesson>
        </Page >
    )
};


