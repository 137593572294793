import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension } from '../NoiseConfig';

export interface IAmplitude {
    outputDimension: dimension;
    fBm: boolean;
}

export const Amplitude: React.FC<IAmplitude> = ({  ...props }: IAmplitude) => {
    return (<>
        { (() => {
            // float
            if (props.outputDimension == "1D") {
                return <Vex.Code>{` * ${props.fBm ? `weight` : `amp`};`}</Vex.Code>
            }
            // vector
            else {
                return <>
                    <Vex.Code>{` * (`}</Vex.Code>
                    <Vex.Code type>{`vector`}</Vex.Code>
                    <Vex.Code>{`)${props.fBm ? `weight` : `amp`};`}</Vex.Code>
                </>
            }
        })()}
    </>)
}
