import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';


export const Triplanar: React.FC = (props) => {
    return (
        <Page topic="rendering">
            <Lesson title="Triplanar Texture">
                <Section title="TriPlanar Texture" divider={false}>

                    <Python>
                        {`
# create material subnet
subnet = hou.node("/mat").createNode("subnet");
subnet.setName('triplanar', unique_name=True);

#add principled shader
principled_shader = subnet.createNode('principledshader::2.0');

principled_shader.moveToGoodPosition()

#get subnet output
output = hou.node(subnet.path() + '/suboutput1')

#wire principled shader layer output to subnet out
output.setInput(0, principled_shader, 2)

# create triplanar for base color
base_color_triplanar = subnet.createNode('uvtriplanarproject')
principled_shader.setInput(1, base_color_triplanar, 0)
base_color_triplanar.moveToGoodPosition()

hou.ui.displayMessage('Inputs: {}'.format(principled_shader.inputConnections())) 

# get list of parameters on subnet
g = subnet.parmTemplateGroup()

# define new string parameter ("id", "Label", number of input fields)
base_color_texture_map = hou.StringParmTemplate("basecolortexturemap", "Base Color Texture Map", 1, string_type=hou.stringParmType.FileReference ,file_type=hou.fileType.Image)

# append new parameters
g.append(base_color_texture_map)

# apply changes
subnet.setParmTemplateGroup(g)



                            `}
                    </Python>
                </Section>

            </Lesson>
        </Page >
    )
};
