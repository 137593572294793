import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension, NoiseFunction } from '../NoiseConfig';

export interface ICellularArguments {
    inputDimension: dimension;
    noise: NoiseFunction;
}

export const CellularArguments: React.FC<ICellularArguments> = ({  ...props }: ICellularArguments) => {
    return (<>
        { (() => {
            // worley
            if (props.noise == "Worley") {
                return <>
                    <Vex.Code>{`, seed, f1, f2, f3, f4`}</Vex.Code>
                </>
            }
            // vector
            else if (props.noise == "Voronoi") {
                return <>
                    {props.inputDimension == "2D" ?
                        <Vex.Code>{`, jitter.x, jitter.y, seed, f1, f2, pos1x, pos1y, pos2x, pos2y`}</Vex.Code>
                        :
                        <Vex.Code>{`, jitter, seed, f1, f2, pos1, pos2`}</Vex.Code>}
                </>
            }
            else return <></>
        })()}
    </>)
}
