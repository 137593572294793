
import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../../Chapter/Chapter';

import { Link } from '../../../Chapter/Link/Link';
import { CCode } from '../../../CodeBlock/CCode';
import { Code, Tip } from '../../../CodeBlock/CodeBlock';
import { ImageContainer, Img } from '../../../Images/Images';
import { TableComponent } from '../../../TableComponent/TableComponent';
import { WideBlogSection } from '../../../WebComponents/WideBlogSection';
import { Python } from '../../Hom/Python';
import { Section } from '../../Section';

import { Generator } from './Generator';

export const VexNoiseGenerators: React.FC = (props) => {

    return (
        <Page topic="noise">
            <Lesson title="Vex Noise Generator">
                <WideBlogSection max_width="1280px">

                    <Generator />
                </WideBlogSection>

            </Lesson>

            <Section title="HDA" icon="hda" divider>

                <Words>Same idea, but with some improvements and made into an HDA. Way faster iteration.</Words>

                <Words>HDA: <Link text="vexnoisegenerator.hdalc" url="https://github.com/keatonwilliamson/hda/raw/master/vexnoisegenerator.hdalc" /></Words>

                <Words>The HDA works by generating a VEX string based on noise settings. It uses an attribute wrangle or volume wrangle to run the VEX on the input geometry. There's a button to generate a new wrangle (free from any HDA definition) with the VEX and parameters added.</Words>
                <Words><Code>If you find a bug shoot me an email me at georgekwilliamson at gmail</Code></Words>

                <WideBlogSection max_width="800px">
                    <ImageContainer>
                        <Img ratio={0.6725} src="https://houdini-stuff.s3.amazonaws.com/Houdini/GIFS/vexnoisegenerator_walkthrough.gif" />
                    </ImageContainer>
                </WideBlogSection>
                <ImageContainer>
                    <Img ratio={1} src="https://houdini-stuff.s3.amazonaws.com/Houdini/GIFS/vex_noise_generator_demo.gif" />
                </ImageContainer>

            </Section>

            <Lesson title="HDA Overview">
                <Section title="" divider>
                    <Words>There are three main sections of the interface.</Words>
                    <OrderedList>
                        <li><strong>Noise Settings</strong> for configuring the vex string. (same as the web tool)</li>
                        <li><strong>Noise Parameters</strong> for testing out the noise.</li>
                        <li><strong>Generate</strong> for creating a new wrangle with the generated vex and parameters.</li>
                    </OrderedList>
                    <WideBlogSection max_width="900px">

                        <ImageContainer>
                            <Img ratio={0.7} src="https://houdini-stuff.s3.amazonaws.com/Houdini/NOISE/NoiseVEXGeneratorInterface.jpg" />
                        </ImageContainer>

                    </WideBlogSection>
                    <Words>Inside the tool each wrangle channel references the Noise Parameters and VEX string. Used for testing the noise.</Words>
                    <ImageContainer>
                        <Img ratio={0.9} src="https://houdini-stuff.s3.amazonaws.com/Houdini/NOISE/NoiseVEXGeneratorInside.jpg" />
                    </ImageContainer>

                </Section>
            </Lesson>
            <Lesson title="Noise Settings (Config)">
                <Words>The tool is driven by a single "config" dictionary made up of the Noise Settings.</Words>

                <Section title="Noise Type" >
                    <Words>Ordered menu dropdown to select noise type.</Words>
                    <TableComponent
                        headers={["Token", "Label"]}

                        colB={["Perlin",
                            "Simplex",
                            "Original Perlin",
                            "Sparse Convolution",
                            "Alligator",
                            "Worley",
                            "Voronoi",
                            "Flow",
                            "Curl"]}

                        colA={["0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8"]}

                    />


                </Section>
                <Section title="Input / Output Dimensions" divider>
                    <Words>Each noise function has different input and output dimensions available. To handle this there is a different dropdown parmameter for each set of options. The dropdowns are hidden based on the selected noise so you only see one at a time.</Words>
                    <WideBlogSection max_width="1200px">

                        <TableComponent
                            headers={["Avalable Inputs", "Noises", "Hide/Disable When"]}

                            colB={["Perlin, Simplex, Worley, Voronoi",
                                "Original Perlin, Sparse Convolution, Alligator",
                                "Flow",
                                "Curl",
                                "2D Curl"]}

                            colA={["1D, 2D, 3D, 4D",
                                "3D",
                                "2D, 3D, 4D",
                                "3D, 4D",
                                "2D, 3D"]}

                            colC={["{ noise == 2 } { noise == 3 } { noise == 4 } { noise == 7 } { noise == 8 }",
                                "{ noise != 2 noise != 3 noise != 4 }",
                                "{ noise != 7 }",
                                "{ noise != 8 } { use_curl2D == 1 }",
                                "{ noise != 8 } { use_curl2D == 0 }"]}

                        />
                    </WideBlogSection>
                    <WideBlogSection max_width="1200px">

                        <TableComponent
                            headers={["Avalable Outputs", "Noises", "Hide/Disable When"]}

                            colB={["Perlin, Simplex, Original Perlin, Sparse Convolution, Alligator",
                                "Worley, Voronoi",
                                "Curl"]}

                            colA={["1D, 3D",
                                "1D",
                                "3D"]}

                            colC={["{ noise == 5 } { noise == 6 } { noise == 8 }",
                                "{ noise != 5 noise != 6 }",
                                "{ noise != 8 }"]}

                        />
                    </WideBlogSection>
                    {/* <Section title=""> */}
                    <Words>These helper functions convert the multiple input/output dropdown values to a consistent value.</Words>
                    <Tip> input returns 1-4, output returns 1 or 3</Tip>
                    <Python>{`#########################################
###### Get Input / Output Dimensions ####
#########################################

# returns a number 1-4
def getInputDimension(node):

    noise = node.parm("noise").eval()
    use_curl2D = node.parm("use_curl2D").eval()
    
    dimension_lut = {
        "input_all": [1,2,3,4],            
        "input_three": [3],     
        "input_flow": [2,3,4],
        "input_curl": [3,4],
        "input_curl2D": [2,3]
    }
    
    parm_name = {
        0: "input_all", #perlin
        1: "input_all", #simplex
        2: "input_three", #original_perlin
        3: "input_three", #sparse_convolution
        4: "input_three", #alligator
        5: "input_all", #worley
        6: "input_all", #voronoi
        7: "input_flow", #flow
        8: "input_curl2D" if use_curl2D else "input_curl" #curl
    }[noise]
    
    parm_val = node.parm(parm_name).eval()
    
    return dimension_lut[parm_name][parm_val]
    
# returns a number 1 or 3
def getOutputDimension(node):

    noise = node.parm("noise").eval()
    
    dimension_lut = {
        "output_both": [1,3],               
        "output_one": [1],               
        "output_three": [3]             
    }
    
    parm_name = {
        0: "output_both", #perlin
        1: "output_both", #simplex
        2: "output_both", #original_perlin
        3: "output_both", #sparse_convolution
        4: "output_both", #alligator
        5: "output_one", #worley
        6: "output_one", #voronoi
        7: "output_both", #flow
        8: "output_three" #curl
    }[noise]
    
    parm_val = node.parm(parm_name).eval()
    
    return dimension_lut[parm_name][parm_val]
                            `}
                    </Python>
                </Section>
                {/* </Section> */}
                <Section title="Get Parameter Config" icon="python" divider>

                    <Words>This config dictionary is used as an argument to every other function in the tool. The nested helper functions add useful conditions and strings to the config.</Words>

                    <WideBlogSection max_width="1000px">



                        <Python>
                            {`######################################
######## Parameter Config ############
######################################

def getParameterConfig(node):
        
    def encodeFunctionName(str):   
        #https://www.sidefx.com/docs/houdini/hom/hou/text.html
        if bool(str): return hou.text.encode(str)
        else: return "Noise"

    def isPeriodic(**kwargs):
        periodic = bool(kwargs['periodic'])
        not_curl = kwargs['noise'] != 8
        return periodic and not_curl
        
    def useBuiltInTurbulence(**kwargs): 
        noise = kwargs["noise"]
        is_original_sparse_or_alligator = (noise == 2 or noise == 3 or noise == 4)
        use_built_in = bool(kwargs['enable_turbulence']) and bool(kwargs['enable_built_in_turbulence'])
        return is_original_sparse_or_alligator and use_built_in
        
    def usefBm(**kwargs):
        return bool(kwargs["enable_turbulence"]) and not useBuiltInTurbulence(**kwargs)
    
    def isZeroCentered(**kwargs):
        noise = kwargs["noise"]
        is_original_or_sparse = (noise == 2 or noise == 3)
        return is_original_or_sparse
        
    def getChannelNames(**kwargs):        
        prefix = kwargs["ch_prefix"]     
        names = ["frequency",
                "amplitude",
                "offset",
                "attenuation",
                "pos_4D",
                "period_x",
                "period_y",
                "period_z",
                "period_w",
                "flow",
                "jitter_x",
                "jitter_y",
                "jitter_z",
                "turbulence",
                "int_turbulence",
                "roughness",
                "lacunarity"]
        # channel names are put in a dictionary so they can optionally be prefixed during wrangle generation
        ch_names = {names[i]: names[i] for i in range(len(names))}
        return ch_names
        
        
    def getNoiseTitleString(**kwargs):
        noise = kwargs["noise"]
        inputDimension = kwargs["inputDimension"]
        use_curl = (noise == 8)
        
        inverted = "Inverted " if (kwargs["invert"]) else ""
        turbulent = "Turbulent " if kwargs["enable_turbulence"] else ""
        input = {
            1: "1D ",
            2: "2D ",
            3: "",
            4: "4D "
        }[inputDimension]
        
        periodic = "Periodic " if kwargs["is_periodic"] else ""
        noiseName = {
            0: "Perlin",
            1: "Simplex",
            2: "Original Perlin",
            3: "Sparse Convolution",
            4: "Alligator",
            5: {0: "", 1: "Manhattan ", 2: "Chebyshev "}[kwargs["distance_metric"]] + "Worley",
            6: "Voronoi",
            7: "Flow",
            8: "Curl"
        }[noise] + " "
        curlSimplex = "Simplex " if (use_curl and kwargs["use_curlSimplex"]) else ""
        curl2D = "2D " if (use_curl and kwargs["use_curl2D"]) else ""
        
        output = " ─ 3D output" if (kwargs["outputDimension"] == 3 and not use_curl ) else ""
        
        return inverted  + periodic + turbulent + input + curlSimplex + noiseName  + curl2D + "Noise" + output
               
  
    parms = {
        "noise":             node.parm("noise").eval(),
        "periodic":          node.parm("periodic").eval(),
        "enable_turbulence": node.parm("enable_turbulence").eval(),
        "enable_built_in_turbulence": node.parm("enable_built_in_turbulence").eval(),
        "distance_metric":   node.parm("distance").eval(), # Euclidean, Manhattan, Chebyshev
        "distance_value":    node.parm("distance_value").eval(), # F1, F2-F1
        "use_curl2D":        node.parm("use_curl2D").eval(),
        "use_curlSimplex":   node.parm("use_curlSimplex").eval(),
        "invert":            node.parm("invert").eval(),
        "make_function":     node.parm("make_function").eval(),
        "function_name":     encodeFunctionName(node.parm("function_name").eval()),
        "output_name":       node.parm("output_name").eval(),
        "ch_prefix":         node.parm("ch_prefix").eval(),
        "octave_normalization": node.parm("octave_normalization").eval(),
    }
            
    config = {
        "inputDimension":  getInputDimension(node),
        "outputDimension": getOutputDimension(node),
        "is_periodic":     isPeriodic(**parms),
        "use_built_in_turbulence": useBuiltInTurbulence(**parms),
        "use_inversion":   bool(parms["invert"]) and not useBuiltInTurbulence(**parms),
        "is_zero_centered": isZeroCentered(**parms),
        "use_fBm": usefBm(**parms),
        "tab": (' ' * 4) if usefBm(**parms) else "",
        "ch_names": getChannelNames(**parms)
    }
    
    # merge parms into config
    config.update(parms)
    
    # add title string
    config["noise_title_string"] = getNoiseTitleString(**config)

    return config

    
    `}
                        </Python>
                    </WideBlogSection>
                    <Section title="Example Config">


                        <Python>{`# example config
{
    "use_curlSimplex": 1, 
    "noise": 0, 
    "use_fBm": true, 
    "outputDimension": 1, 
    "use_curl2D": 0, 
    "enable_built_in_turbulence": 0, 
    "ch_names": {
        "pos_4D": "pos_4D", 
        "period_w": "period_w", 
        "turbulence": "turbulence", 
        "period_z": "period_z", 
        "period_x": "period_x", 
        "period_y": "period_y", 
        "flow": "flow", 
        "attenuation": "attenuation", 
        "roughness": "roughness", 
        "frequency": "frequency", 
        "lacunarity": "lacunarity", 
        "amplitude": "amplitude", 
        "offset": "offset", 
        "jitter_z": "jitter_z", 
        "jitter_y": "jitter_y", 
        "jitter_x": "jitter_x", 
        "int_turbulence": "int_turbulence"
    }, 
    "use_built_in_turbulence": false, 
    "tab": "    ", 
    "ch_prefix": "", 
    "is_zero_centered": false, 
    "is_periodic": false, 
    "noise_title_string": "Turbulent Perlin Noise", 
    "octave_normalization": 0, 
    "make_function": 0, 
    "distance_value": 1, 
    "invert": 0, 
    "enable_turbulence": 1, 
    "periodic": 0, 
    "inputDimension": 3, 
    "distance_metric": 0, 
    "use_inversion": false, 
    "output_name": "noise", 
    "function_name": "Noise"
}
                `}</Python>
                    </Section>
                </Section>
            </Lesson>
            <Lesson title="Building the VEX String">
                <Section title="Ch Strings" icon="python">
                    <Words>These functions return VEX strings used to channel reference the Noise Parameters. **kwargs is where the config gets passed</Words>
                    <WideBlogSection max_width="1000px">

                        <Python>{`#############################
##### Channel Strings #######
#############################

def freqAmpOffsetAttenChannels(**kwargs):
    ch_names = kwargs["ch_names"]
    freq = 'vector freq = chv("{}");'.format(ch_names["frequency"])
    amp = '\\n' + 'float amp = chf("{}");'.format(ch_names["amplitude"])
    offset = '\\n' + 'vector offset = chv("{}");'.format(ch_names["offset"])
    atten = '\\n' + 'float atten = chf("{}"); // default is 1'.format(ch_names["attenuation"])
    return freq + amp + offset + atten;
    
def pos4DChannel(**kwargs):
    ch_names = kwargs["ch_names"]
    pos4D = '\\n' + 'float pos4D = chf("{}"); // @Time'.format(ch_names["pos_4D"])
    return pos4D if (kwargs["inputDimension"] == 4) else "" 
        
    
def periodicChannels(**kwargs):
    ch_names = kwargs["ch_names"]

    period_x = '\\n' + 'int period_x = chi("{}");'.format(ch_names["period_x"])
    period_y = '\\n' + 'int period_y = chi("{}");'.format(ch_names["period_y"])
    period_z = '\\n' + 'int period_z = chi("{}");'.format(ch_names["period_z"])
    period_w = '\\n' + 'int period_w = chi("{}");'.format(ch_names["period_w"])
    
    channels = {
        1: period_x,
        2: period_x + period_y,
        3: period_x + period_y + period_z,
        4: period_x + period_y + period_z + period_w
    }[kwargs["inputDimension"]]
                
    return '\\n' + channels if kwargs["is_periodic"] else ""
    
    
def flowChannel(**kwargs):
    ch_names = kwargs["ch_names"]
    flow = '\\n' + 'float flow = chf("{}"); // @Time'.format(ch_names["flow"])
    return flow if (kwargs["noise"] == 7) else "" 

def jitterChannel(**kwargs):
    ch_names = kwargs["ch_names"]

    jitter_x = '\\n' + 'float jitter_x = chf("{}");'.format(ch_names["jitter_x"])
    jitter_y = '\\n' + 'float jitter_y = chf("{}");'.format(ch_names["jitter_y"])
    jitter_z = '\\n' + 'float jitter_z = chf("{}");'.format(ch_names["jitter_z"])
    
    channels = {
        1: jitter_x,
        2: jitter_x + jitter_y,
        3: jitter_x + jitter_y + jitter_z,
        4: jitter_x + jitter_y + jitter_z
    }[kwargs["inputDimension"]]
                
    jitter = {
        1: '',
        2: '',
        3: '\\n' + 'vector jitter = set(jitter_x, jitter_y, jitter_z);',
        4: '\\n' + 'vector4 jitter = set(jitter_x, jitter_y, jitter_z, 0);'
    }[kwargs["inputDimension"]]
    return '\\n' + channels + jitter if (kwargs["noise"] == 6) else ""

def turbRoughLacunChannels(**kwargs):
    ch_names = kwargs["ch_names"]
    use_built_in_turbulence = kwargs["use_built_in_turbulence"]
    is_periodic = kwargs["is_periodic"]
    
    def turb():
        if use_built_in_turbulence: str = 'int turbulence = chi("{}");'.format(ch_names["int_turbulence"])
        else: str = 'float turbulence = chf("{}");'.format(ch_names["turbulence"])
        return '\\n\\n' + str
       
    def lacun():
        if not use_built_in_turbulence: 
            if is_periodic: return '\\n' + "float lacun = 2.0; // must be 2 for tileable periodic noise"
            else: return '\\n' + 'float lacun = chf("{}");  // default is 2'.format(ch_names["lacunarity"])
        else: return ""
       
    rough = '\\n' + 'float rough = chf("{}");  // default is 0.5'.format(ch_names["roughness"])
    
    if (bool(kwargs["enable_turbulence"])):
        return turb() + rough + lacun()
    else: 
        return ""


`}</Python>
                    </WideBlogSection>
                </Section>
                <Section title="Noise Call String" icon="python" divider>
                    <Words>These functions build the VEX string that calls the main noise function</Words>
                    <WideBlogSection max_width="1200px">

                        <Python>{`###############################
##### Noise Call String #######
###############################
    
def positionArguments(**kwargs): 
    return {
        1: '(posx',
        2: '(pos.x, pos.y',
        3: '(pos',
        4: '(pos'
    }[kwargs["inputDimension"]]

def periodicArguments(**kwargs):
    if not kwargs["is_periodic"]: 
        return ""
    elif kwargs["use_fBm"]:
        return {
            1: ', px',
            2: ', px, py',
            3: ', px, py, pz',
            4: ', px, py, pz, pw'
        }[kwargs["inputDimension"]] 
    else:
        return {
            1: ', period_x',
            2: ', period_x, period_y',
            3: ', period_x, period_y, period_z',
            4: ', period_x, period_y, period_z, period_w'
        }[kwargs["inputDimension"]]    
    
def noiseFunction(**kwargs):
    def getNval(**kwargs):
        underscore = "_" if kwargs["use_fBm"] else ""
        type = {
            1: "float ",
            3: "vector "
        }[kwargs["outputDimension"]]
        return type + underscore + "nval = "
        
    pos_args = positionArguments(**kwargs)
    period_args = periodicArguments(**kwargs)   
    is_periodic = kwargs["is_periodic"]
    turb_args = ', turbulence, rough, atten' if kwargs["use_built_in_turbulence"] else ''
    cellular_value = ["f1;", "(f2 - f1);"][kwargs["distance_value"]]
    if bool(kwargs["use_curl2D"]):
        curl_func = "curlxnoise2d" if kwargs["use_curlSimplex"] else "curlnoise2d"
    else: 
        curl_func = "curlxnoise" if kwargs["use_curlSimplex"] else "curlnoise"
    
    noise = {
        0: ("pnoise" if is_periodic else "noise") + pos_args + period_args + ");", #perlin
        1: ("pxnoise" if is_periodic else "xnoise") + pos_args + period_args + ");", #simplex
        2: "onoise" + pos_args + period_args + turb_args + ");", #original_perlin
        3: "snoise" + pos_args + period_args + turb_args + ");", #sparse_convolution
        4: "anoise" + pos_args + period_args + turb_args + ");", #alligator
        5: cellular_value, #worley
        6: cellular_value, #voronoi
        7: ("flowpnoise" if is_periodic else "flownoise") + pos_args + period_args + ", flow);", #flow
        8: curl_func + pos_args + ");"  #curl
    }[kwargs["noise"]]
    
    return "\\n" + kwargs["tab"] + getNval(**kwargs) + noise

    

def cellularFunction(**kwargs):
    def cellularArguments(**kwargs):
        noise = kwargs["noise"]
        if (noise == 5): # Worley 
            return ', seed, f1, f2, f3, f4'
        elif (noise == 6): # Voronoi
            return {
                1: ', jitter_x, seed, f1, f2, pos1, pos2',
                2: ', jitter_x, jitter_y, seed, f1, f2, pos1x, pos1y, pos2x, pos2y',
                3: ', jitter, seed, f1, f2, pos1, pos2',
                4: ', jitter, seed, f1, f2, pos1, pos2',
            }[kwargs["inputDimension"]]
        else: 
            return ""
           
    pos_args = positionArguments(**kwargs)        
    cell_args = cellularArguments(**kwargs)
    period_args = periodicArguments(**kwargs)   
    tab = kwargs["tab"]
    noise = kwargs["noise"]
    
    if (noise == 5): # Worley
        func_name = {
            0: "wnoise",
            1: "mwnoise",
            2: "cwnoise"
        }[kwargs["distance_metric"]]
        
        return "\\n" + tab + func_name + pos_args + cell_args + period_args + ");"
        
    elif (noise == 6): # Voronoi
        seed_pos = {
            1: "\\n" + tab + 'pos1 = (float)(pos1 - offset) / freq;' + "\\n" + tab + 'pos2 = (float)(pos2 - offset) / freq;',
            2: "\\n" + tab + 'vector pos1 = (set(pos1x, pos1y) - offset) / freq;' + "\\n" + tab + 'vector pos2 = (set(pos2x, pos2y) - offset) / freq;',
            3: "\\n" + tab + 'pos1 = (pos1 - offset) / freq;' + "\\n" + tab + 'pos2 = (pos2 - offset) / freq;',
            4: "\\n" + tab + 'pos1 = (pos1 - offset) / freq;' + "\\n" + tab + 'pos2 = (pos2 - offset) / freq;',
        }[kwargs["inputDimension"]]
        
        return "\\n" + tab + 'vnoise' + pos_args + cell_args + period_args + ");" + seed_pos
    else: 
        return ""
    

        
def cellularVariables(**kwargs):
    tab = kwargs["tab"]
    noise = kwargs["noise"]
    if (noise == 5): # Worley
        return "\\n" + tab + 'int seed; float f1, f2, f3, f4;'
    elif (noise == 6): # Voronoi
        str = 'int seed; float f1, f2'
        args = {
            1: ', pos1, pos2;',
            2: ', pos1x, pos1y, pos2x, pos2y;',
            3: '; vector pos1, pos2;',
            4: '; vector4 pos1, pos2;'
        }[kwargs["inputDimension"]]
        return "\\n" + tab + str + args
    else: 
        return ""
        
           
def getPosition(**kwargs):
    tab = kwargs["tab"]
    P = "P" if kwargs["make_function"] else "v@P"
    return {
        1: "\\n" + tab + 'float posx = set({} * freq + offset);'.format(P),
        2: "\\n" + tab + 'vector2 pos = set({} * freq + offset);'.format(P),
        3: "\\n" + tab + 'vector pos = set({} * freq + offset);'.format(P),
        4: "\\n" + tab + 'vector4 pos = set({} * freq + offset);'.format(P) + "\\n" + tab + "pos.w = pos4D;",
    }[kwargs["inputDimension"]]



`}</Python>
                    </WideBlogSection>
                </Section>
                <Section title="Turbulence String (Fractal Brownian Motion)" icon="python">
                    <Words>Takes the noise call string and wraps it in an fBm loop.</Words>
                    <WideBlogSection max_width="1200px">

                        <Python>{`################################
########## fBm String ##########
################################
    
def fBmInversion(**kwargs):
    tab = kwargs["tab"]
    if (kwargs["use_inversion"]):
        if (kwargs["outputDimension"] == 3): # 3D noises
            inversion = "_nval = -1 * _nval; // invert"
        elif (kwargs["is_zero_centered"]): # Original, Sparse
            inversion = "_nval = -1 * _nval; // zero-centered inversion"
        else: 
            inversion = "_nval = 1 - _nval; // inversion assuming 0-1 range"
        return "\\n" + tab + inversion 
    else:
        return ""
        
def fBmPeriodicVariables(**kwargs):
    tab = kwargs["tab"]
    period_x = '\\n' + tab + 'int px = period_x * period_scale;'
    period_y = '\\n' + tab + 'int py = period_y * period_scale;'
    period_z = '\\n' + tab + 'int pz = period_z * period_scale;'
    period_w = '\\n' + tab + 'int pw = period_w * period_scale;'
    
    vars = {
        1: period_x,
        2: period_x + period_y,
        3: period_x + period_y + period_z,
        4: period_x + period_y + period_z + period_w
    }[kwargs["inputDimension"]]
                
    return '\\n' + tab + "// period" + '\\n' + tab +"int period_scale = int(pow(2, i));" + vars + '\\n' if kwargs["is_periodic"] else ""
    
    

def fBmLoop(noise_function, **kwargs):

    def nval(**kwargs):
        return {
            1: 'float nval = 0;',
            3: 'vector nval = {0, 0, 0};',
        }[kwargs["outputDimension"]]
    
    def weight(**kwargs):  
        return {
            1: '    _nval *= weight;',
            3: '    _nval *= (vector)weight;',
        }[kwargs["outputDimension"]] + '\\n'
        
    def octaveNormalization(**kwargs):
        return {
            0: 'max_amp += (float)(1/pow(2, i)) * remainder;',
            1: 'max_amp += weight * remainder;'
        }[kwargs["octave_normalization"]]
        
  
        
    str = '\\n\\n' + '''turbulence += 1.0; // run at least once
int octaves = min(int(ceil(turbulence)), 16); // max octaves 16
{nval}
float weight = 1;
float max_amp = 0;

// fBm
for (int i = 0; i < octaves; i++) {{
{fBmPeriodicVariables}{noise_function}{inversion}
{weight}
    // fractional part of the last octave - equal to 1 on other octaves
    float remainder = min(turbulence - i, 1);

    // update values
    nval += _nval * remainder;
    {octave_normalization}
    freq *= lacun;
    weight *= rough;
}};

nval /= max_amp; // normalization'''

    return str.format(
                    nval = nval(**kwargs),
                    noise_function = noise_function,
                    weight = weight(**kwargs),
                    inversion = fBmInversion(**kwargs),
                    fBmPeriodicVariables = fBmPeriodicVariables(**kwargs),
                    octave_normalization = octaveNormalization(**kwargs),
                )    

`}</Python>
                    </WideBlogSection>
                </Section>
                <Section title="Misc Noise Strings" icon="python">
                    <Words>A few extra functions used to build the VEX string.</Words>
                    <WideBlogSection max_width="1200px">

                        <Python>{`#######################################
####### Header / Footer Strings #######
#######################################

def functionHeader(**kwargs):
    type = {
        1: "float ", 
        3: "vector "
    }[kwargs["outputDimension"]]
    header = "function " + type + kwargs["function_name"] + "(vector P;)" + "\\n{\\n"
    return header if kwargs["make_function"] else ""
  
       
def getInversion(**kwargs):
    if (kwargs["use_inversion"] and not kwargs["enable_turbulence"]):
        if (kwargs["outputDimension"] == 3): # 3D noises
            inversion = "nval = -1 * nval; // invert"
        elif (kwargs["is_zero_centered"]): # Original, Sparse
            inversion = "nval = -1 * nval; // zero-centered inversion"
        else: 
            inversion = "nval = 1 - nval; // inversion assuming 0-1 range"            
        return "\\n" + inversion 
    else:
        return ""   
    
def getAttenuation(**kwargs):
    if not (kwargs["use_built_in_turbulence"]):
        return "\\n" + "nval = sign(nval) * pow(abs(nval), atten); // attenuation"
    else: 
        return ""
  
        
def getAmp(**kwargs):
    cast = {
        1: "",
        3: "(vector)"
    }[kwargs["outputDimension"]]
    
    return "\\n" + "nval *= " + cast + "amp; // amp"
    
    
def outputNoise(**kwargs):

    def encodeAttribName(str):        
        if bool(str): return hou.text.encodeAttrib(str)
        else: return "noise"
        
    make_function = kwargs["make_function"]
    function_name = kwargs["function_name"]
    output_name = encodeAttribName(kwargs["output_name"])
    output_string = {
        1: "f@" + output_name + " = " + (function_name + "(v@P);" if make_function else "nval;"),
        3: "v@"+ output_name + " = " + (function_name + "(v@P);" if make_function else "nval;"),
    }[kwargs["outputDimension"]]
    
    make_function_ending = ("\\n\\n" + "return nval;" + "\\n" + "};") if make_function else ""
    
    return  make_function_ending + "\\n\\n" + output_string   

`}</Python>
                    </WideBlogSection>
                </Section>
                <Section title="Build VEX String" icon="python" divider>
                    <Words>Finally, bring everything together to get the VEX string</Words>
                    <WideBlogSection max_width="1200px">

                        <Python>{`##########################
##### Build String #######
##########################    

def getVEXString(node, config):
    
    channels = (
        freqAmpOffsetAttenChannels(**config)
        + pos4DChannel(**config)
        + periodicChannels(**config)     
        + flowChannel(**config) 
        + jitterChannel(**config) 
        + turbRoughLacunChannels(**config)
    )
          
    noise_function = (
        "\\n\\n" + config["tab"] + "// " + config["noise_title_string"]
        + cellularVariables(**config)
        + getPosition(**config)
        + cellularFunction(**config)
        + noiseFunction(**config)
    )
    
    footer = (
        "\\n" 
        + getInversion(**config)
        + getAttenuation(**config)
        + getAmp(**config)
        + outputNoise(**config)
    )
    
    ##### Build String #######
    str = ""
    str += functionHeader(**config)
    str += channels
    if (config["use_fBm"]):
        str += fBmLoop(noise_function, **config)
    else:
        str += noise_function
    str += footer
    
    return str

`}</Python>
                    </WideBlogSection>
                </Section>
            </Lesson>
            <Lesson title="Noise Parameters" >
                <WideBlogSection max_width="800px">
                    <ImageContainer>
                        <Img ratio={0.6} src="https://houdini-stuff.s3.amazonaws.com/Houdini/NOISE/NoiseVEXGeneratorNoiseParameters.jpg" />
                    </ImageContainer>
                </WideBlogSection>
                <Section title="Hide Noise Parameters" divider>
                    <Words>The exising Noise Parameters on the HDA must be hidden based on the Noise Settings</Words>
                    <WideBlogSection max_width="1200px">

                        <TableComponent
                            headers={["Parameter", "Hide/Disable When"]}
                            colA={["Position 4D", "Flow", "JitterY", "JitterZ", "PeriodY", "PeriodZ", "PeriodW", "Turbulence", "Int Turbulence (built-in)", "Lacunarity"]}
                            colB={[`{noise == 0 input_all != 3}
                            {noise == 1 input_all != 3}
                            {noise == 2 }
                            {noise == 3 }
                            {noise == 4 }
                            {noise == 5 input_all != 3}
                            {noise == 6 input_all != 3}
                            {noise == 7 input_flow != 2}
                            {noise == 8 input_curl != 1}
                            {noise == 8 use_curl2D == 1}`,
                                "{noise != 7}",
                                "{ noise != 6 } { noise == 6 input_all < 1 }",
                                "{ noise != 6 } { noise == 6 input_all < 2 }", "{ periodic == 0 } { noise == 8 } { noise == 0 input_all < 1 } { noise == 1 input_all < 1 } { noise == 5 input_all < 1 } { noise == 6 input_all < 1 }", // Period Y
                                "{ periodic == 0 } { noise == 8 } { noise == 0 input_all < 2 } { noise == 1 input_all < 2 } { noise == 5 input_all < 2 } { noise == 6 input_all < 2 } { noise == 7 input_flow < 1 }", // Period Z
                                "{ periodic == 0 } { noise == 8 } { noise == 0 input_all < 3 } { noise == 1 input_all < 3 } { noise == 2 } { noise == 3 } { noise == 4 } { noise == 5 input_all < 3 } { noise == 6 input_all < 3 } { noise == 7 input_flow < 2 }", // Period W
                                "{ enable_turbulence == 0 } { noise == 2 enable_built_in_turbulence == 1 } { noise == 3 enable_built_in_turbulence == 1 } { noise == 4 enable_built_in_turbulence == 1 }", // Float Turbulence
                                "{ noise != 2 noise != 3 noise != 4 } { enable_turbulence == 0 } { enable_built_in_turbulence == 0 }", // Int Turbulence
                                "{ enable_turbulence == 0 } { noise == 2 enable_built_in_turbulence == 1 } { noise == 3 enable_built_in_turbulence == 1 } { noise == 4 enable_built_in_turbulence == 1 } { periodic == 1 noise != 8 }"
                            ]}
                        />
                    </WideBlogSection>
                </Section>

                <Section title="Generate Noise Parameters" divider>


                    <Words>The Noise Parameters can be added to a newly generated wrangle. These functions make up a folder of ParmTemplates.</Words>
                    <WideBlogSection max_width="1200px">
                        <Python>{`#######################################
###### Spare Parameter Creation #######
#######################################


def freqAmpOffsetAttenParms(**kwargs):
    ch_names = kwargs["ch_names"]
    freq = hou.FloatParmTemplate(ch_names["frequency"], "Frequency", 3, default_value=(1,1,1))
    amp = hou.FloatParmTemplate(ch_names["amplitude"], "Amplitude", 1, max=5, default_value=((1),))
    offset = hou.FloatParmTemplate(ch_names["offset"], "Offset", 3, default_value=((0),(0),(0),))
    atten = hou.FloatParmTemplate(ch_names["attenuation"], "Attenuation", 1, max=2, default_value=(1,))
    return (freq,amp,offset,atten);
    
    
def pos4DParm(**kwargs):
    ch_names = kwargs["ch_names"]
    pos4D = hou.FloatParmTemplate(ch_names["pos_4D"], "Position 4D", 1, default_expression=("@Time",))
    if (kwargs["inputDimension"] == 4 ): return (pos4D,)
    else: return ()
    
def flowParm(**kwargs):
    ch_names = kwargs["ch_names"]
    flow = hou.FloatParmTemplate(ch_names["flow"], "Flow", 1, default_expression=("@Time",))
    if (kwargs["noise"] == 7 ): return (flow,)
    else: return ()
    
    
def jitterParm(**kwargs):
    
    ch_names = kwargs["ch_names"]
    jitter_x = hou.FloatParmTemplate(ch_names["jitter_x"], "Jitter X", 1, max=1, default_value=(1,))
    jitter_y = hou.FloatParmTemplate(ch_names["jitter_y"], "Jitter Y", 1, max=1, default_value=(1,))
    jitter_z = hou.FloatParmTemplate(ch_names["jitter_z"], "Jitter Z", 1, max=1, default_value=(1,))
    
    parm_templates = {
        1: (jitter_x,),
        2: (jitter_x, jitter_y),
        3: (jitter_x, jitter_y, jitter_z),
        4: (jitter_x, jitter_y, jitter_z)
    }[kwargs["inputDimension"]]
            
    folder = hou.FolderParmTemplate(
        "jitter_folder",
        "Voronoi Jitter",
        parm_templates = parm_templates,
        folder_type = hou.folderType.Simple
    )
    
    return (folder,) if (kwargs["noise"] == 6)  else ()
          
    
def periodicParms(**kwargs):
    ch_names = kwargs["ch_names"]
    period_x = hou.IntParmTemplate(ch_names["period_x"], "Period X", 1, max=10, default_value=(1,))
    period_y = hou.IntParmTemplate(ch_names["period_y"], "Period Y", 1, max=10, default_value=(1,))
    period_z = hou.IntParmTemplate(ch_names["period_z"], "Period Z", 1, max=10, default_value=(1,))
    period_w = hou.IntParmTemplate(ch_names["period_w"], "Period W", 1, max=10, default_value=(9999,))
    
    parm_templates = {
        1: (period_x,),
        2: (period_x, period_y),
        3: (period_x, period_y, period_z),
        4: (period_x, period_y, period_z, period_w)
    }[kwargs["inputDimension"]]
            
    folder = hou.FolderParmTemplate(
        "periodic_folder",
        "Periodic",
        parm_templates = parm_templates,
        folder_type = hou.folderType.Simple
    )
    
    return (folder,) if kwargs["is_periodic"] else ()
    
    

    
def turbRoughLacunParms(**kwargs):
    ch_names = kwargs["ch_names"]
    use_built_in_turbulence = kwargs["use_built_in_turbulence"]
    is_periodic = kwargs["is_periodic"]
    
    def turb():
        if use_built_in_turbulence: return hou.IntParmTemplate(ch_names["int_turbulence"], "Turbulence", 1, max=6, default_value=(3,))
        else: return hou.FloatParmTemplate(ch_names["turbulence"], "Turbulence", 1, max=6, default_value=(3,))

    rough = hou.FloatParmTemplate(ch_names["roughness"], "Roughness", 1, max=1, default_value=(0.5,))
    lacun = hou.FloatParmTemplate(ch_names["lacunarity"], "Lacunarity", 1, max=4 ,default_value=(2,))
    
    parm_templates = (turb(), rough,)
    if (not use_built_in_turbulence and not is_periodic):
        parm_templates += (lacun,)
        
    folder = hou.FolderParmTemplate(
        "fBm_folder",
        "Turbulence",
        parm_templates = parm_templates,
        folder_type = hou.folderType.Simple
    )
    
    return (folder,) if kwargs["enable_turbulence"] else ()


    
# returns a folder of parms
def getSpareParms(node, config):
        
    ########## Parms ##########
    parms = tuple()
    parms += freqAmpOffsetAttenParms(**config)  # freq, amp, offset
    parms += pos4DParm(**config)           # 4D input
    parms += flowParm(**config)            # flow
    parms += jitterParm(**config)          # voronoi jitter
    parms += periodicParms(**config)       # periodic
    parms += turbRoughLacunParms(**config) # fBm
    
    ##### Define Folder ####### 
    folder = hou.FolderParmTemplate("noise_parameters", "Noise Parameters", folder_type=hou.folderType.Simple)
    folder.setParmTemplates(parms)
    
    return folder
            
            `}</Python>
                    </WideBlogSection>

                </Section>
            </Lesson>
            <Lesson title="Update">
                <Section title="">

                    <Words>Update the VEX string when the Noise Settings are changed.</Words>
                    <Python>{`# callback script
hou.pwd().hdaModule().update(kwargs['node'])`}</Python>
                    <WideBlogSection max_width="1000px">

                        <Python>{`
# update the vex string
def update(node): 

    # get config
    config = getParameterConfig(node)
    
    # update vex string
    str = getVEXString(node, config)
    node.parm("generated_vex").set(str)
`}</Python>
                    </WideBlogSection>
                </Section>
                <Section icon="python" title="OnCreated Update" divider>
                    <Words>Call the update function when you first create the node.</Words>

                    <WideBlogSection max_width="1000px">

                        <Python>{`# OnCreated Script

import hou
import toolutils

python_module = toolutils.createModuleFromSection("foo", kwargs["type"], "PythonModule")

python_module.update(kwargs["node"])
`}</Python>
                    </WideBlogSection>
                </Section>
            </Lesson>
            <Lesson title="Generate Wrangle">
                <Section title="" divider>
                    <WideBlogSection max_width="900px">
                        <ImageContainer>
                            <Img ratio={0.67} src="https://houdini-stuff.s3.amazonaws.com/Houdini/NOISE/NoiseVEXGeneratorGeneration.jpg" />
                        </ImageContainer>
                    </WideBlogSection>
                    <Words>Creates a wrangle node outside of the HDA and adds the VEX string and Noise Parameters.</Words>
                    <Python>{`# button callback script
hou.pwd().hdaModule().generateWrangle(kwargs['node'])`}</Python>

                    <WideBlogSection max_width="1000px">


                        <Python>
                            {`###################################
###### Generate Wrangle ###########
###################################

# generate new wrangle, add spare parms, add vex string
def generateWrangle(node):

   #https://www.sidefx.com/docs/houdini/hom/hou/text.html
    def prefixParmName(parm_name, config):        
        if bool(parm_name): return hou.text.encodeParm(config["ch_prefix"] + parm_name)
        else: return ""   


    # get config 
    config = getParameterConfig(node)
    
    # prefix the channel names - prefixing is optional
    config["ch_names"] = {k: prefixParmName(v, config) for (k,v) in config["ch_names"].items()}
        
    # create node
    wrangle_type = "volumewrangle" if (node.parm("wrangle_type").eval()) else "attribwrangle"
    wrangle = node.parent().createNode(wrangle_type) 
                    
    # set vex string
    str = getVEXString(node, config)
    wrangle.parm("snippet").set(str)
    
    # set node name - replace non-alphanumeric with "_"
    name = re.sub("[^0-9a-zA-Z]+", "_", config["noise_title_string"])
    wrangle.setName(name, unique_name=True)
    
    # connect and move to good position
    inputConnections = node.inputConnections()
    if len(inputConnections) > 0:
        connection = inputConnections[0]
        wrangle.setFirstInput(connection.inputNode(), connection.inputIndex())
        wrangle.moveToGoodPosition()
    else: 
        pos = node.position()
        transformed_pos = pos + hou.Vector2((2.0, -1.0))
        wrangle.setPosition(transformed_pos)
    
    # add parms
    generated_parm_folder = getSpareParms(node, config)
    ptg = wrangle.parmTemplateGroup()
    ptg.append(generated_parm_folder)
    wrangle.setParmTemplateGroup(ptg)
    
    ###############################
    #### Set parms to values on HDA
    
    # lut to get the original un-prefixed parm names
    parm_name_lut = {v: k for (k,v) in config["ch_names"].items()}
    
    for pt in wrangle.parmTuples():
        is_noise_parm = pt.name() in list(parm_name_lut.keys())
        nameOfTupleOnHDA = parm_name_lut[pt.name()] if is_noise_parm else pt.name()
        hda_pt = node.parmTuple(nameOfTupleOnHDA)
        if hda_pt is not None:
            for i in range(len(pt)):
                hda_parm = hda_pt[i]
                try:
                    expression = hda_parm.expression()
                except:
                    expression = ""
                finally:
                    if bool(expression):
                        language = hda_parm.expressionLanguage()
                        pt[i].setExpression(expression, language=language)
                    else:
                        pt[i].set(hda_parm.eval())
                        
        # manually set the group parm - different for volume and attrib wrangles
        if wrangle_type == "volumewrangle":
            wrangle.parm("group").set(node.parm("volume_wrangle_group").eval())
        elif wrangle_type == "attribwrangle":
            wrangle.parm("group").set(node.parm("attribute_wrangle_group").eval())

            
    `}
                        </Python>
                    </WideBlogSection>
                </Section>
            </Lesson>
            <Lesson title="Sync Parameters">
                <Section title="" divider>
                    <Words>These helper functions sync up the multiple Input Dimension dropdowns and two Turbulence parameters.</Words>
                    <Tip>The Int Turbulence parameter is used for the noise functions that take a built-in turbulence argument. (Original Perlin, Sparse Convolution, Alligator)</Tip>


                    <WideBlogSection max_width="1000px">


                        <Python>
                            {`#####################################
############ Sync Parms  ############
#####################################

# try setting the input dropdowns to the same value
def syncInputDropdowns(node):
    # call on change of noise, input, or use_curl2D

    # get current input dimension
    input_dimension = getInputDimension(node)
    
    lut = {
        "input_all": [1,2,3,4],            
        "input_three": [3],     
        "input_flow": [2,3,4],
        "input_curl": [3,4],
        "input_curl2D": [2,3]
    }
    
    # sync all dropdowns if they contain current value
    for key in lut:
        arr = lut[key]
        if input_dimension in arr:
            idx = arr.index(input_dimension)
            node.parm(key).set(idx)


def syncTurbulenceParms(node, parm):
    # call on change of turbulence parms
    if parm.name() == "turbulence":
        node.parm("int_turbulence").set(math.ceil(parm.eval()))
        
    elif parm.name() == "int_turbulence":
        node.parm("turbulence").set(parm.eval())
     
    `}
                        </Python>
                    </WideBlogSection>
                </Section>
            </Lesson>
            <Words><strong>References:</strong></Words>
            <Words><Link text="Noise in VEX" url="https://mrkunz.com/blog/03-04-2017_Using-noise-in-VEX.html" /> ─ mrkunz.com</Words>
            <Words><Link text="Turbulence in Houdini" url="https://vimeo.com/361104780" /> ─ Manja Vimeo</Words>
            {/* <Words><Link text="VEX in Houdini" url="https://www.cgmasteracademy.com/courses/15-vex-in-houdini" /> by Johannes Richter</Words> */}
            <Words><Link text="Wrangle Pattern" url="https://sites.google.com/site/fujitarium/Houdini/sop/wrangle/wrangle-pattern" /> ─ Ryoji CG Memo</Words>
        </Page >
    )
};


// #########################################
// ###### Get Input / Output Dimensions ####
// #########################################

// # returns a number 1-4
// def getInputDimension(node):

//     noise = node.parm("noise").eval()
//     use_curl2D = node.parm("use_curl2D").eval()

//     dimension_lut = {
//         "input_all": [1,2,3,4],            
//         "input_three": [3],     
//         "input_flow": [2,3,4],
//         "input_curl": [3,4],
//         "input_curl2D": [2,3]
//     }

//     parm_name = {
//         0: "input_all", #perlin
//         1: "input_all", #simplex
//         2: "input_three", #original_perlin
//         3: "input_three", #sparse_convolution
//         4: "input_three", #alligator
//         5: "input_all", #worley
//         6: "input_all", #voronoi
//         7: "input_flow", #flow
//         8: "input_curl2D" if use_curl2D else "input_curl" #curl
//     }[noise]

//     parm_val = node.parm(parm_name).eval()

//     return dimension_lut[parm_name][parm_val]

// {noise == 0 input_all != 3}
// {noise == 1 input_all != 3}
// {noise == 2 }
// {noise == 3 }
// {noise == 4 }
// {noise == 5 input_all != 3}
// {noise == 6 input_all != 3}
// {noise == 7 input_flow != 2}
// {noise == 8 input_curl != 1}
// {noise == 8 use_curl2D == 1}






// { noise != 2 noise != 3 noise != 4 } { enable_turbulence == 0 } { enable_built_in_turbulence == 0 }

// { enable_turbulence == 0 } { noise == 2 enable_built_in_turbulence == 1 } { noise == 3 enable_built_in_turbulence == 1 } { noise == 4 enable_built_in_turbulence == 1 }


// #########################################
// ########## Show / Hide Parms ############
// #########################################

// # make noise parms invisble based on config
// def showHideNoiseParms(node, config):

//     # get all parm names in a folder and sub folders
//     def allParmNames(node, group_or_folder): 

//         # recursively get all parmTemplates in a group or folder
//         def allParmTemplates(group_or_folder):
//             for parm_template in group_or_folder.parmTemplates():
//                 if (parm_template.type() == hou.parmTemplateType.Folder and parm_template.isActualFolder()):
//                     for sub_parm_template in allParmTemplates(parm_template):
//                         yield sub_parm_template
//                 else: yield parm_template

//         parm_tuples = [node.parmTuple(template.name()) for template in allParmTemplates(group_or_folder)]
//         parms = [p for tupl in parm_tuples for p in tupl] # flatten
//         return [p.name() for p in parms] # return string names


//     # get generated parms
//     generated_parm_folder = getSpareParms(node, config)
//     generated_parm_names = allParmNames(node, generated_parm_folder)

//     # all parms in existing noise folder
//     noise_parm_folder = node.parmTemplateGroup().findFolder("Noise Parameters")
//     noise_parm_names = allParmNames(node, noise_parm_folder)

//     # if the noise parm is in the list of generated parm names, show it, else hide
//     for p in node.parms():
//         if (p.name() in noise_parm_names):
//             if (p.name() in generated_parm_names):
//                 p.hide(False)
//             else: 
//                 p.hide(True)



// { enable_turbulence == 0 } { noise == 2 enable_built_in_turbulence == 1 } { noise == 3 enable_built_in_turbulence == 1 } { noise == 4 enable_built_in_turbulence == 1 } { periodic == 1 noise != 8 }


