import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension } from '../NoiseConfig';

export interface IOutNoiseProps {
    dimension: dimension;
    useFunction: boolean;
    functionName: string;
}

export const OutNoise: React.FC<IOutNoiseProps> = ({ useFunction, ...props }: IOutNoiseProps) => {
    const functionName = (!!props.functionName) ? props.functionName : "Noise"
    const JSX: Record<dimension, any> = {
        "1D": <>

            <Vex.Line space>
                <Vex.Code>{`f@noise = ${useFunction ? `${functionName}(v@P)` : "nval"};`}</Vex.Code>
                <Vex.Code comment>{` // output float noise`}</Vex.Code>
            </Vex.Line>
        </>,
        "2D": <>
        </>,
        "3D": <>

            <Vex.Line space>
                <Vex.Code>{`v@vel = ${useFunction ? `${functionName}(v@P)` : "nval"};`}</Vex.Code>
                <Vex.Code comment>{` // output vector noise`}</Vex.Code>
            </Vex.Line>
        </>,
        "4D": <>
        </>
    }

    return <>
        {useFunction ?
            <>
                <Vex.Line space>
                    <Vex.Code type>{`return `}</Vex.Code>
                    <Vex.Code>{`nval;`}</Vex.Code>
                </Vex.Line>
                <Vex.Line>
                    <Vex.Code>{`};`}</Vex.Code>
                </Vex.Line>
            </> : <></>
        }
        {JSX[props.dimension]}
    </>
};
