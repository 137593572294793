import React from 'react';
import styled from 'styled-components'

const StyledVexCode = styled.span<{ color: string }>`
color: ${props => props.color};
`

interface IVexCodeBase {
    children: any;
}

interface IComment extends IVexCodeBase {
    comment: boolean;
}
interface IType extends IVexCodeBase {
    type: boolean;
}
interface IString extends IVexCodeBase {
    string: boolean;
}
interface IFunction extends IVexCodeBase {
    function: boolean;
}

export type IVexCode = IComment | IString | IType | IFunction | IVexCodeBase;

export const VexCode: React.FC<IVexCode> = (props: IVexCode) => {
    let color = "#ffffff";
    if ((props as any).comment) color = "#EDED77";
    else if ((props as any).type) color = "#ED77ED";
    else if ((props as any).string) color = "#66CC66";
    else if ((props as any).function) color = "#66CCCC";
    return (
        <StyledVexCode {...{ color }}>
            {props.children}
        </StyledVexCode>
    )
};
