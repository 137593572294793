import { IPythonSnippet } from "./Python";

interface ICreateBaseProps {
    node: string;
    var?: string;
    name?: string;
}

interface IContextCreateProps extends ICreateBaseProps {
    context: 'obj' | 'mat';
}

interface IParentCreateProps extends ICreateBaseProps {
    parent: string;
}

export type ICreateProps = IContextCreateProps | IParentCreateProps;

export const Create: IPythonSnippet<ICreateProps> = (props) => {

    const getVar = () => {
        if (props.var) return props.var;
        else return props.node;
    }

    const getName = () => {
        if (props.name) return `, '${props.name}'`
        else return '';
    }

    const getParent = () => {
        let context = (props as any)["context"]
        let parent = (props as any)["parent"]
        if (context === "obj") return "hou.node('/obj')"
        else if (context === "mat") return "hou.node('/mat')"
        else return (props as any)["parent"];
    }

    return `${getVar()} = ${getParent()}.createNode('${props.node}'${getName()}) # create ${props.node}`

}