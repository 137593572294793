import React, { Component, useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useApp } from "../App";
import { Tip } from "../CodeBlock/CodeBlock";
import { DropdownForm, IDropdownState } from "../Form/DropdownForm";
import { Vex } from "../Vex/VexBlock";


const Root = styled.div<{ darkTheme: boolean, xOffset: number; yOffset: number; }>`

    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;

    width: calc(100% + 42px);
    margin-right: -21px;
    margin-left: -21px;
    margin-top: 28px;
    margin-bottom: 28px;
    padding: 24px 16px;
    box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
    border-radius: 12px;
    background: ${props => props.darkTheme ? "#1e1e1e" : "white"};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 728px) {
        border-radius: 0px;
    }
    & h2.interactive {
        font-family: 'Merriweather','Georgia',serif;
        margin-block-end: 20px;
        margin-block-start: 20px;
        margin-top: 0;
    }
    & p.load {
        color: white;
        text-align: center;
        font-family: "Montserrat";
        font-weight: 500 !important;
        font-size: 19px;
        background: #31aae2;
        padding: 8px 16px;
        width: fit-content;
        border-radius: 28px;
        &:hover {
            cursor: pointer;
        }
    }
    & .wrap {
        padding-top: 16px;
        text-align: center;
        font-weight: 600;
        display: flex;
        flex-wrap: wrap;

        & button {
            font-family: Lato;

            cursor: pointer !important;
            text-align: center !important;
            
            border: 1px solid ${props => props.darkTheme ? "rgb(176, 176, 176)" : "rgb(176, 176, 176)"} !important;
            background-color: transparent !important;
            outline: none !important;
            border-radius: 30px !important;
            color: ${props => props.darkTheme ? "white" : "rgb(34, 34, 34)"} !important;
            position: relative !important;
            padding: 8px 16px !important;
            line-height: 16px !important;



            margin: 0px 16px 8px 0px;

            &.active {
                border: 1px solid ${props => props.darkTheme ? "white" : "black"} !important;
                box-shadow: 0 0 0 1px ${props => props.darkTheme ? "white" : "black"} !important;
                // color: #31aae2;
            }
        }
    }

    & .sprite {
        overflow: hidden;
        position: relative;
        display: inline-block;
        height: 512px;
        width: 512px;
        border-radius: 12px;
        // transform: scale(0.75);
        & img {
            position: absolute;
            top: -${props => props.yOffset}px;
            left: -${props => props.xOffset}px;
            filter: brightness(1.1);
            &.back {
                z-index: -999;
                visibility: hidden;
            }
        }
    }

    & .input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 512px;
    }
    & .direction {
        font-size: 18px;
        font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    }
    & hr {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        margin-bottom: calc(1.75rem - 1px);
        background: #ccc;
        border: none;
        height: 1px;
    }
`
interface slidersState {
    x: number;
    y: number;
    z: number;
    disp: number;
}


interface IDomainWarpSpritesProps {
}

export const DomainWarpSprites: React.FC<IDomainWarpSpritesProps> = (props: IDomainWarpSpritesProps) => {
    const { darkTheme } = useApp();

    const [domainWarpFunction, setDomainWarpFunction] = useState<IDropdownState>({
        "Warp Function": {
            options: ['Use fBm', 'Use a low frequency noise', 'Use both in series'],
            value: 0
        },
    });

    const [sliders, setSliders] = useState<slidersState>({
        x: 4,
        y: 2,
        z: 4,
        disp: 0,
    });

    const [offset, setOffset] = useState<number[]>([0, 0]);

    const handleSlider = (event: any) => {
        let id = event.target.id;
        let value = Number(event.target.value);
        setSliders(_ => ({ ..._, [id]: value }));
    }

    useEffect(() => {
        const idx = ((sliders.disp * 36) + (sliders.x * 6) + sliders.z);
        const tileXSize = 512;
        const tileYSize = 512;
        const rows = 16;
        const columns = 16;
        const xOffset = (idx % columns) * tileXSize;
        const yOffset = (Math.floor(idx / columns)) * tileYSize;
        setOffset([xOffset, yOffset])
    }, [sliders])


    return (<>
        <Root xOffset={offset[0]} yOffset={offset[1]} {...{ darkTheme, }}>
            <div className="sprite">
                <img className={`${(sliders.y === 0 && domainWarpFunction["Warp Function"].value === 0) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/montage0.jpg"} />
                <img className={`${(sliders.y === 1 && domainWarpFunction["Warp Function"].value === 0) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/montage1.jpg"} />
                <img className={`${(sliders.y === 2 && domainWarpFunction["Warp Function"].value === 0) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/montage2.jpg"} />
                <img className={`${(sliders.y === 3 && domainWarpFunction["Warp Function"].value === 0) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/montage3.jpg"} />
                <img className={`${(sliders.y === 4 && domainWarpFunction["Warp Function"].value === 0) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/montage4.jpg"} />
                <img className={`${(sliders.y === 5 && domainWarpFunction["Warp Function"].value === 0) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/montage5.jpg"} />

                <img className={`${(sliders.y === 0 && domainWarpFunction["Warp Function"].value === 1) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/BroadWarp/montage0.jpg"} />
                <img className={`${(sliders.y === 1 && domainWarpFunction["Warp Function"].value === 1) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/BroadWarp/montage1.jpg"} />
                <img className={`${(sliders.y === 2 && domainWarpFunction["Warp Function"].value === 1) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/BroadWarp/montage2.jpg"} />
                <img className={`${(sliders.y === 3 && domainWarpFunction["Warp Function"].value === 1) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/BroadWarp/montage3.jpg"} />
                <img className={`${(sliders.y === 4 && domainWarpFunction["Warp Function"].value === 1) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/BroadWarp/montage4.jpg"} />
                <img className={`${(sliders.y === 5 && domainWarpFunction["Warp Function"].value === 1) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/BroadWarp/montage5.jpg"} />

                <img className={`${(sliders.y === 0 && domainWarpFunction["Warp Function"].value === 2) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/Serial/montage0.jpg"} />
                <img className={`${(sliders.y === 1 && domainWarpFunction["Warp Function"].value === 2) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/Serial/montage1.jpg"} />
                <img className={`${(sliders.y === 2 && domainWarpFunction["Warp Function"].value === 2) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/Serial/montage2.jpg"} />
                <img className={`${(sliders.y === 3 && domainWarpFunction["Warp Function"].value === 2) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/Serial/montage3.jpg"} />
                <img className={`${(sliders.y === 4 && domainWarpFunction["Warp Function"].value === 2) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/Serial/montage4.jpg"} />
                <img className={`${(sliders.y === 5 && domainWarpFunction["Warp Function"].value === 2) ? "front" : "back"}`} src={"https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/DomainWarp/Serial/montage5.jpg"} />
            </div>
            <DropdownForm dropdownState={domainWarpFunction} setDropdownState={setDomainWarpFunction} />
            <p>{`Sample direction: {${sliders.x}, ${sliders.y}, ${sliders.z}}`}</p>
            <div className="input">
                <div>{`x:`}</div>
                <input id="x" type="range" min="0" max="5" value={sliders.x} onChange={handleSlider}></input>
            </div>
            <div className="input">
                <div>{`y:`}</div>
                <input id="y" type="range" min="0" max="5" value={sliders.y} onChange={handleSlider}></input>
            </div>
            <div className="input">
                <div>{`z:`}</div>
                <input id="z" type="range" min="0" max="5" value={sliders.z} onChange={handleSlider}></input>
            </div>
            {/* <Tip>Use the xyz sliders to create warped noise, then use the bottom slider to see where each value came from.</Tip> */}
            <div className="input">
                <div>Move points to sample position: {sliders.disp * 20}%</div>
                <input id="disp" type="range" min="0" max="5" value={sliders.disp} onChange={handleSlider}></input>
            </div>
            <hr />
            <Vex>
                <Vex.Line nobreak>
                    <Vex.Code comment>{`// Perlin fBm`}</Vex.Code>
                </Vex.Line>
                <Vex.Line>
                    <Vex.Code>{`function `}</Vex.Code>
                    <Vex.Code type>{`float `}</Vex.Code>
                    <Vex.Code>{`fBmNoise(`}</Vex.Code>
                    <Vex.Code type>{`vector `}</Vex.Code>
                    <Vex.Code>{`pos;){`}</Vex.Code>
                </Vex.Line>
                <Vex.Line tabs={1}>
                    <Vex.Code type>{`return `}</Vex.Code>
                    <Vex.Code function>{`fit`}</Vex.Code>
                    <Vex.Code>{`(`}</Vex.Code>
                    <Vex.Code function>{`onoise`}</Vex.Code>
                    <Vex.Code>{`(pos, 5, 0.5, 0.8),-0.5, 0.6, 0, 1.2);`}</Vex.Code>
                </Vex.Line>
                <Vex.Line>
                    <Vex.Code >{`}`}</Vex.Code>
                </Vex.Line>
                {(domainWarpFunction["Warp Function"].value != 0) && <>
                    <Vex.Line space>
                        <Vex.Code comment >{`// Low frequency Perlin - zero centered`}</Vex.Code>
                    </Vex.Line>
                    <Vex.Line>
                        <Vex.Code>{`function `}</Vex.Code>
                        <Vex.Code type>{`float `}</Vex.Code>
                        <Vex.Code>{`LowFreqNoise(`}</Vex.Code>
                        <Vex.Code type>{`vector `}</Vex.Code>
                        <Vex.Code>{`pos;){`}</Vex.Code>
                    </Vex.Line>
                    <Vex.Line tabs={1}>
                        <Vex.Code type>{`return `}</Vex.Code>
                        <Vex.Code function>{`onoise`}</Vex.Code>
                        <Vex.Code>{`(pos*0.4, 0, 0.5, 1);`}</Vex.Code>
                    </Vex.Line>
                    <Vex.Line>
                        <Vex.Code >{`}`}</Vex.Code>
                    </Vex.Line>
                </>}
                <Vex.Line space>
                    <Vex.Code comment >{`// get the first noise value from the point position`}</Vex.Code>
                </Vex.Line>
                <Vex.Line>
                    <Vex.Code type >{`float `}</Vex.Code>
                    <Vex.Code>{`n1 = ${(domainWarpFunction["Warp Function"].value === 1) ? "LowFreqNoise" : "fBmNoise"}(v@P);`}</Vex.Code>
                </Vex.Line>
                <Vex.Line space>
                    <Vex.Code comment >{`// Scale the sample direction by the first noise`}</Vex.Code>
                </Vex.Line>
                <Vex.Line>
                    <Vex.Code type>{`vector `}</Vex.Code>
                    <Vex.Code>{`sample_direction = `}</Vex.Code>
                    <Vex.Code function >{`chv`}</Vex.Code>
                    <Vex.Code>{`(`}</Vex.Code>
                    <Vex.Code string>{`"warp_direction"`}</Vex.Code>
                    <Vex.Code>{`) * n1;`}</Vex.Code>
                </Vex.Line>
                <Vex.Line space>
                    <Vex.Code comment >{`// get the second noise value from the sample position`}</Vex.Code>
                </Vex.Line>
                <Vex.Line>
                    <Vex.Code type >{`float `}</Vex.Code>
                    <Vex.Code>{`n2 = fBmNoise(v@P + sample_direction);`}</Vex.Code>
                </Vex.Line>



                {(domainWarpFunction["Warp Function"].value == 2) && <>
                    <Vex.Line space>
                        <Vex.Code comment >{`// Re-scale the sample direction by the second noise`}</Vex.Code>
                    </Vex.Line>
                    <Vex.Line>
                        <Vex.Code>{`sample_direction = `}</Vex.Code>
                        <Vex.Code function >{`chv`}</Vex.Code>
                        <Vex.Code>{`(`}</Vex.Code>
                        <Vex.Code string>{`"warp_direction"`}</Vex.Code>
                        <Vex.Code>{`) * n2;`}</Vex.Code>
                    </Vex.Line>
                    <Vex.Line space>
                        <Vex.Code comment >{`// get the third noise value from the new sample position`}</Vex.Code>
                    </Vex.Line>
                    <Vex.Line>
                        <Vex.Code type >{`float `}</Vex.Code>
                        <Vex.Code>{`n3 = fBmNoise(v@P + sample_direction);`}</Vex.Code>
                    </Vex.Line>
                </>}



                <Vex.Line space>
                    <Vex.Code comment >{`// out float noise`}</Vex.Code>
                </Vex.Line>
                <Vex.Line>
                    <Vex.Code>{`f@noise = ${(domainWarpFunction["Warp Function"].value === 2) ? "n3" : "n2"};`}</Vex.Code>
                </Vex.Line>
            </Vex>
        </Root>
        {/* <DomainWarpVex useBroadNoise={domainWarpFunction.Function.value === 1} /> */}
    </>
    )
};




// export const DomainWarpVex: React.FC<IDomainWarpVexProps> = (props: IDomainWarpVexProps) => {
//     return (
//     )
// };


