import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension } from '../NoiseConfig';

export interface IJitterChannel {
    inputDimension: dimension;
    // channelPrefix: string;
}

export const JitterChannel: React.FC<IJitterChannel> = ({...props}: IJitterChannel) => {

    const JSX: Record<dimension, any> = {
        "1D": <>

            <Vex.Line>
                {/* <Vex.Channel type="int" var="jitter" string="jitter"></Vex.Channel> */}
                <Vex.Code type>{`float `}</Vex.Code>
                <Vex.Code>{`jitter = 1;`}</Vex.Code>
            </Vex.Line>
        </>,
        "2D": <>
            <Vex.Line>
                <Vex.Code type>{`vector2 `}</Vex.Code>
                <Vex.Code>{`jitter = `}</Vex.Code>
                <Vex.Code function>{`set`}</Vex.Code>
                <Vex.Code>{`(1, 1);`}</Vex.Code>
                {/* <Vex.Channel type="vector2" var="jitter" string="jitter"></Vex.Channel> */}
            </Vex.Line>
        </>,
        "3D": <>
            <Vex.Line>
                <Vex.Code type>{`vector `}</Vex.Code>
                <Vex.Code>{`jitter = `}</Vex.Code>
                <Vex.Code function>{`set`}</Vex.Code>
                <Vex.Code>{`(1, 1, 1);`}</Vex.Code>
                {/* <Vex.Channel type="vector" var="jitter" string="jitter"></Vex.Channel> */}
            </Vex.Line>
        </>,
        "4D": <>
            <Vex.Line>
                <Vex.Code type>{`vector4 `}</Vex.Code>
                <Vex.Code>{`jitter = `}</Vex.Code>
                <Vex.Code function>{`set`}</Vex.Code>
                <Vex.Code>{`(1, 1, 1, 0);`}</Vex.Code>
                {/* <Vex.Channel type="vector4" var="jitter" string="jitter"></Vex.Channel> */}
            </Vex.Line>
        </>
    }

    return JSX[props.inputDimension]
};