import React from 'react';
import { ColorSpan as C, CodeBlock as Block, BreakSpan as Br, Code } from '../../CodeBlock/CodeBlock';
import {
    VEX_type,
    VEX_comment,
    VEX_string,
    VEX_func,
    VEX_white
} from '../../CodeBlock/CodeBlock';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';







// const StyledLesson = styled.div`
//     padding: 12px;
//     border-radius: 12px;
// `
// interface ILesson {
//     title: string;
//     children: any;
// }  
export const SphericalCoordinates: React.FC = (props) => {
    return (
        <Page topic="vex">
            <Lesson title="Spherical Coordinates">
            <Section title="" icon="houdini" divider={false}>

                    <Words>These functions convert between spherical and cartesian coordinates.</Words>

                </Section>

                <Section title="Spherical to Cartesian" icon="houdini" divider={false}>
                    <Block>
                        <Br>
                            <C color={VEX_comment}>{`//spherical {r, θ, φ} to cartesian {x, y, z}`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_white}>{`function `}</C>
                            <C color={VEX_type}>{`vector `}</C>
                            <C color={VEX_white}>{`toCartesian(`}</C>
                            <C color={VEX_type}>{`vector `}</C>
                            <C color={VEX_white}>{`spherical;)`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_white}>{`{`}</C>
                        </Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`float `}</C>
                            <C color={VEX_white}>{`r = spherical.x;`}</C>
                        </Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`float `}</C>
                            <C color={VEX_white}>{`theta = spherical.y;`}</C>
                        </Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`float `}</C>
                            <C color={VEX_white}>{`phi = spherical.z;`}</C>
                        </Br>
                        <Br></Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`float `}</C>
                            <C color={VEX_white}>{`x = r * `}</C>
                            <C color={VEX_func}>{`sin`}</C>
                            <C color={VEX_white}>{`(theta) * `}</C>
                            <C color={VEX_func}>{`cos`}</C>
                            <C color={VEX_white}>{`(phi);`}</C>
                        </Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`float `}</C>
                            <C color={VEX_white}>{`y = r * `}</C>
                            <C color={VEX_func}>{`cos`}</C>
                            <C color={VEX_white}>{`(theta);`}</C>
                        </Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`float `}</C>
                            <C color={VEX_white}>{`z = r * `}</C>
                            <C color={VEX_func}>{`sin`}</C>
                            <C color={VEX_white}>{`(theta) * `}</C>
                            <C color={VEX_func}>{`sin`}</C>
                            <C color={VEX_white}>{`(phi);`}</C>
                        </Br>
                        <Br></Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`return `}</C>
                            <C color={VEX_func}>{`set`}</C>
                            <C color={VEX_white}>{`(x, y, z);`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_white}>{`}`}</C>
                        </Br>
                        <Br></Br>
                        <Br></Br>
                    </Block>




                </Section>









                {/* 
                    function vector toSpherical(vector cartesian;) {
                        float r = length(cartesian); // r = √(x² + y² + z²)
                        float theta = acos(cartesian.y/r);
                        float phi = atan2(cartesian.z,cartesian.x); //use atan2 for built-in checks

                        return set(r, theta, phi);
                    }; */}



                <Section title="Cartesian to Spherical" icon="houdini" divider={false}>

                    <Block>
                        <Br>
                            <C color={VEX_comment}>{`//cartesian {x, y, z} to spherical {r, θ, φ}`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_white}>{`function `}</C>
                            <C color={VEX_type}>{`vector `}</C>
                            <C color={VEX_white}>{`toSpherical(`}</C>
                            <C color={VEX_type}>{`vector `}</C>
                            <C color={VEX_white}>{`cartesian;)`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_white}>{`{`}</C>
                        </Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`float `}</C>
                            <C color={VEX_white}>{`r = `}</C>
                            <C color={VEX_func}>{`length`}</C>
                            <C color={VEX_white}>{`(cartesian); `}</C>
                            <C color={VEX_comment}>{`// r = √(x² + y² + z²)`}</C>


                        </Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`float `}</C>
                            <C color={VEX_white}>{`theta = `}</C>
                            <C color={VEX_func}>{`acos`}</C>
                            <C color={VEX_white}>{`(cartesian.y/r);`}</C>
                        </Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`float `}</C>
                            <C color={VEX_white}>{`phi = `}</C>
                            <C color={VEX_func}>{`atan2`}</C>
                            <C color={VEX_white}>{`(cartesian.z,cartesian.x); `}</C>
                            <C color={VEX_comment}>{`//use atan2 for built-in checks`}</C>

                        </Br>
                        <Br></Br>
                        <Br tab={1}>
                            <C color={VEX_type}>{`return `}</C>
                            <C color={VEX_func}>{`set`}</C>
                            <C color={VEX_white}>{`(r, theta, phi);`}</C>
                        </Br>
                        <Br>
                            <C color={VEX_white}>{`}`}</C>
                        </Br>
                        <Br></Br>
                        <Br></Br>
                    </Block>
                </Section>

                <Section title="More Info" icon="houdini" divider={false}>
                    <ImageContainer>
                        <Img ratio={0.66} src="https://houdini-stuff.s3.amazonaws.com/VEX/ConversionFunctions/spherical_polar.jpg" />
                    </ImageContainer>
                    <Words>These functions use the <Link text="ISO convention" url="https://en.wikipedia.org/wiki/Spherical_coordinate_system" /> commonly used in physics where θ (theta) denotes the polar angle and φ (phi) denotes the azimuthal angle. Both in radians. r is the radius from the origin.</Words>
                    <Words>The y and z axis are swapped to match houdini. The atan2 function is used to handle a divide by zero situation that can occur with regular atan.</Words>


                    {/* function vector toCartesian(vector spherical;) {
                        float r = spherical.x;
                        float theta = spherical.y;
                        float phi = spherical.z;

                        float x = r * sin(theta) * cos(phi);
                        float y = r * cos(theta);
                        float z = r * sin(theta) * sin(phi);
                        return set(x, y, z);
                        }; */}

                </Section>

            </Lesson>
        </Page >
    )
};
