import React from 'react';
import { Lesson, Page, Words } from '../../Chapter/Chapter';
import { CCode } from '../../CodeBlock/CCode';
import { Tip } from '../../CodeBlock/CodeBlock';
import { Section } from '../Section';

export const TransformOrient: React.FC = (props) => {
    return (
        <Page topic="vex">
            <Lesson title="Transforms and Orients">
                <Section title="Orient Geo Based on a Prim" icon="houdini">
                    <Words>Given a prim number, transform and orient the geometry so that the prim is located at the centroid and facing straight up.</Words>
                    <Tip>Use a Divide SOP beforehand so you are working with triangles only</Tip>
                    <Tip>You can reverse the up direction so the geometry is resting on the ground</Tip>
                    <CCode>
                        {`// in a detail wrangle

int prim_num = chi("primnum");

// get prim centroid
vector centroid = prim(0, "P", prim_num);

// get prim normal
int prim;
vector uvw;
xyzdist(0, centroid, prim, uvw);
vector normal = prim_normal(0, prim_num, uvw);

// get a random point on the prim
int pt = primpoints(0, prim_num)[0];
int pt_pos = point(0, "P", pt);


/////////////////////////////////
/////// create orient //////////

vector forward = normalize(pt_pos - centroid);
vector up = normalize(normal);
vector cross = cross(forward, up);
forward = cross(up, cross); // locks this direction to perfect 90 degress from up

matrix3 m = maketransform(forward, up);
vector4 orient = quaternion(m);

setdetailattrib(0, "prim_orient", orient, "set");
setdetailattrib(0, "prim_centroid", centroid, "set");`}
                    </CCode>
                    <CCode>
                        {`// in a subsequent point wrangle

function vector orientAndTransformToOrigin(vector pos; vector tranform_pos; vector4 orient;) {
    vector position = pos;
    position -= tranform_pos;
    position = qrotate(qinvert(orient), position);
    return position;
}

vector4 orient = detail(0, "prim_orient");
vector tranform_pos = detail(0, "prim_centroid");

vector pos = orientAndTransformToOrigin(v@P, tranform_pos, orient);
v@P = pos;
`}
                    </CCode>
                </Section>
            </Lesson>
        </Page >
    )
};

