import React, { useState, useEffect, useRef } from 'react';
import { useReducer } from 'react';
import styled from 'styled-components'
import { useApp } from '../App';
// import { usePage } from '../Chapter/Chapter';
export const { slate, thiss,
    propss,
    variable,
    func,
    type,
    white,
    purple,
    red,
    greenParen,
    lightBrack,
    medBrack,
    darkBrack,
    string,
    number,
    array,
    comment } = {
    slate: "#1e1e1e",
    thiss: "#569cd6",
    propss: "#4bbfff",
    variable: "#9cdcfe",
    func: "#dad59d",
    type: "#4ec9b0",
    white: "#d4d4d4",
    purple: "#c586bf",
    red: "#be0266",
    greenParen: "#00a95f",
    lightBrack: "#c3c7a1",
    medBrack: "#d19f73",
    darkBrack: "#9a6427",
    string: "#ce9178",
    number: "#b5cea6",
    array: "#33ccfa",
    comment: "#6a9955",
}

export const {
    VEX_type,
    VEX_comment,
    VEX_string,
    VEX_func,
    VEX_white
} = {
    VEX_type: "#ED77ED",
    VEX_comment: "#EDED77",
    VEX_string: "#66CC66",
    VEX_func: "#66CCCC",
    VEX_white: "#fff"
}



const P = styled.p<Pick<ICodeBlock, "backgroundColor">>`
    background-color: ${props => props.backgroundColor};
    padding: 12px;
    border-radius: 12px;
    font-family: 'Inconsolata', monospace;
    margin-right: -21px;
    margin-left: -21px;
    margin-bottom: 28px;
    @media (max-width: 728px) {
        border-radius: 0px;
      }
`

interface ICodeBlock {
    backgroundColor?: string;
    children: any;
}

export const CodeBlock: React.FC<ICodeBlock> = ({ backgroundColor = "#1e1e1e", ...props }: ICodeBlock) => {
    return (
        <P {...{ backgroundColor }}>
            {props.children}
        </P>
    )
};


const Span = styled.span<Pick<IColorSpan, "color">>`
    color: ${props => props.color};
`
interface IColorSpan {
    color: string;
    children: any;
}
export const ColorSpan: React.FC<IColorSpan> = (props: IColorSpan) => {
    return (
        <Span {...props}>
            {props.children}
        </Span>
    )
}


interface IBreakSpanProps {
    tab?: number;
};

export const BreakSpan: React.FC<IBreakSpanProps> = ({ tab, ...props }) => {
    const tabs = new Array(tab ? tab * 4 : 0).fill(null).map((_, i) => <React.Fragment key={i}>&nbsp;</React.Fragment>);
    return (<>
        <br />
        <>{tabs}{props.children}</>
    </>
    )
};







const StyledCode = styled.code<ICode>`
    background: ${props => props.darkTheme ? "#737c9933" : "#ffe56433"};
    color: ${props => props.darkTheme ? "white" : "inherit"};
    border-radius: .3em;
    color: var(--inlineCode-text);
    padding: .15em .2em .05em;
    white-space: normal;

    white-space: nowrap;


    font-family: 'Inconsolata', monospace;
    font-feature-settings: normal;
    text-align: left;


    word-spacing: normal;
    word-break: normal;

    line-height: 1.5;
    margin-bottom: 0;

    tab-size: 4;
    hyphens: none;
`

interface ICode {
    darkTheme?: boolean;
    children: any;
}

export const Code: React.FC<ICode> = ({ ...props }: ICode) => {
    const { darkTheme } = useApp();
    return (
        <StyledCode {...{ darkTheme }}>
            {props.children}
        </StyledCode>
    )
};

const StyledTip = styled.p<ICode>`
    background: ${props => props.darkTheme ? "#737c9933" : "#ffe56433"};
    color: ${props => props.darkTheme ? "white" : "inherit"};
    font-size: 0.9em;
    border: 1px solid ${props => props.darkTheme ? "#ffffff33" : "#00000033"};;
    border-radius: .75em;
    padding: 0.75em;
    margin: 0px 0px 1.75rem 0px;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

`

interface ITip {
    darkTheme?: boolean;
    children: any;
}

export const Tip: React.FC<ITip> = ({ ...props }: ITip) => {
    const { darkTheme } = useApp();
    return (
        <StyledTip {...{ darkTheme }}>
            {props.children}
        </StyledTip>
    )
};


// const test: React.FC = (props) => {


//     const chf = (any: any) => any;

//     type vector = number;
//     let P: vector = 0;
//     let N: vector;





//     float amount = chf("amount");
//     v@P = v@P + amount * n@N;







//     return (<>
//         <br />{props.children}
//     </>
//     )
// }


// arrow →

// ↖ ↑ ↗
// ← · →
// ↙ ↓ ↘

// ⬉ ⬆ ⬈
// ⬅ ⊙ ➡
// ⬋ ⬇ ⬊