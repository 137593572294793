import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Code, Tip } from '../../CodeBlock/CodeBlock';


export const PythonEditorNode: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="Python Editor Node">
                <Section title="" icon="houdini" divider>
                    <ImageContainer>
                        <Img ratio={0.5625} src="https://houdini-stuff.s3.amazonaws.com/Houdini/GIFS/pythoneditor.gif" />
                    </ImageContainer>
                    <Words>This idea comes from cgwiki and is explained in detail <Link text="here" url="https://www.tokeru.com/cgwiki/index.php?title=HoudiniPython" />. It's basically a multi-line python string parameter and a button attached to a null node. Unlike the python shell, you can write and execute multi-line python code here, similar to a shelf tool. </Words>
                </Section>
                <Section title="Create From Python Code" icon="python" divider={true}>
                    <Words>Drop this code into the python shell to create the node. You can test the node by using it to make more copies</Words>
                    <Words>This example hijacks a Python Script node to take advantage of the cool icon. To use any other kind of node, just change the first line. For example, change the first line to <Code>node = hou.node('/obj').createNode('subnet')</Code> and you're ready to make an HDA.</Words>
                    <Python>
                        {`
# create python script node
node = hou.node('/obj').createNode('pythonscript', 'python_editor')
node.setColor(hou.Color((0.22, 0.46, 0.67)))
node.setUserData("nodeshape", "wave")
node.setDisplayFlag(0)
node.parm('picking').set(0)
node.moveToGoodPosition()

# get parm template group
ptg = node.parmTemplateGroup()

# hide existing parameters
for parm in ptg.entries(): ptg.hide(parm, True)

# define python string parameter ("id", "Label", num inputs)
python_string = hou.StringParmTemplate("pythoncode", "Python Code", 1)
# enable multi-line string and python language
python_string.setTags({"editor": "1", "editorlang": "python"})
python_string.hideLabel(True)

# define execute button parameter ("id", "Label")
execute_button = hou.ButtonParmTemplate("execute", "Run Code", script_callback_language=hou.scriptLanguage.Python)
execute_button.setScriptCallback("exec(kwargs['node'].parm('pythoncode').eval())")

# define floating pane button parameter
floating_pane_button = hou.ButtonParmTemplate("pane", "Open Floating Pane", script_callback_language=hou.scriptLanguage.Python,join_with_next=True)
floating_pane_button.setScriptCallback("""exec('''
pane = hou.ui.curDesktop().createFloatingPane(hou.paneTabType.Parm)
pane.setCurrentNode(kwargs['node'])
pane.setPin(True)
''')""")

# separator
separator = hou.SeparatorParmTemplate("seperator");

# add everything to new folder
folder = hou.FolderParmTemplate("python_editor", "Python Editor", parm_templates=((floating_pane_button),(execute_button),(separator),(python_string)), folder_type=hou.folderType.Simple)

# append new parameters
ptg.append(folder)

# apply changes
node.setParmTemplateGroup(ptg)

# --- Made in Houdini 18.5 ---
                            `}
                    </Python>
                </Section>
                <Section title="HDA" icon="hda" divider>
                    <Words>Same as above but made into an HDA. Added an OnCreated script for the node color and shape.</Words>
                    <Words>Obj level: <Link text="pythoneditor.hdalc" url="https://github.com/keatonwilliamson/hda/raw/master/pythoneditor.hdalc" /></Words>
                    <Words>SOP level: <Link text="pythoneditorsop.hdalc" url="https://github.com/keatonwilliamson/hda/raw/master/pythoneditorsop.hdalc" /></Words>
                    <Python>
                        {`# OnCreated

node = kwargs['node']
node.setColor(hou.Color((0.22, 0.46, 0.67)))
node.setUserData("nodeshape", "wave")

# for obj level
# node.setDisplayFlag(0) # display flag
# node.parm('picking').set(0) # selectable
`}
                    </Python>
                </Section>
                <Words><strong>References:</strong></Words>
                <Words><Link text="HoudiniPython" url="https://www.tokeru.com/cgwiki/index.php?title=HoudiniPython" /> ─ cgwiki</Words>
                <Words><Link text="Python in Houdini" url="https://vfxbrain.wordpress.com/2016/03/15/python-in-houdini/" /> ─ VFX Brain</Words>
            </Lesson>
        </Page >
    )
};
