import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { Fields } from "./Fields";
import { SimpleSource } from "./SimpleSource";


const base_url = "/houdini/pyro"

export const PyroRoutes = () => {
    return (<>
        <Route exact path={`${base_url}/fields`}>
            <Fields />
        </Route>
        <Route exact path={`${base_url}/source`}>
            <SimpleSource />
        </Route>
    </>
    )
};

export const PyroArticles = () => {
    return (<>
        <ArticleLink
            title="Pyro Fields"
            date="Mar 20, 2021"
            description="Temperature, fuel, burn, density, divergence, flame"
            url={`${base_url}/fields`}
            emoji="🔥"
        // icon=""
        />
        <ArticleLink
            title="Simple Pyro Source"
            date="Mar 21, 2021"
            description="Simple Pyro Source with temperature, fuel, velocity"
            url={`${base_url}/source`}
            emoji="🔥"
            icons={["python"]}
        />
    </>
    )
};