import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Tip } from '../../CodeBlock/CodeBlock';


export const ParmTemplateGroup: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="ParmTemplates">
                <Section title="Hide Parameter" icon="houdini" divider>
                    <Python highlighted={[9, 12]}>
                        {`
# create geo
geo = hou.node('/obj').createNode('geo', 'hide_parameter_example')

# get ParmTemplateGroup
ptg = geo.parmTemplateGroup()

# hide the translate parameter
ptg.hide("t", True)

# un-hide the lookatpath parameter
ptg.hide("lookatpath", False)

# apply changes
geo.setParmTemplateGroup(ptg)
`}
                    </Python>
                    <Words><Link text="hou.ParmTemplateGroup.hide()" url="https://www.sidefx.com/docs/houdini/hom/hou/ParmTemplateGroup.html#hide" /></Words>
                </Section>
                <Section title="Hide When, Disable When" icon="houdini" divider>
                    <Python >
                        {`
node.setConditional(hou.parmCondType.DisableWhen, "{uniformsamples != 4}")
node.setConditional(hou.parmCondType.HideWhen, "{uniformsamples != 4}")
`}
                    </Python>
                    <Words><Link text="hou.parmCondType" url="https://www.sidefx.com/docs/houdini/hom/hou/parmCondType.html" /></Words>
                </Section>
                <Section title="Array of All ParmTemplates" icon="houdini" divider>
                    <Python highlighted={[9]}>
                        {`
# create geo
geo = hou.node('/obj').createNode('geo') 

# get ParmTemplateGroup
ptg = geo.parmTemplateGroup()

# get ParmTemplates
parm_templates = ptg.entries()

# print names
print([p.name() for p in parm_templates])
`}
                    </Python>
                    <Words><Link text="ParmTemplateGroup.entries()" url="https://www.sidefx.com/docs/houdini/hom/hou/ParmTemplateGroup.html#entries" /></Words>
                    <Tip><strong>Docs: </strong>Note that folders are returned as <Link text="hou.FolderParmTemplate" url="https://www.sidefx.com/docs/houdini/hom/hou/FolderParmTemplate.html" /> objects, and each folder parm template contains the parm templates inside it. Use <Link text="hou.FolderParmTemplate.parmTemplates()" url="https://www.sidefx.com/docs/houdini/hom/hou/FolderParmTemplate.html#parmTemplates" /> to access the elements in a folder.
<br />
<br />
                    The following example creates a function named allParmTemplates that returns a single sequence of parm templates, including folders, for all parm templates in the group:
                    </Tip>
                    <Words>To get all ParmTemplates, including those in folders, use this:</Words>
                    <Python>
                        {`
# create geo
geo = hou.node('/obj').createNode('geo', 'all_parmtemplates') 

# get ParmTemplateGroup
ptg = geo.parmTemplateGroup()

# function straight from houdini docs
def allParmTemplates(group_or_folder):
    for parm_template in group_or_folder.parmTemplates():
        yield parm_template

    # Note that we don't want to return parm templates inside multiparm
    # blocks, so we verify that the folder parm template is actually
    # for a folder.
        if (parm_template.type() == hou.parmTemplateType.Folder and
        parm_template.isActualFolder()):
            for sub_parm_template in allParmTemplates(parm_template):
                yield sub_parm_template

# print names
print([p.name() for p in allParmTemplates(ptg)])
                            `}
                    </Python>
                    {/* <Python>
                        {`
    # https://www.sidefx.com/docs/houdini/hom/hou/ParmTemplateGroup.html#entries
    def allParmTemplates(folder):
        for parm_template in folder.parmTemplates():
            print(parm_template)
            if (parm_template.type() == hou.parmTemplateType.Folder and parm_template.isActualFolder()):
                for sub_parm_template in allParmTemplates(parm_template):
                    yield sub_parm_template
            else: yield parm_template 
                            `}
                    </Python> */}
                </Section>
                <Section title="Render Properties" icon="houdini" divider>
                    <Python>
                        {`
# classes
classes = hou.properties.classes()

# categories of mantra class
categories = hou.properties.categories("mantra")

# parameters of Shading category of mantra class
parameters = hou.properties.parameters("mantra", "Shading")

# dictionary of all
render_props = { i : { j : [k for k in hou.properties.parameters(i, j)] for j in hou.properties.categories(i)} for i in hou.properties.classes() }
`}
                    </Python>
                    <Words><Link text="hou.properties" url="https://www.sidefx.com/docs/houdini/hom/hou/properties.html" /></Words>
                    <Words><Link text="Mantra rendering properties" url="https://www.sidefx.com/docs/houdini/props/mantra.html" /></Words>
                </Section>
                <Section title="Add a Render Property to a Material Vop" icon="houdini" divider>
                    <Words>Adds displacement to a Material Builder:</Words>
                    {/* <Words><Link text="hou.properties" url="https://www.sidefx.com/docs/houdini/hom/hou/properties.html" /></Words> */}
                    <Python highlighted={[13]}>
                        {`
# create materialbuilder
mat_builder = hou.node("/mat").createNode("materialbuilder", "add_render_prop");

# add a properties vop for displacement bounds
properties = mat_builder.createNode("properties", "displacement_bounds");

# get parmTemplateGroup
ptg = properties.parmTemplateGroup()

# get Displacement Bounds ParmTemplate 
# hou.properties.parmTemplate(class_name, parm_name) → hou.ParmTemplate
displacement_bounds = hou.properties.parmTemplate("mantra", "vm_displacebound")

# add to new Shading folder
shading = hou.FolderParmTemplate("Shading", "Shading", parm_templates=((displacement_bounds),))

# append Shading folder to group
ptg.append(shading)

# apply changes
properties.setParmTemplateGroup(ptg)

# set displacement bounds to 1
properties.parm("vm_displacebound").set(1)

# get material output_collect
output = hou.node(mat_builder.path() + '/output_collect')

# wire displacement_bounds to output
output.setNextInput(properties, 0)
                            `}
                    </Python>
                </Section>

            </Lesson>
        </Page >
    )
};

// AttributeError: 'MenuParmTemplate' object has no attribute 'rawValue'