import React, { ChangeEventHandler, PropsWithChildren, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components'
import { useApp } from '../App';
import { string } from '../CodeBlock/CodeBlock';
import sort from "./sort.svg";
import sort_blue from "./sort_blue.svg";

const StyledSliderSingle = styled.div<{ darkTheme: boolean; }>`
    color: ${props => props.color};
    padding: 8px 0px 0px;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    & .label {
        padding: 4px;
        color: ${props => props.darkTheme ? "white" : "black"};
        user-select: none;
        pointer-events: none;
    }
    & .input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 224px;
        & input {
            width: 100%;
        }
    }
    
`


interface ISliderSingleProps {
    id: string;
    label: string;
    value: number;
    setValue: (id: string, value: number) => void;
    min: number;
    max: number;
    // options: DropdownSingleOption[];
    darkTheme?: "dark" | "light";
}

export const SliderSingle: React.FC<ISliderSingleProps> = (props: ISliderSingleProps) => {
    let { darkTheme, nodeSelected, setNodeSelected } = useApp();
    if (!!props.darkTheme) {
        if (props.darkTheme == "dark") darkTheme = true;
        else if (props.darkTheme == "light") darkTheme = false;
    }

    const handleDropdownChange = (event: any, data: any) => {
        let id = data.id;
        let value = Number(data.value);
        props.setValue(id, value);
    };
    const handleSliderChange = (event: any) => {
        let id = event.target.id;
        let value = Number(event.target.value);
        props.setValue(id, value);
    };

    return (
        <StyledSliderSingle darkTheme={darkTheme}>
            <div className="item">
                <div className="label">{props.label}</div>
                <div className="input">
                    <input id={props.id} type="range" min={props.min} max={props.max} value={props.value} onChange={handleSliderChange}></input>
                </div>
            </div>
        </StyledSliderSingle>
    )
};

