import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension, NoiseFunction } from '../NoiseConfig';

export interface IPosition {
    noise: NoiseFunction;
    dimension: dimension;
    tabs?: number;
    useFunction: boolean;
    channelPrefix: string;
}

export const Position: React.FC<IPosition> = ({ tabs, useFunction, ...props }: IPosition) => {

    // const isCellular = (props.noise == "Voronoi" || props.noise == "Worley");

    const JSX: Record<dimension, any> = {
        "1D": <>
            <Vex.Line {...{ tabs }}>
                <Vex.Code type>{`float `}</Vex.Code>
                <Vex.Code>{`posx = `}</Vex.Code>
                <Vex.Code function>{`set`}</Vex.Code>
                <Vex.Code>{`(${useFunction ? "P" : "v@P"} * freq + offset);`}</Vex.Code>
            </Vex.Line>
        </>,
        "2D": <>
            <Vex.Line {...{ tabs }}>
                <Vex.Code type>{`vector2 `}</Vex.Code>
                <Vex.Code>{`pos = `}</Vex.Code>
                <Vex.Code function>{`set`}</Vex.Code>
                <Vex.Code>{`(${useFunction ? "P" : "v@P"} * freq + offset);`}</Vex.Code>
            </Vex.Line>
        </>,
        "3D": <>
        </>,
        "4D": <>
            <Vex.Line {...{ tabs }}>
                <Vex.Code type>{`vector4 `}</Vex.Code>
                <Vex.Code>{`pos4D = `}</Vex.Code>
                <Vex.Code function>{`set`}</Vex.Code>
                <Vex.Code>{`(${useFunction ? "P" : "v@P"} * freq + offset);`}</Vex.Code>
            </Vex.Line>
            {useFunction ?
                <Vex.Line {...{ tabs }}>
                    <Vex.Code>{`pos4D.w = `}</Vex.Code>
                    <Vex.Code function>{`chf`}</Vex.Code>
                    <Vex.Code>{`(`}</Vex.Code>
                    <Vex.Code string>{`"${props.channelPrefix}pos_4D"`}</Vex.Code>
                    <Vex.Code>{`);`}</Vex.Code>
                </Vex.Line>
                :

                <Vex.Line {...{ tabs }}>
                    <Vex.Code>{`pos4D.w = @Time;`}</Vex.Code>
                </Vex.Line>
            }
        </>
    }

    return JSX[props.dimension]
};