import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';


export const Parms: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="Parms">
                <Section title="Ramp Parameters" icon="houdini" divider={false}>
                    <Python>
                        {`
# setting a float ramp
bases = [hou.rampBasis.Linear] * 3
keys = [0.0, 0.5, 1.0]
values = [0, 0.3, 1]
node.parm("float_ramp").set(hou.Ramp(bases, keys, values))

# setting a color ramp
color_bases = [hou.rampBasis.Linear] * 4
color_keys = [0.0, 0.33333, 0.66666, 1.0]
color_values = [(0,0,0), (1,0,0), (1,1,0), (1,1,1)]
node.parm("color_ramp").set(hou.Ramp(color_bases, color_keys, color_values))
                            `}
                    </Python>
                    <Python>
                        {`
# one-line reset float ramp
node.parm("float_ramp").set(hou.Ramp([hou.rampBasis.Linear] * 2, [0, 1], [0, 1]))

# one-line reset color ramp
node.parm("color_ramp").set(hou.Ramp([hou.rampBasis.Linear] * 2, [0, 1], [(0,0,0), (1,1,1)]))
                            `}
                    </Python>

                    <Python>
                        {`
# get list of ramp parameters
ramp_parms = [p for p in node.parms() if p.parmTemplate().type() == hou.parmTemplateType.Ramp]
                            `}
                    </Python>
                    <Python>
                        {`
# get ramp type 
for p in node.parms(): 
    # if parm is ramp
    if (p.parmTemplate().type() == hou.parmTemplateType.Ramp): 
        # float
        if (p.parmTemplate().parmType() == hou.rampParmType.Float): 
            print("float ramp")
        # color  
        elif (p.parmTemplate().parmType() == hou.rampParmType.Color): 
            print("color ramp") 
                            `}
                    </Python>
                </Section>
                <Section title="All Parameters" icon="houdini" divider={false}>
                    <Words>Print the value of every parameter on a node</Words>
                    <Python highlighted={[6]}>
                        {`
# create a geo node
geo = hou.node('/obj').createNode('geo')

# get list of parameters
parms = [(parm.name(),parm.eval()) for parm in geo.parms()]

# sort by alphabetical order
parms.sort()

# print all parameters
for p in parms: print p
                            `}
                    </Python>
                </Section>
                <Section title="All Parms in a Folder" icon="houdini" divider={false}>
                    <Words>Get all parms in a folder inculing multiparms</Words>
                    <Python highlighted={[6]}>
                        {`
def getParmsInFolder(node, folder):
    # get all parms in Noise Folder including multiparms
    # note: use Label name, not internal name of folder
    parm_tuples = node.parmTuplesInFolder((folder.label(),))
    parms = [p for tupl in parm_tuples for p in tupl] # flatten
    return [p.name() for p in parms] # return string names
                            `}
                    </Python>
                </Section>
                <Section title="All Parms in a Folder" icon="houdini" divider={false}>
                    <Words>Get all parms in a folder inculing multiparms</Words>
                    <Python highlighted={[6]}>
                        {`
# recursively get all parmTemplates in a group or folder
def allParmTemplates(group_or_folder):
    for parm_template in group_or_folder.parmTemplates():
        yield parm_template
        if (parm_template.type() == hou.parmTemplateType.Folder and parm_template.isActualFolder()):
            for sub_parm_template in allParmTemplates(parm_template):
                yield sub_parm_template
                            `}
                    </Python>
                </Section>
                <Section title="All Parms in a Folder" icon="houdini" divider={false}>
                    <Words>Get all parms in a folder inculing multiparms</Words>
                    <Python highlighted={[6]}>
                        {`
def allParmNames(node, group_or_folder):
    # recursively get all parmTemplates in a group or folder
    def allParmTemplates(group_or_folder):
        for parm_template in group_or_folder.parmTemplates():
            if (parm_template.type() == hou.parmTemplateType.Folder and parm_template.isActualFolder()):
                for sub_parm_template in allParmTemplates(parm_template):
                    yield sub_parm_template
            else: yield parm_template
    parm_tuples = [node.parmTuple(template.name()) for template in allParmTemplates(group_or_folder)]
    parms = [p for tupl in parm_tuples for p in tupl] # flatten
    return [p.name() for p in parms] # return string names
                            `}
                    </Python>
                </Section>
                <Section title="All Parms in a Folder" icon="houdini" divider={false}>
                    <Words>Delete Channel References</Words>
                    <Python highlighted={[6]}>
                        {`
# delete channel references
for p in node.parms():   
    p.deleteAllKeyframes()
                            `}
                    </Python>
                </Section>
                <Section title="Compare Parameters" icon="houdini" divider={false}>
                <Words>Compare the parameters of two nodes. Print the values unique to each. Known bug sometimes: Typeerror: unhashable type: 'dict'</Words>
                    <Python highlighted={[12, 13]}>
                        {`
# grab two nodes to compare
# you can drag and drop nodes
nodeA = hou.node('/obj/geo1')
nodeB = hou.node('/obj/geo2')

# get full list of parameters on each
parmsA = [(parm.name(),parm.eval()) for parm in nodeA.parms()]
parmsB = [(parm.name(),parm.eval()) for parm in nodeB.parms()]

# print parameters unique to each
print("Parameters unique to node A", list(set(parmsA) - set(parmsB)))
print("Parameters unique to node B", list(set(parmsB) - set(parmsA)))
                            `}
                    </Python>
                </Section>

            </Lesson>
        </Page >
    )
};


// # recursively get all parmTemplates in a group or folder
// def allParmTemplates(group_or_folder):
//     for parm_template in group_or_folder.parmTemplates():
//         yield parm_template
//         if (parm_template.type() == hou.parmTemplateType.Folder and parm_template.isActualFolder()):
//             for sub_parm_template in allParmTemplates(parm_template):
//                 yield sub_parm_template
        

// def allParmNames(node, group_or_folder):
//     parm_tuples = [node.parmTuple(template.name()) for template in allParmTemplates(group_or_folder)]
//     parms = [p for tupl in parm_tuples for p in tupl] # flatten
//     return [p.name() for p in parms] # return string names

    
// # similar to above function, but using a folder label    
// def getParmNamesInFolder(node, folderLabel):
//     parm_tuples = node.parmTuplesInFolder((folderLabel,))
//     parms = [p for tupl in parm_tuples for p in tupl] # flatten
//     return [p.name() for p in parms] # return string names 