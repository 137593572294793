import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { transform } from 'typescript';
import { useApp } from '../App';
import { ReactToggle } from '../Chapter/Chapter';
import { Link } from '../Chapter/Link/Link';
import { Gallery } from '../Gallery/Gallery';
import { Generator } from '../Houdini/Noise/VexGenerators/Generator';
import { TurbulentSprites } from '../Sprites/TurbulentSprites';
import { WideBlogSection } from '../WebComponents/WideBlogSection';
import { CloudLoader } from './CloudLoader';
import { Frame } from './Frame';
import { ILandingPageContentProps } from './Landing';



const StyledWeb = styled.div<{ darkTheme: boolean; loaded: boolean; }>`
    ${props =>
        !props.loaded ?
            `
            &>* {
                display: none
            };
            opacity: 0;
            `
            :
            `
            opacity: 1;
            `}
    transition: opacity .78s;
    & .section {
      margin: 1.5vw auto;
      padding: 0px 1.5vw;
      max-width: 1280px;
      width: auto;
      &.padtop {
          padding-top: 8vw;
      }
    }   
`



const Landing: React.FC<ILandingPageContentProps> = (props) => {
    const { darkTheme, setDarkTheme } = useApp();
    const [loadedElements, setLoadedELements] = useState({
        minimoog: false,
        // pokemon: false,
        houdini: false
    });


    useEffect(() => {
        props.setPageContentLoaded(false);
    }, []);
    useEffect(() => {
        if (Object.values(loadedElements).every(_ => _)) {
            props.setPageContentLoaded(true);
        }
    }, [loadedElements]);

    const handleFrameLoad = (evt: any) => {
        let id = evt.target.id;
        setLoadedELements(obj => ({ ...obj, [id]: true }))
    }

    const loaded = Object.values(loadedElements).every(_ => _);
    return (<>
        <CloudLoader loaded={loaded} />
        <StyledWeb loaded={loaded} {...{ darkTheme }}>

            <div className="section" >
                <h2 className="item-title">Minimoog Model D</h2>
                <p>
                    Minimoog Model D synthesizer interface. Made with React.
      </p>
            </div>

            <Frame handleFrameLoad={handleFrameLoad} src="https://master.d182vtwg4pmsjy.amplifyapp.com/" frameId="minimoog" />
            {/* <div className="section padtop" >
                <h2 className="item-title">Pokemon Music Game</h2>
                <p>
                    Music education game made with Phaser.js and React while attending Nashville Software School
      </p>
                <Frame handleFrameLoad={handleFrameLoad} src="https://pokemondashboard.com" frameId="pokemon" unLockedFrameId={unLockedFrameId}/>
            </div> */}
            <div className="section padtop" >
                <h2 className="item-title">Houdini Notes</h2>
                <p>
                    Notes on VFX software SideFX Houdini. Made with React and styled-components. <Link nowrap text="Go to Full Site →" url="https://www.keatonwilliamson.com/houdini" />
                </p>
                <Frame handleFrameLoad={handleFrameLoad} src="https://keatonwilliamson.com/houdini/landing" frameId="houdini" />
            </div>
            <div className="section padtop" >
                <h2 className="item-title">VEX Noise Generator</h2>
                <p>
                    VEX code generator for noise functions in SideFX Houdini. <Link nowrap text="Go to Full Page →" url="https://www.keatonwilliamson.com/houdini/noise/vex_noise_generator" />
                </p>
                <Generator />
            </div>
            <div className="section padtop" >
                <h2 className="item-title">Turbulence Visualizer</h2>
                <p>
                    See the result of fBm on different noises in SideFX Houdini. <Link nowrap text="Go to Full Page →" url="https://www.keatonwilliamson.com/houdini/noise/turbulence_visualizer" />
                </p>

                    <TurbulentSprites />
            </div>
        </StyledWeb>
    </>)
};

export default Landing;
