import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { SatelliteData } from "./SatelliteData";


export const TerrainRoutes = () => {
    return (<>
        <Route exact path="/houdini/terrain/satellitedata">
            <SatelliteData />
        </Route>

    </>
    )
};

export const TerrainArticles = () => {
    return (<>
        <ArticleLink
            title="Satellite Data in Heightfields"
            date="Jan 18, 2021"
            description="Satellite Data in Heighfields"
            url="/houdini/terrain/satellitedata"
            emoji="🌍"
        />
    </>
    )
};