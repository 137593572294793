import { debounce } from 'lodash';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Ref } from 'semantic-ui-react';
import styled from 'styled-components'
import { useApp } from '../App';
import { ReactToggle } from '../Chapter/Chapter';
import { number } from '../CodeBlock/CodeBlock';

import { ArticleLink } from './ArticleLink';
import { BlenderArticles } from './Blender/Articles';
import { CameraArticles } from './Camera/Articles';
import { GeometryArticles } from './Geometry/GeometryArticles';
import { HDAArticles } from './HDA/Articles';
import { MantraArticles } from './Mantra/Articles';
import { NoiseArticles } from './Noise/Articles';
import { NukeArticles } from './Nuke/Articles';
import { PyroArticles } from './Pyro/Articles';
import { PythonArticles } from './Python/Articles';
import { Section } from './Section';
import { TerrainArticles } from './Terrain/Articles';
import { UiArticles } from './UI/Articles';
import { VexArticles } from './VEX/Articles';
import { VolumesArticles } from './Volumes/Articles';



const StyledHoudini = styled.div<{ darkTheme: boolean; }>`
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background: ${props => props.darkTheme ? "#282c35" : "white"};
    color: ${props => props.darkTheme ? "white" : "#222222"};
    transition: color 0.2s ease-out, background 0.2s ease-out;
    & > div {
        max-width: 42rem;
        padding: 42px 21px;
        margin-left: auto;
        margin-right: auto;
        & > header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 64px;
            & > .wrap {
                display: flex;
                align-items: flex-end;
                & a {
                    color: ${props => props.darkTheme ? "white" : "#222222"};
                    font-family: Montserrat, sans-serif;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    line-height: 2.625rem;
                    font-size: 32px;
                }
                & img.houdini {
                    margin-right: 16px;
                    width: 48px;
                    height: 48px;
                    align-self: center;
                }
            }

        }

        & .wrapper {
            width: 100%;
            margin: auto auto;
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(4, 1fr);
            align-content: center;
            align-items: center;



            grid-template-columns: repeat(auto-fill, minmax(114px, 1fr));
            /* This is better for small screens, once min() is better supported */
            /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
             grid-gap: 1rem;
            /* This is the standardized property now, but has slightly less support */
            /* gap: 1rem */



            & .wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                &:hover {
                    cursor: pointer;
                    & .icon {
                        filter: saturate(200%);
                        transform: scale(1.1);
                        &.bug {
                            box-shadow: 0 0 20px #92BC2C70;
                        }
                        &.python {
                            box-shadow: 0 0 20px #3771a1;
                        }
                        &.dark {
                            box-shadow: 0 0 20px #59576170;
                        }
                        .electric {
                            box-shadow: 0 0 20px #F2D94E70;
                        }
                        &.fire {
                            box-shadow: 0 0 20px #FBA54C70;
                        }
                        &.grass {
                            box-shadow: 0 0 20px #5FBD5870;
                        }
                        &.water {
                            box-shadow: 0 0 20px #539DDF70;
                        }
                        &.ground {
                            box-shadow: 0 0 20px #DA7C4D70;
                        }
                        &.rock {
                            box-shadow: 0 0 20px #C9BB8A70;
                        }
                        &.gear {
                            box-shadow: 0 0 20px #5695A370;
                        }
                        &.normal {
                            box-shadow: 0 0 20px #A0A29F70;
                        }
                        &.cloud {
                            box-shadow: 0 0 20px #80d4f770;
                        }
                        &.wrangle {
                            box-shadow: 0 0 20px #EE90E670;
                        }
                        &.cloth {
                            box-shadow: 0 0 20px #D3425F70;
                        }
                        &.groom {
                            box-shadow: 0 0 20px #59576170;
                        }
                        &.electric {
                            box-shadow: 0 0 20px #F2D94E70;
                        }
                        &.ghost {
                            box-shadow: 0 0 20px #5F6DBC70;
                        }
                        &.flying {
                            box-shadow: 0 0 20px #A1BBEC70;
                        }
                        &.ice {
                            box-shadow: 0 0 20px #75D0C170;
                        }
                        &.psychic {
                            box-shadow: 0 0 20px #FA858170;
                        }                          
                        &.uv {
                            box-shadow: 0 0 20px #51515370;
                        }
                        &.poison {
                            box-shadow: 0 0 20px #B763CF70;
                        } 
                        &.nuke {
                            box-shadow: 0 0 20px #fbb437;
                        } 
                    }
                }
                & p {
                    font-weight: 600;
                    font-size: 16px;
                    margin: 8px;
                }
                & .icon {
                    border-radius: 100%;
                    height: 100px;
                    width: 100px;
                    margin: auto;
                    transition: 200ms all;
                    & img {
                        height: 60%;
                        width: 60%;
                        margin: 20%;
                    }
                    &.python {
                        background: #3771a1;
                    }
                    &.bug {
                        background: #92BC2C;
                    }
                    &.dark {
                        background: #595761;
                    }
                    .electric {
                        background: #F2D94E;
                    }
                    &.fire {
                        background: #FBA54C;
                    }
                    &.grass {
                        background: #5FBD58;
                    }
                    &.water {
                        background: #539DDF;
                    }
                    &.ground {
                        background: #DA7C4D;
                    }
                    &.rock {
                        background: #C9BB8A;
                    }
                    &.gear {
                        background: #5695A3;
                    }
                    &.normal {
                        background: #A0A29F;
                    }
                    &.cloud {
                        background: #80d4f7;
                    }
                    &.wrangle {
                        background: #EE90E6;
                    }
                    &.cloth {
                        background: #D3425F;
                    }
                    &.groom {
                        background: #595761;
                    }
                    &.electric {
                        background: #F2D94E;
                    }
                    &.ghost {
                        background: #5F6DBC;
                    }
                    &.flying {
                        background: #A1BBEC;
                    }
                    &.ice {
                        background: #75D0C1;
                    }
                    &.psychic {
                        background: #FA8581;
                    }                          
                    &.uv {
                        background: #515153;
                    }
                    &.poison {
                        background: #b763cf; 
                    }
                    &.nuke {
                        background: #fbb437;
                    }                      
                    &.hda {
                        background: #c69e81;
                    }                      
                    &.blender {
                        background: #EB882B;
                    }                      
            }
        }
    }

`





export type iconName = "houdini" | "geometry" | "python" | "noise" | "volumes" | "pyro" | "growth" | "terrain" | "fluids" | "destruction" | "cloth" | "groom" | "abstract" | "organic" | "camera" | "lighting" | "rendering" | "uv" | "texturing" | "vex" | "ui" | "nuke" | "hda" | "blender"


interface RouteInfo {
    topic: string;
}

interface IHoudiniProps extends RouteComponentProps<RouteInfo> {
}


const Houdini: React.FC<IHoudiniProps> = (props) => {
    const { darkTheme, setDarkTheme, houdiniScrollPos, setHoudiniScrollPos } = useApp();

    const containerRef = useRef(null);

    const scrollToPos = (ref: React.MutableRefObject<any>, pos: number) => {
        ref.current.scrollTop = pos;
        console.log(ref.current, pos, "should scroll");
    }

    useLayoutEffect(() => {
        if (!!containerRef) scrollToPos(containerRef, houdiniScrollPos);
    }, []);


    const geometryRef = useRef(null);
    const pythonRef = useRef(null);
    const vexRef = useRef(null);
    const noiseRef = useRef(null);
    const volumesRef = useRef(null);
    const pyroRef = useRef(null);
    const growthRef = useRef(null);
    const terrainRef = useRef(null);
    const fluidsRef = useRef(null);
    const destructionRef = useRef(null);
    const clothRef = useRef(null);
    const groomRef = useRef(null);
    const abstractRef = useRef(null);
    const organicRef = useRef(null);
    const cameraRef = useRef(null);
    const lightingRef = useRef(null);
    const mantraRef = useRef(null);
    const uvRef = useRef(null);
    const texturingRef = useRef(null);
    const uiRef = useRef(null);
    const hdaRef = useRef(null);
    const nukeRef = useRef(null);
    const blenderRef = useRef(null);

    const executeScroll = (ref: React.MutableRefObject<any>) => {
        if (!!ref) ref.current.scrollIntoView({ behavior: "smooth" });
    };




    const setScrollPos = (ref: React.MutableRefObject<any>) => {
        if (!!ref) setHoudiniScrollPos(ref.current.scrollTop)
    }

    const handleScroll = debounce(() => {
        setScrollPos(containerRef);
    }, 40);



    return (
        <StyledHoudini ref={containerRef} onScroll={handleScroll} {...{ darkTheme }}>
            <div>
                <header>
                    <div className="wrap">
                        <img className="houdini" src={require("./../TypeIcons/swirl.svg")} alt="" />
                        <Link to="/houdini/landing">Houdini Notes</Link>
                        {/* <h1>Houdini Notes</h1> */}
                    </div>
                    <ReactToggle checked={darkTheme} defaultChecked={darkTheme} onChange={() => setDarkTheme(_ => !_)} />
                </header>
                <div>

                </div>


                <div className="wrapper">

                    {/* <div className="wrap" onClick={() => executeScroll(geometryRef)}>
                        <div className="icon normal">
                            <img src={require("./../TypeIcons/normal.svg")} />
                        </div>
                        <p>Geometry</p>
                    </div> */}

                    <div className="wrap" onClick={() => executeScroll(pythonRef)}>
                        <div className="icon python">
                            <img src={require("./../TypeIcons/python.svg")} />
                        </div>
                        <p>Python</p>
                    </div>

                    <div className="wrap" onClick={() => executeScroll(vexRef)}>
                        <div className="icon wrangle">
                            <img src={require("./../TypeIcons/wrangle.svg")} />
                        </div>
                        <p>VEX</p>
                    </div>
                    <div className="wrap" onClick={() => executeScroll(uiRef)}>
                        <div className="icon gear">
                            <img src={require("./../TypeIcons/gear.svg")} />
                        </div>
                        <p>UI Navigation</p>
                    </div>

                    <div className="wrap" onClick={() => executeScroll(noiseRef)}>
                        <div className="icon groom">
                            <img src={require("./../TypeIcons/noise.svg")} />
                        </div>
                        <p>Noise</p>
                    </div>

                    <div className="wrap" onClick={() => executeScroll(terrainRef)}>
                        <div className="icon ground">
                            <img src={require("./../TypeIcons/ground.svg")} />
                        </div>
                        <p>Terrain</p>
                    </div>

                    <div className="wrap" onClick={() => executeScroll(volumesRef)}>
                        <div className="icon cloud">
                            <img src={require("./../TypeIcons/cloud.svg")} />
                        </div>
                        <p>Volumes</p>
                    </div>


                    {/* <div className="wrap" onClick={() => executeScroll(growthRef)}>
                        <div className="icon grass">
                        <img src={require("./../TypeIcons/monstera.svg")} />
                        </div>
                        <p>Growth</p>
                    </div> */}



                    {/* <div className="wrap" onClick={() => executeScroll(fluidsRef)}>
                        <div className="icon water">
                        <img src={require("./../TypeIcons/drop.svg")} />
                        </div>
                        <p>Fluids</p>
                    </div> */}


                    {/* <div className="wrap" onClick={() => executeScroll(destructionRef)}>
                        <div className="icon rock">
                        <img src={require("./../TypeIcons/rock.svg")} />
                        </div>
                        <p>Destruction</p>
                    </div> */}

                    {/* <div className="wrap" onClick={() => executeScroll(clothRef)}>
                        <div className="icon cloth">
                        <img src={require("./../TypeIcons/drape.svg")} />
                        </div>
                        <p>Cloth</p>
                    </div> */}

                    {/* <div className="wrap" onClick={() => executeScroll(groomRef)}>
                        <div className="icon groom">
                        <img src={require("./../TypeIcons/groom.svg")} />
                        </div>
                        <p>Groom</p>
                    </div> */}

                    {/* 
                    <div className="wrap" onClick={() => executeScroll(abstractRef)}>
                    <div className="icon poison">
                    <img src={require("./../TypeIcons/abstract.svg")} />
                    </div>
                    <p>Abstract</p>
                </div> */}

                    {/* 
                    <div className="wrap" onClick={() => executeScroll(organicRef)}>
                    <div className="icon bug">
                    <img src={require("./../TypeIcons/bug.svg")} />
                    </div>
                    <p>Organic</p>
                </div> */}

                    <div className="wrap" onClick={() => executeScroll(cameraRef)}>
                        <div className="icon ghost">
                            <img src={require("./../TypeIcons/camera.svg")} />
                        </div>
                        <p>Camera</p>
                    </div>

                    {/* <div className="wrap" onClick={() => executeScroll(lightingRef)}>
                        <div className="icon electric">
                        <img src={require("./../TypeIcons/light.svg")} />
                        </div>
                        <p>Lighting</p>
                    </div> */}

                    <div className="wrap" onClick={() => executeScroll(mantraRef)}>
                        <div className="icon ice">
                            <img src={require("./../TypeIcons/reel.svg")} />
                        </div>
                        <p>Mantra</p>
                    </div>

                    <div className="wrap" onClick={() => executeScroll(pyroRef)}>
                        <div className="icon fire">
                            <img src={require("./../TypeIcons/fire.svg")} />
                        </div>
                        <p>Fire</p>
                    </div>
                    <div className="wrap" onClick={() => executeScroll(hdaRef)}>
                        <div className="icon hda">
                            <img src={require("./../TypeIcons/box.svg")} />
                        </div>
                        <p>HDA</p>
                    </div>
                    {/* <div className="wrap" onClick={() => executeScroll(hdaRef)}>
                        <div className="icon blender">
                            <img src={require("./../TypeIcons/blender.svg")} />
                        </div>
                        <p>Blender</p>
                    </div> */}
                    {/* <div className="wrap" onClick={() => executeScroll(nukeRef)}>
                        <div className="icon nuke">
                            <img src={require("./../TypeIcons/nuke.svg")} />
                        </div>
                        <p>Nuke</p>
                    </div> */}

                    {/* <div className="wrap" onClick={() => executeScroll(uvRef)}>
                        <div className="icon flying">
                        <img src={require("./../TypeIcons/checker.svg")} />
                        </div>
                        <p>UV</p>
                    </div> */}

                    {/* <div className="wrap" onClick={() => executeScroll(texturingRef)}>
                        <div className="icon psychic">
                        <img src={require("./../TypeIcons/palette.svg")} />
                        </div>
                        <p>Texturing</p>
                    </div> */}



                </div>

            </div>
            <div ref={pythonRef}>
                <Section round title="Python" icon="python" divider>
                    <PythonArticles />
                </Section>
            </div>
            {/* <div ref={geometryRef}>
                <Section round title="Geometry" icon="geometry">
                    <GeometryArticles />
                </Section>
            </div> */}
            <div ref={vexRef}>
                <Section round title="VEX" icon="vex" divider>
                    <VexArticles />
                </Section>
            </div>
            <div ref={uiRef}>
                <Section round title="UI Navigation" icon="ui" divider>
                    <UiArticles />
                </Section>
            </div>

            <div ref={noiseRef}>
                <Section round title="Noise" icon="noise" divider>
                    <NoiseArticles />
                </Section>
            </div>
            <div ref={terrainRef}>
                <Section round title="Terrain" icon="terrain" divider>
                    <TerrainArticles />
                </Section>
            </div>
            <div ref={volumesRef}>
                <Section round title="Volumes" icon="volumes" divider>
                    <VolumesArticles />
                </Section>
            </div>
            <div ref={mantraRef}>
                <Section round title="Mantra" icon="rendering" divider>
                    <MantraArticles />
                </Section>
            </div>
            <div ref={pyroRef}>
                <Section round title="Fire" icon="pyro" divider>
                    <PyroArticles />
                </Section>
            </div>
            <div ref={hdaRef}>
                <Section round title="HDA" icon="hda" divider>
                    <HDAArticles />
                </Section>
            </div>
            {/* <div ref={blenderRef}>
                <Section round title="Blender" icon="blender" divider>
                    <BlenderArticles />
                </Section>
            </div> */}
            {/* <div ref={nukeRef}>
                <Section round title="Nuke" icon="nuke" divider>
                    <NukeArticles />
                </Section>
            </div> */}
            {/* <div ref={growthRef}>
                <Section round title="Growth" icon="growth">
                
                </Section>
            </div> */}
            {/* <div ref={fluidsRef}>
                <Section round title="Fluids" icon="fluids">
                
                </Section>
            </div> */}
            {/* <div ref={destructionRef}>
                <Section round title="Destruction" icon="destruction">
                
                </Section>
            </div> */}
            {/* <div ref={clothRef}>
                <Section round title="Cloth" icon="cloth">
                
                </Section>
            </div> */}
            {/* <div ref={groomRef}>
                <Section round title="Groom" icon="groom">
                
                </Section>
            </div> */}
            {/* <div ref={abstractRef}>
                <Section round title="Abstract" icon="abstract">
                
                </Section>
            </div> */}
            {/* <div ref={organicRef}>
                <Section round title="Organic" icon="organic">
                
                </Section>
            </div> */}
            <div ref={cameraRef}>
                <Section round title="Camera" icon="camera">
                    <CameraArticles />
                </Section>
            </div>
            {/* <div ref={lightingRef}>
                <Section round title="Lighting" icon="lighting">
                
                </Section>
            </div> */}
            {/* <div ref={renderingRef}>
                <Section round title="Rendering" icon="rendering">
                
                </Section>
            </div> */}
            {/* <div ref={uvRef}>
                <Section round title="UV" icon="uv">
                
                </Section>
            </div> */}
            {/* <div ref={texturingRef}>
                <Section round title="Texturing" icon="texturing">
                
                </Section>
            </div> */}


        </StyledHoudini>
    )
};

export default withRouter(Houdini);