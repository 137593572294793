import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { TableComponent } from '../../TableComponent/TableComponent';
import { Section } from '../Section';

export const NukeShortcuts: React.FC = (props) => {
    return (
        <Page topic="nuke">
            <Lesson title="Nuke Shortcuts">
                <Section title="Node Graph" divider={false}>
                    <TableComponent
                        colA={[
                            "Connect Node to Viewer Input",
                            "Disable",
                            "Node Properties",
                            "Project Settings",
                            "Switch A and B inputs",
                            "Swap Place of Two Nodes"]}
                        colB={[
                            "1 or other number",
                            "D",
                            "Ctrl + double click",
                            "S",
                            "Shift + X",
                            "Ctrl + Shift + drag onto other node"]}
                    />



                </Section>
                <Section title="Create Nodes" divider={false}>
                    <TableComponent
                        colA={["Read Image",
                            "Color Correct",
                            "Roto",
                            "Transform"]}
                        colB={["R",
                            "C",
                            "O",
                            "T"]}
                    />

                </Section>
                <Section title="Viewer" divider={false}>
                    <TableComponent
                        colA={["Toggle Alpha",
                            "Fullscreen",
                            "Play",
                            "Stop",
                            "Play Backward",
                            "Select all points on Roto",
                            "Hide Roto Overlay",
                            "Move Transform anchor point",
                            "View channel dependencies"]}
                        colB={["A",
                            "Spacebar",
                            "L",
                            "K",
                            "J",
                            "Ctrl + A",
                            "Q",
                            "Ctrl + drag",
                            "Alt + E"]}
                    />

                </Section>
                <Section title="3D Viewer" divider={false}>
                    <TableComponent
                        colA={["Zoom out to see all in frame"]}
                        colB={["F"]}
                    />

                </Section>
            </Lesson>
        </Page>
    )
};


// ctrl+ alt to add dots on hue correct