import React from 'react'
import { Table } from 'semantic-ui-react'
import { useApp } from '../App';

interface ITable {
    headers?: string[];
    colA: string[];
    colB: string[];
    colC?: string[];
    colD?: string[];
    colE?: string[];
    colF?: string[];
    colG?: string[];
    colH?: string[];
}

export const TableComponent: React.FC<ITable> = ({ headers, colA, colB, colC, colD, colE, colF, colG, colH }: ITable) => {
    const { darkTheme } = useApp();
    return (
        <Table  celled inverted={darkTheme}>
            {!!headers && (
                <Table.Header>
                    <Table.Row>
                        {headers.map((_, i) => <Table.HeaderCell key={i}>{_}</Table.HeaderCell>)}
                    </Table.Row>
                </Table.Header>
            )}
            <Table.Body>
                {colA.map((_, i) => <React.Fragment key={i}>
                    <Table.Row style={{ fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;" }}>
                        <Table.Cell>{_}</Table.Cell>
                        <Table.Cell>{colB[i]}</Table.Cell>
                        {!!colC && <Table.Cell>{colC[i]}</Table.Cell>}
                        {!!colD && <Table.Cell>{colD[i]}</Table.Cell>}
                        {!!colE && <Table.Cell>{colE[i]}</Table.Cell>}
                        {!!colF && <Table.Cell>{colF[i]}</Table.Cell>}
                        {!!colG && <Table.Cell>{colG[i]}</Table.Cell>}
                        {!!colH && <Table.Cell>{colH[i]}</Table.Cell>}
                    </Table.Row>
                </React.Fragment>)}
            </Table.Body>
        </Table>
    )
};

{/* <Table size='small'>
<Table.Header>
    <Table.Row>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Notes</Table.HeaderCell>
    </Table.Row>
</Table.Header>

<Table.Body>
    <Table.Row>
        <Table.Cell>John</Table.Cell>
        <Table.Cell>Approved</Table.Cell>
        <Table.Cell>None</Table.Cell>
    </Table.Row>
    <Table.Row>
        <Table.Cell>Jamie</Table.Cell>
        <Table.Cell>Approved</Table.Cell>
        <Table.Cell>Requires call</Table.Cell>
    </Table.Row>
    <Table.Row>
        <Table.Cell>Jill</Table.Cell>
        <Table.Cell>Denied</Table.Cell>
        <Table.Cell>None</Table.Cell>
    </Table.Row>
</Table.Body>

<Table.Footer>
    <Table.Row>
        <Table.HeaderCell>3 People</Table.HeaderCell>
        <Table.HeaderCell>2 Approved</Table.HeaderCell>
        <Table.HeaderCell />
    </Table.Row>
</Table.Footer>
</Table> */}