import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension } from '../NoiseConfig';

export interface IPositionArguments {
    inputDimension: dimension;
    useFunction: boolean;
}

export const PositionArguments: React.FC<IPositionArguments> = ({useFunction, ...props}: IPositionArguments) => {

    const JSX: Record<dimension, any> = {
        "1D": <>
            <Vex.Code>{`(posx`}</Vex.Code>
        </>,
        "2D": <>

            <Vex.Code>{`(pos.x, pos.y`}</Vex.Code>
        </>,
        "3D": <>
            <Vex.Code>{`(${useFunction ? "P" : "v@P"} * freq + offset`}</Vex.Code>
        </>,
        "4D": <>
            <Vex.Code>{`(pos4D`}</Vex.Code>
        </>
    }

    return JSX[props.inputDimension]
};
