import React, { Component, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useApp } from "../App";
import { IThreeViewProps, ThreeView } from "./ThreeView";

export interface IThreeViewWithVisibilitySelectorProps extends Omit<IThreeViewProps, "modelVisibilities"> {
    modelNames: string[];
}

const Root = styled.div<{ darkTheme: boolean }>`
    width: calc(100% + 42px);
    margin-right: -21px;
    margin-left: -21px;
    margin-top: 28px;
    margin-bottom: 28px;
    padding: 24px 16px;
    box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
    border-radius: 12px;
    background: ${props => props.darkTheme ? "#1e1e1e" : "white"};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 728px) {
        border-radius: 0px;
      }
    & h2.interactive {
        font-family: 'Merriweather','Georgia',serif;
        margin-block-end: 20px;
        margin-block-start: 20px;
        margin-top: 0;
    }
    & p.load {
        color: white;
        text-align: center;
        font-family: "Montserrat";
        font-weight: 500 !important;
        font-size: 19px;
        background: #31aae2;
        padding: 8px 16px;
        width: fit-content;
        border-radius: 28px;
        &:hover {
            cursor: pointer;
        }
    }
    & .wrap {
        padding-top: 16px;
        text-align: center;
        font-weight: 600;
        display: flex;
        flex-wrap: wrap;

        & button {
            font-family: Lato;






            cursor: pointer !important;
            text-align: center !important;
            
            border: 1px solid ${props => props.darkTheme ? "rgb(176, 176, 176)" : "rgb(176, 176, 176)"} !important;
            background-color: transparent !important;
            outline: none !important;
            border-radius: 30px !important;
            color: ${props => props.darkTheme ? "white" : "rgb(34, 34, 34)"} !important;
            position: relative !important;
            padding: 8px 16px !important;
            line-height: 16px !important;



            margin: 0px 16px 8px 0px;













// .range-input-wrapper{
//     flex-grow: 1;
//     position: relative;
//     // outline: 1px solid #b0b0b0;
//     // border-radius: 8px;
//     // box-shadow: 0 0 0 1px #b0b0b0;
//     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
//     border: 1px solid #F7F7F7 !important;
// }
// .range-input-wrapper:focus-within {
//     box-shadow: 0 0 0 2px black;
//   }















            &.active {
                border: 1px solid ${props => props.darkTheme ? "white" : "black"} !important;
                box-shadow: 0 0 0 1px ${props => props.darkTheme ? "white" : "black"} !important;
                // color: #31aae2;
            }
        }
    }
`

export const ThreeViewWithVisibilitySelector: React.FC<IThreeViewWithVisibilitySelectorProps> = (props: IThreeViewWithVisibilitySelectorProps) => {
    const { darkTheme } = useApp();
    const [visibleModel, setVisibleModel] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [shouldLoad, setShouldLoad] = useState<boolean>(false);
    const modelVisibilities: boolean[] = props.modelNames.map((_, i) => i === visibleModel ? true : false);
    return (
        <Root {...{ darkTheme }}>
            {!!shouldLoad ?
                <>
                    <ThreeView {...{ modelVisibilities, setLoaded, ...props }} />
                    <div className="wrap">
                        {!!loaded && props.modelNames.map((_, i) => <button key={i} className={`${!!modelVisibilities[i] ? "active" : ""}`} onClick={() => setVisibleModel(i)}>{_}</button>)}
                    </div>
                </>
                : <>
                    <h2 className="interactive">Interactive Model</h2>
                    <p onClick={() => setShouldLoad(true)} className="load">Load 3D Model</p>
                </>
            }

        </Root>
    )
};

