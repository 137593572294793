import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';


export const PythonJSON: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="JSON">

                <Section title="Print formatted JSON in console" icon="houdini">
                    <Python>
                        {`
 def printJSON(object):
    import json
    try:
        json.dumps(object)
    except TypeError:
        print("Unable to serialize the object")
    else:
        print(json.dumps(object, sort_keys=True, indent=4))
 
printJSON()
                            `}
                    </Python>
                </Section>
                <Section title="Increase Console Lines" icon="houdini" divider>
                    <Words>The houdini console displays 256 lines at a time by default. You can increase it up to 65535.</Words>
                    <Words>In houdini.env:</Words>
                    <Python>
                        {`
HOUDINI_CONSOLE_LINES = 1024
                            `}
                    </Python>
                    <Words>This will help you preview JSON before writing it to a file.</Words>
                </Section>
            </Lesson>
        </Page >
    )
};

// data = {
//     "name": parm_template.name(),
//     "type": parm_template.type().name(),
//     "label": parm_template.label()
//     "parm_templates": []
// }

// geo = hou.node('/obj/grid1/mountain1').geometry()

// data = [point.position().y() for point in geo.points()]
// import json
// with open('C:/Users/default.LAPTOP-BDGQD293/JSON/rray.json', 'w') as outfile:
//     json.dump(data, outfile, indent=4)
