import React from 'react';
import { Lesson, Page, Words } from '../../Chapter/Chapter';
import { CCode } from '../../CodeBlock/CCode';
import { Section } from '../Section';

export const Snippets: React.FC = (props) => {
    return (
        <Page topic="vex">
            <Lesson title="Snippets">
                <Section title="" icon="houdini">
                    <Words strong>Quantize normalized values</Words>
                    <CCode>
                        {`string attribute = "Cd";
int steps = chi("steps");

function float Quantize01(float value, steps;) {
    return rint(value * steps)/steps;  
};

float val = point(0, attribute, @ptnum);
val = Quantize01(val, steps);

setpointattrib(0, attribute, @ptnum, val, "set");`}
                    </CCode>
                    <Words strong>Blend an attribute between two inputs</Words>
                    <CCode>
                        {`string attribute = "Cd";
float blend = chf("blend");

float first_input_val = point(0, attribute, @ptnum);
float second_input_val = point(1, attribute, @ptnum);

float val = lerp(first_input_val, second_input_val, blend);
setpointattrib(0, attribute, @ptnum, val, "set");`}
                    </CCode>
                    <Words strong>Crystal Pattern</Words>
                    <CCode>
                        {`float freq = chf("frequency");
vector offset = chv("offset");
float atten = chf("attenuation"); // set this to around 2
vector jitter = set(1, 1, 1);
vector rand_offset = {77.7,-77.7,77.7};

// write to variables
int seed; float f1, f2; vector pos1, pos2;

vnoise(v@P * freq + offset, jitter, seed, f1, f2, pos1, pos2);
float first_val = clamp(pow(f1, atten), 0, 1);

vnoise(v@P * freq + offset + rand_offset , jitter, seed, f1, f2, pos1, pos2); // plus rand offset for different result
float seecond_val = clamp(pow(f1, atten), 0, 1);

float a = clamp(first_val - seecond_val, 0, 1);
float b = clamp(seecond_val - first_val, 0, 1);

f@Cd = pow(a + b, 2);`}
                    </CCode>
                </Section>
            </Lesson>
        </Page >
    )
};

// create a sphere of points around a point

// int res = 2;

// for(int i = -res; i<= res; i++){
//     for(int j = -res; j<= res; j++){
//         for(int k = -res; k<= res; k++){
//         vector dir = normalize(set(i, j, k));
//         addpoint(0, dir);
//         }
//     }
// }



// int pts[] = primpoints(0,@primnum);
// vector pos;
// float y = 0;

// for(int i=1;i<len(pts);i++){
//    pos = point(0,"P",pts[i]);
//    y += clamp(vector(point(0,"P",pts[i-1])).y-pos.y,-1000000,0);
//    setpointattrib(0,"P",pts[i],pos+set(0,y,0));
// }


// // get number of points
// int num_pts = npoints(0); 

// // total reduction to collect
// float reduction = 0;

// for(int i = 0; i < num_pts; i++) {

//     // get current point pos
//     vector pos = point(0, 'P', i);

//     // reduce the y by collected reduction ( 0 to start with )
//     setpointattrib(0, "P", i, set(pos.x, pos.y - reduction, pos.z), "set");

//     // get position of next point
//     vector next_pos = point(0, 'P', i + 1);

//     // find difference between current and next pos
//     float vertical_difference = next_pos.y - pos.y;

//     // if next is upwards, add difference to reduction
//     if (sign(vertical_difference) > 0) reduction += vertical_difference;

// }



// this code kinda sucks



// float lowest = 9999;
// int num_pts = npoints(0); 

// // find lowest
// for(int i = 0; i < num_pts; i++) {
//     float noise = point(0, 'noise', i);
//     if (noise < lowest) lowest = noise;
// }

// adddetailattrib(0, "noise_min", lowest);

// // subtract lowest value from all
// for(int i = 0; i < num_pts; i++) {
//     float new_val = point(0, 'noise', i) - lowest;
//     setpointattrib(0, "noise", i, new_val, "set");
// }

// (sin(deg(($T - 0.5)*$PI))/2 + 0.5) * 0.2

// floor(@Time/5) start at 0, increment by 1 every 5 seconds


//padzero(4, max(($F - 1) * 6, 1))
//does this do it?
//https://houdinitricks.tumblr.com/post/112866939764/quicktip-frame-padding/

//SCRAP
// // in a detail wrangle

// // automatically add verticies
// int prim_count = nprimitives(0);
// for (int i=0; i < prim_count; i++) {
//     int points[] = primpoints(0, i);

//     for (int j=0; j < len(points); j++) {
//         addvertex(0, i, points[j]);
//     }
// }