import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { TableComponent } from '../../TableComponent/TableComponent';
import { Code } from '../../CodeBlock/CodeBlock';
import { CCode } from '../../CodeBlock/CCode';


export const VolumeFitFloat: React.FC = (props) => {
    return (
        <Page topic="volumes">
            <Lesson title="Fit Scalar Volume HDA">
                <Section title="" icon="houdini">
                    <ImageContainer>
                        <Img ratio={0.8} src="https://houdini-stuff.s3.amazonaws.com/Houdini/VOLUMES/fit_scalar_volume.jpg" />
                    </ImageContainer>
                </Section>
                <Section title="HDA" icon="hda" divider>
                    <Words>Takes a scalar volume or VDB and fits the range. Includes an output curve ramp.</Words>
                    <Words><Link text="fitscalarvolume.hdalc" url="https://github.com/keatonwilliamson/hda/raw/master/fitscalarvolume.hdalc" /></Words>
                </Section>
                <Section title="Main Functionality VEX" icon="vex" divider>
                    <CCode>{`float old_min = chf("old_min");
float old_max = chf("old_max");
float new_min = chf("new_min");
float new_max = chf("new_max");

float val = fit(f@_internal_volume_name, old_min, old_max, new_min, new_max);

// output ramp
float normalized_val = fit(val, new_min, new_max, 0, 1);
normalized_val = chramp("output_curve", normalized_val);
val = fit(normalized_val, 0, 1, new_min, new_max);

// set
f@_internal_volume_name = val;`}</CCode>
                </Section>
                <Section title="Volume Name Dropdown" icon="python" divider>
                    <Words>Creates an auto populated dropdown of volume names in a string parameter. In the parameter interface check <Code>Use Menu</Code> and select <Code>Replace (Field + Single Selection Menu)</Code>.</Words>
                    <Python>
                        {`# menu script
r = []
node = hou.pwd()
inputs = node.inputs()

if inputs and inputs[0]:
    geo = inputs[0].geometry()
    
    if geo:
        strings = geo.findPrimAttrib("name").strings()
        
        for s in strings:            
            r.extend ([s, s])
            
return r

                            `}
                    </Python>
                </Section>
            </Lesson>
        </Page >
    )
};
