import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension, distance, DistanceMetric, NoiseFunction } from '../NoiseConfig';

export interface INoiseComment {
    noise: NoiseFunction;
    inputDimension: dimension;
    outputDimension: dimension;
    periodic: boolean;
    tabs?: number;
    turbulent: boolean;
    distanceMetric: DistanceMetric;
    curl2D: boolean;
}
export const NoiseCommentString: (props: INoiseComment) => string = (props: INoiseComment) => {
    let output = (() => {
        if (props.noise == "Curl") return ""
        else return props.outputDimension == "3D" ? " ─ 3D output" : ""
    })()
    let input = props.inputDimension == "3D" ? "" : props.inputDimension;
    let periodic = (props.periodic && props.noise != "Curl") ? "Periodic " : "";
    let curl2D = (props.noise == "Curl" && props.curl2D && props.inputDimension != "2D") ? "2D" : "";
    let turbulent = (props.turbulent) ? "Turbulent " : ""
    let noise = (() => {
        if (props.noise == "Worley") return (props.distanceMetric != "Euclidian") ? `${props.distanceMetric} ${props.noise}` : `${props.noise}`
        else return props.noise;
    })()
    return `${turbulent} ${input}${curl2D} ${periodic} ${noise} Noise${output}`
}

export const NoiseComment: React.FC<INoiseComment> = ({ tabs, ...props }: INoiseComment) => {
    let comment = <>
        <Vex.Line {...{ tabs }}>
            {/* <Vex.Code comment>{`// ${input} ${periodic} ${turbulent}${noise} Noise${output}`}</Vex.Code> */}
            <Vex.Code comment>{`// ${NoiseCommentString(props)}`}</Vex.Code>
        </Vex.Line>
    </>
    return comment
};