import { IPythonSnippet } from "./Python";

interface IIO {
    node: string;
    name?: string;
}

//out
interface IOutput extends IIO {
    i?: number;
}

// in
interface IInput extends IIO {
    i?: number | "next";
}

export interface IConnectProps {
    out: IOutput;
    in: IInput;
    comment?: boolean;
}

export const Connect: IPythonSnippet<IConnectProps> = ({ comment = true, ...props }) => {

    const getComment = () => {
        if (comment === false) return "";
        let output = props.out.name ? props.out.name : props.out.node;
        let input = props.in.name ? props.in.name : props.in.node;
        return `# ${output} → ${input}`
    }

    const getConnection = () => {
        let outIdx: number = props.out.i ? props.out.i : 0;
        let inIdx: number | "next" = props.in.i ? props.in.i : 0;
        if (inIdx === 0) {
            if (outIdx === 0) return `${props.in.node}.setFirstInput(${props.out.node})`
            else return `${props.in.node}.setFirstInput(${props.out.node}, ${outIdx})`
        }
        else if (inIdx === "next") {
            if (outIdx === 0) return `${props.in.node}.setNextInput(${props.out.node})`
            else return `${props.in.node}.setNextInput(${props.out.node}, ${outIdx})`
        }
        else {
            if (outIdx === 0) return `${props.in.node}.setInput(${inIdx}, ${props.out.node})`
            else return `${props.in.node}.setInput(${inIdx}, ${props.out.node}, ${outIdx})`
        }
    }

    return `${getConnection()} ${getComment()}`
}