import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Javascript } from '../../CodeBlock/Javascript';

export const ListNodes: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="Get a JSON file of All Nodes">
                <ImageContainer>
                    <Img ratio={0.56} src="https://houdini-stuff.s3.amazonaws.com/Houdini/nodes_json.jpg" />
                </ImageContainer>
                <Section title="Command Line Tools" icon="houdini" divider={false}>
                    <Words>Run this in the command line tools. It will create a JSON file on your computer of all the nodes and parameters in Houdini </Words>
                    <Python>
                        {`
# windows
hython %HH%/python2.7libs/opnode_sum.py -p > result.json

# Mac, Linux (I haven't tested this)
hython $HH/python2.7libs/opnode_sum.py > result.json
                            `}
                    </Python>
                </Section>
                <Section title="Windows path" icon="houdini" divider={false}>
                    <Words>For the command to work in Windows - Add to the PATH variable in houdini.env</Words>
                    <Python>
                        {`
PATH = "$HFS/bin"
                            `}
                    </Python>
                </Section>

                <Section title="Working with the Data in Javascript" icon="houdini" divider={false}>
                    <Words>Javascript queries to get interesting parts of the data</Words>
                    <Javascript>
                        {`
import * as input from './path_to.json';

// array of sop names
Object.keys(input.Nodes.Sop);

// array of objects {"name": "label"}
Object.values(input.Nodes.Sop).map(({name, label}) => ({[name]: label}));
                            `}
                    </Javascript>
                </Section>

                <Words><strong>References:</strong></Words>
                <Words><Link text="Get Houdini node list" url="https://qiita.com/kit2cuz/items/01283e419b5b0e37ff09" /> ─ Qiita</Words>
                <Words><Link text="Python command to list all nodes in houdini?" url="https://www.sidefx.com/forum/topic/18448/?page=1#post-254872" /> ─ SideFX Forums</Words>
                <Words><Link text="Command-Line Scripting" url="https://www.sidefx.com/docs/houdini/hom/commandline.html" /> ─ SideFX Docs</Words>
            </Lesson>
        </Page >
    )
};
