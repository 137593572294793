import React, { useState, useEffect, useRef } from 'react';
import { useReducer } from 'react';
import styled from 'styled-components'
import { NumericLiteral } from 'typescript';
import { useApp } from '../App';
// import { usePage } from '../Chapter/Chapter';

const Div = styled.div<Pick<IResponsiveImage, "src" | "ratio" | "darkTheme">>`
    position: relative;
    max-width: 100%;
    background: url(${props => props.src});
    width: 100%;
    border: 1px solid ${props => props.darkTheme ? "#ffffff33" : "transparent"};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px;
    margin-bottom: 12px;
    & div {
        padding-bottom: ${props => props.ratio * 100}%;
    }
`

interface IResponsiveImage {
    src: string;
    darkTheme?: boolean;
    ratio: number;
};

export const ResponsiveImage: React.FC<IResponsiveImage> = ({ src, ratio}: IResponsiveImage) => {
    const { darkTheme } = useApp();
    return (
        <Div {...{src, ratio, darkTheme}}>
            <div />
        </Div>
    )
};
