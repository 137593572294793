import React from 'react'
import styled from 'styled-components'
import { IScriptSnapshot } from 'typescript'
import { ResponsiveImage } from '../ResponsiveImage/ResponsiveImage'
// import CodeTest from './CodeTest.png'
// import TRexExploded from './Week1/TRexExploded.png'
// import TRexNotExploded from './Week1/TRexNotExploded.png'
// import ThreeWays from './Week1/ThreeWays.png'
// import PeakDeformerNodeNetwork from './Week1/PeakDeformerNodeNetwork.png'
// import WranglePeak from './Week1/WranglePeak.png'
// import RexDent from './Week1/RexDent.png'
// import SmoothPredator from './Week1/SmoothPredator.png'

// import ExplodedAvocado from './Week1/SmoothPredator.png'


// type Source = "CodeTest" | "TRexExploded" | "TRexNotExploded" | "ThreeWays" | "PeakDeformerNodeNetwork" | "WranglePeak"
//     | "RexDent"
//     | "SmoothPredator"


// const Sources: Record<Source, string> = {
//     CodeTest: CodeTest,
//     TRexExploded: TRexExploded,
//     TRexNotExploded: TRexNotExploded,
//     ThreeWays: ThreeWays,
//     PeakDeformerNodeNetwork: PeakDeformerNodeNetwork,
//     WranglePeak: WranglePeak,
//     RexDent: RexDent,
//     SmoothPredator: SmoothPredator
// };

interface IImage {
    // src: Source;
    src: string;
    ratio?: number;
};

export const Img: React.FC<IImage> = ({ src, ratio = 0.67 }: IImage) => {
    return (< ResponsiveImage
        // src={Sources[src]}
        src={src}
        ratio={ratio} />
    )
};




const StyledImageContainer = styled.div<IImageContainer>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 16px;
`
interface IImageContainer {
    children: any;
};

export const ImageContainer: React.FC<IImageContainer> = (props: IImageContainer) => {
    return (
        <StyledImageContainer {...props}>
            {props.children}
        </StyledImageContainer>
    )
};


