import React from 'react';
import { StaticReadUsage } from 'three';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension, distance, DistanceMetric, NoiseFunction } from '../NoiseConfig';
import { Amplitude } from './Amplitude';
import { CellularArguments } from './CellularArguments';
import { PeriodArguments } from './PeriodArguments';
import { PositionArguments } from './PositionArguments';
import { TurbRoughAttenArguments } from './TurbRoughAtten';

export interface INoiseVEXFunction {
    inputDimension: dimension;
    outputDimension: dimension;
    tabs?: number;
    fBm: boolean;
    periodic: boolean;
    distanceMetric: DistanceMetric;
    noise: NoiseFunction;
    curlSimplex: boolean;
    curl2D: boolean;
    turbulent: boolean;
    distance: distance;
    useFunction: boolean;
}

export const NoiseVEXFunction: React.FC<INoiseVEXFunction> = ({ tabs, ...props }: INoiseVEXFunction) => {

    const outputLookup: Record<dimension, string> = {
        "1D": "float",
        "2D": "",
        "3D": "vector",
        "4D": "",
    }

    const nval = <>
        {props.fBm ?
            (<>
                < Vex.Code type>{`${outputLookup[props.outputDimension]} `}</Vex.Code>
                < Vex.Code >{`_nval = `}</Vex.Code>
            </>
            ) : (<>
                < Vex.Code type>{`${outputLookup[props.outputDimension]} `}</Vex.Code>
                < Vex.Code >{`nval = `}</Vex.Code>
            </>
            )}
    </>

    const NoiseFunction: Record<NoiseFunction, any> = {
        "Perlin": <>
            <Vex.Code function>{props.periodic ? `pnoise` : `noise`}</Vex.Code>
            <PositionArguments inputDimension={props.inputDimension} useFunction={props.useFunction}/>
            {props.periodic && <PeriodArguments inputDimension={props.inputDimension} fBm={props.fBm}/>}
            <Vex.Code>{`)`}</Vex.Code>
        </>,
        "Simplex": <>
            <Vex.Code function>{props.periodic ? `pxnoise` : `xnoise`}</Vex.Code>
            <PositionArguments inputDimension={props.inputDimension} useFunction={props.useFunction}/>
            {props.periodic && <PeriodArguments inputDimension={props.inputDimension} fBm={props.fBm}/>}
            <Vex.Code>{`)`}</Vex.Code>
        </>,
        "Original Perlin": <>
            <Vex.Code function>{`onoise`}</Vex.Code>
            <PositionArguments inputDimension={props.inputDimension} useFunction={props.useFunction}/>
            {props.periodic && <PeriodArguments inputDimension={props.inputDimension} fBm={props.fBm}/>}
            {props.turbulent && TurbRoughAttenArguments}
            <Vex.Code>{`)`}</Vex.Code>
        </>,
        "Sparse Convolution": <>
            <Vex.Code function>{`snoise`}</Vex.Code>
            <PositionArguments inputDimension={props.inputDimension} useFunction={props.useFunction}/>
            {props.periodic && <PeriodArguments inputDimension={props.inputDimension} fBm={props.fBm}/>}
            {props.turbulent && TurbRoughAttenArguments}
            <Vex.Code>{`)`}</Vex.Code>
        </>,
        "Alligator": <>
            <Vex.Code function>{`anoise`}</Vex.Code>
            <PositionArguments inputDimension={props.inputDimension} useFunction={props.useFunction}/>
            {props.periodic && <PeriodArguments inputDimension={props.inputDimension} fBm={props.fBm}/>}
            {props.turbulent && TurbRoughAttenArguments}
            <Vex.Code>{`)`}</Vex.Code>
        </>,
        "Worley": <>
            <Vex.Code>{`${({
                "F1": `f1`,
                "F2-F1": `(f2 - f1)`,
            } as Record<distance, string>)[props.distance]}`}</Vex.Code>
        </>,
        "Flow": <>
            <Vex.Code function>{props.periodic ? `flowpnoise` : `flownoise`}</Vex.Code>
            <PositionArguments inputDimension={props.inputDimension} useFunction={props.useFunction}/>
            {props.periodic && <PeriodArguments inputDimension={props.inputDimension} fBm={props.fBm}/>}
            <Vex.Code>{`, flow`}</Vex.Code>
            <Vex.Code>{`)`}</Vex.Code>
        </>,
        "Voronoi": <>
            <Vex.Code>{`${({
                "F1": `f1`,
                "F2-F1": `(f2 - f1)`,
            } as Record<distance, string>)[props.distance]}`}</Vex.Code>
        </>,
        "Curl": <>
            <Vex.Code function>{`${(() => {
                if (props.curl2D) {
                    if (props.curlSimplex) return "curlxnoise2d";
                    else return "curlnoise2d"
                }
                else {
                    if (props.curlSimplex) return "curlxnoise";
                    else return "curlnoise"
                }
            })()}`}</Vex.Code>
            <PositionArguments inputDimension={props.inputDimension} useFunction={props.useFunction}/>
            <Vex.Code>{`)`}</Vex.Code>
        </>,
    }

    return (
        <Vex.Line  {...{ tabs }}>
            {nval}
            {NoiseFunction[props.noise]}
            {/* <PositionArguments inputDimension={props.inputDimension} /> */}
            <Amplitude outputDimension={props.outputDimension} fBm={props.fBm}/>
        </Vex.Line>
    )
};