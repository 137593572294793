import React from 'react';
import styled, { CSSObject } from 'styled-components'
import { IVexChannel, VexChannel } from './VexChannel';
import { IVexCode, VexCode } from './VexCode';
import { IVexLine, VexLine } from './VexLine';
import { IVexLoop, VexLoop } from './VexLoop';
import { IVexTurbulenceLoop, VexTurbulenceLoop } from './VexTurbulenceLoop';


// export const {
//     VEX_type,
//     VEX_comment,
//     VEX_string,
//     VEX_func,
//     VEX_white
// } = {
//     VEX_type: "#ED77ED",
//     VEX_comment: "#EDED77",
//     VEX_string: "#66CC66",
//     VEX_func: "#66CCCC",
//     VEX_white: "#fff"
// }



interface IVexComposition {
    Line: React.FC<IVexLine>;
    Code: React.FC<IVexCode>;
    Channel: React.FC<IVexChannel>;
    Loop: React.FC<IVexLoop>;
    TurbulenceLoop: React.FC<IVexTurbulenceLoop>;
}

const StyledVex = styled.p<{ styles?: CSSObject }>`
    
    background-color: #1e1e1e;
    padding: 12px;
    border-radius: 12px;
    font-family: 'Inconsolata', monospace;
    margin-right: -21px;
    margin-left: -21px;
    margin-bottom: 28px;
    @media (max-width: 728px) {
        border-radius: 0px;
    }
    ${props => props.styles};
`

interface IVex {
    children: any;
    styles?: CSSObject;
}

const Vex: React.FC<IVex> & IVexComposition = ({ styles, ...props }: IVex) => {
    return (
        <StyledVex styles={styles}>
            {props.children}
        </StyledVex>
    )
};


Vex.Code = VexCode;
Vex.Line = VexLine;
Vex.Channel = VexChannel;
Vex.Loop = VexLoop;
Vex.TurbulenceLoop = VexTurbulenceLoop;

export { Vex };
