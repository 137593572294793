import React, { Component, useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import { Button, Icon, Loader, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { useApp } from "../App";
import { string } from "../CodeBlock/CodeBlock";
import { CheckboxSingle } from "../Form/CheckboxSingle";
import { DropdownSingle, DropdownSingleOption } from "../Form/DropdownSingle";
import { SliderSingle } from "../Form/SliderSingle";

export interface ISpritesProps {

}

const Root = styled.div<{ darkTheme: boolean, xOffset: number; yOffset: number; }>`

    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;

    background: white;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(512px, 1fr));

    box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
    overflow: hidden;
    border-radius: 12px;

    @media (max-width: 728px) {
        border-radius: 0px;
    }
    & .presets {
        position: absolute;
        top: 12px;
        left: 12px;
    }
    & .sprite_container {

        padding: 24px 16px;
        box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        & .loader_wrap {
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 512px;
            width: 512px;
            & .ui.loader:after {
                border-color: #31aae2 transparent transparent !important;
            }  
        }
        & .sprite {
            overflow: hidden;
            position: relative;
            display: inline-block;
            height: 512px;
            width: 512px;
            border-radius: 12px;
            transform: scale(0.75);
            margin: auto;
            color: black;
    
            & img {
                position: absolute;
                top: -${props => props.yOffset}px;
                left: -${props => props.xOffset}px;
                filter: brightness(1.1);
                &.back {
                    z-index: -999;
                    visibility: hidden;
                }
            }
        }
    }



    & .controls {
        background: #3a3a3a;
        // border-radius: 8px;
        padding: 1em 1em 0.25em;
        // margin-bottom: 16px;
        // display: flex;
        // justify-content: center;
        width: 100%;
        & .wrap {
            margin: auto;
            max-width: 700px;

        }
        & .header {
            color: white;
            padding: 8px 8px 0px;
            font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
            // text-transform: uppercase;
            font-size: 21px;
            margin-bottom: 16px !important;
        }
        & .grid {
            display: grid;
            grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
            grid-gap: 1em;
            margin-bottom: 1em;
            & .folder {
                flex-grow: 1;
                padding: 8px 16px 24px;
            }

        }
        & .border {
            border: 1px solid #ffffff80;
            border-radius: 8px;
        }

    }
`
const StyledPresetContainer = styled.div<{}>`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(512px, 1fr));
    width: inherit;
    & .card {
            overflow: hidden;
            position: relative;
            display: inline-block;
            height: 512px;
            width: 512px;
            border-radius: 12px;
            transform: scale(0.75);
            margin: auto;
            box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
            transition: 200ms all;
            border: 3px solid #00000000;
            &:hover {
                cursor: pointer;
                transform: scale(0.8);
            }
            &.selected {
                border: 3px solid #31aae2;
                box-shadow: #31aae2 0px 5px 15px;
            }
    }
`


type ITurbulentNoiseFunction =
    "Perlin" |
    "Original Perlin" |
    "Sparse Convolution" |
    "Alligator" |
    "Worley" |
    "Voronoi";

type DistanceMetric = "Euclidian" | "Manhattan" | "Chebyshev";

type distance = "F1" | "F2-F1";

type jitter = 0 | 1 | 2;

export interface ITurbulentSpritesState {
    // noise
    noise: ITurbulentNoiseFunction;
    distanceMetric: DistanceMetric;
    distance: distance;
    inverted: boolean;

    // params
    turbulence: number;
    roughness: number;
    attenuation: number;
    jitter: jitter;
}

const initialState: ITurbulentSpritesState = {
    noise: "Perlin",
    distanceMetric: "Euclidian",
    distance: "F1",
    inverted: false,
    turbulence: 3,
    roughness: 2,
    attenuation: 2,
    jitter: 2
};


type Action =
    | { type: 'NOISE', payload: { noise: ITurbulentNoiseFunction } }
    | { type: 'DISTANCE_METRIC', payload: { distanceMetric: DistanceMetric } }
    | { type: 'DISTANCE', payload: { distance: distance } }
    | { type: 'JITTER', payload: { jitter: jitter } }
    | { type: 'INVERTED', payload: { inverted: boolean } }
    | { type: 'TURBULENCE', payload: { turbulence: number } }
    | { type: 'ROUGHNESS', payload: { roughness: number } }
    | { type: 'ATTENUATION', payload: { attenuation: number } }
    | { type: 'SET_STATE', payload: { state: ITurbulentSpritesState } }

interface IDropdownLookups {
    noise: ITurbulentNoiseFunction[];
    distanceMetric: DistanceMetric[];
    distance: distance[];
}

const noiseOptions: DropdownSingleOption[] = [{ name: "Perlin" }, { name: "Original Perlin" }, { name: "Sparse Convolution" }, { name: "Alligator" }, { name: "Worley" }, { name: "Voronoi" }]

const distanceMetricOptions: DropdownSingleOption[] = [{ name: "Euclidian" }, { name: "Manhattan" }, { name: "Chebyshev" }];

const distanceOptions: DropdownSingleOption[] = [{ name: "F1" }, { name: "F2-F1" }];



type INoiseName =
    "Perlin" |
    "Original Perlin" |
    "Sparse Convolution" |
    "Alligator" |
    "AlligatorInverted" |
    "WorleyF1" |
    "WorleyF2-F1" |
    "WorleyF1Inverted" |
    "WorleyF2-F1Inverted" |
    "ManhattanF1" |
    "ManhattanF2-F1" |
    "ManhattanF1Inverted" |
    "ManhattanF2-F1Inverted" |
    "ChebyshevF1" |
    "ChebyshevF2-F1" |
    "ChebyshevF1Inverted" |
    "ChebyshevF2-F1Inverted" |
    "VoronoiF1Jitter00" |
    "VoronoiF1Jitter05" |
    "VoronoiF1Jitter10" |
    "VoronoiF2-F1Jitter00" |
    "VoronoiF2-F1Jitter05" |
    "VoronoiF2-F1Jitter10";

interface spriteURL {
    noiseName: INoiseName;
    remoteURL: string;
    localURL: string;
    loaded: boolean;
}

const initialSpriteURLs: spriteURL[] = [
    {
        noiseName: "Perlin",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/Perlin.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "Original Perlin",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/OriginalPerlin.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "Sparse Convolution",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/SparseConvolution.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "Alligator",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/Alligator.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "AlligatorInverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/AlligatorInverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "WorleyF1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/WorleyF1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "WorleyF2-F1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/WorleyF2-F1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "WorleyF1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/WorleyF1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "WorleyF2-F1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/WorleyF2-F1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ManhattanF1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ManhattanF1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ManhattanF2-F1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ManhattanF2-F1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ManhattanF1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ManhattanF1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ManhattanF2-F1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ManhattanF2-F1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ChebyshevF1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ChebyshevF1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ChebyshevF2-F1",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ChebyshevF2-F1.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ChebyshevF1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ChebyshevF1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "ChebyshevF2-F1Inverted",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/ChebyshevF2-F1Inverted.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "VoronoiF1Jitter00",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/VoronoiF1Jitter00.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "VoronoiF1Jitter05",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/VoronoiF1Jitter05.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "VoronoiF1Jitter10",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/VoronoiF1Jitter10.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "VoronoiF2-F1Jitter00",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/VoronoiF2-F1Jitter00.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "VoronoiF2-F1Jitter05",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/VoronoiF2-F1Jitter05.jpg",
        localURL: "",
        loaded: false
    },
    {
        noiseName: "VoronoiF2-F1Jitter10",
        remoteURL: "https://houdini-stuff.s3.amazonaws.com/Houdini/Sprites/Turbulent/VoronoiF2-F1Jitter10.jpg",
        localURL: "",
        loaded: false
    },
]



const getLocalURL: (remoteURL: string) => Promise<string> = async (remoteURL) => {
    if (!!remoteURL) {
        const response = await fetch(remoteURL);
        if (response.ok) {
            console.log(response)
            const blob = await response.blob();
            const localURL = URL.createObjectURL(blob);
            return localURL;
        }
        else return "";
    }
    else return "";
}


const getOffset: (turb: number, rough: number, atten: number) => { x: number, y: number } = (turb, rough, atten) => {
    const idx = ((turb * 36) + (rough * 6) + atten);
    const tileXSize = 512;
    const tileYSize = 512;
    const rows = 16;
    const columns = 16;
    const xOffset = (idx % columns) * tileXSize;
    const yOffset = (Math.floor(idx / columns)) * tileYSize;
    return { x: xOffset, y: yOffset };
}


const reducer: React.Reducer<ITurbulentSpritesState, Action> = (state: ITurbulentSpritesState, action: Action): ITurbulentSpritesState => {
    switch (action.type) {
        case 'NOISE':
            return { ...state, noise: action.payload.noise };
        case 'DISTANCE_METRIC':
            return { ...state, distanceMetric: action.payload.distanceMetric };
        case 'DISTANCE':
            return { ...state, distance: action.payload.distance };
        case 'JITTER':
            return { ...state, jitter: action.payload.jitter };
        case 'INVERTED':
            return { ...state, inverted: action.payload.inverted };
        case 'TURBULENCE':
            return { ...state, turbulence: action.payload.turbulence };
        case 'ROUGHNESS':
            return { ...state, roughness: action.payload.roughness };
        case 'ATTENUATION':
            return { ...state, attenuation: action.payload.attenuation };
        case 'SET_STATE':
            return { ...action.payload.state };

        default:
            return state;
        //throw new Error();
    }
}

const presetIMG = (state: ITurbulentSpritesState, spriteURLs: spriteURL[]) => {
    const offset = getOffset(state.turbulence || 0, state.roughness || 0, state.attenuation || 0);
    const style = { top: -offset.y, left: -offset.x, position: 'absolute' as 'absolute' }
    return <img style={style} className="front" src={getSpriteSheetURLFromState(state, spriteURLs)} />

}

const getNoiseNameFromState: (state: ITurbulentSpritesState) => INoiseName = (state) => {
    // let state: ITurbulentSpritesState = { ...s }
    return {
        "Perlin": "Perlin",
        "Original Perlin": "Original Perlin",
        "Sparse Convolution": "Sparse Convolution",
        "Alligator": (state.inverted ? "AlligatorInverted" : "Alligator"),
        "Worley": {
            "Euclidian": {
                "F1": (state.inverted ? "WorleyF1Inverted" : "WorleyF1"),
                "F2-F1": (state.inverted ? "WorleyF2-F1Inverted" : "WorleyF2-F1")
            }[state.distance],
            "Manhattan": {
                "F1": (state.inverted ? "ManhattanF1Inverted" : "ManhattanF1"),
                "F2-F1": (state.inverted ? "ManhattanF2-F1Inverted" : "ManhattanF2-F1")
            }[state.distance],
            "Chebyshev": {
                "F1": (state.inverted ? "ChebyshevF1Inverted" : "ChebyshevF1"),
                "F2-F1": (state.inverted ? "ChebyshevF2-F1Inverted" : "ChebyshevF2-F1")
            }[state.distance]
        }[state.distanceMetric],
        "Voronoi": {
            0: {
                "F1": "VoronoiF1Jitter00",
                "F2-F1": "VoronoiF2-F1Jitter00"
            }[state.distance],
            1: {
                "F1": "VoronoiF1Jitter05",
                "F2-F1": "VoronoiF2-F1Jitter05"
            }[state.distance],
            2: {
                "F1": "VoronoiF1Jitter10",
                "F2-F1": "VoronoiF2-F1Jitter10"
            }[state.distance]
        }[state.jitter],
    }[state.noise] as INoiseName
}


const getSpriteSheetURLFromState: (state: ITurbulentSpritesState, spriteURLs: spriteURL[]) => string = (state, spriteURLs) => {
    const noiseName = getNoiseNameFromState(state);
    if (!!noiseName) {
        const obj = spriteURLs.find(_ => _.noiseName == noiseName)
        if (!!obj) return obj.localURL;
        else return ""
    }
    else return ""
}

const presets: ITurbulentSpritesState[] = [
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Perlin",
        roughness: 3,
        turbulence: 4,
        jitter: 2,
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Original Perlin",
        roughness: 2,
        turbulence: 5,
        jitter: 2,
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Sparse Convolution",
        roughness: 2,
        turbulence: 5,
        jitter: 2,
    },
    {
        attenuation: 5,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Alligator",
        roughness: 3,
        turbulence: 5,
        jitter: 2,
    },
    {
        attenuation: 2,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Alligator",
        roughness: 2,
        turbulence: 4,
        jitter: 2,
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: true,
        noise: "Alligator",
        roughness: 3,
        turbulence: 5,
        jitter: 2,
    },
    {
        attenuation: 1,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Alligator",
        roughness: 3,
        turbulence: 4,
        jitter: 2,
    },
    {
        attenuation: 5,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 3,
        jitter: 2,
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: true,
        noise: "Worley",
        roughness: 2,
        turbulence: 5,
        jitter: 2,
    },
    {
        attenuation: 1,
        distance: "F2-F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 3,
        jitter: 2,
    },
    {
        attenuation: 2,
        distance: "F2-F1",
        distanceMetric: "Euclidian",
        inverted: true,
        noise: "Worley",
        roughness: 2,
        turbulence: 4,
        jitter: 2,
    },
    {
        attenuation: 3,
        distance: "F2-F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 3,
        jitter: 2,
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Euclidian",
        inverted: false,
        noise: "Voronoi",
        roughness: 2,
        turbulence: 4,
        jitter: 2,
    },
    {
        attenuation: 2,
        distance: "F1",
        distanceMetric: "Manhattan",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 4,
        jitter: 2,
    },
    {
        attenuation: 4,
        distance: "F2-F1",
        distanceMetric: "Chebyshev",
        inverted: false,
        noise: "Worley",
        roughness: 2,
        turbulence: 5,
        jitter: 2,
    },
    {
        attenuation: 5,
        distance: "F1",
        distanceMetric: "Manhattan",
        inverted: true,
        noise: "Worley",
        roughness: 2,
        turbulence: 5,
        jitter: 2,
    },
    {
        attenuation: 3,
        distance: "F1",
        distanceMetric: "Chebyshev",
        inverted: true,
        noise: "Worley",
        roughness: 2,
        turbulence: 4,
        jitter: 2,
    },
    {
        attenuation: 2,
        distance: "F2-F1",
        distanceMetric: "Chebyshev",
        inverted: true,
        noise: "Worley",
        roughness: 3,
        turbulence: 5,
        jitter: 2,
    }

]

export const TurbulentSprites: React.FC<ISpritesProps> = (props: ISpritesProps) => {
    const { darkTheme } = useApp();

    const [state, dispatch] = useReducer<React.Reducer<ITurbulentSpritesState, Action>>(reducer, initialState);
    const [offset, setOffset] = useState<number[]>([0, 0]);

    const [openPresetsModal, setOpenPresetsModal] = React.useState(false)
    const [selectedPreset, setSelectedPreset] = React.useState<ITurbulentSpritesState | null>(null)
    const [spriteURLs, setSpriteURLs] = React.useState<spriteURL[]>(initialSpriteURLs)

    const modal = <>
        <Modal
            size="fullscreen"
            open={openPresetsModal}
            onClose={() => setOpenPresetsModal(false)}
            onOpen={() => {
                setSelectedPreset(null)
                setOpenPresetsModal(true)
            }}
            trigger={<Button>Presets</Button>}
        >
            <Modal.Header>Presets</Modal.Header>
            <Modal.Content image scrolling style={{ flexDirection: "column", alignItems: "center" }}  >

                <StyledPresetContainer>

                    {presets.map(s => <>
                        <div
                            className={`card ${s == selectedPreset ? "selected" : ""}`}
                            onClick={() => {
                                if (selectedPreset != s) setSelectedPreset(s)
                                else setSelectedPreset(null);
                            }}>
                            {presetIMG(s, spriteURLs)}
                        </div>
                    </>)}
                </StyledPresetContainer>

                {/* </div> */}

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    if (!!selectedPreset) {
                        dispatch({ type: "SET_STATE", payload: { state: selectedPreset } });
                    }
                    setOpenPresetsModal(false)
                }} primary>
                    {!selectedPreset ? "Back" : "Use"} <Icon name='chevron right' />
                </Button>
            </Modal.Actions>
        </Modal>
    </>;





    useEffect(() => {

        async function getURLS(spriteURLs: spriteURL[]): Promise<spriteURL[]> {
            let _spriteURLs = [...spriteURLs];
            _spriteURLs = await Promise.all(_spriteURLs.map(async (_) => {
                let obj: spriteURL = { ..._ }
                const localURL = await getLocalURL(_.remoteURL);
                if (!!localURL) {
                    obj.localURL = localURL;
                }
                obj.loaded = true;
                setSpriteURLs(s => s.map(o => o.noiseName == _.noiseName ? obj : o))
                return obj
            }));
            return _spriteURLs
        }

        getURLS(spriteURLs);

    }, [])


    useEffect(() => {
        const offset = getOffset(state.turbulence, state.roughness, state.attenuation);
        setOffset([offset.x, offset.y])
    }, [state])

    const Lookups: IDropdownLookups = {
        noise: ["Perlin", "Original Perlin", "Sparse Convolution", "Alligator", "Worley", "Voronoi"],
        distanceMetric: ["Euclidian", "Manhattan", "Chebyshev"],
        distance: ["F1", "F2-F1"]
    }


    const handleDropdownChange = (id: string, value: number) => {
        if (id === "noise") dispatch({ type: "NOISE", payload: { noise: Lookups.noise[value] } });
        else if (id === "distanceMetric") dispatch({ type: "DISTANCE_METRIC", payload: { distanceMetric: Lookups.distanceMetric[value] } });
        else if (id === "distance") dispatch({ type: "DISTANCE", payload: { distance: Lookups.distance[value] } });
    }
    const handleSliderChange = (id: string, value: number) => {
        if (id === "turbulence") dispatch({ type: "TURBULENCE", payload: { turbulence: value } });
        if (id === "roughness") dispatch({ type: "ROUGHNESS", payload: { roughness: value } });
        if (id === "attenuation") dispatch({ type: "ATTENUATION", payload: { attenuation: value } });
        if (id === "jitter") dispatch({ type: "JITTER", payload: { jitter: value as jitter } });
    }
    const handleCheckboxChange = (id: string, value: boolean) => {
        if (id === "inverted") dispatch({ type: "INVERTED", payload: { inverted: value } });
    }


    console.log(state)
    return (
        <Root xOffset={offset[0]} yOffset={offset[1]} {...{ darkTheme, }}>
            <div className="sprite_container">

                <div className="presets">
                    {modal}
                </div>

                {spriteURLs.every(_ => _.loaded) ?
                    <div className="sprite">
                        <img className="front" src={getSpriteSheetURLFromState(state, spriteURLs)} />
                    </div>
                    :
                    <div className="loader_wrap">
                        <div className="percent"></div>
                        <Loader size='massive' active inline='centered'>Loading {Math.round(spriteURLs.filter(_ => _.loaded).length / spriteURLs.length * 100)}%</Loader >
                    </div>
                }

            </div>

            <div className="controls">
                <div className="wrap">
                    <div className="grid">
                        <div className="folder border">
                            <SliderSingle darkTheme="dark" id="turbulence" value={state.turbulence} setValue={handleSliderChange} label={`Turbulence: ${state.turbulence}`} min={0} max={5} />
                            <SliderSingle darkTheme="dark" id="roughness" value={state.roughness} setValue={handleSliderChange} label={`Roughness: ${(state.roughness / 5).toFixed(1)}`} min={0} max={5} />
                            <SliderSingle darkTheme="dark" id="attenuation" value={state.attenuation} setValue={handleSliderChange} label={`Attenuation: ${[0, 0.1, 0.5, 1.0, 1.5, 2.0][state.attenuation]}`} min={0} max={5} />
                        </div>
                        <div className="folder border">
                            < DropdownSingle
                                label="Noise Type"
                                id="noise"
                                value={Lookups.noise.indexOf(state.noise)}
                                setValue={handleDropdownChange}
                                options={noiseOptions}
                                dark
                            />
                            {state.noise == "Worley" && (<>
                                < DropdownSingle
                                    label="Distance Metric"
                                    id="distanceMetric"
                                    value={Lookups.distanceMetric.indexOf(state.distanceMetric)}
                                    setValue={handleDropdownChange}
                                    options={distanceMetricOptions}
                                    dark
                                />
                            </>)}
                            {state.noise == "Worley" || state.noise == "Voronoi" && (<>
                                < DropdownSingle
                                    label="Value"
                                    id="distance"
                                    value={Lookups.distance.indexOf(state.distance)}
                                    setValue={handleDropdownChange}
                                    options={distanceOptions}
                                    dark
                                />
                            </>)}

                            {((state.noise == "Worley") || (state.noise == "Alligator")) && (<>
                                <CheckboxSingle
                                    id="inverted"
                                    label="Inverted"
                                    value={state.inverted}
                                    setValue={handleCheckboxChange}
                                    dark
                                />
                            </>
                            )}
                            {(state.noise == "Voronoi") && (<>
                                <SliderSingle darkTheme="dark" id="jitter" value={state.jitter} setValue={handleSliderChange} label={`Jitter: ${[0.0, 0.5, 1.0][state.jitter]}`} min={0} max={2} />
                            </>
                            )}
                        </div>
                    </div>
                </div>
            </div>



        </Root >
    )
};
