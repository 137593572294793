import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Tip } from '../../CodeBlock/CodeBlock';


export const NodeCreation: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="Nodes with Python">
                <Section title="Create a Node" icon="houdini">
                    {/* <Words>Create a node</Words> */}
                    <Python>{`
# create geo
obj = hou.node('/obj')
geo = obj.createNode('geo') 

# shorthand
geo = hou.node('/obj').createNode('geo') 
                    `}
                    </Python>
                </Section>
                <Section title="Make a Copy" icon="houdini">
                    <Tip>Doesn't work in locked assets</Tip>
                    {/* <Words>Create a node</Words> */}
                    <Python>{`
# make a copy
copied_node = hou.copyNodesTo((original_node,), parent_node)[0] 

# move a node
moved_node = hou.moveNodesTo((original_node,), parent_node)[0]
                    `}
                    </Python>
                </Section>
                <Section title="Naming" icon="houdini" divider>
                    <Python>{`
# create geo named 'my_geo'
geo = hou.node('/obj').createNode('geo', 'my_geo') 

# rename it 'josh'
geo.setName('josh', unique_name=True)

# print name
print(geo.name())
                            `}
                    </Python>
                    <Tip>If the unique_name parameter is set to True, the supplied name may be changed to ensure that it doesn’t match the name of any existing node.</Tip>
                </Section>

                <Section title="Color" icon="houdini">
                    <Python>{`
# color red
geo.setColor(hou.Color((1, 0, 0)))
                            `}
                    </Python>
                </Section>
                <Section title="Shape" icon="houdini" divider>
                    <Python>{`
# set wave shape
geo.setUserData('nodeshape', 'wave')
                            `}
                    </Python>
                    <Python>{`
# print all node shapes
editor = hou.ui.paneTabOfType(hou.paneTabType.NetworkEditor)
print(editor.nodeShapes())
                            `}
                    </Python>
                    <Tip><strong>All node shapes: </strong>('rect', 'bone', 'bulge', 'bulge_down', 'burst', 'camera', 'chevron_down', 'chevron_up', 'cigar', 'circle', 'clipped_left', 'clipped_right', 'cloud', 'diamond', 'ensign', 'gurgle', 'light', 'null', 'oval', 'peanut', 'pointy', 'slash', 'squared', 'star', 'tabbed_left', 'tabbed_right', 'tilted', 'trapezoid_down', 'trapezoid_up', 'wave')
</Tip>
                    <Words><Link text="Per-node user-defined data" url="https://www.sidefx.com/docs/houdini/hom/nodeuserdata.html" />  ─ SideFX docs</Words>
                </Section>

                <Section title="Node Objects and Paths" icon="houdini" divider>
                    <Python>
                        {`
# Return the full path (i.e. starting with /) of this node in the network.
path = geo.path()

# return node object from a path string
geo = hou.node(path)
                            `}
                    </Python>
                    <Python>{`# reference self from the callback script of a parameter
exec('''self = kwargs['node']\\nprint(self.name())''')`}</Python>
                    {/* <Tip>If the path starts with a /, Houdini will look for a node with that exact path. Otherwise, the Houdini searches relative to the current path.</Tip> */}
                    <Words><Link text="hou.NetworkMovableItem.path()" url="https://www.sidefx.com/docs/houdini/hom/hou/NetworkMovableItem.html#path" /> ─ SideFX docs</Words>
                    <Words><Link text="hou.node HOM function" url="https://www.sidefx.com/docs/houdini/hom/hou/node_.html" /> ─ SideFX docs</Words>
                </Section>
                <Section title="Layout" icon="houdini" divider>
                    <Python>
                        {`# move self
geo.moveToGoodPosition()

# layout children
geo.layoutChildren()`}
                    </Python>
                    <Python>
                        {`# move node outside of parent
parent = node.parent()
node = hou.moveNodesTo((node,), parent)[0]`}
                    </Python>
                    <Python>
                        {`# move to a position relative to another node
pos = reference_node.position()
transformed_pos = pos + hou.Vector2((2.0, -1.0))
node.setPosition(transformed_pos)`}
                    </Python>
                    {/* <Tip>If the path starts with a /, Houdini will look for a node with that exact path. Otherwise, the Houdini searches relative to the current path.</Tip> */}
                    <Words><Link text="Layout" url="https://www.sidefx.com/docs/houdini/hom/hou/Node.html#moveToGoodPosition" /> ─ SideFX docs</Words>
                </Section>
            </Lesson>
        </Page >
    )
};
