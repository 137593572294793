import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Code, Tip } from '../../CodeBlock/CodeBlock';
import { CCode } from '../../CodeBlock/CCode';


export const FontFromVex: React.FC = (props) => {
    return (
        <Page topic="python">
            <Lesson title="Font From VEX Using Spare Inputs">
                <Section title="" icon="houdini" divider>

                    <ImageContainer>
                        <Img ratio={(421 / 800)} src="https://houdini-stuff.s3.amazonaws.com/Houdini/GIFS/fontfromvex.gif" />
                    </ImageContainer>
                    <Words>This example shows a way to use VEX instead of HScript to make dynamic font text.</Words>
                </Section>

                <Section title="Building the String" icon="houdini">
                    <Words>Start with a wrangle node set to <Code>detail</Code>. Build a string using VEX to access attributes, channel references, etc.. . Set the string as a detail attribute.</Words>
                    <CCode>{`string str = "";

// example
append(str, "• Cool\\n");

// example 2
if (chi("awesome") == 1) append(str, "• Awesome\\n");
else if (chi("awesome") == 2) append(str, "• Woah\\n");


// set as detail attrib
setdetailattrib(0, "detail_string", str, "set");`}</CCode>

                </Section>
                <Section title="Font Node" icon="houdini">
                    <Words>Access the detail attribute via a spare input. On the font node click <Code>{`Gear Icon → Add Spare Input`}</Code> then drag and drop the detail wrangle into the newly created <Code>{`Spare Input 0`}</Code> parameter. You should see a purple dashed wire connecting the two nodes in the network.</Words>
                    <Words>Add this as the font text: </Words>
                    <CCode>{'`details(-1, "detail_string")`'}</CCode>
                    <Words></Words>
                    <Tip>The backticks are needed to run HScript and 'details' has an 's' on the end.
                        <br />
                        <br />
                        Spare inputs are accessed using negative indicies. Spare Input 0 is -1. Spare Input 1 is -2. etc..</Tip>
                </Section>
                <Section title="Transform" icon="houdini" divider>
                    <Words>To position the text above the geometry add a transform node with these HScript expressions. The wrangle and font node are referenced via spare inputs.</Words>

                    <CCode>{`// Wrangle node is Spare Input 0 (-1)
// Font node is Spare Input 1 (-2)

// tx - centroid of geo
centroid(-1, D_X)

// ty - centroid of geo + half the bbox of geo + half the bbox of font
centroid(-1, D_Y) + (bbox(-1,D_YSIZE)/2) + (bbox(-2,D_YSIZE)/2)

// tz - centroid of geo
centroid(-1, D_Z)
`}</CCode>

                </Section>
                <Section title="HDA" icon="hda" divider>
                    <Words>Example network as an extractable HDA: <Link text="fontfromvex.hdalc" url="https://github.com/keatonwilliamson/hda/raw/master/fontfromvex.hdalc"></Link></Words>
                    <ImageContainer>
                        <Img ratio={(640 / 1068)} src="https://houdini-stuff.s3.amazonaws.com/Houdini/UI/fontfromvex.jpg" />
                    </ImageContainer>
                </Section>
            </Lesson>
        </Page >
    )
};
