import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { TableComponent } from '../../TableComponent/TableComponent';
import { Code } from '../../CodeBlock/CodeBlock';


export const CalcVoxelSize: React.FC = (props) => {
    return (
        <Page topic="volumes">
            <Lesson title="Calculate Voxel Size">
                <Section title="Calculate Voxel Size" icon="houdini" divider>
                    <ImageContainer>
                        <Img ratio={0.5625} src="https://houdini-stuff.s3.amazonaws.com/Houdini/GIFS/calc_voxel_size.gif" />
                    </ImageContainer>
                    <Words>This null node calculates a voxel size in a central place that you can channel reference throughout your project. It uses uniform divisions by axis of input geometry or a manually set voxel size.</Words>
                </Section>
                <Section title="Python" icon="python" divider>
                <Words>This generates the null node by itself.</Words>
                    <Python>
                        {`
# create geo
geo = hou.node('/obj').createNode('geo', 'calc_voxel_size')
geo.setUserData("nodeshape", "cloud")
geo.moveToGoodPosition()

# create null controller
null_controller = geo.createNode('null', 'controller')
null_controller.setColor(hou.Color((1, 1, 1))) # white
null_controller.setUserData("nodeshape", "cloud")

# get null controller ParmTemplateGroup
ptg = null_controller.parmTemplateGroup()

# hide existing parms
for parm in ptg.entries(): ptg.hide(parm, True)


###########################
####### Controls ##########

# axis to divide by
uniform_samples = hou.IntParmTemplate("uniformsamples", "Uniform Sampling", 1)
uniform_samples.setMenuItems(("X Axis", "Y Axis", "Z Axis", "Max Axis", "By Size"))
uniform_samples.setScriptCallbackLanguage(hou.scriptLanguage.Python)

# num divisions
divs = hou.IntParmTemplate("samplediv", "Uniform Sampling Divs", 1, max=600, default_value=((150),))
divs.setConditional(hou.parmCondType.DisableWhen, "{uniformsamples == 4}")
divs.setConditional(hou.parmCondType.HideWhen, "{uniformsamples == 4}")

# div size
div_size = hou.FloatParmTemplate("divsize", "Div Size", 1, max=1, default_value=((0.01),))
div_size.setConditional(hou.parmCondType.DisableWhen, "{uniformsamples != 4}")
div_size.setConditional(hou.parmCondType.HideWhen, "{uniformsamples != 4}")

##########################
######### Result #########

# size of selected axis
axis_size = hou.FloatParmTemplate("axissize", "Axis Size", 1, default_expression_language=((hou.scriptLanguage.Python),))
axis_size.setDefaultExpression(('''

size = hou.pwd().geometry().boundingBox().sizevec()
x = size[0]
y = size[1]
z = size[2]

dropdown = ch("uniformsamples")

if (dropdown == 0): return x
elif (dropdown == 1): return y
elif (dropdown == 2): return z
elif (dropdown == 3): return max(x,y,z)
else: return 0

''',))
axis_size.setConditional(hou.parmCondType.DisableWhen, "{uniformsamples == 4}")

# calculated voxel size
voxel_size = hou.FloatParmTemplate("voxelsize", "Voxel Size", 1, max=1, default_expression_language=((hou.scriptLanguage.Python),))
voxel_size.setDefaultExpression(('''

dropdown = ch("uniformsamples")

if (dropdown == 4): return ch("divsize")
else: return (ch("axissize")/ch("samplediv"))

''',))

###########################

# controls folder
controls = hou.FolderParmTemplate("controls", "Controls", parm_templates=((uniform_samples),(divs),(div_size)), folder_type=hou.folderType.Simple)
ptg.append(controls)

# result folder
result = hou.FolderParmTemplate("result", "Result", parm_templates=((axis_size),(voxel_size)), folder_type=hou.folderType.Simple)
ptg.append(result)

# apply changes
null_controller.setParmTemplateGroup(ptg)
                            `}
                    </Python>
                </Section>
                <Section title="HDA" icon="hda" divider>
                    <Words>These HDAs use the null node plus an object merge.</Words>
                    <Words>Obj level: <Link text="calculate_voxel_size.hdalc" url="https://github.com/keatonwilliamson/hda/raw/master/calculate_voxel_size.hdalc" /></Words>
                    <Words>SOP level: <Link text="calculate_voxel_size_sop.hdalc" url="https://github.com/keatonwilliamson/hda/raw/master/calculate_voxel_size_sop.hdalc" /></Words>
                </Section>
            </Lesson>
        </Page >
    )
};
{/* <Python>
{`
# create geo
geo = hou.node('/obj').createNode('geo', 'calc_voxel_size')
geo.setUserData("nodeshape", "cloud")
geo.moveToGoodPosition()

# create null controller
null_controller = geo.createNode('null', 'controller')
null_controller.setColor(hou.Color((1, 1, 1))) # white
null_controller.setUserData("nodeshape", "cloud")

# get null controller ParmTemplateGroup
ptg = null_controller.parmTemplateGroup()

# hide existing parms
for parm in ptg.entries(): ptg.hide(parm, True)


###########################
####### Controls ##########

# axis to divide by
uniform_samples = hou.IntParmTemplate("uniformsamples", "Uniform Sampling", 1)
uniform_samples.setMenuItems(("Max Axis", "X Axis", "Y Axis", "Z Axis", "By Size"))
uniform_samples.setScriptCallbackLanguage(hou.scriptLanguage.Python)
uniform_samples.setScriptCallback('''

# remove expression if 'By Size', otherwise set expression to axis/divisions
if int(kwargs['parm'].eval()) == 4:
kwargs['node'].parm('voxel_size').deleteAllKeyframes()
else: 
kwargs['node'].parm('voxel_size').setExpression('ch("axissize")/ch("samplediv")')

# set axis size expression
kwargs['node'].parm('axissize').setExpression(["max(max(bbox('.', D_XSIZE), bbox('.', D_YSIZE)), bbox('.', D_ZSIZE))", "bbox('.', D_XSIZE)", "bbox('.', D_YSIZE)", "bbox('.', D_ZSIZE)", ""][int(kwargs['parm'].eval())])

''')

# num divisions
divs = hou.IntParmTemplate("samplediv", "Uniform Sampling Divs", 1, max=600, default_value=((150),))
divs.setConditional(hou.parmCondType.DisableWhen, "{uniformsamples == 4}")

# folder for controls
controls = hou.FolderParmTemplate("controls", "Controls", parm_templates=((uniform_samples),(divs)), folder_type=hou.folderType.Simple)
ptg.append(controls)


##########################
######### Result #########

# size of selected axis
axis_size = hou.FloatParmTemplate("axissize", "Axis Size", 1)
axis_size.setDefaultExpression(("max(max(bbox('.', D_XSIZE), bbox('.', D_YSIZE)), bbox('.', D_ZSIZE))",))
axis_size.setConditional(hou.parmCondType.DisableWhen, "{uniformsamples == 4}")

# calculated voxel size
voxel_size = hou.FloatParmTemplate("voxel_size", "Voxel Size", 1, max=1)
voxel_size.setDefaultExpression(('ch("axissize")/ch("samplediv")',))

# folder for results
result = hou.FolderParmTemplate("result", "Result", parm_templates=((axis_size),(voxel_size)), folder_type=hou.folderType.Simple)
ptg.append(result)

###########################

# apply changes
null_controller.setParmTemplateGroup(ptg)
    `}
</Python> */}

// parent = hou.Node.parent(hou.node(hou.selectedNodes()[-1].path())) if hou.selectedNodes() else None
// parent_is_geo = False if not parent else parent.type().name() == 'geo'
// geo = parent if parent_is_geo else hou.node('/obj').createNode("geo"); 
// if not parent_is_geo: geo.moveToGoodPosition(); geo.setName('calc_voxel_size', unique_name=True); hou.ui.displayMessage('Created in new geo: {}'.format(geo.path()))  