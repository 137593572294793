import houdini from "./../TypeIcons/houdini.svg"
import normal from "./../TypeIcons/normal.svg"
import python from "./../TypeIcons/python.svg"
import noise from "./../TypeIcons/noise.svg"
import cloud from "./../TypeIcons/cloud.svg"
import fire from "./../TypeIcons/fire.svg"
import monstera from "./../TypeIcons/monstera.svg"
import ground from "./../TypeIcons/ground.svg"
import drop from "./../TypeIcons/drop.svg"
import rock from "./../TypeIcons/rock.svg"
import drape from "./../TypeIcons/drape.svg"
import groom from "./../TypeIcons/groom.svg"
import abstract from "./../TypeIcons/abstract.svg"
import bug from "./../TypeIcons/bug.svg"
import camera from "./../TypeIcons/camera.svg"
import light from "./../TypeIcons/light.svg"
import reel from "./../TypeIcons/reel.svg"
import checker from "./../TypeIcons/checker.svg"
import palette from "./../TypeIcons/palette.svg"
import wrangle from "./../TypeIcons/wrangle.svg"
import gear from "./../TypeIcons/gear.svg"
import nuke from "./../TypeIcons/nuke.svg"
import box from "./../TypeIcons/box.svg"
import blender from "./../TypeIcons/blender.svg"

import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components'

import houdiniLogo from "./../sideFX-square.svg"
import { iconName } from "./Houdini"





// styled.div<{ darkTheme: boolean; }>`
//     min-height: 100vh;
//     background: ${props => props.darkTheme ? "#282c35" : "white"};



const StyledSection = styled.div<{ color: string; round: boolean; }>`

    & hr {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        margin-bottom: calc(1.75rem - 1px);
        background: #ccc;
        border: none;
        height: 1px;
        &.transparent {
            background: transparent !important;
        }
    }
    & .title {
        display: flex;
        align-items: flex-end;
        margin-bottom: 28px;
        & h2 {
            font-family: 'Merriweather','Georgia',serif;
            // margin-block-end: 20px;
            // margin-block-start: 20px;
            margin: 0;
        }
        & .icon {
            background: ${props => props.color};
            border-radius: ${props => props.round ? 100 : 0}%;
            height: ${props => props.round ? 40 : 28}px;
            width: ${props => props.round ? 40 : 28}px;
            min-height: ${props => props.round ? 40 : 28}px;
            min-width: ${props => props.round ? 40 : 28}px;
            margin: 0 12px ${props => props.round ? 0 : 4}px 0px;
            & img {
                height: ${props => props.round ? 64 : 80}%;
                width: ${props => props.round ? 64 : 80}%;
                margin: ${props => props.round ? 18 : 10}%;
            }
        }
    }

`
interface ISection {
    title: string;
    icon?: iconName;
    round?: boolean;
    divider?: boolean;
    children: any;
    ref?: any;
}

export interface icon { icon: string; color: string; }

export const Section: React.FC<ISection> = ({ round = false, divider = false, ...props }: ISection) => {
    const icons: Record<iconName, icon> = {
        "houdini": {
            icon: houdini,
            color: "#ff6600"
        },
        "geometry": {
            icon: normal,
            color: "#A0A29F"
        },
        "python": {
            icon: python,
            color: "#3771a1"
        },
        "noise": {
            icon: noise,
            color: "#595761"
        },
        "volumes": {
            icon: cloud,
            color: "#80d4f7"
        },
        "pyro": {
            icon: fire,
            color: "#FBA54C"
        },
        "growth": {
            icon: monstera,
            color: "#5FBD58"
        },
        "terrain": {
            icon: ground,
            color: "#DA7C4D"
        },
        "fluids": {
            icon: drop,
            color: "#539DDF"
        },
        "destruction": {
            icon: rock,
            color: "#C9BB8A"
        },
        "cloth": {
            icon: drape,
            color: "#D3425F"
        },
        "groom": {
            icon: groom,
            color: "#595761"
        },
        "abstract": {
            icon: abstract,
            color: "#b763cf"
        },
        "organic": {
            icon: bug,
            color: "#92BC2C"
        },
        "camera": {
            icon: camera,
            color: "#5F6DBC"
        },
        "lighting": {
            icon: light,
            color: "#F2D94E"
        },
        "rendering": {
            icon: reel,
            color: "#75D0C1"
        },
        "uv": {
            icon: checker,
            color: "#A1BBEC"
        },
        "texturing": {
            icon: palette,
            color: "#FA8581"
        },
        "vex": {
            icon: wrangle,
            color: "#EE90E6"
        },
        "ui": {
            icon: gear,
            color: "#5695A3"
        },
        "nuke": {
            icon: nuke,
            color: "#fbb437"
        },
        "hda": {
            icon: box,
            color: "#c69e81"
        },
        "blender": {
            icon: blender,
            color: "#EB882B"
        }

    };
    return (
        <StyledSection ref={props.ref} color={props.icon ? icons[props.icon].color : ""} round={round}>
            <div className="title">
                {(!!props.icon && props.icon != "houdini") && <div className="icon">
                    <img src={icons[props.icon].icon} />
                </div>}
                <h2>

                    {props.title}
                </h2>
            </div>
            {props.children}
            <hr className={`${!divider ? 'transparent' : ''}`} />
        </StyledSection>
    )
};