import React from 'react';
import { Vex } from '../../../../Vex/VexBlock';
import { dimension, DistanceMetric, NoiseFunction } from '../NoiseConfig';
import { CellularArguments } from './CellularArguments';
import { PeriodArguments } from './PeriodArguments';
import { PositionArguments } from './PositionArguments';

export interface ICellularFunctions {
    inputDimension: dimension;
    noise: NoiseFunction;
    tabs?: number;
    distanceMetric: DistanceMetric;
    periodic: boolean;
    useFunction: boolean;
    fBm: boolean;
}

export const CellularFunctions: React.FC<ICellularFunctions> = ({ tabs, ...props }: ICellularFunctions) => {
    return (<>
        { (() => {
            // worley
            if (props.noise == "Worley") {
                return <>
                    <Vex.Line {...{ tabs }}>
                        <Vex.Code function>{`${(() => {
                            if (props.distanceMetric === "Euclidian") return "wnoise";
                            else if (props.distanceMetric === "Manhattan") return "mwnoise";
                            else if (props.distanceMetric === "Chebyshev") return "cwnoise";
                        })()}`}</Vex.Code>
                        <PositionArguments inputDimension={props.inputDimension} useFunction={props.useFunction}/>
                        <CellularArguments noise={props.noise} inputDimension={props.inputDimension}/>
                        {props.periodic && <PeriodArguments inputDimension={props.inputDimension} fBm={props.fBm}/>}
                        <Vex.Code>{`);`}</Vex.Code>
                    </Vex.Line>
                </>
            }
            // vector
            else if (props.noise == "Voronoi") {
                return <>
                    <Vex.Line {...{ tabs }}>
                        <Vex.Code function>{`vnoise`}</Vex.Code>
                        <PositionArguments inputDimension={props.inputDimension} useFunction={props.useFunction}/>
                        <CellularArguments noise={props.noise} inputDimension={props.inputDimension}/>
                        {props.periodic && <PeriodArguments inputDimension={props.inputDimension} fBm={props.fBm}/>}
                        <Vex.Code>{`);`}</Vex.Code>
                    </Vex.Line>
                    {{
                        "1D": <>
                            <Vex.Line {...{ tabs }}>
                                <Vex.Code>{`pos1 = (`}</Vex.Code>
                                <Vex.Code type>{`float`}</Vex.Code>
                                <Vex.Code>{`)(pos1 - offset) / freq;`}</Vex.Code>
                            </Vex.Line>
                            <Vex.Line {...{ tabs }}>
                                <Vex.Code>{`pos2 = (`}</Vex.Code>
                                <Vex.Code type>{`float`}</Vex.Code>
                                <Vex.Code>{`)(pos2 - offset) / freq;`}</Vex.Code>
                            </Vex.Line>
                        </>,
                        "2D": <>
                            <Vex.Line {...{ tabs }}>
                                <Vex.Code type>{`vector `}</Vex.Code>
                                <Vex.Code>{`pos1 = (`}</Vex.Code>
                                <Vex.Code function>{`set`}</Vex.Code>
                                <Vex.Code>{`(pos1x, pos1y) - offset) / freq;`}</Vex.Code>
                            </Vex.Line>
                            <Vex.Line {...{ tabs }}>
                                <Vex.Code type>{`vector `}</Vex.Code>
                                <Vex.Code>{`pos2 = (`}</Vex.Code>
                                <Vex.Code function>{`set`}</Vex.Code>
                                <Vex.Code>{`(pos2x, pos2y) - offset) / freq;`}</Vex.Code>
                            </Vex.Line>

                        </>,
                        "3D": <>
                            <Vex.Line {...{ tabs }}>
                                <Vex.Code>{`pos1 = (pos1 - offset) / freq;`}</Vex.Code>
                            </Vex.Line>
                            <Vex.Line {...{ tabs }}>
                                <Vex.Code>{`pos2 = (pos2 - offset) / freq;`}</Vex.Code>
                            </Vex.Line>
                        </>,
                        "4D": <>
                            <Vex.Line {...{ tabs }}>
                                <Vex.Code>{`pos1 = (pos1 - offset) / freq;`}</Vex.Code>
                            </Vex.Line>
                            <Vex.Line {...{ tabs }}>
                                <Vex.Code>{`pos2 = (pos2 - offset) / freq;`}</Vex.Code>
                            </Vex.Line>
                        </>,
                    }[props.inputDimension]}

                </>
            }
            else return <></>
        })()}
    </>)
}
