import React from "react";
import { Route } from "react-router-dom";
import { ArticleLink } from "../ArticleLink";
import { AddingGeoToNormal } from "./AddingGeoToNormals";
import { SphericalCoordinates } from "./SphericalCoordinates";
import { MovingPointsAlongTheNormal } from "./MovingPointsAlongTheNormal";
import { Snippets } from "./Snippets";
import { TransformOrient } from "./TransformOrient";


const base_url = "/houdini/vex"
export const VexRoutes = () => {
    return (<>

        {/* <Route exact path="/houdini/vex/moving_points_along_normals">
                <MovingPointsAlongTheNormal />
            </Route> */}
        <Route exact path={`${base_url}/adding_geometry_to_normals`}>
            <AddingGeoToNormal />
        </Route>
        <Route exact path={`${base_url}/conversion_functions`}>
            <SphericalCoordinates />
        </Route>
        <Route exact path={`${base_url}/snippets`}>
            <Snippets />
        </Route>
        <Route exact path={`${base_url}/transforms_orients`}>
            <TransformOrient />
        </Route>
    </>
    )
};

export const VexArticles = () => {
    return (<>
        {/* <ArticleLink
            title="Deform Geometry By Normals"
            date="Jan 14, 2021"
            description="Intro Level VEX exercise"
            url="/houdini/vex/moving_points_along_normals"
            emoji="🥑"
        /> */}
        <ArticleLink
            title="Snippets"
            date="March 29, 2021"
            description="VEX snippets"
            url={`${base_url}/snippets`}
            emoji="📃"
        />
        <ArticleLink
            title="Draw Lines Between Points"
            date="November 22, 2020"
            description="Add Node and shared id attributes"
            url={`${base_url}/adding_geometry_to_normals`}
            emoji="🥑"
        />
        <ArticleLink
            title="Sphercial Coordinates"
            date="November 23, 2020"
            description="VEX functions that convert to and from Spherical Coordinates"
            url={`${base_url}/conversion_functions`}
            emoji="🌎"
        />
        <ArticleLink
            title="Transforms and Orients"
            date="May 10, 2021"
            description="Transforms and Orients"
            url={`${base_url}/transforms_orients`}
            emoji="🌎"
        />
    </>
    )
};