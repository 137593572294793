import React, { PropsWithChildren, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components'
import { useApp } from '../App';
import { string } from '../CodeBlock/CodeBlock';
import sort from "./sort.svg";
import sort_blue from "./sort_blue.svg";

const StyledDropdownForm = styled.div<{ darkTheme: boolean; }>`
    color: ${props => props.color};
    padding: 8px 0px 0px;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    & .label {
        padding: 4px;
    }
    & .ui.dropdown {
        ${props => props.darkTheme ? "background-image: linear-gradient(#555555, #3c3c3c);" : ""}
        ${props => props.darkTheme ? "border: 1px solid #232323 !important;" : ""}
        & i {
            &:before {
                ${props => props.darkTheme ? `content: url('${sort}') !important;` : ""}
                
            }
            ${props => props.darkTheme ? `
                margin: 0px !important;
                top: -1px !important;
                bottom: -1px !important;
                right: -1px !important;
                // padding: 0px !important;
                padding: .8em !important;
                padding-right: 1.2em !important;
                background: pink;
                opacity: 1 !important;
                border-top-right-radius: .28571429rem;
                border-bottom-right-radius: .28571429rem;
                background-image: linear-gradient(#3f3f3f, #282828);
                border-top: 1px solid #232323;
                border-right: 1px solid #232323;
                border-bottom: 1px solid #232323;
                height: calc(100% + 2px);
                position: absolute;
                width: 2.71428571em !important;
                color: white;

            ` : ""}
        }
        &.active.visible {
            ${props => props.darkTheme ? "background-image: linear-gradient(#677880, #949494);" : ""}
            ${props => props.darkTheme ? "border: 1px solid black !important;" : ""}
            & i {
                &:before {
                    ${props => props.darkTheme ? `content: url('${sort_blue}') !important;` : ""}  
                }
                ${props => props.darkTheme ? `
                    background-image: linear-gradient(#485B64, #737879);
                    border-bottom-right-radius: 0px;
                ` : ""}
            }
            &.upward {
                & i {
                    ${props => props.darkTheme ? `
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: .28571429rem;
                    ` : ""}
                }
            }
        }
        & .text {
            color: ${props => props.darkTheme ? "white" : "black"} !important;
        }
        & .menu {
            &.visible {
                ${props => props.darkTheme ? "background: #3a3a3a !important;" : ""} 
                ${props => props.darkTheme ? `
                    border-right: 1px solid #262626 !important;
                    border-bottom: 1px solid #262626 !important;
                    border-left: 1px solid #949494 !important;
                    border-top: 1px solid #949494 !important;

                ` : ""}
            }
            & .item {
                border-top: none !important;
                color: ${props => props.darkTheme ? "white" : "black"} !important;
                &.selected {
                    ${props => props.darkTheme ? "background: #b36600 !important;" : ""}
                }
            }
            &:hover {
                & .item {
                    &.selected {
                            background: transparent !important;
                    }
                    &:hover {
                        ${props => props.darkTheme ? "background: #b36600 !important;" : ""}
                    }
                }
                }
            }
        }
    }
`
interface IDropdown {
    options: string[];
    value: number;
}


export type IDropdownState = Record<string, IDropdown>


interface IDropdownFormProps {
    dropdownState: IDropdownState;
    setDropdownState: React.Dispatch<React.SetStateAction<IDropdownState>>;
}

// interface IDropdownForm {
//     dropdownState: any;
//     setDropdownState: React.Dispatch<React.SetStateAction<undefined>>;
//     dropdowns: IDropdown[]
// }


export const DropdownForm: React.FC<IDropdownFormProps> = (props: IDropdownFormProps) => {
    const { darkTheme, nodeSelected, setNodeSelected } = useApp();
    // const [sss, setsss] = useState();
    // const sssss = sss;

    // let initialState = props.dropdowns.reduce((acc: Record<string, number>, item: IDropdown) => {
    //     acc[item.label] = 0;
    //     return acc;
    // }, {});

    const handleDropdownChange = (event: any, data: any) => {
        let label = data.id;
        let value = Number(data.value);
        props.setDropdownState(
            {
                ...props.dropdownState,
                [`${label}`]: {
                    ...props.dropdownState[`${label}`],
                    value: value
                }
            });
    };

    return (
        <StyledDropdownForm {...{ darkTheme }}>
            {Object.entries(props.dropdownState).map((_, i) =>
                <div className="item" key={i}>
                    <div className="label">{_[0]}</div>
                    <Dropdown
                        // {...(darkTheme && { icon: "sort" })}
                        onChange={handleDropdownChange}
                        id={_[0]}
                        selection
                        options={_[1].options.map((option, i) => ({ text: option, key: i, value: i }))}
                        value={_[1].value}
                    >
                    </Dropdown>
                </div>
            )}
        </StyledDropdownForm>
    )
};

// export function useDropdowns(dropdowns: IDropdown[]) {

//     let initialState = dropdowns.reduce((acc: Record<string, number>, item: IDropdown) => {
//         acc[item.label] = 0;
//         return acc;
//     }, {})

//     const [dropdownState, setDropdownState] = useState(initialState);

//     const handleDropdownChange = (event: any) => {
//         let id = event.target.id;
//         let value = Number(event.target.value);
//         setDropdownState(_ => ({ ..._, [id]: value }));
//     }

//     const JSX = <>
//         {dropdowns.map((_) =>
//             <Dropdown
//                 onChange={handleDropdownChange}
//                 id={_.label}
//                 selection
//                 options={_.options.map((option, i) => ({ text: option, key: i, value: i }))}
//             />
//         )}
//     </>

//     return { JSX, dropdownState };
// }