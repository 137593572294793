import { DropdownSingleOption } from "../../../Form/DropdownSingle";

export type dimension = "1D" | "2D" | "3D" | "4D";

export type NoiseFunction = "Perlin" | "Simplex" | "Original Perlin" | "Sparse Convolution" | "Alligator" | "Worley" | "Flow" | "Voronoi" | "Curl";

// export type CurlNoiseType = "Perlin" | "Simplex" | "Original Perlin" | "Sparse Convolution" | "Alligator";

export type DistanceMetric = "Euclidian" | "Manhattan" | "Chebyshev";

export type distance = "F1" | "F2-F1" | "pos1";

export interface INoiseConfig {
    // dropdown
    input: DropdownSingleOption[];
    // dropdown
    output: DropdownSingleOption[];
    // toggle
    periodic: boolean; // all but curl
    // toggle
    turbulence: boolean; // original sparse alligator
    // dropdown
    // distanceMetrics: DropdownSingleOption[] | null; // worley
    //determines if output is disabled
    //dropdown
    distances: DropdownSingleOption[] | null; // worley and voronoi
}


// need to figure out how to handle turb and fBm

export interface INoiseState {
    noise: NoiseFunction;
    // dropdown
    input: dimension,
    // dropdown
    output: dimension,
    // toggle
    periodic: boolean;
    // toggle
    turbulent: boolean;
    // dropdown
    distanceMetric: DistanceMetric;
    //determines if output is disabled
    //dropdown
    distance: distance;

    fBm: boolean;
    // for curl
    curlSimplex: boolean; // disappears for vop
    curl2D: boolean;

    channelPrefix: string;

    useFunction: boolean;

    functionName: string;
    //useVOPCurl: boolean; //
    //VOPCurlNoiseType: boolean; // will also have options for analytical
}

export const noiseOptions: DropdownSingleOption[] = [{ name: "Perlin" }, { name: "Simplex" }, { name: "Original Perlin" }, { name: "Sparse Convolution" }, { name: "Alligator" }, { name: "Worley" }, { name: "Flow" }, { name: "Voronoi" }, { name: "Curl" }]

export const distanceMetrics: DropdownSingleOption[] = [{ name: "Euclidian" }, { name: "Manhattan" }, { name: "Chebyshev" }];

interface IOutputArrays {
    one: DropdownSingleOption[]
    three: DropdownSingleOption[]
    both: DropdownSingleOption[]
}
const OutputArrays: IOutputArrays = {
    one: [
        { name: "1D" },
        { name: "3D", disabled: true }
    ],
    three: [
        { name: "1D", disabled: true },
        { name: "3D" }
    ],
    both: [
        { name: "1D" },
        { name: "3D" }],
}

interface IInputArrays {
    startAtTwo: DropdownSingleOption[];
    three: DropdownSingleOption[];
    all: DropdownSingleOption[];
    twoAndThree: DropdownSingleOption[];
    threeAndFour: DropdownSingleOption[];
}
export const InputArrays: IInputArrays = {
    startAtTwo: [
        { name: "1D", disabled: true },
        { name: "2D" },
        { name: "3D" },
        { name: "4D" }
    ],
    three: [
        { name: "1D", disabled: true },
        { name: "2D", disabled: true },
        { name: "3D" },
        { name: "4D", disabled: true }
    ],
    all: [
        { name: "1D" },
        { name: "2D" },
        { name: "3D" },
        { name: "4D" }
    ],
    twoAndThree: [
        { name: "1D", disabled: true },
        { name: "2D" },
        { name: "3D" },
        { name: "4D", disabled: true }
    ],
    threeAndFour: [
        { name: "1D", disabled: true },
        { name: "2D", disabled: true },
        { name: "3D" },
        { name: "4D" }
    ],
}

const distanceMetricOptions: DropdownSingleOption[] = [
    { name: "Euclidian" },
    { name: "Manhattan" },
    { name: "Chebyshev" }
]
const distanceOptions: DropdownSingleOption[] = [
    { name: "F1" },
    { name: "F2-F1" }
]




// all are periodic. curl isn't
export const config: Record<NoiseFunction, INoiseConfig> = {
    Perlin: {
        input: InputArrays.all,
        output: OutputArrays.both,
        periodic: true,
        turbulence: false,
        distances: null,
    },
    Simplex: {
        input: InputArrays.all,
        output: OutputArrays.both,
        periodic: true,
        turbulence: false,
        distances: null,
    },
    "Original Perlin": {
        input: InputArrays.three,
        output: OutputArrays.both,
        periodic: true,
        turbulence: true,
        distances: null,
    },
    "Sparse Convolution": {
        input: InputArrays.three,
        output: OutputArrays.both,
        periodic: true,
        turbulence: true,
        distances: null,
    },
    Alligator: {
        input: InputArrays.three,
        output: OutputArrays.both,
        periodic: true,
        turbulence: true,
        distances: null,
    },
    Worley: {
        input: InputArrays.all,
        output: OutputArrays.one,
        periodic: true,
        turbulence: false,
        distances: distanceOptions,
    },
    // has flow
    Flow: {
        input: InputArrays.startAtTwo,
        output: OutputArrays.both,
        periodic: true,
        turbulence: false,
        distances: null,
    },
    // has jitter
    Voronoi: {
        input: InputArrays.all,
        // disable pos out.. too complex
        output: OutputArrays.one,
        periodic: true,
        turbulence: false,
        distances: distanceOptions,
    },
    Curl: {
        input: InputArrays.startAtTwo,
        output: OutputArrays.three,
        periodic: false,
        turbulence: false,
        distances: null,
    }


}







