import React from 'react';
import { Lesson, Page, Words } from '../../Chapter/Chapter';
import { Link } from '../../Chapter/Link/Link';
import { Tip } from '../../CodeBlock/CodeBlock';
import { TableComponent } from '../../TableComponent/TableComponent';
import { Python } from '../Hom/Python';
import { Section } from '../Section';


export const SimpleSource: React.FC = (props) => {
    return (
        <Page topic="pyro">
            <Lesson title="Simple Pyro Source">
                <Section title="Simple Pyro Source" divider={false}>
                    <Python>
                        {`
# create geo
geo = hou.node('/obj').createNode('geo', 'simple_pyro_source')
geo.setUserData("nodeshape", "cloud")
geo.setColor(hou.Color((1.0, 0.5, 0.0))) # orange
geo.moveToGoodPosition()

sphere = geo.createNode('sphere')
sphere.parm('type').set(1) # polygon
sphere.parm('freq').set(10) # polygon

mountain = geo.createNode('mountain::2.0')
mountain.parm("time").setExpression(("@Time * 2"),)

# compute velocity
trail = geo.createNode("trail")
trail.parm("result").set(3) # compute velocity
trail.parm("velapproximation").set(1) # central difference


# set temperature and fuel attributes
pyrosource = geo.createNode('pyrosource')
pyrosource.parm("mode").set(0) # surface scatter
pyrosource.parm("particlesep").set(0.05) # more points
pyrosource.parm("attributes").set(2)
pyrosource.parm("attribute1").set(1) # temperature
pyrosource.parm("attribute2").set(3) # fuel


# noise temp and fuel
attribnoise = geo.createNode('attribnoise::2.0')
attribnoise.parm("attribtype").set(0) # float
attribnoise.parm("attribs").set("temperature fuel")
attribnoise.parm("noiserange").set(1) # zero centered
attribnoise.parm("operation").set(3) # multiply
attribnoise.parm("doclampmin").set(1) # clamp minimum
attribnoise.parm("rough").set(0.8) # fractal roughness

# convert to volume
volumerasterizeattributes = geo.createNode("volumerasterizeattributes")
volumerasterizeattributes.parm("attributes").set("temperature fuel v")

# connect nodes
mountain.setFirstInput(sphere)
trail.setFirstInput(mountain)
pyrosource.setFirstInput(trail)
attribnoise.setFirstInput(pyrosource)
volumerasterizeattributes.setFirstInput(attribnoise)

# output
out = geo.createNode("null", "OUT_PYRO_SOURCE")
out.setFirstInput(volumerasterizeattributes)

# organize
geo.layoutChildren()
out.setDisplayFlag(1)
out.setRenderFlag(1)

# --- Made in Houdini 18.5 --- 
                            `}
                    </Python>
                    {/* <Python>
                        {`
# create geo
geo = hou.node('/obj').createNode('geo', 'simple_pyro_source')
geo.setUserData("nodeshape", "cloud")
geo.setColor(hou.Color((1.0, 0.5, 0.0))) # orange
geo.moveToGoodPosition()

toy = geo.createNode('testgeometry_rubbertoy')

mountain = geo.createNode('mountain::2.0')
mountain.parm("offsety").setExpression(("@Time * 10"),)
mountain.setFirstInput(toy)

# compute velocity
trail = geo.createNode("trail")
trail.parm("result").set(3) # compute velocity
trail.parm("velapproximation").set(1) # central difference
trail.setFirstInput(mountain)

# set temperature and fuel attributes
pyrosource = geo.createNode('pyrosource')
pyrosource.parm("mode").set(0) # surface scatter
pyrosource.parm("particlesep").set(0.05) # more points
pyrosource.parm("attributes").set(2)
pyrosource.parm("attribute1").set(1) # temperature
pyrosource.parm("attribute2").set(3) # fuel
pyrosource.setFirstInput(trail)

# convert to volume
volumerasterizeattributes = geo.createNode("volumerasterizeattributes")
volumerasterizeattributes.parm("attributes").set("temperature fuel v")
volumerasterizeattributes.setFirstInput(pyrosource)

# output
out = geo.createNode("null", "OUT_PYRO_SOURCE")
out.setFirstInput(volumerasterizeattributes)

# organize
geo.layoutChildren()
out.setDisplayFlag(1)
out.setRenderFlag(1)

# --- Made in Houdini 18.5 --- 
                            `}
                    </Python> */}
                </Section>
            </Lesson>
        </Page >
    )
};
