import styled from 'styled-components';
import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useApp } from '../App';



const StyledCloudLoader = styled.div<{ darkTheme: boolean; loaded: boolean; }>`
    display: ${props => props.loaded ? "none" : "block"};
    background: ${props => props.darkTheme ? "#2a2b37" : "white"};
    overflow-y: hidden;
    width: 100%;
    //height: calc(100vh - 3vw);
    height: 100vh;
    position: absolute;
    // border: 7px solid red;
    & .wrap {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        & p {
            font-family: futura-pt;
            font-weight: 400;
            font-style: normal;
            font-size: 18px;
            letter-spacing: .35em;
            text-transform: uppercase;
            line-height: 1em;
            color: ${props => props.darkTheme ? "white" : "black"};
        }
    }
`


interface CloudLoaderProps {
    loaded: boolean;
}

export const CloudLoader: React.FC<CloudLoaderProps> = (props) => {
    const { darkTheme } = useApp();
    return (<StyledCloudLoader loaded={props.loaded} {...{ darkTheme }}>
        <div className="wrap">
            <p>Loading</p>
            {darkTheme ? <img src={require("./cubes_dark.gif")} /> :
                <img src={require("./cubes_light.gif")} />}
        </div>
    </StyledCloudLoader>
    )

};