import React from "react";
import Toggle from "react-toggle";
import styled, { CSSObject } from "styled-components";
import sun from "./../Images/sun.svg"
import moon from "./../Images/moon.svg"

interface IReactLargeToggle {
  defaultChecked: boolean;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  styles?: CSSObject;
  checked: boolean;

}

const StyledLargeToggleWrap = styled.div<Pick<IReactLargeToggle, "styles">>`
    ${props => props.styles};
    & .react-toggle {
      touch-action: pan-x;
    
      display: inline-block;
      position: relative;
      cursor: pointer; 
      background-color: transparent;
      border: 0;
      padding: 0;
    
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      -webkit-tap-highlight-color: transparent;
    }
    
    & .react-toggle-screenreader-only {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    & .react-toggle--disabled {
      cursor: not-allowed;
      opacity: 0.5;
      -webkit-transition: opacity 0.25s;
      transition: opacity 0.25s;
    }
    
    & .react-toggle-track {
      width: 65px;
      height: 32px;
      // width: 50px;
      // height: 24px;
      padding: 0;
      border-radius: 30px;
      border: 2px solid #e4e7ec;
      box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px 0px;
      // background-color: #4D4D4D;
      background-color: white;
      transition: all 0.2s ease;
  
      & img.sun {
          width: 21px;
          position: absolute;
          top: 50%;
          left: 10%;
          transform: translate(-50%, -50%); 
      }
      & img.moon {
          width: 18px;
          position: absolute;
          top: 50%;
          left: 80%;
          transform: translate(-50%, -50%) scaleX(-1); 
      }
    }

    & .react-toggle--checked .react-toggle-track {
      border-color: #485365;
      background: #374151;
    }
    
    // & .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    //   background-color: #000000;
    // }
    
    
    // & .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    //   // background-color: #128D15;
    //   background-color: #0f1114;
    // }
    
    & .react-toggle-track-check {
      position: absolute;
      width: 14px;
      height: 10px;
      top: 0px;
      bottom: 0px;
      margin-top: auto;
      margin-bottom: auto;
      line-height: 0;
      left: 8px;
      opacity: 0;
      -webkit-transition: opacity 0.25s ease;
      -moz-transition: opacity 0.25s ease;
      transition: opacity 0.25s ease;
    }
    
    & .react-toggle--checked .react-toggle-track-check {
      opacity: 1;
      -webkit-transition: opacity 0.25s ease;
      -moz-transition: opacity 0.25s ease;
      transition: opacity 0.25s ease;
    }
    
    & .react-toggle-track-x {
      position: absolute;
      width: 10px;
      height: 10px;
      top: 0px;
      bottom: 0px;
      margin-top: auto;
      margin-bottom: auto;
      line-height: 0;
      right: 10px;
      opacity: 1;
      transition: opacity 0.25s ease;
    }
    
    & .react-toggle--checked .react-toggle-track-x {
      opacity: 0;
    }
    
    & .react-toggle-thumb {
      transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      position: absolute;
      top: 6px;
      left: 8px;
      width: 20px;
      height: 20px;
      border: 3px solid #ffbb52;
      border-radius: 50%;
      background-color: #ffeccf;
    
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      transition: all 0.25s ease;
    }
    
    & .react-toggle--checked .react-toggle-thumb {
      left: 36px;
      background: #485365;
      border-color: white;
    }
    
    // & .react-toggle--focus.react-toggle--checked .react-toggle-thumb {
    //   box-shadow: 0px 0px 2px 3px #8e4fff;
    //   border-color: #8e4fff;
    // }
    
    // & .react-toggle:active:not(.react-toggle--disabled).react-toggle--checked .react-toggle-thumb {
    //   box-shadow: 0px 0px 5px 5px #8e4fff;
    //   border-color: #8e4fff;
    // }
    
    // & .react-toggle--focus .react-toggle-thumb {
    //   box-shadow: 0px 0px 2px 3px #31aae2;
    //   border-color: #31aae2;
    // }
    
    // & .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    //   box-shadow: 0px 0px 5px 5px #31aae2;
    //   border-color: #31aae2;
    // }
        
  `




export const LargeToggle: React.FC<IReactLargeToggle> = ({ defaultChecked, onChange, styles, checked }: IReactLargeToggle) => {
  return (
    <StyledLargeToggleWrap styles={styles}>
      <Toggle {...{ defaultChecked, onChange, checked }} icons={{
        // unchecked: <img className="sun" src={sun} alt="" />,
        // checked: <img className="moon" src={moon} alt="" />,
        unchecked: <img className="sun" src={sun} alt="" />,
        checked: <img className="moon" src={moon} alt="" />,
      }} />
    </StyledLargeToggleWrap>
  )
};