import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Code, Tip } from '../../CodeBlock/CodeBlock';
import { CCode } from '../../CodeBlock/CCode';


export const BlenderGeometryPython: React.FC = (props) => {
    return (
        <Page topic="blender">
            <Lesson title="Blender Geometry Python">
                <Section title="" icon="houdini" divider>
                    <Words></Words>
                    <Python>{`
`}</Python>
                </Section>
            </Lesson>
        </Page >
    )
};

