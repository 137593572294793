import { useLayoutEffect, useState, useCallback, RefObject } from "react";
import ResizeObserver from "resize-observer-polyfill";

//https://codesandbox.io/s/zw8kylol8m?file=/src/useResizeObserver.ts
//https://stackoverflow.com/questions/43159887/make-a-single-property-optional-in-typescript
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type myDOMRectReadOnly = Omit<DOMRectReadOnly, "toJSON">;



export interface ResizeObserverEntry {
  target: Element;
  contentRect: myDOMRectReadOnly;
  //contentRect: Optional<DOMRectReadOnly, 'toJSON'>;
}

export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback?: (entry: myDOMRectReadOnly) => void
) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let RO: ResizeObserver | null = new ResizeObserver((entries: ResizeObserverEntry[]) =>
      handleResize(entries)
    );
    RO.observe(ref.current);

    return () => {
      if (!!RO) RO.disconnect();
      RO = null;
    };
  }, [ref]);

  return [width, height];
};
