import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Code, Tip } from '../../CodeBlock/CodeBlock';
import { CCode } from '../../CodeBlock/CCode';
import { WideBlogSection } from '../../WebComponents/WideBlogSection';


export const CameraFrustrum: React.FC = (props) => {
    return (
        <Page topic="camera">
            <Lesson title="Camera Frustrum">
                <Section title="Geometry Frustrum" icon="houdini" divider>
                    <Words>Generates a frustrum from a single detail wrangle. Just add an Operator Path parameter pointing to the camera and near/far clip parameters. and water</Words>
                    <WideBlogSection max_width="1000px">

                        <CCode>{`// in a detail wrangle

//////////////////////////
/// Camera Parameters ////
//////////////////////////

// path to camera
string camera = chsop("camera_path");

float focus = chf(concat(camera, "/focal")) * 0.001; // Focal Length - meters to mm
float aperture = chf(concat(camera, "/aperture")) * 0.001; // Aperture - meters to mm
float resx = chf(concat(camera, "/resx")); // Resolution x
float resy = chf(concat(camera, "/resy")); // Resolution y
float aspect = chf(concat(camera, "/aspect")); // Pixel Aspect Ratio
float aspect_ratio = (resy/resx)/aspect; // calc aspect ratio

matrix camera_transform = optransform(camera); // Camera Transform


//////////////////////////
/// Calculate Stuff //////
//////////////////////////

// find focal point
vector origin = set(0, 0, 0);
vector focal_pos = origin + set(0, 0, -focus);

// find angles of view
vector x_edge_pos = focal_pos + set(aperture/2, 0, 0);
vector y_edge_pos = focal_pos + set(0, (aperture/2) * aspect_ratio, 0);
float x_angle = acos(dot(normalize(focal_pos), normalize(x_edge_pos)));
float y_angle = acos(dot(normalize(focal_pos), normalize(y_edge_pos)));

// find centers of clip planes 
float near_clip = max(chf("near_clip"), 0);
float far_clip = max(chf("far_clip"), 0);
vector near_center = normalize(focal_pos) * near_clip;
vector far_center = normalize(focal_pos) * far_clip;

// distance to edge of clip planes from center
float near_x_dist = near_clip * tan(x_angle);
float near_y_dist = near_clip * tan(y_angle);
float far_x_dist = far_clip * tan(x_angle);
float far_y_dist = far_clip * tan(y_angle);

/////////////////////////////
//// Point Positions ////////
/////////////////////////////

vector near_bottom_left_pos = near_center + set(-near_x_dist, -near_y_dist, 0);
vector near_top_left_pos = near_center + set(-near_x_dist, near_y_dist, 0);
vector near_top_right_pos = near_center + set(near_x_dist, near_y_dist, 0);
vector near_bottom_right_pos = near_center + set(near_x_dist, -near_y_dist, 0);

vector far_bottom_left_pos = far_center + set(-far_x_dist, -far_y_dist, 0);
vector far_top_left_pos = far_center + set(-far_x_dist, far_y_dist, 0);
vector far_top_right_pos = far_center + set(far_x_dist, far_y_dist, 0);
vector far_bottom_right_pos = far_center + set(far_x_dist, -far_y_dist, 0);

// multiply by camera transform
near_bottom_left_pos *= camera_transform;
near_top_left_pos *= camera_transform;
near_top_right_pos *= camera_transform;
near_bottom_right_pos *= camera_transform;

far_bottom_left_pos *= camera_transform;
far_top_left_pos *= camera_transform;
far_top_right_pos *= camera_transform;
far_bottom_right_pos *= camera_transform;

////////////////////////
//// Add Points ////////
////////////////////////

int near_bottom_left = addpoint(0, near_bottom_left_pos);
int near_top_left = addpoint(0, near_top_left_pos);
int near_top_right = addpoint(0, near_top_right_pos);
int near_bottom_right = addpoint(0, near_bottom_right_pos);

int far_bottom_left = addpoint(0, far_bottom_left_pos);
int far_top_left = addpoint(0, far_top_left_pos);
int far_top_right = addpoint(0, far_top_right_pos);
int far_bottom_right = addpoint(0, far_bottom_right_pos);

////////////////////////
//// Add Faces /////////
////////////////////////

int near_face = addprim(0, "poly", near_bottom_left, near_top_left, near_top_right, near_bottom_right);
int far_face = addprim(0, "poly", far_bottom_right, far_top_right, far_top_left, far_bottom_left);

int top_face = addprim(0, "poly", near_top_left, far_top_left, far_top_right, near_top_right);
int bottom_face = addprim(0, "poly", near_bottom_right, far_bottom_right, far_bottom_left, near_bottom_left);

int left_face = addprim(0, "poly", near_bottom_left, far_bottom_left, far_top_left, near_top_left);
int right_face = addprim(0, "poly", near_top_right, far_top_right, far_bottom_right, near_bottom_right);`}
                        </CCode>

                    </WideBlogSection>
                </Section>
                <Section title="Scattered Points Frustrum" icon="houdini" divider>
                    <Words>Scatters points within the frustrum bounds</Words>

                    <Words>1. Generate points.</Words>

                    <CCode>{`// in a detail wrangle
int num_points = chi("num_points");

for(int i = 0; i < num_points; i++) {
    addpoint(0, {0, 0, 0});
}`}
                    </CCode>
                    <WideBlogSection max_width="800px">
                        <Words>2. Then calculate a few properties from the camera and set them as detail attributes.</Words>
                        <CCode>{`// in a detail wrangle

//////////////////////////
/// Camera Parameters ////
//////////////////////////

// path to camera
string camera = chsop("camera_path");

float focus = chf(concat(camera, "/focal")) * 0.001; // Focal Length - meters to mm
float aperture = chf(concat(camera, "/aperture")) * 0.001; // Aperture - meters to mm
float resx = chf(concat(camera, "/resx")); // Resolution x
float resy = chf(concat(camera, "/resy")); // Resolution y
float aspect = chf(concat(camera, "/aspect")); // Pixel Aspect Ratio
float aspect_ratio = (resy/resx)/aspect; // calc aspect ratio

matrix camera_transform = optransform(camera); // Camera Transform


//////////////////////////
/// Calculate Stuff //////
//////////////////////////

// find focal point
vector origin = set(0, 0, 0);
vector focal_pos = origin + set(0, 0, -focus);

// find angles of view
vector x_edge_pos = focal_pos + set(aperture/2, 0, 0);
vector y_edge_pos = focal_pos + set(0, (aperture/2) * aspect_ratio, 0);
float x_angle = acos(dot(normalize(focal_pos), normalize(x_edge_pos)));
float y_angle = acos(dot(normalize(focal_pos), normalize(y_edge_pos)));

// find centers of clip planes 
float near_clip = max(chf("near_clip"), 0);
float far_clip = max(chf("far_clip"), 0);
vector near_center = normalize(focal_pos) * near_clip;
vector far_center = normalize(focal_pos) * far_clip;

/////////////////////////////
/// Set Detail Attribs //////
/////////////////////////////

setdetailattrib(0, "camera_transform", camera_transform, "set");
setdetailattrib(0, "x_angle", x_angle, "set");
setdetailattrib(0, "y_angle", y_angle, "set");
setdetailattrib(0, "near_clip", near_clip, "set");
setdetailattrib(0, "far_clip", far_clip, "set");`}
                        </CCode>

                    </WideBlogSection>
                    <Words>3. Finally, scatter points in the frustrum area</Words>
                    <CCode>{`// in a point wrangle

// get details
matrix camera_transform = detail(0, "camera_transform");
float x_angle = detail(0, "x_angle");
float y_angle = detail(0, "y_angle");
float near_clip = detail(0, "near_clip");
float far_clip = detail(0, "far_clip");

// random seeds
int seed = chi("seed");
float random_x = random(@ptnum + seed*123);
float random_y = random(@ptnum + seed*456);
float random_z = random(@ptnum + seed*789);

// find position
float z = lerp(near_clip, far_clip, random_z); // z - axis

float x_range = z * tan(x_angle);
float y_range = z * tan(y_angle);
float x = fit01(random_x, -x_range, x_range);
float y = fit01(random_y, -y_range, y_range);

// set position transformed by camera
vector pos = set(x, y, -z);
pos *= camera_transform;
v@P = pos;
`}
                    </CCode>
                </Section>
                <Words><strong>References:</strong></Words>
                <Words><Link text="How to Scatter Points into a Box | Houdini Quicktips" url="https://www.youtube.com/watch?v=p0lFEq3ifrs" /> ─ David Kahl</Words>
                <Words><Link text="Creating a Camera Frustum in Houdini" url="https://www.youtube.com/watch?v=W2SOaZxNotI" /> ─ Patrick Woo</Words>

            </Lesson>
        </Page >
    )
};

