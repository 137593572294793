import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';


export const MaterialBuilder: React.FC = (props) => {
    return (
        <Page topic="rendering">
            <Lesson title="Basic Material Builder Setup">
                {/* <Section title="Principled Shader in Material Builder" divider={false}>
                    <Words>Makes a Principled Shader inside a Material Builder. Changes the base color to red</Words>
                    <Python>
                        {`
# create materialbuilder
builder = hou.node("/mat").createNode("materialbuilder");

# add principled shader
principled_shader = builder.createNode('principledshader::2.0');

# make red
principled_shader.parm("basecolorr").set(1)
principled_shader.parm("basecolorg").set(0)
principled_shader.parm("basecolorb").set(0)

# add surface exports
surface_exports = builder.createNode('surfaceexports');

# wire principled shader layer output to surface exports layer input
surface_exports.setInput(0, principled_shader, 2)

# get surface output
surface_output = hou.node(builder.path() + '/surface_output')

#wire surface exports to surface output (Cf, Of, F)
surface_output.setInput(0, surface_exports, 0)
surface_output.setInput(1, surface_exports, 1)
surface_output.setInput(4, surface_exports, 2)

# organize nodes
builder.layoutChildren()
builder.setColor(hou.Color((1, 0, 0)))
builder.setName('red_shader', unique_name=True)
                            `}
                    </Python>
                </Section>
                <Section title="Material Builder with Layer Output" divider={false}>
                    <Words>Makes a Principled Shader inside a Material Builder. Changes the base color to red and includes a layer output</Words>
                    <Python highlighted={[27, 48]}>
                        {`
# create materialbuilder
builder = hou.node("/mat").createNode("materialbuilder");

# add principled shader
principled_shader = builder.createNode('principledshader::2.0');

# make red
principled_shader.parm("basecolorr").set(1)
principled_shader.parm("basecolorg").set(0)
principled_shader.parm("basecolorb").set(0)

# add surface exports
surface_exports = builder.createNode('surfaceexports');

# wire principled shader layer output to surface exports layer input
surface_exports.setInput(0, principled_shader, 2)

# get surface output
surface_output = hou.node(builder.path() + '/surface_output')

#wire surface exports to surface output (Cf, Of, F)
surface_output.setInput(0, surface_exports, 0)
surface_output.setInput(1, surface_exports, 1)
surface_output.setInput(4, surface_exports, 2)

# ----- begin layer output -----

# create parameter vop
layer = builder.createNode('parameter');

# set name and label
layer.parm("parmname").set("layer")
layer.parm("parmlabel").set("Shader Layer")

# set Export parameter to Always
layer.parm("exportparm").set(1)

# set Type parameter to Struct
layer.parm("parmtype").set(41)

# set struct default to ShaderLayer
layer.parm("parmtypename").set("ShaderLayer")

# wire principled shader layer output to layer parameter input
layer.setInput(0, principled_shader, 2)

# ----- end layer output -----

# organize nodes
builder.layoutChildren()
builder.setColor(hou.Color((1, 0, 0)))
builder.setName('red_shader_layer', unique_name=True)
`}
                    </Python>
                    <Words><Link text="How to add a layer output to your custom material" url="https://www.sidefx.com/docs/houdini/shade/build.html#layeroutput" /> ─ SideFX Docs</Words>
                </Section> */}
                <Section title="Material Builder + Displacement" divider={false}>
                    <OrderedList>
                        <li>Makes a Principled Shader inside a Material Builder.</li>
                        <li>Layer output for surface</li>
                        <li>Creates Anti-Aliased Noise displacement</li>
                        <li>Adds Displacement bounds render property</li>
                        <li>Layer output for displacement</li>
                    </OrderedList>
                    <Python>
                        {`
# create prinicpled shader in new materialbuilder
builder = hou.node("/mat").createNode("materialbuilder");
principled_shader = builder.createNode('principledshader::2.0');
principled_shader.parm("basecolorr").set(1) # red
principled_shader.parm("basecolorg").set(0) # red
principled_shader.parm("basecolorb").set(0) # red

# add surface exports
surface_exports = builder.createNode('surfaceexports');
surface_exports.setInput(0, principled_shader, 2) # connect layer out to layer in

# get surface output and connect
surface_output = hou.node(builder.path() + '/surface_output')
surface_output.setInput(0, surface_exports, 0) # Cf
surface_output.setInput(1, surface_exports, 1) # Of
surface_output.setInput(4, surface_exports, 2) # F

# ----- begin surface layer output -----

# create parameter
layer = builder.createNode('parameter');
layer.parm("parmname").set("layer") # set name
layer.parm("parmlabel").set("Shader Layer") # set label
layer.parm("exportparm").set(1) # set to Always
layer.parm("parmtype").set(41) # set to Struct
layer.parm("parmtypename").set("ShaderLayer") # set struct default
layer.parm("useownexportcontext").set(True) # use own export context

# connect layer out to layer in
layer.setInput(0, principled_shader, 2) 

# ----- end surface layer output -----

# ----- begin displacement -----

# create displacement node
displace = builder.createNode('displace')

# get displacement output and connect
displacement_output = hou.node(builder.path() + '/displacement_output') 
displacement_output.setInput(0, displace, 0) # outP to P
displacement_output.setInput(1, displace, 1) # outN to N

# create anti-aliased noise
noise = builder.createNode('aanoise')
displace.setInput(3, noise, 0) # connect to displace value input

# get displacement_globals
displacement_globals = hou.node(builder.path() + '/displacement_globals')
noise.setInput(0, displacement_globals, 0) # connect P to pos

# ----- end displacement -----

# --- begin displacement bounds -----

# create a properties node
properties = builder.createNode("properties", "displacement_bounds");

# get parmTemplateGroup
g = properties.parmTemplateGroup()

# get Displacement Bounds ParmTemplate 
# hou.properties.parmTemplate(class_name, parm_name) → hou.ParmTemplate
displacement_bounds = hou.properties.parmTemplate("mantra", "vm_displacebound")

# create Shading Folder and add displacement bounds
shading = hou.FolderParmTemplate("Shading", "Shading")
shading.addParmTemplate(displacement_bounds) # add displacement bounds
g.append(shading) # add folder to parmTemplateGroup
properties.setParmTemplateGroup(g) # apply changes

# set displacement bounds to 1
properties.parm("vm_displacebound").set(1)

# get material output_collect
output = hou.node(builder.path() + '/output_collect')
output.setInput(2, properties, 0) # connect displacement_bounds to output

# ----- end displacement bounds -----

# ----- begin displacement layer output -----

# create layer pack vop
layerpack = builder.createNode('layerpack');
layerpack.setInput(3, displace, 0)
layerpack.setInput(4, displace, 1)

# create parameter
displacement_layer = builder.createNode('parameter');
displacement_layer.parm("parmname").set("layer") # set name
displacement_layer.parm("parmlabel").set("Shader Layer") # set label
displacement_layer.parm("exportparm").set(1) # set to Always
displacement_layer.parm("parmtype").set(41) # set to Struct
displacement_layer.parm("parmtypename").set("ShaderLayer") # set struct default
displacement_layer.parm("useownexportcontext").set(True) # use own export context
displacement_layer.parm("exportcontext").set("displace") # export in Displacement Context

# connect to layer pack
displacement_layer.setFirstInput(layerpack, 0)

# ----- end displacement layer output -----

# organize nodes
builder.layoutChildren()
builder.setColor(hou.Color((1, 0, 0)))
builder.setName('red_anti_aliased_noise', unique_name=True)

# --- Made in Houdini 18.5 ---             
                            `}
                    </Python>
                </Section>
                <Words><strong>References:</strong></Words>
                <Words><Link text="Houdini 16 Masterclass - Custom Shading" url="https://www.youtube.com/watch?v=e14kHmVSbIc" /> ─ SideFX Youtube</Words>
                <Words><Link text="How to add a layer output to your custom material" url="https://www.sidefx.com/docs/houdini/shade/build.html#layeroutput" /> ─ SideFX Docs</Words>
            </Lesson>
        </Page >
    )
};


