import React, { Component, useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import { Button, Icon, Loader, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { useApp } from "../App";
import * as CSS from 'csstype';

type maxWidth = Pick<CSS.Properties, "maxWidth">["maxWidth"]

export interface IWideBlogSectionProps {
    max_width?: maxWidth;
}

const StyledWideBlogSection = styled.div<{ darkTheme: boolean; max_width?: maxWidth }>`
    @media (max-width: 728px) {
        border-radius: 0px;
    }

    // size minus padding, or 100% on mobile
    // --container_width: min((42rem - (21px * 2)), 100%);
    --container_width: 100%;
    --max_width: ${props => !!props.max_width ? props.max_width : "100vw"};  
    --gutter_width: calc((100vw - var(--container_width)) / 2);
    --max_gutter_width: calc( max(var(--max_width) - var(--container_width), 0px) / 2);
    --negative_margin: calc(min(var(--gutter_width), var(--max_gutter_width)) * -1);
    ${props => !!props.max_width ? `max-width: ${props.max_width};` : ""}
    margin-right: var(--negative_margin);
    margin-left: var(--negative_margin);
    margin-top: 28px;
    margin-bottom: 28px;
    padding: 24px 16px;
    position: relative;   
`




export const WideBlogSection: React.FC<IWideBlogSectionProps> = ({ children, max_width }) => {
    const { darkTheme } = useApp();


    return (
        <StyledWideBlogSection  {...{ max_width, darkTheme }}>
            {children}
        </StyledWideBlogSection>
    )
};
