import React, { useReducer, useState } from 'react';
import { Vex } from '../../../Vex/VexBlock';
import { config, dimension, distance, DistanceMetric, distanceMetrics, INoiseConfig, INoiseState, InputArrays, NoiseFunction, noiseOptions } from './NoiseConfig';
import { DropdownSingle } from '../../../Form/DropdownSingle';
import { CheckboxSingle } from '../../../Form/CheckboxSingle';
import styled from 'styled-components';
import { useApp } from '../../../App';
import { FreqAmpOffset } from './Components/FreqAmpOffset';
import { TurbRoughAttenChannels } from './Components/TurbRoughAtten';
import { PeriodChannel } from './Components/PeriodChannel';
import { OutNoise } from './Components/OutNoise';
import { Position } from './Components/Position';
import { NoiseVEXFunction } from './Components/NoiseVEXFunction';
import { JitterChannel } from './Components/JitterChannel';
import { CellularVariables } from './Components/CellularVariables';
import { NoiseComment, NoiseCommentString } from './Components/NoiseComment';
import { CellularFunctions } from './Components/CellularFunctions';
import { InputSingle } from '../../../Form/InputSingle';
import { FunctionHeader } from './Components/FunctionHeader';
import { FBmPeriodicVariables } from './Components/FBmPeriodicVariables';


const initialState: INoiseState = {
    noise: "Perlin",
    input: "3D",
    output: "1D",
    periodic: false,
    turbulent: false,
    distanceMetric: "Euclidian",
    distance: "F1",
    fBm: false,
    curlSimplex: false,
    curl2D: false,
    channelPrefix: "",
    functionName: "",
    useFunction: false
};


type Action =
    | { type: 'NOISE', payload: { noise: NoiseFunction } }
    | { type: 'INPUT', payload: { input: dimension } }
    | { type: 'OUTPUT', payload: { output: dimension } }
    | { type: 'PERIODIC', payload: { periodic: boolean } }
    | { type: 'TURBULENT', payload: { turbulent: boolean } }
    | { type: 'DISTANCE_METRIC', payload: { distanceMetric: DistanceMetric } }
    | { type: 'DISTANCE', payload: { distance: distance } }
    | { type: 'FBM', payload: { fBm: boolean } }
    | { type: 'CURL_SIMPLEX', payload: { curlSimplex: boolean } }
    | { type: 'CURL_2D', payload: { curl2D: boolean } }
    | { type: 'CHANNEL_PREFIX', payload: { channelPrefix: string } }
    | { type: 'USE_FUNCTION', payload: { useFunction: boolean } }
    | { type: 'FUNCTION_NAME', payload: { functionName: string } }

const StyledGenerator = styled.div<{ darkTheme: boolean; }>`
    // grid-template-columns: repeat(auto-fit, minmax(512px, 1fr));
    display: flex;
    flex-wrap: wrap-reverse;
    box-shadow: rgba(0, 0, 24, 0.18) 0px 5px 15px;
    // overflow: hidden;
    border-radius: 12px;
    background: #1e1e1e;
    & .controls {
        flex-basis: 600px;
        flex-grow: 1;
        flex-shrink: 1; 
        background: #3a3a3a;
        border-radius: 12px;
        padding: 1em 1em 0.25em;
        margin: 0px;
        // display: flex;
        // justify-content: center;
        & .wrap {
            // margin: auto;
            // max-width: 700px;

        }
        & .header {
            color: white;
            padding: 8px 8px 0px;
            font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
            // text-transform: uppercase;
            font-size: 21px;
            margin-bottom: 16px !important;
        }
        & .grid {
            display: grid;
            grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
            grid-gap: 1em;
            margin-bottom: 1em;
            & .folder {
                flex-grow: 1;
                padding: 8px 16px;
            }

        }
        & .border {
            border: 1px solid #ffffff80;
            border-radius: 8px;
        }

    }
`

const reducer: React.Reducer<INoiseState, Action> = (state: INoiseState, action: Action): INoiseState => {
    switch (action.type) {
        case 'NOISE':
            return {
                noise: action.payload.noise,
                input: (() => {
                    let inputItem = config[action.payload.noise].input.find(_ => _.name == state.input);
                    // if found and not disabled don't change
                    if (inputItem != undefined && !inputItem.disabled) return state.input;
                    // otherwise default to 3D
                    else return "3D" as dimension;
                })(),
                output: (() => {
                    let outputItem = config[action.payload.noise].output.find(_ => _.name == state.output);
                    // if found and not disabled don't change - addition: and if not coming back from curl noise
                    if (outputItem != undefined && !outputItem.disabled && state.noise != "Curl") return state.output;
                    // or default to 3D
                    else if (action.payload.noise === "Curl") return "3D" as dimension;
                    // or 1D
                    else return "1D" as dimension;
                })(),
                periodic: state.periodic,
                turbulent: (() => {
                    // if switching to a turbulent noise and fBm is on - turn turbulence off
                    if (config[action.payload.noise].turbulence && state.fBm) return false;
                    else return state.turbulent
                })(),
                distanceMetric: state.distanceMetric,
                distance: state.distance,
                fBm: state.fBm,
                curlSimplex: state.curlSimplex,
                curl2D: (() => {
                    if (action.payload.noise == "Curl") {
                        if (state.input === "2D") return true;
                        else if (state.input === "4D") return false;
                        else return state.curl2D
                    }
                    else return state.curl2D
                })(),
                channelPrefix: state.channelPrefix,
                useFunction: state.useFunction,
                functionName: state.functionName
            };
        case 'INPUT':
            return { ...state, input: action.payload.input };
        case 'OUTPUT':
            return { ...state, output: action.payload.output };
        case 'PERIODIC':
            return { ...state, periodic: action.payload.periodic };
        case 'TURBULENT':
            return {
                ...state,
                turbulent: action.payload.turbulent,
                // if turbulence changed to true turn off fbm
                fBm: action.payload.turbulent ? false : state.fBm
            };
        case 'DISTANCE_METRIC':
            return { ...state, distanceMetric: action.payload.distanceMetric };
        case 'DISTANCE':
            return { ...state, distance: action.payload.distance };
        case 'FBM':
            return {
                ...state,
                fBm: action.payload.fBm,
                // if is turb noise and fBm changed to true  turn off turbulence
                turbulent: (config[state.noise].turbulence && action.payload.fBm) ? false : state.turbulent
            };
        case 'CURL_SIMPLEX':
            return { ...state, curlSimplex: action.payload.curlSimplex };
        case 'CURL_2D':
            return {
                ...state,
                curl2D: action.payload.curl2D,
                input: (() => {
                    if (action.payload.curl2D == true) {
                        if (state.input === "4D") return "3D"
                        else return state.input
                    }
                    else {
                        if (state.input === "2D") return "3D"
                        else return state.input
                    }
                })()
            };
        case 'CHANNEL_PREFIX':
            return { ...state, channelPrefix: action.payload.channelPrefix };
        case 'USE_FUNCTION':
            return { ...state, useFunction: action.payload.useFunction };
        case 'FUNCTION_NAME':
            return { ...state, functionName: action.payload.functionName };
        default:
            // return state;
            throw new Error();
    }
}

export const Generator: React.FC = (props) => {
    const { darkTheme } = useApp();
    const [state, dispatch] = useReducer<React.Reducer<INoiseState, Action>>(reducer, initialState);


    interface IDropdownLookups {
        noise: NoiseFunction[];
        input: dimension[];
        output: dimension[];
        distanceMetrics: DistanceMetric[];
        distances: distance[];
    }

    const Lookups: IDropdownLookups = {
        noise: ["Perlin", "Simplex", "Original Perlin", "Sparse Convolution", "Alligator", "Worley", "Flow", "Voronoi", "Curl"],
        input: ["1D", "2D", "3D", "4D"],
        output: ["1D", "3D"],
        distanceMetrics: ["Euclidian", "Manhattan", "Chebyshev"],
        distances: ["F1", "F2-F1"]
    }

    const handleDropdownChange = (id: string, value: number) => {
        if (id === "noise") dispatch({ type: "NOISE", payload: { noise: Lookups.noise[value] } });
        else if (id === "input") dispatch({ type: "INPUT", payload: { input: Lookups.input[value] } });
        else if (id === "output") dispatch({ type: "OUTPUT", payload: { output: Lookups.output[value] } });
        else if (id === "distanceMetric") dispatch({ type: "DISTANCE_METRIC", payload: { distanceMetric: Lookups.distanceMetrics[value] } });
        else if (id === "distance") dispatch({ type: "DISTANCE", payload: { distance: Lookups.distances[value] } });
    }
    const handleCheckboxChange = (id: string, value: boolean) => {
        if (id === "turbulent") dispatch({ type: "TURBULENT", payload: { turbulent: value } });
        else if (id === "periodic") dispatch({ type: "PERIODIC", payload: { periodic: value } });
        else if (id === "fBm") dispatch({ type: "FBM", payload: { fBm: value } });
        // curl
        else if (id === "curlSimplex") dispatch({ type: "CURL_SIMPLEX", payload: { curlSimplex: value } });
        else if (id === "curl2D") dispatch({ type: "CURL_2D", payload: { curl2D: value } });
        else if (id === "useFunction") dispatch({ type: "USE_FUNCTION", payload: { useFunction: value } });
    }
    const handleInputChange = (id: string, value: string) => {
        if (id === "channel_prefix") dispatch({ type: "CHANNEL_PREFIX", payload: { channelPrefix: value } });
        else if (id === "function_name") dispatch({ type: "FUNCTION_NAME", payload: { functionName: value } });
    }

    const distanceMetricsDropdown = () => {
        return (state.noise === "Worley" ? < DropdownSingle
            label="Distance"
            id="distanceMetric"
            value={Lookups.distanceMetrics.indexOf(state.distanceMetric)}
            setValue={handleDropdownChange}
            options={distanceMetrics}
            dark /> : <></>)
    }

    const distancesDropdown = () => {
        let outputVal = config[state.noise].distances
        return (outputVal ? < DropdownSingle
            label="Value"
            id="distance"
            value={Lookups.distances.indexOf(state.distance)}
            setValue={handleDropdownChange}
            options={outputVal}
            dark
        /> : <></>)
    }
    const periodicToggle = () => {
        return config[state.noise].periodic ?
            <CheckboxSingle
                id="periodic"
                label="Periodic"
                value={state.periodic}
                setValue={handleCheckboxChange}
                dark
            /> : <></>
    }
    const turbulenceToggle = () => {
        return config[state.noise].turbulence ?
            <CheckboxSingle
                id="turbulent"
                label="Built-in Turbulence"
                value={state.turbulent}
                setValue={handleCheckboxChange}
                dark
            /> : <></>
    }
    const curlToggles = () => {
        return state.noise === "Curl" ?
            <>
                <CheckboxSingle
                    id="curlSimplex"
                    label="Simplex Based"
                    value={state.curlSimplex}
                    setValue={handleCheckboxChange}
                    dark
                />
                <CheckboxSingle
                    id="curl2D"
                    label="2D Curl"
                    value={state.curl2D}
                    setValue={handleCheckboxChange}
                    dark
                />
            </>
            : <></>
    }

    const getInputOptions = () => {
        if (state.noise === "Curl") {
            if (state.curl2D) return InputArrays.twoAndThree
            else return InputArrays.threeAndFour
        }
        else return config[state.noise].input
    }

    const flowVariable = (<>
        {state.useFunction ?
            <>
                <Vex.Line>
                    <Vex.Channel type="float" var="flow" string={`${state.channelPrefix}flow`}></Vex.Channel>
                </Vex.Line>
            </>
            :
            <Vex.Line>
                <Vex.Code type>{'float '}</Vex.Code>
                <Vex.Code>{'flow = @Time;'}</Vex.Code>
            </Vex.Line>
        }
    </>)

    return (
        <StyledGenerator {...{ darkTheme }}>
            <Vex styles={{ margin: "0px !important", borderRadius: "12px", flexGrow: 1, flexBasis: 600, flexShrink: 999 }}>
                <FunctionHeader dimension={state.output} useFunction={state.useFunction} functionName={state.functionName} />
                <FreqAmpOffset useFunction={state.useFunction} channelPrefix={state.channelPrefix} />

                {(config[state.noise].periodic && state.periodic) && <PeriodChannel dimension={state.input} channelPrefix={state.channelPrefix} />}
                {(state.noise === "Flow") && flowVariable}
                {(state.noise === "Voronoi") && <JitterChannel inputDimension={state.input} />}
                {((config[state.noise].turbulence && state.turbulent) || state.fBm) && <TurbRoughAttenChannels fBm={state.fBm} turbulent={state.turbulent} channelPrefix={state.channelPrefix} />}

                {state.fBm ? <>
                    <Vex.TurbulenceLoop
                        outputDimension={state.output}
                    >
                        <FBmPeriodicVariables inputDimension={state.input} tabs={1} channelPrefix={state.channelPrefix} />
                        <NoiseComment tabs={1} periodic={state.periodic} noise={state.noise} inputDimension={state.input} outputDimension={state.output} turbulent={state.turbulent} distanceMetric={state.distanceMetric} curl2D={state.curl2D} />
                        <CellularVariables tabs={1} inputDimension={state.input} noise={state.noise} />
                        <Position noise={state.noise} dimension={state.input} tabs={1} useFunction={state.useFunction} channelPrefix={state.channelPrefix} />
                        <CellularFunctions tabs={1} noise={state.noise} inputDimension={state.input} distanceMetric={state.distanceMetric} periodic={state.periodic} useFunction={state.useFunction} fBm={state.fBm} />
                        <NoiseVEXFunction
                            tabs={1}
                            fBm={state.fBm}
                            periodic={state.periodic}
                            distanceMetric={state.distanceMetric}
                            noise={state.noise}
                            curlSimplex={state.curlSimplex}
                            curl2D={state.curl2D}
                            inputDimension={state.input}
                            outputDimension={state.output}
                            turbulent={state.turbulent}
                            distance={state.distance}
                            useFunction={state.useFunction}
                        />
                    </Vex.TurbulenceLoop>


                </> : <>
                    <Vex.Line />
                    <NoiseComment periodic={state.periodic} noise={state.noise} inputDimension={state.input} outputDimension={state.output} turbulent={state.turbulent} distanceMetric={state.distanceMetric} curl2D={state.curl2D} />
                    <CellularVariables inputDimension={state.input} noise={state.noise} />
                    <Position noise={state.noise} dimension={state.input} useFunction={state.useFunction} channelPrefix={state.channelPrefix} />
                    <CellularFunctions noise={state.noise} inputDimension={state.input} distanceMetric={state.distanceMetric} periodic={state.periodic} useFunction={state.useFunction} fBm={state.fBm} />
                    <NoiseVEXFunction
                        fBm={state.fBm}
                        periodic={state.periodic}
                        distanceMetric={state.distanceMetric}
                        noise={state.noise}
                        curlSimplex={state.curlSimplex}
                        curl2D={state.curl2D}
                        inputDimension={state.input}
                        outputDimension={state.output}
                        turbulent={state.turbulent}
                        distance={state.distance}
                        useFunction={state.useFunction}
                    />
                </>}


                <OutNoise dimension={state.output} useFunction={state.useFunction} functionName={state.functionName} />

            </Vex>
            <div className="controls">
                <div className="wrap">


                    <p className="header">{`${state.fBm ? "Turbulent" : ""} ${NoiseCommentString({
                        periodic: state.periodic,
                        noise: state.noise,
                        inputDimension: state.input,
                        outputDimension: state.output,
                        turbulent: state.turbulent,
                        distanceMetric: state.distanceMetric,
                        curl2D: state.curl2D
                    })}`}</p>
                    <div className="grid">
                        <div className="folder border">

                            <DropdownSingle
                                label="Noise Type"
                                id="noise"
                                value={Lookups.noise.indexOf(state.noise)}
                                setValue={handleDropdownChange}
                                options={noiseOptions}
                                dark />
                            <DropdownSingle
                                label="Input"
                                id="input"
                                value={Lookups.input.indexOf(state.input)}
                                setValue={handleDropdownChange}
                                options={getInputOptions()}
                                dark />
                            <DropdownSingle
                                label="Output"
                                id="output"
                                value={Lookups.output.indexOf(state.output)}
                                setValue={handleDropdownChange}
                                options={config[state.noise].output}
                                dark />
                            <CheckboxSingle
                                id="fBm"
                                label="Add Turbulence"
                                value={state.fBm}
                                setValue={handleCheckboxChange}
                                dark
                            />


                        </div>
                        <div className="folder border">
                            {distanceMetricsDropdown()}
                            {distancesDropdown()}
                            {turbulenceToggle()}
                            {periodicToggle()}
                            {curlToggles()}

                        </div>
                    </div>
                    <div className="grid border">
                        <div className="folder">
                            <InputSingle
                                id="channel_prefix"
                                label="Ch Prefix"
                                placeholder="Channel prefix..."
                                value={state.channelPrefix}
                                setValue={handleInputChange}
                                dark
                            />
                        </div>
                        <div className="folder">

                            <InputSingle
                                id="function_name"
                                label="Function Name"
                                placeholder="Function name..."
                                value={state.functionName}
                                setValue={handleInputChange}
                                dark
                                disabled={!state.useFunction}
                            />
                            <CheckboxSingle
                                id="useFunction"
                                label="Make Into Function"
                                value={state.useFunction}
                                setValue={handleCheckboxChange}
                                dark
                            />

                        </div>
                    </div>
                </div>
            </div>

        </StyledGenerator >
    )
};
