import React from 'react';
import { Lesson, OrderedList, Page, Words } from '../../Chapter/Chapter';
import { ImageContainer, Img } from '../../Images/Images';
import { Link } from '../../Chapter/Link/Link';
import { Section } from '../Section';
import { Python } from '../Hom/Python';
import { Code, Tip } from '../../CodeBlock/CodeBlock';


export const DisplacementBounds: React.FC = (props) => {
    return (
        <Page topic="rendering">
            <Lesson title="Displacement Bounds">
                <Section title="Principled Shader HScript Expression" divider={false}>
                    <Words>The principled shader has this long HScript expression in the displacement bounds parameter to calculate it</Words>
                    <Python>
                        {`
1.01*ch("dispInput_enable")*ch("dispInput_max") + ch("dispTex_enable")*max(abs((1.0+ch("dispTex_offset"))*ch("dispTex_scale")), abs(ch("dispTex_offset")*ch("dispTex_scale"))) + ch("dispNoise_enable")*abs(ch("dispNoise_amp"))
                            `}
                    </Python>
                    <Words>It's hard to read so here it is broken out. It sums the 3 different displacement sources. If the source is enabled with the checkbox it is multiplied by 1, otherwise it's multiplied by 0 and has no effect</Words>
                    <Python>
                        {`
# manually entered max input
1.01*ch("dispInput_enable")*ch("dispInput_max") 

# texture scale
+ ch("dispTex_enable")*max(
    abs((1.0+ch("dispTex_offset"))*ch("dispTex_scale")),
    abs(ch("dispTex_offset")*ch("dispTex_scale"))
    ) 

# noise amp
+ ch("dispNoise_enable")*abs(ch("dispNoise_amp"))
                            `}
                    </Python>
                    <Words>The texture part is tricky to understand, so here are the functions plotted out. It chooses the max of the two</Words>
                    <ImageContainer>
                        <Img ratio={0.5625} src="https://houdini-stuff.s3.amazonaws.com/VEX/graphs.mantra_ipr.0224.jpg" />
                    </ImageContainer>
                    <Words>To visualize further, each dude represents the range of possible values in a displacement texture map (0 - 1). They are plotted at various offsets to show how the displacement bounds function handles the highest and lowest possible values. The default offset of -0.5 returns a displacement bounds of 0.5. Offset values higher than -0.5 use the blue function to handle high values. Offset values lower than -0.5 use the red function to handle the lowest values, which would otherwise be clipped by the inner bounds (mirrored green).</Words>
                    <ImageContainer>
                        <Img ratio={0.5625} src="https://houdini-stuff.s3.amazonaws.com/VEX/graphs.displacement_range.jpg" />
                    </ImageContainer>
                    <Words>After looking at the function plotted out, I realized it could also be written as: <Code>bounds = abs(offset + 0.5) + 0.5;</Code> </Words>
                    <Python>
                        {`
# texture scale
+ ch("dispTex_enable")*(abs(ch("dispTex_offset") + 0.5) + 0.5)*ch("dispTex_scale")
                            `}
                    </Python>
                    <Tip>The offset is the same as an Add Const on the input value before getting multiplied by scale</Tip>
                    {/* <Words>I'm still trying to understand this. After some tests with the principled shader and the built-in noise it seems like you can use displacement bounds much lower than what's calculated by the HScript. </Words> */}
                </Section>
                <Section title="Material Builder Displacement Bounds" divider={false}>
                    <Words>To specify displacement bounds from a material builder: Add a <Code>properties</Code> vop. Go to Edit Rendering Parameters... Find Displacement Bound <Code>(vm_displacementbound)</Code> and add it. Wire the output into any of the inputs of the <Code>collect_output</Code> vop.</Words>

                </Section>
                {/* <Section title="Noise Displacement" divider={false}>
                    <Python>
                        {`
# create material subnet
builder = hou.node("/mat").createNode("materialbuilder");

# add principled shader
principled_shader = builder.createNode('principledshader::2.0');

# ----- begin surface -----

# add surface exports
surface_exports = builder.createNode('surfaceexports');

# wire principled shader layer output to surface exports layer input
surface_exports.setInput(0, principled_shader, 2)

# get surface output
surface_output = hou.node(builder.path() + '/surface_output')

# wire surface exports to surface output (Cf, Of, F)
surface_output.setInput(0, surface_exports, 0)
surface_output.setInput(1, surface_exports, 1)
surface_output.setInput(4, surface_exports, 2)

# ----- end surface -----

# ----- begin displacement -----

# create displace node
displace = builder.createNode('displace')

# get displacement output
displacement_output = hou.node(builder.path() + '/displacement_output')

# wire displace outputs to displacement_output inputs (outP, outN, P, N)
displacement_output.setInput(0, displace, 0)
displacement_output.setInput(1, displace, 1)

# create anti-aliased noise
noise = builder.createNode('aanoise')

# wire noise output to displace value input
displace.setInput(0, noise, 3)

# get displacement_globals
displacement_globals = hou.node(builder.path() + '/displacement_globals')

# wire displacement_globals P output to noise pos input
noise.setInput(0, displacement_globals, 0)

# create parameter for displacement bounds
displacement_bound = builder.createNode('parameter')


# ----- end displacement -----

# organize nodes
builder.layoutChildren()
builder.setColor(hou.Color((0, 0, 1)))
builder.setName('texture_displacement', unique_name=True)
                            `}
                    </Python>
                </Section> */}
                <Words><strong>References:</strong></Words>
                <Words><Link text="Houdini 16 Masterclass - Custom Shading" url="https://www.youtube.com/watch?v=e14kHmVSbIc" /> ─ SideFX Youtube</Words>
                <Words><Link text="Displacement Bounds" url="https://www.sidefx.com/forum/topic/3119/?page=1#post-13389" /> ─ SideFX Forum</Words>

            </Lesson>
        </Page >
    )
};
